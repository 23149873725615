import React, { type PropsWithChildren } from 'react';
import {
	DEFAULT_GAS_CHANNEL,
	type NonPrivacySafeContext,
	type FiltersAnalyticsContext,
} from '../events';
import { AnalyticsListener } from '@atlaskit/analytics-next';
import { type SupportedContext } from '..';

type SearchDialogAnalyticContextProps = PropsWithChildren<{
	/**
	 * Either a map of additional attributes or a callback that returns a map of additional attributes.
	 * This is called whenever a relevant event is fired. Adds the additional attributes or the output of the callback to the attributes of the event
	 */
	analyticContext: SupportedContext | (() => SupportedContext);
	nonPrivacySafeAnalyticContext?: NonPrivacySafeContext | {};
	nonPrivacySafeAnalyticContextGenerator?: () => NonPrivacySafeContext | {};
	filterAnalyticsContext?: FiltersAnalyticsContext | {};
}>;

export const SearchDialogAnalyticsContext = ({
	analyticContext,
	nonPrivacySafeAnalyticContext = {},
	filterAnalyticsContext = {},
	nonPrivacySafeAnalyticContextGenerator,
	children,
}: SearchDialogAnalyticContextProps) => {
	return (
		<AnalyticsListener
			channel={DEFAULT_GAS_CHANNEL}
			onEvent={(event) => {
				const additionalAttributes: SupportedContext =
					typeof analyticContext === 'function' ? analyticContext() : analyticContext;

				const nonPrivacySafeAttributes: NonPrivacySafeContext | {} =
					nonPrivacySafeAnalyticContextGenerator !== undefined
						? nonPrivacySafeAnalyticContextGenerator()
						: nonPrivacySafeAnalyticContext;

				event.update((payload) => {
					const filters = {
						...payload.attributes?.filters,
						...filterAnalyticsContext,
					};
					// Operational event not support nonPrivacySafeAttributes
					const nonPrivacySafeAttributesPayload =
						payload.eventType === 'operational'
							? {}
							: {
									nonPrivacySafeAttributes: {
										...payload?.nonPrivacySafeAttributes,
										...nonPrivacySafeAttributes,
									},
								};
					return {
						...payload,
						attributes: {
							...payload.attributes,
							...additionalAttributes,
							filters: Object.keys(filters).length > 0 ? filters : undefined,
						},
						...nonPrivacySafeAttributesPayload,
					};
				});
			}}
		>
			{children}
		</AnalyticsListener>
	);
};
