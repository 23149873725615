/**
 * This file provides the necessary wrappers and utility functions to provide
 * analytics data for Quick Find result positions. Quick Find analytics contains
 * data such as the number of results, the number of sections, and the
 * position / section of a selected result.
 *
 * It does this by using the DOM as the source of truth rather than having to
 * maintain section objects and indexes in the code. This allows rendering
 * display components to be simplified and could prove to be more robust, e.g.
 * if a search result doesn't render due to an error and subsequently hits
 * its error boundary, section and results count remain correct.
 *
 * There is however the requirement that all heading and results components
 * are siblings in the DOM tree.
 */

import React from 'react';

export const SEARCH_DIALOG_HEADING_CLASS = 'search-dialog-heading';
export const SEARCH_DIALOG_RESULT_CLASS = 'search-dialog-result';

type SearchDialogHeaderAnalyticsWrapperProps = {
	id: string;
};

const resultTypes = [
	'peopleAndTeams',
	'recentQueries',
	'recentActivities',
	'queryFilters',
	'searchResults',
] as const;
export type ResultType = (typeof resultTypes)[number];

type SearchDialogResultAnalyticsWrapperProps = {
	resultType: ResultType;
	product?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const SearchDialogHeaderAnalyticsWrapper = React.forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<SearchDialogHeaderAnalyticsWrapperProps>
>(({ children, id, ...rest }, ref) => {
	return (
		// Ignoring this check as class is not being used for styling
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
		<div className={SEARCH_DIALOG_HEADING_CLASS} data-section-id={id} ref={ref} {...rest}>
			{children}
		</div>
	);
});

export const SearchDialogResultAnalyticsWrapper = React.forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<SearchDialogResultAnalyticsWrapperProps>
>(({ children, resultType, product, ...rest }, ref) => {
	return (
		<div
			// Ignoring this check as class is not being used for styling
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className={SEARCH_DIALOG_RESULT_CLASS}
			data-result-type={resultType}
			data-product={product}
			ref={ref}
			{...rest}
		>
			{children}
		</div>
	);
});

export const getProductsInResults = () => {
	const resultNodes = Array.from(document.getElementsByClassName(SEARCH_DIALOG_RESULT_CLASS));

	const productSet = new Set<string>();
	for (let i = 0; i < resultNodes.length; i++) {
		const product = resultNodes[i].getAttribute('data-product');
		product ? productSet.add(product) : null;
	}

	const productsInResults = Array.from(productSet);
	return productsInResults;
};

export const getResultTypeCounts: () => Record<ResultType, number> = () => {
	const resultNodes = Array.from(document.getElementsByClassName(SEARCH_DIALOG_RESULT_CLASS));

	return resultTypes.reduce(
		(o, resultType) => {
			o[resultType] =
				resultNodes.filter((node) => node.getAttribute('data-result-type') === resultType).length ||
				0;
			return o;
		},
		{} as Record<ResultType, number>,
	);
};

const getAllHeadingsAndResults = () => {
	const headingNodes = Array.from(document.getElementsByClassName(SEARCH_DIALOG_HEADING_CLASS));
	const resultNodes = Array.from(document.getElementsByClassName(SEARCH_DIALOG_RESULT_CLASS));

	if (!headingNodes.length || !resultNodes.length) {
		return {
			allNodes: [],
			headingNodes: [],
			resultNodes: [],
		};
	}

	const parentNode = headingNodes.length
		? headingNodes[0].parentNode
		: resultNodes.length
			? resultNodes[0].parentNode
			: undefined;

	if (!parentNode) {
		throw new Error('Could not find a parent node for headings and results');
	}

	for (const node of [...headingNodes, ...resultNodes]) {
		if (node.parentNode !== parentNode) {
			throw new Error('All headings and results do not share a parent');
		}
	}

	const allNodes = Array.from(parentNode.children).filter((e) => isHeading(e) || isResult(e));

	return {
		allNodes,
		headingNodes,
		resultNodes,
	};
};

const isHeading = (e: Element): e is HTMLElement => {
	return e.classList.contains(SEARCH_DIALOG_HEADING_CLASS);
};

const isResult = (e: Element): e is HTMLElement => {
	return e.classList.contains(SEARCH_DIALOG_RESULT_CLASS);
};

export const getSectionsAndResultsCount = () => {
	try {
		const { allNodes, headingNodes, resultNodes } = getAllHeadingsAndResults();

		// If the first node is a result, then we count it as a section
		const sectionCount = allNodes.length
			? headingNodes.length + (isResult(allNodes[0]) ? 1 : 0)
			: 0;

		return {
			sectionCount,
			resultCount: resultNodes.length,
		};
	} catch (e) {
		return {};
	}
};

export const getNodePosition = (targetNode: Element) => {
	try {
		const { sectionCount, resultCount } = getSectionsAndResultsCount();
		const { allNodes } = getAllHeadingsAndResults();

		// If the first node is a result, then we count it as a section
		let sectionIndex = allNodes.length ? (isResult(allNodes[0]) ? 0 : -1) : -1;
		let sectionId = '';

		// Initialise variables to -1 as when we encounter a node,
		// we increment to represent the current index of the node
		let resultIndex = -1;
		let indexWithinSection = -1;

		for (const node of allNodes) {
			if (isHeading(node)) {
				sectionIndex++;
				sectionId = node.dataset.sectionId || '';
				indexWithinSection = -1;
			}

			if (isResult(node)) {
				resultIndex++;
				indexWithinSection++;
			}

			if (node === targetNode) {
				break;
			}
		}

		return {
			sectionId,
			sectionCount,
			resultCount,
			sectionIndex,
			globalIndex: resultIndex,
			indexWithinSection: indexWithinSection,
		};
	} catch (e) {
		return {};
	}
};
