import type { GraphQLError } from 'graphql';

export class GraphQLNoResultsWithErrorResponseError extends Error {
	graphQLErrors: ReadonlyArray<GraphQLError>;

	constructor(graphQLErrors: ReadonlyArray<GraphQLError>) {
		super();
		this.name = 'GraphQLNoResultsWithErrorResponseError';
		this.graphQLErrors = graphQLErrors;
		this.message = 'GraphQL returned errors and no results';
	}
}
