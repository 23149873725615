import React from 'react';

import { PeopleLink } from '@confluence/people-directory';
import { Link } from '@confluence/route-manager';

const isNewTabClick = (e: React.MouseEvent<Element>) => e && (e.metaKey || e.shiftKey || e.ctrlKey);

interface Props {
	children?: React.ReactNode;
	href?: string;
	target?: '_blank' | '_self' | '_parent' | '_top';
	className?: string;
}

export const getResultLinkComponent = (closeDrawer: () => void): React.ComponentType<Props> =>
	class ResultLinkComponent extends React.Component<Props> {
		handleClick = (event: React.MouseEvent<Element>) => {
			if (closeDrawer && !isNewTabClick(event)) {
				closeDrawer();
			}
		};

		getLinkComponent = (href: string | null | undefined) =>
			href && href.startsWith('/people/') ? PeopleLink : Link;

		render() {
			const { children, href, target, className } = this.props;
			const LinkComponent = this.getLinkComponent(href);
			return (
				<LinkComponent
					href={href}
					onClick={this.handleClick}
					target={target}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={className}
				>
					{children}
				</LinkComponent>
			);
		}
	};
