import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const containerStyles = xcss({
	marginInlineStart: 'space.100',
	marginInlineEnd: 'space.100',
});

const resultStyles = xcss({
	paddingBlockStart: 'space.075',
	paddingBlockEnd: 'space.075',
	paddingInlineStart: 'space.100',
	paddingInlineEnd: 'space.100',
	borderRadius: 'border.radius.200',
	display: 'flex',
	alignItems: 'center',
	pointerEvents: 'none',
});

const iconAreaStyles = xcss({
	width: 'size.200',
	height: 'size.200',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const iconStyles = xcss({
	width: 'size.200',
	height: 'size.200',
	backgroundColor: 'color.skeleton',
	borderRadius: 'border.radius.100',
});

const middleAreaStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	minWidth: '0',
	flex: 1,
	marginLeft: 'space.150',
});

const titleStyles = xcss({
	backgroundColor: 'color.skeleton',
	borderRadius: 'border.radius.100',
	height: '16px',
	width: '40%',
});

const metadataStyles = xcss({
	color: 'color.text.subtlest',
	marginTop: 'space.050',
	display: 'flex',
	alignItems: 'center',
	backgroundColor: 'color.skeleton',
	width: '20%',
	borderRadius: 'border.radius.100',
	height: '13px',
});

export const SearchResultSkeleton = () => {
	return (
		<Box xcss={containerStyles}>
			<Box xcss={[resultStyles]}>
				<Box xcss={iconAreaStyles}>
					<Box xcss={iconStyles} />
				</Box>
				<Box xcss={middleAreaStyles}>
					<Box xcss={[titleStyles]}></Box>
					<Box xcss={[metadataStyles]}></Box>
				</Box>
			</Box>
		</Box>
	);
};
