import type { ComponentType } from 'react';
import React, { useContext } from 'react';

import type { RoutesContextType } from './RoutesContext';
import { RoutesContext } from './RoutesContext';

/**
 * XXX For function components, please use `useContext(RoutesContext)` instead!
 *
 * XXX If you're chaining `withRoutesContext` with other higher-order components
 * and you're encoutering TypeScript errors:
 * 1. If you're wrapping a function component, you should rather be using
 *    `useContext(RoutesContext)` instead!
 * 2. Or be explicit and tell `withRoutesContext` the type `P`.
 * 3. Or be explicit and tell other HOCs the types that they're inferring.
 *
 * Implements a higher-order component which provides `RoutesContext` to a
 * specific `WrappedComponent` as props.
 */
export function withRoutesContext<P>(WrappedComponent: ComponentType<P & RoutesContextType>) {
	function WithRoutesContext(props: P & Partial<Omit<RoutesContextType, keyof P>>) {
		const routesContext = useContext(RoutesContext);
		return <WrappedComponent {...routesContext} {...props} />;
	}

	WithRoutesContext.displayName = `withRoutesContext(${
		WrappedComponent.displayName || WrappedComponent.name
	})`;

	return WithRoutesContext;
}
