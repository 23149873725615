import { SimpleTag as Tag, type TagColor } from '@atlaskit/tag';
import React from 'react';

import { cssMap } from '@atlaskit/css';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { SearchResultContent } from '@atlassian/search-dialog';
import { ResultAvatar } from '../../../common/result-avatar';
import { defaultResultRenderer } from '../../../extensible/search-result/default-result-renderer';
import { type ResultRendererArgs } from '../../product-router';
import { ProductStates } from '../../product-state-machine/product-state-machine-types';
import { ResultIcon } from './result-icon';

/**
 * This function renders a component search result using the default component offered
 * by the search platform. Specifically, the SearchResultContent is used.
 *
 * We override this to use different size icons for pre query and post query states
 */
export const componentResultRenderer = (args: ResultRendererArgs) => {
	const { productState, searchResult } = args;

	const iconSize = productState === ProductStates.PreQuerySuccess ? 'small' : 'medium';
	const iconType = searchResult.icon?.toString() ?? '';

	return defaultResultRenderer({
		...args,
		searchResult: {
			...searchResult,
			icon: <ResultIcon type={iconType} size={iconSize} />,
		},
	});
};

/**
 * This function renders a people or team search result using the default component offered
 * by the search platform. Specifically, the SearchResultContent is used.
 *
 * We override this to use different size icons for pre query and post query states
 */
export const peopleAndTeamsResultRenderer = (args: ResultRendererArgs) => {
	const { productState, searchResult } = args;

	const iconSize = productState === ProductStates.PreQuerySuccess ? 'xsmall' : 'small';

	return defaultResultRenderer({
		...args,
		searchResult: {
			...searchResult,
			icon: (
				<ResultAvatar
					borderColor="transparent"
					src={searchResult.iconUrl}
					appearance="square"
					size={iconSize}
					testId="result-avatar"
				/>
			),
		},
	});
};

const MethodDetails: { [key: string]: { abbreviation: string; color: TagColor } } = {
	POST: { abbreviation: 'POST', color: 'purpleLight' },
	GET: { abbreviation: 'GET', color: 'greenLight' },
	PUT: { abbreviation: 'PUT', color: 'blueLight' },
	DELETE: { abbreviation: 'DEL', color: 'redLight' },
	PATCH: { abbreviation: 'PATCH', color: 'greyLight' },
	OPTIONS: { abbreviation: 'OPT', color: 'greyLight' },
	HEAD: { abbreviation: 'HEAD', color: 'greyLight' },
	TRACE: { abbreviation: 'TRACE', color: 'greyLight' },
	CONNECT: { abbreviation: 'CONN', color: 'greyLight' },
};

const styles = cssMap({
	methodTag: { marginLeft: token('space.negative.050', '-4px') },
});

export const endpointResultRenderer = (args: ResultRendererArgs) => {
	const { searchResult } = args;
	const method = (typeof searchResult.icon === 'string' ? searchResult.icon : '').toUpperCase();
	const methodDetails = MethodDetails[method];

	const methodTag = (
		<Box xcss={styles.methodTag}>
			{methodDetails ? (
				<Tooltip content={methodDetails.abbreviation}>
					<Tag text={methodDetails.abbreviation} color={methodDetails.color} />
				</Tooltip>
			) : (
				<Tooltip content={'OTHER'}>
					<Tag text={'OTHER'} color="greyLight" />
				</Tooltip>
			)}
		</Box>
	);

	const title = <Box paddingInlineStart="space.300">{searchResult.title}</Box>;

	return (
		<SearchResultContent
			key={`item_${searchResult.id}`}
			isKeyboardHighlighted={args.isKeyboardHighlighted}
			title={title}
			icon={methodTag}
			isCollapsed={args.isCollapsed}
			meta={searchResult.meta}
		/>
	);
};
