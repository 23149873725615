import React, { useCallback, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import LightbulbIcon from '@atlaskit/icon/core/lightbulb';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { getRovoTip, setRovoTip } from '@atlassian/search-common';
import { EnlargedReturnIconContainer, RemoveIcon } from '@atlassian/search-dialog';

import { usePrimaryProduct, useUser } from '../../../../../controllers/store';

import { messages } from './messages';

const RovoTipMessageStyle = xcss({
	width: '95%',
	marginLeft: 'space.050',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	paddingLeft: 'space.050',
});

const RovoTipContainerStyle = xcss({
	paddingBottom: 'space.075',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});

const RovoTipStyle = xcss({
	display: 'flex',
	width: '100%',
	alignItems: 'center',
	position: 'relative',
	boxSizing: 'border-box',
	minHeight: `${token('space.500', '40px')}`,
	paddingLeft: 'space.150',
	paddingRight: 'space.100',
	borderRadius: `${token('space.050', '4px')}`,
	backgroundColor: 'color.background.accent.purple.subtlest',
	color: 'color.text.accent.purple',
});

export const RovoTipBanner = () => {
	const [showRovoTip, setShowRovoTip] = useState<boolean>(false);

	const [primaryProduct] = usePrimaryProduct();
	const [user] = useUser();

	useEffect(() => {
		if (!primaryProduct || !user || !user.id) {
			return;
		}

		const rovoTipFromLocalStorage = getRovoTip(primaryProduct, user.id);
		setShowRovoTip(rovoTipFromLocalStorage);
	}, [primaryProduct, user]);

	const dismissRovoTip = useCallback(() => {
		if (!primaryProduct || !user || !user.id) {
			return;
		}

		const rovoTipFromLocalStorage = setRovoTip(primaryProduct, user.id);
		setShowRovoTip(rovoTipFromLocalStorage);
	}, [primaryProduct, user]);

	if (!showRovoTip) {
		return null;
	}

	return (
		<Box xcss={RovoTipContainerStyle}>
			<Box xcss={RovoTipStyle}>
				<LightbulbIcon label="blank" color={token('color.text.accent.purple', '#5E4DB2')} />
				<Box xcss={RovoTipMessageStyle}>
					<b>
						<FormattedMessage {...messages.rovoTip} />
					</b>{' '}
					<FormattedMessage {...messages.rovoBannerMessage} />
				</Box>
				<EnlargedReturnIconContainer>
					<IconButton
						icon={RemoveIcon}
						spacing={'compact'}
						onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
							event.preventDefault();
							event.stopPropagation();
							dismissRovoTip();
						}}
						label={<FormattedMessage {...messages.dismissBannerLabel} />}
						appearance={'subtle'}
					/>
				</EnlargedReturnIconContainer>
			</Box>
		</Box>
	);
};
