import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { type SearchResultSection } from '../..';
import {
	type UserTeamConfig,
	usePeopleAndTeamsQuery,
} from '../../../common/clients/people-and-teams-supplier';
import { type SiteFilter } from '../../../extensible/filters/filter-types';
import { messages } from './messages';
import {
	type PostQuerySupplierArgs,
	type PreQuerySupplierArgs,
	type SearchItems,
} from '../../product-router';
import { useDefaultSuppliers } from '../../result-supplier';
import type { ExtensibleTabProps } from '../extensible-tab-types';
import { MAX_ITEMS_PER_SECTION, getSections } from './sections';
import { type AggregatorTownsquareResponse, TownsquareScope } from './types';
import getAtlasViewAllRedirectUrl, {
	createProfileLink,
	createTeamProfileLink,
} from './url-generators';

export type TownsquareTabProps = ExtensibleTabProps & {
	userTeamConfig?: UserTeamConfig;
	organizationId?: string;
	atlasClientBaseUrlOverride?: string;
};

export const TOWNSQUARE_PRODUCT_ID = 'townsquare';

const sectionSortOrder: string[] = [
	TownsquareScope.TownsquareProject,
	TownsquareScope.TownsquareGoal,
	TownsquareScope.People,
	TownsquareScope.Teams,
	TownsquareScope.TownsquareTag,
	TownsquareScope.TownsquareHelpLink,
	TownsquareScope.TownsquareLearning,
];

/**
 * Sorting sections to match the order defined in the array {sectionSortOrder}
 * While this should not happen, if the section is not part of the array, it is considered to be at the end.
 */
const sortSections = (sectionA: SearchResultSection, sectionB: SearchResultSection) => {
	const indexA = sectionSortOrder.indexOf(sectionA.id);
	const indexB = sectionSortOrder.indexOf(sectionB.id);

	if (indexA === -1) {
		return 1;
	}
	if (indexB === -1) {
		return -1;
	}

	return indexA - indexB;
};

export const useTownsquareSuppliers = ({
	userTeamConfig,
	preQueryItemSupplier,
	postQueryItemSupplier,
	workspaces,
	organizationId,
	isGlobalExperienceWorkspace,
}: Pick<
	TownsquareTabProps,
	| 'userTeamConfig'
	| 'preQueryItemSupplier'
	| 'postQueryItemSupplier'
	| 'workspaces'
	| 'organizationId'
> & { isGlobalExperienceWorkspace?: boolean }) => {
	const intl = useIntl();
	const orgId = organizationId ?? userTeamConfig?.context.organizationId;
	const itemSuppliers = useDefaultSuppliers<AggregatorTownsquareResponse, TownsquareScope>(
		TOWNSQUARE_PRODUCT_ID,
		getSections(intl, workspaces, isGlobalExperienceWorkspace, orgId),
		{
			preQueryItemSupplier,
			postQueryItemSupplier,
		},
	);

	// If we are in a global experience workspace, we need to set the product key to 'people'
	if (userTeamConfig) {
		userTeamConfig.context.productKey = isGlobalExperienceWorkspace
			? 'people'
			: userTeamConfig?.context.productKey;
	}

	const peopleAndTeamSupplier = usePeopleAndTeamsQuery(
		{
			people: {
				id: TownsquareScope.People,
				title: intl.formatMessage(messages.townsquare_people_heading),
				viewAllLinkGenerator: (query) =>
					getAtlasViewAllRedirectUrl(
						'people',
						query,
						workspaces,
						isGlobalExperienceWorkspace,
						orgId,
					),
				viewAllLinkRenderer: () => <FormattedMessage {...messages.townsquare_people_view_all} />,
			},
			teams: {
				id: TownsquareScope.Teams,
				title: intl.formatMessage(messages.townsquare_teams_heading),
				viewAllLinkGenerator: (query) =>
					getAtlasViewAllRedirectUrl(
						'teams',
						query,
						workspaces,
						isGlobalExperienceWorkspace,
						orgId,
					),
				viewAllLinkRenderer: () => <FormattedMessage {...messages.townsquare_teams_view_all} />,
			},
		},
		MAX_ITEMS_PER_SECTION,
	);

	const preQueryItemSupplierWithPeopleAndTeams = async (
		args: PreQuerySupplierArgs,
	): Promise<SearchItems> => {
		const existingSections = await itemSuppliers.preQueryItemSupplier!(args);
		const people = userTeamConfig
			? await peopleAndTeamSupplier('', {
					resolveUserUrl: (userId: string) =>
						createProfileLink(userId, isGlobalExperienceWorkspace, orgId),
					resolveTeamUrl: (teamId: string) =>
						createTeamProfileLink(teamId, isGlobalExperienceWorkspace, orgId),
					...userTeamConfig,
				})
			: null;

		const unsortedSections = [...existingSections.sections, ...(people?.sections ?? [])];

		return {
			size: unsortedSections.length,
			sections: unsortedSections.sort(sortSections),
		};
	};

	const postQueryItemSupplierWithPeopleAndTeams = async (
		args: PostQuerySupplierArgs,
	): Promise<SearchItems> => {
		const existingSections = await itemSuppliers.postQueryItemSupplier!(args);
		const { filters } = args;
		const selectedWorkspace = filters
			.filter((filter) => filter['@type'] === 'sites')
			.map((filter) => (filter as SiteFilter).values[0])
			.filter((val) => val)
			.shift();

		const people = userTeamConfig
			? await peopleAndTeamSupplier(args.query, {
					resolveUserUrl: (userId: string) =>
						createProfileLink(userId, isGlobalExperienceWorkspace, orgId),
					resolveTeamUrl: (teamId: string) =>
						createTeamProfileLink(teamId, isGlobalExperienceWorkspace, orgId),
					...userTeamConfig,
					context: {
						...userTeamConfig.context,
						siteId: selectedWorkspace || userTeamConfig.context.siteId,
					},
				})
			: null;

		const unsortedSections = [...existingSections.sections, ...(people?.sections ?? [])];

		return {
			size: unsortedSections.length,
			sections: unsortedSections.sort(sortSections),
		};
	};

	const preQuerySectionTitleGenerator = (section: SearchResultSection) => {
		switch (section.id) {
			case TownsquareScope.TownsquareProject:
				return intl.formatMessage(messages.townsquare_recent_projects);
			case TownsquareScope.TownsquareGoal:
				return intl.formatMessage(messages.townsquare_recent_goals);
			case TownsquareScope.TownsquareHelpLink:
				return intl.formatMessage(messages.townsquare_recent_help_links);
			case TownsquareScope.TownsquareTag:
				return intl.formatMessage(messages.townsquare_recent_topics);
			case TownsquareScope.TownsquareLearning:
				return intl.formatMessage(messages.townsquare_recent_learnings_risks_decisions);
			case TownsquareScope.People:
				return intl.formatMessage(messages.townsquare_recent_people);
			default:
				return '';
		}
	};

	return {
		preQueryItemSupplier: preQueryItemSupplierWithPeopleAndTeams,
		postQueryItemSupplier: postQueryItemSupplierWithPeopleAndTeams,
		preQuerySectionTitleGenerator,
	};
};
