import { type GasPayload } from '@atlaskit/analytics-gas-types';

export const onAdvancedSearchLinkSelected = ({
	attributes,
	nonPrivacySafeAttributes,
	actionSubjectId,
}: {
	attributes: Record<string, any>;
	nonPrivacySafeAttributes: Record<string, any>;
	actionSubjectId: string;
}): GasPayload => ({
	eventType: 'track',
	action: 'selected',
	actionSubject: 'advancedSearchLink',
	actionSubjectId,
	attributes,
	nonPrivacySafeAttributes,
});
