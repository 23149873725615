import React, { type PropsWithChildren, Suspense } from 'react';

import { TimeLazyLoad } from '../../../common/lazy-load-timing';
import { LoadingSpinner } from '../../../common/loading-spinner';
import { type ProductContextProps, withProductContext } from '../product-router';
import { type SearchDialogProductProps } from './search-dialog-product';
import { InitialisationExperienceSuccess } from './initialisation-experience-success';
import { ActiveProduct } from './active-product';

export class AsyncProductInner extends React.Component<
	PropsWithChildren<SearchDialogProductProps & ProductContextProps>
> {
	componentWillUnmount() {
		const { removeProduct, id } = this.props;
		removeProduct(id);
	}

	render() {
		const { fallback: FallbackComponent, id, children } = this.props;

		return (
			<Suspense
				fallback={
					<ActiveProduct id={id}>
						<TimeLazyLoad product={id}>
							{FallbackComponent ? <FallbackComponent /> : <LoadingSpinner />}
						</TimeLazyLoad>
					</ActiveProduct>
				}
			>
				<InitialisationExperienceSuccess {...this.props} />
				{children}
			</Suspense>
		);
	}
}

/**
 * A component to register the product with a product router and asyncronously load its contents
 */
export const AsyncProduct = withProductContext(AsyncProductInner);
