import React, { type PropsWithChildren, useContext, useMemo } from 'react';
import {
	type BackendExperiment,
	getFrontendExperiment,
	getMutuallyExclusiveExperiment,
	STATSIG_QUICK_FIND_LAYER,
	type FrontendExperiments,
} from '@atlassian/search-experiment';

export const ExperimentProvider = ({
	quickFindEnabled,
	children,
}: PropsWithChildren<{ quickFindEnabled?: boolean }>) => {
	const layerName = quickFindEnabled ? STATSIG_QUICK_FIND_LAYER : undefined;
	const { backendExperiment, frontendExperiment } = useMemo(
		() => getMutuallyExclusiveExperiment(layerName),
		[layerName],
	);

	let frontendExperiments;

	if (quickFindEnabled) {
		const exampleFrontendExperiment = getFrontendExperiment({ abTestId: 'fe_search_experiment' });
		frontendExperiments = {
			// the mutually exclusive frontend experiment
			...(frontendExperiment?.abTestId && {
				[frontendExperiment.abTestId]: frontendExperiment,
			}),
			// add other frontend experiments that can be run alongside backend experiments here
			[exampleFrontendExperiment.abTestId]: exampleFrontendExperiment,
		};
	}

	return (
		<ExperimentContext.Provider value={{ backendExperiment, frontendExperiments }}>
			{children}
		</ExperimentContext.Provider>
	);
};

type ExperimentContext = {
	backendExperiment: BackendExperiment;
	frontendExperiments: FrontendExperiments;
};

const ExperimentContext = React.createContext<ExperimentContext>({
	backendExperiment: undefined,
	frontendExperiments: undefined,
});

export const useExperiments = () => {
	return useContext(ExperimentContext);
};
