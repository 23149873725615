/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import React, { type FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { messages } from '../../messages';
// eslint-disable-next-line import/no-internal-modules
import Button from '@atlaskit/button/new';

export interface FeedbackButtonProps {
	onClick: (e: React.KeyboardEvent | React.MouseEvent) => any;
}

const buttonStyles = css({ margin: '0 auto', width: 'fit-content' });

export const FeedbackButton: FunctionComponent<FeedbackButtonProps> = ({ onClick }) => {
	return (
		<div css={buttonStyles}>
			<Button onClick={onClick} appearance="subtle">
				<FormattedMessage {...messages.common_give_feedback} />
			</Button>
		</div>
	);
};
