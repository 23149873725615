import React from 'react';
import { FormattedDate, FormattedMessage, FormattedRelativeTime } from 'react-intl-next';
import { messages } from '../../../messages';

const SECONDS_IN_MIN = 60;
const SECONDS_IN_HR = SECONDS_IN_MIN * 60;
const SECONDS_IN_DAY = SECONDS_IN_HR * 24;

const WithUpdatedLabel = (formattedDate: string) => {
	const i18nMessage = messages.common_updated_date_formatter;

	return <FormattedMessage {...i18nMessage} values={{ date: formattedDate }} />;
};

export function dateFormatter(lastModified: string) {
	const secondsSinceUpdate = Math.floor((Date.now() - new Date(lastModified).getTime()) / 1000);

	const totalDays = Math.floor(secondsSinceUpdate / SECONDS_IN_DAY);
	const totalHours = Math.floor(secondsSinceUpdate / SECONDS_IN_HR);
	const totalMinutes = Math.floor(secondsSinceUpdate / SECONDS_IN_MIN);
	if (secondsSinceUpdate > 1 && secondsSinceUpdate < 60) {
		return (
			<FormattedRelativeTime value={-secondsSinceUpdate} style="narrow" unit="second">
				{WithUpdatedLabel}
			</FormattedRelativeTime>
		);
	}

	if (totalMinutes >= 1 && totalMinutes < 60) {
		return (
			<FormattedRelativeTime value={-totalMinutes} style="narrow" unit="minute">
				{WithUpdatedLabel}
			</FormattedRelativeTime>
		);
	}

	if (totalHours >= 1 && totalHours < 24) {
		return (
			<FormattedRelativeTime value={-totalHours} style="narrow" unit="hour">
				{WithUpdatedLabel}
			</FormattedRelativeTime>
		);
	}

	if (totalDays >= 1 && totalDays < 8) {
		return (
			<FormattedRelativeTime value={-totalDays} style="narrow" unit="day">
				{WithUpdatedLabel}
			</FormattedRelativeTime>
		);
	}

	return (
		<FormattedDate year="numeric" month="short" day="2-digit" value={lastModified}>
			{WithUpdatedLabel}
		</FormattedDate>
	);
}
