import React, { type FC } from 'react';
import { InputBoxOverlayRight } from './search-input.styled';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';

import type { SearchCSS } from '../theme';
import { EnlargedClearIconWrapper } from './search-input-enlarged.styled';

type EnlargedSearchInputOverlayProps = {
	allowChangeExpand?: (expand: boolean) => void;
	onClear?: () => void;
	theme: SearchCSS;
	showClearButton?: boolean;
	clearSearchLabel?: string;
	isExpanded: boolean;
};

export const EnlargedSearchInputOverlay: FC<EnlargedSearchInputOverlayProps> = ({
	theme,
	showClearButton,
	onClear,
	allowChangeExpand,
	clearSearchLabel,
	isExpanded,
}) => {
	return (
		<InputBoxOverlayRight>
			{showClearButton && (
				<EnlargedClearIconWrapper
					{...theme}
					role="button"
					onClick={onClear}
					onMouseEnter={() => {
						allowChangeExpand?.(false);
					}}
					onMouseLeave={() => {
						allowChangeExpand?.(true);
					}}
					tabIndex={0}
					isExpanded={isExpanded}
				>
					<EditorCloseIcon
						color="currentColor"
						label={clearSearchLabel || 'Clear search session'}
						LEGACY_size="medium"
						spacing="spacious"
					/>
				</EnlargedClearIconWrapper>
			)}
		</InputBoxOverlayRight>
	);
};
