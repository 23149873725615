import { type SearchItemsTransformerProps } from '../../../product-router/product/result-provider/result-provider-types';
import { useQuery } from '../../../query-context';
import {
	filterAllSectionItems,
	highlightPostQueryResultTitles,
} from '../../../product-router/product/result-provider/faster-search-helpers';

const RECENTLY_VIEWED_ITEMS_SHOWN = 10;

/**
 * This component takes care of filtering results matching the entered query.
 */
export const QueryFilterTransformer: React.FC<SearchItemsTransformerProps> = ({
	preQueryItems,
	postQueryItems,
	productState,
	children,
}) => {
	const { query } = useQuery();

	const filteredPreQueryItems = filterAllSectionItems(
		{
			sections: preQueryItems.sections,
			size: preQueryItems.size,
		},
		query,
		RECENTLY_VIEWED_ITEMS_SHOWN,
	);

	const highlightedPostQueryItems = highlightPostQueryResultTitles(postQueryItems, query);

	return children({
		preQueryItems: filteredPreQueryItems,
		postQueryItems: highlightedPostQueryItems,
		productState,
	});
};
