import { useMemo } from 'react';

import { useSelectFilter } from '../../filters/base-select-filter';
import type { SelectedQueryFilter } from '../../store/quick-find';
import {
	CONTRIBUTOR_FILTER_QUERY_KEYWORDS,
	filter as contributorFilter,
} from '../../store/search/filters/contributor';

/**
 * Returns an object mapping filter keywords to their corresponding filter id
 * @returns
 */
export const useFilterKeywordToFilterId = () => {
	return useMemo(() => {
		const contributorFilterMapping = Object.fromEntries(
			CONTRIBUTOR_FILTER_QUERY_KEYWORDS.map((keyword) => [keyword, contributorFilter.id]),
		);

		return {
			...contributorFilterMapping,
		};
	}, []);
};

/**
 * Returns an object mapping filter keywords to their corresponding filter actions in the store
 * @returns
 */
export const useFilterKeywordToFilterActions = () => {
	const contributorFilterFromStore = useSelectFilter({ filterId: contributorFilter.id });

	return useMemo(() => {
		const contributorFilterMapping = Object.fromEntries(
			CONTRIBUTOR_FILTER_QUERY_KEYWORDS.map((keyword) => [keyword, contributorFilterFromStore[1]]),
		);

		return {
			...contributorFilterMapping,
		};
		// Only want to make this object once as we only need access to the actions
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

/**
 * Given a query and a list of filter keywords, determine if the user is using
 * a query filter. If so, return the filter keyword they are using.
 * @param query
 * @param filterKeywords
 * @returns
 */
export const findQueryFilterKeyword = (query: string, filterKeywords: string[]) => {
	for (const keyword of filterKeywords) {
		if (query.includes(keyword)) {
			return keyword;
		}
	}
};

/**
 * Given a query and a filter keyword, return the query text after the filter keyword.
 * @param query
 * @param inQueryFiltersKey
 * @returns
 */
export const getFilterLookupQuery = (query: string, filterKeyword: string) => {
	if (!query.includes(filterKeyword)) {
		return;
	}

	const lookupQueryIndexStart = query.lastIndexOf(filterKeyword) + filterKeyword.length;
	const lookupQuery = query.substring(lookupQueryIndexStart);

	return lookupQuery;
};

/**
 * Given a query and a list of selected query filters, return the query text
 * without the display text of the selected query filters.
 */
export const getQueryWithoutSelectedQueryFilters = (
	inputQuery: string,
	selectedQueryFilters: SelectedQueryFilter[],
) => {
	let filteredQuery = inputQuery;

	for (const selectedQueryFilter of selectedQueryFilters) {
		filteredQuery = filteredQuery.replace(selectedQueryFilter.displayText, '');
	}

	return filteredQuery;
};

/**
 * Given a query, a filter keyword, and a display text, return a query with
 * the display text populated correct.
 *
 * e.g "test @Jeff" -> "test @Jeffrey Quach"
 */
export const getInputQueryWithNewSelectedQueryFilter = (
	inputQuery: string,
	keyword: string,
	displayText: string,
) => {
	// Find the last occurrence of the keyword
	const keywordIndex = inputQuery.lastIndexOf(keyword);

	return inputQuery.substring(0, keywordIndex) + keyword + displayText;
};
