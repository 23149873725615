import { type Languages } from '../i18n/languages';
import english_messages from '../i18n/en';

type LanguageCode = keyof Languages;

export type LangCode =
	| LanguageCode
	| 'en_ZZ'
	| 'pt-BR'
	| 'pt-PT'
	| 'th'
	| 'tr'
	| 'uk'
	| 'vi'
	| 'zh_TW';

export const ENGLISH_LOCALES = ['en', 'en-US', 'en-GB'];

async function getMessages(locale: LangCode) {
	let messages;
	switch (locale) {
		case 'cs':
			messages = import('../i18n/cs');
			break;
		case 'da':
			messages = import('../i18n/da');
			break;
		case 'de':
			messages = import('../i18n/de');
			break;
		case 'en':
			messages = import('../i18n/en');
			break;
		case 'en_GB':
			messages = import('../i18n/en_GB');
			break;
		case 'en_ZZ':
			messages = import('../i18n/en_ZZ');
			break;
		case 'es':
			messages = import('../i18n/es');
			break;
		case 'et':
			messages = import('../i18n/et');
			break;
		case 'fi':
			messages = import('../i18n/fi');
			break;
		case 'fr':
			messages = import('../i18n/fr');
			break;
		case 'hu':
			messages = import('../i18n/hu');
			break;
		case 'is':
			messages = import('../i18n/is');
			break;
		case 'it':
			messages = import('../i18n/it');
			break;
		case 'ja':
			messages = import('../i18n/ja');
			break;
		case 'ko':
			messages = import('../i18n/ko');
			break;
		case 'nb':
			messages = import('../i18n/nb');
			break;
		case 'nl':
			messages = import('../i18n/nl');
			break;
		case 'pl':
			messages = import('../i18n/pl');
			break;
		case 'pt_BR':
			messages = import('../i18n/pt_BR');
			break;
		case 'pt_PT':
			messages = import('../i18n/pt_PT');
			break;
		case 'ro':
			messages = import('../i18n/ro');
			break;
		case 'ru':
			messages = import('../i18n/ru');
			break;
		case 'sk':
			messages = import('../i18n/sk');
			break;
		case 'sv':
			messages = import('../i18n/sv');
			break;
		case 'th':
			messages = import('../i18n/th');
			break;
		case 'tr':
			messages = import('../i18n/tr');
			break;
		case 'uk':
			messages = import('../i18n/uk');
			break;
		case 'vi':
			messages = import('../i18n/vi');
			break;
		case 'zh':
			messages = import('../i18n/zh');
			break;
		case 'zh_TW':
			messages = import('../i18n/zh_TW');
			break;
		default:
			throw new Error('Unknown locale: ' + locale);
	}
	return (await messages).default;
}

/**
 * Tries to get the most specific messages bundle for a given locale.
 *
 * Strategy:
 * 1. Try to find messages with the exact string (i.e. 'fr_FR')
 * 2. If that doesn't work, try to find messages for the country locale (i.e. 'fr')
 * 3. If that doesn't work, return english messages as a fallback.
 *
 * @param locale string specifying the locale like 'en_GB', or 'fr'.
 */
export const getMessagesForLocale = async (locale: LangCode) => {
	locale = locale.replace('-', '_') as LangCode;
	try {
		return await getMessages(locale);
	} catch (e) {
		// ignore
	}

	try {
		const parentLocale = locale.split(/[-_]/)[0] as LangCode;

		return await getMessages(parentLocale);
	} catch (e) {
		// ignore
	}

	return english_messages;
};
