import { useCallback } from 'react';
import { useFeaturesContext } from '../../features-context';
import { useQueryParams } from '../../query-params-context';
import { type AdvancedSearchUrlGenerator } from '../product-router-state';

export const useGenerateAdvancedSearchUrl = (genUrl?: AdvancedSearchUrlGenerator) => {
	const { isGriffinNavEnabled } = useFeaturesContext();
	const { queryParams } = useQueryParams();

	return useCallback(
		(query: string, baseUrl?: string, usage?: 'onInputEnter' | 'onInputEnterModified') => {
			const advancedSearchUrl = genUrl?.(query, baseUrl, usage) || '';
			if (isGriffinNavEnabled) {
				const urlParts = advancedSearchUrl.split('?');
				// Omit text and originalQuery from the query params
				// originalQuery is used to support AutoCorrection in FPS and we don't want it
				// to persist between search queries
				const { text, originalQuery, ...rest } = queryParams || {};
				return `${urlParts[0]}?${new URLSearchParams({ ...rest, ...(query !== '' && { text: query }) }).toString()}`;
			}
			return advancedSearchUrl;
		},
		[genUrl, isGriffinNavEnabled, queryParams],
	);
};
