import React from 'react';
import { Query } from 'react-apollo';

import { CurrentSpaceQuery } from './CurrentSpaceQuery.graphql';
import type {
	CurrentSpaceQuery as CurrentSpaceQueryType,
	CurrentSpaceQueryVariables,
} from './__types__/CurrentSpaceQuery';

export interface CurrentSpaceData {
	loading: boolean;
	currentSpace?: CurrentSpaceContextDataPayload;
}

export interface CurrentSpaceContextDataPayload {
	id: string | undefined;
	spaceName: string | undefined;
	iconUrl: string | undefined;
	spaceKey: string | undefined;
}

export const CurrentSpaceContext = ({
	children,
	spaceKey,
}: {
	children: (data: CurrentSpaceData) => JSX.Element;
	spaceKey: any;
}) => (
	<Query<CurrentSpaceQueryType, CurrentSpaceQueryVariables>
		query={CurrentSpaceQuery}
		variables={{ spaceKey }}
	>
		{({ data, loading }) => {
			const id = data?.space?.id;
			const spaceName = data?.space?.name;
			const iconUrl = data?.space?.icon?.path;
			const currentSpace =
				id && spaceName && iconUrl
					? {
							id,
							spaceName,
							spaceKey,
							iconUrl,
						}
					: undefined;
			return children({
				loading,
				currentSpace,
			});
		}}
	</Query>
);
