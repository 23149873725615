import { type SearchResultProps } from '@atlassian/search-dialog';
import { useCallback } from 'react';
import {
	onSearchResultContext,
	onSearchResultHighlighted,
	onSearchResultSelected,
	type ScreenType,
	useAnalytics,
} from '../../common/analytics';

interface SearchResultAnalyticsProps extends Pick<SearchResultProps, 'onHighlighted' | 'onSelect'> {
	screen: ScreenType;
	actionSubject: string;
}

export interface SearchResultAnalyticsCallbacks {
	onHighlightCallback: () => void;
	onSelectCallback: (e: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => void;
	onContextMenuCallback: (e: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => void;
}

/**
 * Returns callbacks which fire analytic events which should be triggered on
 * various user actions on a search result.
 */
export const useSearchResultAnalyticsCallbacks = ({
	onHighlighted,
	onSelect,
	screen,
	actionSubject,
}: SearchResultAnalyticsProps): SearchResultAnalyticsCallbacks => {
	const { fireAnalyticsEvent } = useAnalytics();

	const onHighlightCallback = useCallback(() => {
		fireAnalyticsEvent(
			onSearchResultHighlighted({
				screen,
				actionSubject,
			}),
		);

		onHighlighted?.();
	}, [actionSubject, fireAnalyticsEvent, onHighlighted, screen]);

	const onSelectCallback = useCallback(
		(e: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
			fireAnalyticsEvent(
				onSearchResultSelected({
					screen,
					actionSubject,
				}),
			);

			onSelect?.(e);
		},
		[actionSubject, fireAnalyticsEvent, onSelect, screen],
	);

	const onContextMenuCallback = useCallback(
		(e: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
			fireAnalyticsEvent(
				onSearchResultContext({
					screen,
					actionSubject,
				}),
			);
		},
		[actionSubject, fireAnalyticsEvent, screen],
	);

	return {
		onHighlightCallback,
		onSelectCallback,
		onContextMenuCallback,
	};
};
