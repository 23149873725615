import type React from 'react';
import { type WrappedComponentProps, injectIntl } from 'react-intl-next';

import { messages } from '../../messages';

import { RECENT_SEARCHES_SECTION_ID } from '../sections/recent-search-section';

import { type SearchItemsTransformerProps } from '../../../product-router/product/result-provider/result-provider-types';

/**
 * This component adds a title to the prequery recently viewed section if necessary.
 */
export const PreQueryRecentlyViewedTransformerWithIntl: React.FC<
	SearchItemsTransformerProps & WrappedComponentProps
> = ({ intl, preQueryItems, postQueryItems, productState, children }) => {
	const newPreQueryItemsSections = preQueryItems.sections.map((section, i) => {
		if (section.id === RECENT_SEARCHES_SECTION_ID || section.searchResults.length === 0) {
			return section;
		} else {
			const newSection = {
				...section,
				title: intl.formatMessage(messages.quick_find_recently_viewed),
			};

			return newSection;
		}
	});

	preQueryItems.sections = newPreQueryItemsSections;

	return children({
		preQueryItems,
		postQueryItems,
		productState,
	});
};

export const PreQueryRecentlyViewedTransformer: React.FC<SearchItemsTransformerProps> = injectIntl(
	PreQueryRecentlyViewedTransformerWithIntl,
);
