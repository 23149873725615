import React, { Suspense, useEffect } from 'react';
import { type WrappedComponentProps, injectIntl } from 'react-intl-next';

import { useAnalytics } from '../../../../common/analytics';
import { messages } from './messages';

import { Flex } from '@atlaskit/primitives';

const Lozenge = React.lazy(() =>
	import(
		/* webpackPrefetch: true */
		/* webpackChunkName: "@atlaskit-internal_@atlassian/lozenge" */ '@atlaskit/lozenge'
	).then((module) => {
		return {
			default: module.default,
		};
	}),
);

interface UpsellTabProps {
	title: string;
}

const UpsellTabWithIntl: React.FC<UpsellTabProps & WrappedComponentProps> = ({ intl, title }) => {
	const { fireAnalyticsEvent } = useAnalytics();

	useEffect(() => {
		fireAnalyticsEvent({
			eventType: 'track',
			action: 'exposed',
			actionSubject: 'compassSearchCrossFlowExperiment',
			source: 'searchDialog',
		});
	}, [fireAnalyticsEvent]);

	return (
		<Flex alignItems="center" columnGap="space.100" testId="compass-upsell-modal-header">
			{title}
			<Suspense fallback={null}>
				<Lozenge appearance="new">
					{intl.formatMessage(messages.compass_upsell_tab_lozenge)}
				</Lozenge>
			</Suspense>
		</Flex>
	);
};

export const UpsellTab = injectIntl(UpsellTabWithIntl);
