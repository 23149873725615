import React from 'react';
import { EmptyState } from '@atlassian/search-dialog';

import { type WrappedComponentProps, injectIntl, type MessageDescriptor } from 'react-intl-next';
import { messages } from '../../messages';

const Image = React.lazy(
	() =>
		import(
			/* webpackChunkName: "@atlaskit-internal_product-search-dialog/async-chunk/prequery-image" */ './image'
		),
);

const NoPreQueryResults: React.FunctionComponent<
	WrappedComponentProps & {
		message?: MessageDescriptor;
	}
> = ({ intl, message }) => (
	<EmptyState
		title={intl.formatMessage(message || messages.common_empty_state_prequery_heading)}
		Image={Image}
	/>
);
export default injectIntl(NoPreQueryResults);
