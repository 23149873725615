// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { N900, N500, N20, N30 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { Link } from '@atlassian/search-dialog';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AdvancedSearchContainer = styled.div({
	padding: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LabelContainer = styled.span({
	marginRight: token('space.100', '8px'),
	color: token('color.text', N900),
	'&:hover, &:focus': {
		color: token('color.text', N900),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':visited': {
		color: token('color.text', N900),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledLink = styled(Link)({
	WebkitBoxAlign: 'baseline',
	alignItems: 'baseline',
	boxSizing: 'border-box',
	display: 'inline-flex',
	fontWeight: token('font.weight.medium'),
	textAlign: 'center',
	whiteSpace: 'nowrap',
	borderRadius: '3px',
	padding: `${token('space.025', '2px')} ${token('space.150', '8px')}`,
	marginRight: token('space.050', '4px'),
	color: token('color.text.subtle', N500),
	backgroundColor: token('color.background.neutral', N20),
	transition: 'background 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	'&:hover': {
		color: token('color.text.subtle', N500),
		backgroundColor: token('color.background.neutral.hovered', N30),
		textDecoration: 'none',
		transition: 'background 0s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	},
	'&:visited, &:focus-visible, &:focus': {
		color: token('color.text.subtle', N500),
	},
});
