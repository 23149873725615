import React, { useContext, useState } from 'react';

interface RovoContext {
	isRovoEnabled?: boolean;
	setIsRovoEnabled: (isRovoEnabled: boolean) => void;
}

interface Props {
	children?: React.ReactNode;
	isEnabled?: boolean;
}

const RovoContext = React.createContext<RovoContext>({
	isRovoEnabled: false,
	setIsRovoEnabled: (isRovoEnabled: boolean) => {},
});

export const RovoContextProvider = ({ isEnabled, children }: Props) => {
	const [isRovoEnabled, setIsRovoEnabled] = useState<boolean>(isEnabled || false);

	return (
		<RovoContext.Provider value={{ isRovoEnabled, setIsRovoEnabled }}>
			{children}
		</RovoContext.Provider>
	);
};

export function useRovoContext() {
	return useContext(RovoContext);
}
