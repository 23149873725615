import { useEffect, useState } from 'react';
import { type ABTestContext, withABTestContext } from '../../ab-test-context';
import { type ProductContextProps, withProductContext } from '../product-router';
import { type SearchDialogProductProps } from './search-dialog-product';
import { initialisationExperience } from '../../../common/ufo-experiences';

export const InitialisationExperienceSuccessInner = ({
	id,
	abTest,
	getPrimaryProduct,
}: ABTestContext & SearchDialogProductProps & ProductContextProps) => {
	const [successCalled, setSuccessCalled] = useState(false);

	useEffect(() => {
		if (successCalled) {
			return;
		}

		const primaryProduct = getPrimaryProduct();

		if (abTest && primaryProduct && primaryProduct.id === id) {
			initialisationExperience.success();
			setSuccessCalled(true);
		}
	}, [abTest, getPrimaryProduct, id, successCalled]);

	return null;
};

/**
 * This component calls the success method on the UFO initialisation
 * experience when AB test data exists. There is an assumption that when
 * there is a registered primary product in the product router state, it has
 * finished loading, i.e. this component should only be rendered after any
 * asynchronous loading of a product tab.
 */
export const InitialisationExperienceSuccess = withABTestContext(
	withProductContext(InitialisationExperienceSuccessInner),
);
