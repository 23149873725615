// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { SearchFooter } from '@atlassian/search-dialog';
import Button from '@atlaskit/button';
import { N20, N900 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FeedbackLinkWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: token('color.background.neutral', N20),
	padding: `${token('space.050', '4px')} 0`,
});

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FeedbackLink = styled(Button)`
	&& {
		text-decoration: underline;
		&:hover {
			color: ${token('color.link.pressed', N900)} !important;
		}
	}
`;

const searchFooterWrapperHeight = token('space.1000', '80px');

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchFooterWrapper = styled(SearchFooter)({
	display: 'flex',
	flexDirection: 'column',
	height: searchFooterWrapperHeight,
});
