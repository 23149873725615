import FeatureGates from '@atlaskit/feature-gate-js-client';

const CONFLUENCE_REACTIVATION_EXPERIMENT = 'cc_mandalorians_confluence_reactivation_ab';

export const CONFLUENCE_REACTIVATION_EXPERIMENT_COHORT = {
	VARIATION: 'variation',
	CONTROL: 'control',
	NOT_ENROLLED: 'not-enrolled',
};

export const useConfluenceReactivationExperiment = (shouldGetExperimentEnrollment: boolean) => {
	if (shouldGetExperimentEnrollment) {
		return FeatureGates.getExperimentValue<string>(
			CONFLUENCE_REACTIVATION_EXPERIMENT,
			'cohort',
			CONFLUENCE_REACTIVATION_EXPERIMENT_COHORT.NOT_ENROLLED,
			{
				fireExperimentExposure: true,
			},
		);
	}
	return CONFLUENCE_REACTIVATION_EXPERIMENT_COHORT.NOT_ENROLLED;
};
