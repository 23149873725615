import React, { useCallback, useEffect, useMemo, useState, type ReactElement } from 'react';
import { useUserContext } from '../../common/user-context';
import {
	RECENT_SEARCH_KEY,
	getRecentSearches,
	removeRecentSearch as removeRecentSearchFromLocalStorage,
} from '@atlassian/search-common';
import { useProductContext } from '../product-router';
import { DefaultIcon } from './icons/default-icon';
import { sha256Hash } from '../../common/analytics/utils';

export const MAX_RECENT_SEARCHES_RETURNED = 50; // It can be different from the number of records in local storage

interface Props {
	Icon?: React.ReactElement;
	limit?: number;
}

export const useRecentSearches = ({
	Icon = <DefaultIcon />,
	limit = MAX_RECENT_SEARCHES_RETURNED,
}: Props) => {
	const { user } = useUserContext();
	const { getActiveProduct } = useProductContext();

	const product = getActiveProduct();

	const [recentSearchItems, setRecentSearchItems] = useState<
		{ id: string; title: string; icon: ReactElement }[]
	>([]);

	const memoizedIcon = useMemo(() => Icon, [Icon]);

	useEffect(() => {
		if (!product || !user.id) {
			return;
		}

		const recentSearches = getRecentSearches(user.id, product.id, RECENT_SEARCH_KEY);
		const searchResults = recentSearches.map((value) => ({
			id: sha256Hash(value),
			title: value,
			icon: memoizedIcon,
		}));

		if (recentSearchItems.length === 0 && searchResults.length > 0) {
			setRecentSearchItems(searchResults);
		}
	}, [product, user.id, memoizedIcon, recentSearchItems.length]);

	const removeRecentSearch = useCallback(
		(query: string) => {
			if (user?.id && product?.id) {
				const recentSearches = removeRecentSearchFromLocalStorage(
					query,
					user.id,
					product.id,
					RECENT_SEARCH_KEY,
				);
				if (recentSearches) {
					const searchResults = recentSearches.map((value) => ({
						id: sha256Hash(value),
						title: value,
						icon: memoizedIcon,
					}));
					setRecentSearchItems(searchResults);
				}
			}
		},
		[product, user.id, memoizedIcon],
	);

	return {
		recentSearchItems: recentSearchItems.slice(0, limit),
		removeRecentSearch,
	};
};
