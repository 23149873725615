import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { FocusAreaIconMap } from './main';
import type { FocusAreaIconsProps } from './types';

export const STATUS_TO_COLOR_MAP = {
	pending: token('color.icon.accent.gray'),
	paused: token('color.icon.accent.gray'),
	on_track: token('color.icon.accent.lime'),
	at_risk: token('color.icon.warning'),
	off_track: token('color.icon.accent.red'),
	completed: token('color.icon.accent.gray'),
} as const;

const iconContainer = xcss({
	flexShrink: 0,
	lineHeight: 1,
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
});

export default function FocusAreaIcons({
	size,
	level,
	status,
	testId,
	label,
}: FocusAreaIconsProps) {
	return (
		<Box xcss={iconContainer} as="span">
			{FocusAreaIconMap[level](
				status ? STATUS_TO_COLOR_MAP[status] : 'currentColor',
				size ?? 'medium',
				testId,
				label,
			)}
		</Box>
	);
}
