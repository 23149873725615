import { useIntl } from 'react-intl-next';

import type { ResultRendererArgs } from '@atlassian/product-search-dialog';
import { defaultResultRenderer } from '@atlassian/product-search-dialog';

import { i18n } from './messages';
import { ContentIconRenderer } from './IconRenderer';

export const ConfluenceResultRenderer = ({ searchResult, ...rest }: ResultRendererArgs) => {
	const { formatMessage } = useIntl();
	const { productState } = rest;

	// REST API stores recent content type as meta data
	const contentType =
		searchResult.additionalMeta && typeof searchResult.additionalMeta === 'string'
			? searchResult.additionalMeta || ''
			: (searchResult.icon as string) || '';

	// AFE does not return newer content type icons (i.e whiteboard/database) so we render it here
	const renderContentIcon = () => {
		if (searchResult?.type === 'whiteboard' || searchResult.icon === 'whiteboard') {
			return (
				ContentIconRenderer({
					type: 'whiteboard',
					title: searchResult.title,
				}) || ''
			);
		}

		if (searchResult?.type === 'database' || searchResult.icon === 'database') {
			return (
				ContentIconRenderer({
					type: 'database',
					title: searchResult.title,
				}) || ''
			);
		}

		if (searchResult?.type === 'embed' || searchResult.icon === 'embed') {
			return (
				ContentIconRenderer({
					type: 'embed',
					title: searchResult.title,
				}) || ''
			);
		}

		if (searchResult?.type === 'folder' || searchResult.icon === 'folder') {
			return (
				ContentIconRenderer({
					type: 'folder',
					title: searchResult.title,
				}) || ''
			);
		}

		return searchResult.icon;
	};

	if (productState === 'PreQuerySuccess') {
		return defaultResultRenderer({
			...rest,
			searchResult: {
				...searchResult,
				additionalMeta: undefined,
				icon: ContentIconRenderer({
					type: contentType,
					title: searchResult.title || '',
					size: 'small',
				}),
			},
		});
	}
	if (
		productState === 'PostQueryTyping' ||
		productState === 'PostQueryLoading' ||
		productState === 'PostQuerySuccess'
	) {
		return defaultResultRenderer({
			...rest,
			searchResult: {
				...searchResult,
				additionalMeta: !!searchResult.isRecentResult
					? formatMessage(i18n.recentlyViewed)
					: searchResult.additionalMeta,
				icon: !!searchResult.isRecentResult
					? ContentIconRenderer({
							type: contentType,
							title: searchResult.title || '',
						})
					: renderContentIcon(),
			},
		});
	}
	return defaultResultRenderer({ searchResult, ...rest });
};
