import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import {
	type QuickFind1PEntity,
	type ActivityItemEdge,
	type QuickFindSearchResultType,
	QuickFindDataProduct,
} from '@atlassian/search-client';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import { EventTypeMessage } from './event-type-message';
import { messages } from '../../messages';
import { ContentIconRenderer } from './content-icon-renderer';
import { ConfluenceResultMeta } from './confluence-result-meta';
import { QuickFindResultMeta, capitalizeFirstLetter } from './quick-find-meta';

const CONTEXT_PATH = '/wiki';

const labelStyles = xcss({
	marginInlineStart: 'space.050',
});

export const quickFindRecentItemResultMapper: (
	edge: ActivityItemEdge,
) => QuickFindSearchResultType = (edge) => {
	const item = edge.node;
	const data = item.object.data as QuickFind1PEntity;
	const productType = item.object.product as QuickFindDataProduct;
	switch (productType) {
		case QuickFindDataProduct.JIRA:
			return {
				id: item.object.id,
				title: `${data?.key}: ${(data?.fieldsById?.edges || [{}])[0].node?.text}` || '',
				url: data?.webUrl || '',
				icon: ContentIconRenderer({
					type: 'jira',
					title: data?.title,
				}),
				meta: <QuickFindResultMeta meta={['Jira', capitalizeFirstLetter(item.object.type)]} />,
				additionalMeta: (
					<EventTypeMessage timestamp={item.event.timestamp} eventType={item.event.eventType} />
				),
				isRecentResult: true,
				type: `${item.object.product} ${item.object.type}`,
			};
		case QuickFindDataProduct.CONFLUENCE:
			// Do NOT show drafts for live-edit pages since they're not drafts from a user perspective
			const isDraft =
				(data?.pageStatus === 'DRAFT' || data?.blogStatus === 'DRAFT') && data?.subtype !== 'LIVE';
			return {
				ari: item.object.id,
				id: item.object.id,
				title: data?.title || '',
				url: `${data?.links?.base || CONTEXT_PATH}${data?.links?.webUi}`,
				icon: ContentIconRenderer({
					type: item.object.type,
					title: data?.title,
					isDraft,
				}),
				label: isDraft ? (
					<Box xcss={labelStyles} as="span">
						<Lozenge>
							<FormattedMessage {...messages.quick_find_recent_draft_label} />
						</Lozenge>
					</Box>
				) : (
					''
				),
				meta: (
					<ConfluenceResultMeta
						resultContentType={item.object.type}
						resultAncestors={data?.ancestors}
						resultSpace={data?.space}
					/>
				),
				additionalMeta: (
					<EventTypeMessage timestamp={item.event.timestamp} eventType={item.event.eventType} />
				),
				isRecentResult: true,
				type: `${item.object.product} ${item.object.type}`,
				containerId: data?.space?.id || undefined,
			};
		case QuickFindDataProduct.ATLAS:
			return {
				id: item.object.id,
				title: `${data?.name}` || '',
				url: data?.url || '',
				icon: ContentIconRenderer({
					type: 'atlas',
					imageSrc: data?.iconData || '',
					title: data?.title,
				}),
				meta: <QuickFindResultMeta meta={[capitalizeFirstLetter(item.object.type), data?.key]} />,
				additionalMeta: (
					<EventTypeMessage timestamp={item.event.timestamp} eventType={item.event.eventType} />
				),
				isRecentResult: true,
				type: `${item.object.product} ${item.object.type}`,
			};
		default:
			return {
				id: '',
				title: '',
				url: '',
				icon: '',
				meta: '',
			};
	}
};
