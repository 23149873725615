import { type Workspace } from '../../../common/search-config-provider';

export const getDefaultAtlasBaseURL = (isGlobalExperienceWorkspace?: boolean) => {
	const hostname = window.location.hostname;
	const subdomain = isGlobalExperienceWorkspace ? 'home' : 'team';
	if (
		hostname.includes('jira-dev.com') ||
		hostname.includes('.stg.atlassian.') ||
		hostname.startsWith('localhost')
	) {
		return `https://${subdomain}.stg.atlassian.com` as const;
	}

	return `https://${subdomain}.atlassian.com` as const;
};

export const createGlobalExperienceLink = (
	entityId: string,
	path: string,
	isGlobalExperienceWorkspace?: boolean,
	orgId?: string,
) => {
	const baseUrl = getDefaultAtlasBaseURL(isGlobalExperienceWorkspace);
	if (isGlobalExperienceWorkspace && orgId) {
		return `${baseUrl}/o/${orgId}/${path}/${entityId}`;
	}

	return `${baseUrl}/${path}/${entityId}`;
};

export const createProfileLink = (
	accountId: string,
	isGlobalExperienceWorkspace?: boolean,
	orgId?: string,
) => createGlobalExperienceLink(accountId, 'people', isGlobalExperienceWorkspace, orgId);

export const createTeamProfileLink = (
	teamId: string,
	isGlobalExperienceWorkspace?: boolean,
	orgId?: string,
) => createGlobalExperienceLink(teamId, 'people/team', isGlobalExperienceWorkspace, orgId);

type atlasEntityType = 'projects' | 'goals' | 'people' | 'teams' | 'topics';

const getAtlasViewAllRedirectUrl = (
	entity: atlasEntityType,
	searchText: string,
	workspaces?: Workspace[],
	isGlobalExperienceWorkspace?: boolean,
	orgId?: string,
) => {
	let url;
	if (
		workspaces &&
		workspaces.length === 1 &&
		/team\.(stg\.)?atlassian\.com/.test(workspaces[0].baseUrl)
	) {
		url = new URL(workspaces[0].baseUrl);
	} else {
		url = new URL(getDefaultAtlasBaseURL(isGlobalExperienceWorkspace));
	}

	const pathPrefix = isGlobalExperienceWorkspace && orgId ? `o/${orgId}/` : '';
	url.pathname = `${pathPrefix}action/search/${entity}`;
	url.searchParams.append('searchText', searchText);
	return url.href;
};

export default getAtlasViewAllRedirectUrl;
