import React from 'react';
import { EmptyState, Link } from '@atlassian/search-dialog';
import { type WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl-next';
import { getTrigger, isNewTab } from '../analytics';
import { AdvancedSearchLinkSubjectId } from '../analytics/events';
import { messages } from '../../messages';
import { messages as quickFindMessages } from '../../extensible/quick-find/messages';
import Button from '@atlaskit/button';
import { ClearFiltersButtonWrapper } from './no-results.styled';
import { Image, type Props, type ContextProps } from './no-results';

export class NoResultsQuickFindPostQuery extends React.Component<
	Props & WrappedComponentProps & ContextProps
> {
	onAdvancedSearchClicked = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		const { advancedSearchSelected, onClick, advancedSearchUrl } = this.props;
		advancedSearchSelected({
			trigger: getTrigger(e),
			actionSubjectId: AdvancedSearchLinkSubjectId.NO_RESULTS,
			isLoading: false,
			newTab: isNewTab(e),
		});
		onClick && onClick(advancedSearchUrl, e);
	};

	getContent = () => {
		const { linkComponent, advancedSearchUrl, hasFilters, clearFilters, isMultiSite } = this.props;
		if (!hasFilters) {
			return (
				<span role="none" onClick={this.onAdvancedSearchClicked}>
					{isMultiSite || !advancedSearchUrl ? (
						<FormattedMessage {...messages.common_empty_state_body_no_advanced_search} />
					) : (
						<FormattedMessage
							{...quickFindMessages.quick_find_post_query_no_results_body}
							values={{
								SearchContentLink: (s: React.ReactNode[]) => (
									<Link linkComponent={linkComponent} href={advancedSearchUrl}>
										{s}
									</Link>
								),
							}}
						/>
					)}
				</span>
			);
		} else {
			return (
				<>
					<FormattedMessage tagName="div" {...messages.filters_applied} />
					<ClearFiltersButtonWrapper>
						<Button appearance="primary" onClick={clearFilters}>
							<FormattedMessage {...messages.clear_filters} />
						</Button>
					</ClearFiltersButtonWrapper>
				</>
			);
		}
	};

	render() {
		const { intl } = this.props;

		return (
			<EmptyState
				title={intl.formatMessage(quickFindMessages.quick_find_post_query_no_results_header)}
				Image={Image}
				content={this.getContent()}
			/>
		);
	}
}

export default injectIntl(NoResultsQuickFindPostQuery);
