import gql from 'graphql-tag';

const QuickFindSpacesFragment = gql`
	fragment QuickFindSpacesFragment on Space {
		id
		key
		name
		icon {
			path
		}
		links {
			webui
		}
	}
`;

export const QuickFindSpacesQuery = gql`
	query QuickFindSpacesQuery($spaceLimit: Int!) {
		myVisitedSpaces(limit: $spaceLimit) {
			nodes {
				spaces {
					...QuickFindSpacesFragment
				}
			}
		}
	}
	${QuickFindSpacesFragment}
`;
