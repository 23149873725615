import React, { useEffect, useMemo } from 'react';
import { RemoveIcon, SquareReturnIcon } from '../icons';
import { type SearchResultContentProps } from './search-result-content';
import { messages } from '../messages';
import { FormattedMessage } from 'react-intl-next';
import { enrichResultTitle } from '@atlassian/search-common';
import { IconButton } from '@atlaskit/button/new';
import {
	CollapsedResultContentWrapper,
	EnlargedCollapsedResultDetail,
	EnlargedCollapsedResultDetailWrapper,
	EnlargedIconContainer,
	EnlargedResultDetail,
	EnlargedResultContentWrapper,
	ResultLabel,
	EnlargedResultTitleContainer,
	EnlargedReturnIconContainer,
} from './search-result.styled';

/**
 * This component is an alternative to the default result content component.
 * It displays the result content in a taller component. It displays the metadata underneath
 * the title, and show the additional metadata on the right.
 */
export const EnlargedSearchResultContent: React.FC<SearchResultContentProps> = ({
	icon,
	label,
	title,
	highlightedTitle,
	meta,
	additionalMeta,
	displayAdditionalMetaWhenHighlightedOnly,
	isCollapsed,
	isKeyboardHighlighted,
	returnIcon = 'onHighlight',
	isRecentSearch,
	onRemove,
}) => {
	const contentRef = React.useRef<HTMLDivElement>(null);

	const [isHover, setIsHover] = React.useState(false);

	const isActive = isKeyboardHighlighted || isHover;

	const showReturnIcon = useMemo(
		() =>
			(returnIcon === 'always' || (returnIcon === 'onHighlight' && isKeyboardHighlighted)) &&
			!isRecentSearch,
		[isKeyboardHighlighted, returnIcon, isRecentSearch],
	);

	const showRemoveIcon = useMemo(() => isRecentSearch && isActive, [isRecentSearch, isActive]);

	const showAdditionalMeta = useMemo(
		() => additionalMeta && (!displayAdditionalMetaWhenHighlightedOnly || isActive),
		[displayAdditionalMetaWhenHighlightedOnly, isActive, additionalMeta],
	);

	useEffect(() => {
		if (isKeyboardHighlighted && contentRef.current?.scrollIntoView) {
			contentRef.current.scrollIntoView({
				behavior: 'auto',
				block: 'nearest',
			});
		}
	}, [isKeyboardHighlighted]);

	return (
		<EnlargedResultContentWrapper
			ref={contentRef}
			isCollapsed={isCollapsed}
			isKeyboardHighlighted={isKeyboardHighlighted}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<EnlargedIconContainer>{icon}</EnlargedIconContainer>
			<CollapsedResultContentWrapper data-testid="collapsed-result-view">
				<EnlargedResultTitleContainer isCollapsed>
					{typeof title === 'string' ? (
						<>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<span title={title} className="SearchDialogResultTitle">
								{highlightedTitle ? enrichResultTitle(highlightedTitle) : title}
							</span>
						</>
					) : (
						title
					)}
					{typeof label === 'string' ? <ResultLabel title={label}>{label}</ResultLabel> : label}
				</EnlargedResultTitleContainer>
				{meta && (
					<EnlargedCollapsedResultDetailWrapper>
						<EnlargedCollapsedResultDetail>{meta}</EnlargedCollapsedResultDetail>
					</EnlargedCollapsedResultDetailWrapper>
				)}
			</CollapsedResultContentWrapper>
			{showAdditionalMeta && <EnlargedResultDetail>{additionalMeta}</EnlargedResultDetail>}
			{showReturnIcon && (
				<EnlargedReturnIconContainer>
					<SquareReturnIcon />
				</EnlargedReturnIconContainer>
			)}
			{showRemoveIcon && (
				<EnlargedReturnIconContainer>
					<IconButton
						icon={RemoveIcon}
						spacing={'compact'}
						onClick={onRemove}
						label={<FormattedMessage {...messages.remove_button_label} />}
						appearance={'subtle'}
					/>
				</EnlargedReturnIconContainer>
			)}
		</EnlargedResultContentWrapper>
	);
};
