import React from 'react';
import { type LinkComponent } from '@atlassian/search-dialog';
import { AsyncProduct } from './async-product';
import CheckProductPermissions, {
	type CheckProductPermissionsProps,
} from './check-product-permissions';
import { type ResultProviderProps } from './result-provider/result-provider';
import {
	type CacheWarmingProps,
	type ResultSuppliers,
} from './result-provider/result-provider-types';
import {
	type CustomizedRendererChildFn,
	type ScreenSpecificProps,
} from './result-provider/result-renderer';
import { type AdvancedSearchFooterProps } from '../../advanced-search-footer';
import { useDialogExpansionContext } from '../../dialog-expansion-context';
import { type Workspace } from '../../../common/search-config-provider';
import { AsyncProvider } from './result-provider';
import { useGenerateAdvancedSearchUrl } from './generate-advanced-search-url';

export interface SearchDialogProductProps<T = {}> extends CheckProductPermissionsProps<T> {
	/**
	 * A custom link component. This prop is designed to allow a custom link
	 * component to be passed to down when rendering results within in the dialog.
	 */
	linkComponent?: LinkComponent;
	/**
	 * A component to be rendered whilst the search dialog is still loading. This
	 * overrides the default loading spinner.
	 */
	fallback?: React.ComponentType;

	debounceTime?: number;
}

/**
 * A product within the Search Dialog. Each product responds to one tab inside the dialog and its children
 * are conditionally rendered based off whether this product is active
 *
 * The product component does the following
 * Permissions checking, to see if this product can be shown to the user
 * Product registration with the product router
 * Makes a result provider context available to its children
 * Conditionally rendering children if the product is active in the dialog
 * Async loading the component children using React.suspense
 */

export type Props<T = {}> = SearchDialogProductProps<T> &
	CacheWarmingProps &
	ResultSuppliers &
	ResultProviderProps &
	ScreenSpecificProps &
	CustomizedRendererChildFn &
	AdvancedSearchFooterProps & {
		workspaces?: Workspace[];
	};

export const SearchDialogProduct = <T extends {}>({
	generateAdvancedSearchUrl: genUrl,
	...rest
}: Props<T>) => {
	const { isExpanded } = useDialogExpansionContext();

	const generateAdvancedSearchUrl = useGenerateAdvancedSearchUrl(genUrl);

	const props = { ...rest, generateAdvancedSearchUrl };
	return (
		<CheckProductPermissions {...props}>
			<AsyncProduct {...props} fallback={!isExpanded ? () => <></> : undefined}>
				<AsyncProvider {...props} />
			</AsyncProduct>
		</CheckProductPermissions>
	);
};
