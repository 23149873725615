import React, { useContext, useState } from 'react';

export interface UserDetails {
	id?: string;
	name?: string;
	email?: string;
	avatarUrl?: string;
	hasSoftwareAccess?: boolean;
}

interface UserContext {
	user: UserDetails;
	orgId?: string;
	setUser: (user: UserDetails) => void;
}

interface Props {
	children?: React.ReactNode;
	user?: UserDetails;
	orgId?: string;
}

const UserContext = React.createContext<UserContext>({
	user: {},
	setUser: (user: UserDetails) => {},
});

export const UserProvider = ({ user: initialUser, orgId, children }: Props) => {
	const [user, setUser] = useState<UserDetails>(initialUser || {});

	return <UserContext.Provider value={{ user, orgId, setUser }}>{children}</UserContext.Provider>;
};

export function useUserContext() {
	return useContext(UserContext);
}
