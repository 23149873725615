import React, { type PropsWithChildren } from 'react';
import { SearchDialogAnalyticsContext } from '../analytics';
import { BUILD_VERSION } from '../../buildVersion';

export const BuildVersionAnalyticContext = ({ children }: PropsWithChildren<{}>) => (
	<SearchDialogAnalyticsContext
		analyticContext={{ frontend: BUILD_VERSION }}
		nonPrivacySafeAnalyticContext={{}}
	>
		{children}
	</SearchDialogAnalyticsContext>
);
