import { type GasPayload } from '@atlaskit/analytics-gas-types';

export const onSearchDialogResultContextMenuOpened = ({
	actionSubjectId,
	attributes,
	nonPrivacySafeAttributes,
}: {
	actionSubjectId: string;
	attributes: Record<string, any>;
	nonPrivacySafeAttributes: Record<string, any>;
}): GasPayload => ({
	source: 'searchDialog',
	eventType: 'ui',
	action: 'contextMenu opened',
	actionSubject: 'searchResult',
	actionSubjectId,
	attributes,
	nonPrivacySafeAttributes,
});
