import { useIntl } from 'react-intl-next';
import { messages } from '../../../messages';
import {
	JiraScope,
	FILTER_LIST_LIMIT,
	DEFAULT_COLLAB_GRAPH_URL,
	NAVIGATION_V3_EXPERIENCE,
	type SupportedJiraScopeResponses,
	type JiraScopeItemResponse,
	type JiraCollabGraphConfig,
	DEFAULT_ISSUE_LABELS_SERVICE_URL,
	type IssueLabel,
} from './types';
import { type GenericFilterOption } from '../../filters';
import { FilterOptionSource } from '../../../common/filters/types';
import {
	CollaborationGraphClient,
	type CollaborationGraphConfig,
	responseErrorToError,
} from '../../../common/clients';
import { useTypedAggregatorClient } from '../../aggregator-client-context';
import deepEqual from 'deep-equal';
import memo from 'memoize-one';
import { useSearchConfigContext } from '../../../common/search-config-provider';
import { MAX_CG_RESULTS } from '../../../common/clients/collaboration-graph-client/collaboration-graph-client';
import uniqBy from 'lodash/uniqBy';
import { type RequestServiceOptions, utils } from '@atlaskit/util-service-support';
import { sha256Hash, useAnalytics } from '../../../common/analytics';
import { type GasPurePayload, OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';

// Jira uses this for performance monitoring/debugging
const CAPABILITY_HEADER_NAME = 'X-Atlassian-Capability';

const getCollabGraphClientWithConfig = memo(
	(config: CollaborationGraphConfig) =>
		new CollaborationGraphClient({
			collaborationGraphUrl: config.collaborationGraphUrl,
			cloudId: config.cloudId,
			isMultiSite: config.isMultiSite,
			sites: config.sites,
		}),
	deepEqual,
);

const MAX_VISIBLE_ITEMS = 3;

export const useFiltersClient = (config?: JiraCollabGraphConfig) => {
	const { formatMessage } = useIntl();
	const { jiraSites } = useSearchConfigContext();
	const { fireAnalyticsEvent } = useAnalytics();

	const aggregatorClient = useTypedAggregatorClient<SupportedJiraScopeResponses, JiraScope>();

	const statusFilterOptions: GenericFilterOption[] = [
		{
			id: 'open',
			filterSource: FilterOptionSource.STATIC,
			isChecked: false,
			isVisible: true,
			label: formatMessage(messages.binary_status_category_filter_option_open),
		},
		{
			id: 'done',
			filterSource: FilterOptionSource.STATIC,
			isChecked: false,
			isVisible: true,
			label: formatMessage(messages.binary_status_category_filter_option_done),
		},
	];

	const jiraScopeFilterItemMapper = (
		item: JiraScopeItemResponse,
		isVisible: boolean,
		isRecent: boolean,
	): GenericFilterOption => ({
		id: item.id,
		avatarUrl: item.avatarUrl || item.attributes?.avatar?.url,
		label: item.name,
		filterSource: isRecent ? FilterOptionSource.RECENT : FilterOptionSource.SEARCH,
		isChecked: false,
		isVisible,
	});

	const filterSupplier: (
		currentScope: JiraScope,
		query: string,
		defaultItem?: GenericFilterOption,
	) => Promise<GenericFilterOption[]> = async (currentScope, query, defaultItem) => {
		if (!aggregatorClient) {
			return [];
		}

		return aggregatorClient
			.search({
				query,
				context: {
					sessionId: '',
					referrerId: null,
				},
				scopes: [currentScope],
				modelParams: [],
				resultLimit: FILTER_LIST_LIMIT,
				experience: NAVIGATION_V3_EXPERIENCE,
			})
			.then(({ response }) => {
				if (!response) {
					throw responseErrorToError(
						`Expected a response but did not get any for scope: ${currentScope}`,
					);
				}

				const scopedResponse = response.retrieveScope(currentScope);
				if (!scopedResponse) {
					throw new Error(`Expected a response but did not get any for scope: ${currentScope}`);
				}
				if (scopedResponse.error) {
					throw responseErrorToError(scopedResponse.error);
				}

				const maxVisible = defaultItem !== undefined ? MAX_VISIBLE_ITEMS - 1 : MAX_VISIBLE_ITEMS;

				const options = scopedResponse.results.map((result, i) =>
					jiraScopeFilterItemMapper(result, i < maxVisible, query === ''),
				);

				return defaultItem !== undefined ? [defaultItem, ...options] : options;
			});
	};

	const fetchCollabGraphProjectOptions = async (): Promise<GenericFilterOption[]> => {
		const collabGraphClient = getCollabGraphClientWithConfig({
			collaborationGraphUrl: config?.collaborationGraphUrl || DEFAULT_COLLAB_GRAPH_URL,
			cloudId: config?.cloudId || '',
			isMultiSite: jiraSites?.length > 1,
			sites: jiraSites,
		});

		return await collabGraphClient
			.getContainers(['jiraProject'], jiraSites)
			.then(({ collaborationGraphEntities }) => {
				return collaborationGraphEntities.map((entity, i) => ({
					label: entity.containerDetails.name,
					id: entity.containerDetails.id,
					avatarUrl: entity.containerDetails.iconUrl,
					filterSource: FilterOptionSource.COLLABORATION_GRAPH,
					isChecked: false,
					isVisible: i < MAX_VISIBLE_ITEMS,
				}));
			});
	};

	const interleaveCGAndRecentResults = async (
		uniqueFn: (opt: GenericFilterOption) => string,
		collabGraphOptions?: GenericFilterOption[],
	): Promise<GenericFilterOption[]> => {
		if (collabGraphOptions?.length === MAX_CG_RESULTS) {
			return collabGraphOptions;
		} else {
			try {
				const recentOptions = await filterSupplier(JiraScope.Project, '');
				return collabGraphOptions?.length
					? uniqBy([...collabGraphOptions, ...recentOptions], uniqueFn).map((item, i) => ({
							...item,
							isVisible: i < MAX_VISIBLE_ITEMS,
						}))
					: recentOptions;
			} catch (e) {
				return collabGraphOptions?.length ? collabGraphOptions : [];
			}
		}
	};

	const getProjectOptions = async () => {
		let collaborationGraphOptions;
		if (!!config?.cloudId) {
			collaborationGraphOptions = await fetchCollabGraphProjectOptions();
		}
		const interleavedResults = await interleaveCGAndRecentResults(
			(project) => project.id,
			collaborationGraphOptions,
		);
		return interleavedResults;
	};

	const issueLabelFilterItemMapper = (issueLabel: IssueLabel, isExternal: boolean) => {
		return {
			id: sha256Hash(`${config?.cloudId || ''}_${issueLabel.label}`),
			filterSource: isExternal ? FilterOptionSource.EXTERNAL : FilterOptionSource.SEARCH,
			isChecked: false,
			isVisible: isExternal,
			label: issueLabel.label,
		};
	};

	const fireOperationalEvent = (payload: GasPurePayload) => {
		fireAnalyticsEvent({
			eventType: OPERATIONAL_EVENT_TYPE,
			source: 'searchDialog',
			...payload,
		});
	};

	const fetchIssueLabelOptions = async (query: string): Promise<GenericFilterOption[]> => {
		const params = new URLSearchParams([['query', query]]);
		const path = `${DEFAULT_ISSUE_LABELS_SERVICE_URL}?${params.toString()}`;

		const options: RequestServiceOptions = {
			requestInit: {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					[CAPABILITY_HEADER_NAME]: 'PRODUCT_SEARCH_DIALOG',
				},
			},
		};

		try {
			return await utils
				.requestService({ url: path }, options)
				.then((response: any) =>
					response?.suggestions?.map((issueLabel: IssueLabel) =>
						issueLabelFilterItemMapper(issueLabel, query === ''),
					),
				);
		} catch (error) {
			fireOperationalEvent({
				action: 'failed',
				actionSubject: 'fetchIssueLabels',
				attributes: {
					error: responseErrorToError(error).message,
				},
			});
			return [];
		}
	};

	return {
		mapJiraScopeFilterItem: jiraScopeFilterItemMapper,
		mapIssueLabelFilterItem: issueLabelFilterItemMapper,
		statusFilterOptions,
		fetchIssueLabelOptions,
		fetchCollabGraphProjectOptions,
		getProjectOptions,
		getFilterItemsByJiraScope: filterSupplier,
	};
};
