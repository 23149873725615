import { EnlargedSearchResultContent } from '@atlassian/search-dialog';
import React, { type ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { messages } from '../../messages';
import {
	useProductContext,
	type SearchResult,
	type SearchResultSection,
} from '../../../product-router';
import { useQuery } from '../../../query-context';
import { useRecentSearches } from '../../../recent-searches';
import { QuickFindIcon } from '../../../recent-searches/icons/quick-find-icon';
import { useSearchResultAnalyticsCallbacks } from '../../../search-result';
import { PersistRecentSearch } from '../../../recent-searches/persist-recent-search';
import { useInputContext } from '../../../../extensible/input-context';

export const RECENT_SEARCHES_SECTION_ID = 'recent-searches';
const MAX_RECENT_SEARCHES_SHOWN_IN_PRE_QUERY = 3;
const MAX_RECENT_SEARCHES_SHOWN_IN_POST_QUERY = 1;

export const useRecentSearchesSection = (): SearchResultSection => {
	const { query } = useQuery();
	const { getActiveProduct } = useProductContext();
	const { focusInput } = useInputContext();

	const { recentSearchItems, removeRecentSearch } = useRecentSearches({
		Icon: <QuickFindIcon />,
	});

	const recentSearches: SearchResult[] = recentSearchItems.map(
		(result: { id: any; title: string; icon: any }) => ({
			id: result.id,
			title: result.title,
			icon: result.icon,
			url: getActiveProduct()?.generateAdvancedSearchUrl?.(result.title) || '',
			meta: '',
			additionalMeta:
				query !== '' ? (
					<FormattedMessage {...messages.quick_find_recent_search_text_dialog} />
				) : (
					<FormattedMessage {...messages.quick_find_recent_search} />
				),
		}),
	);

	const recentSearchesSection: SearchResultSection = useMemo(() => {
		return {
			id: RECENT_SEARCHES_SECTION_ID,
			title: '',
			searchResults: recentSearches,
			resultRenderer: ({ searchResult, isKeyboardHighlighted, isCollapsed }) => {
				return (
					<PersistRecentSearch overrideQuery={searchResult.title}>
						<EnlargedSearchResultContent
							{...searchResult}
							icon={searchResult.icon as ReactElement}
							isKeyboardHighlighted={isKeyboardHighlighted}
							isCollapsed={isCollapsed}
							isRecentSearch
							onRemove={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								event.preventDefault();
								event.stopPropagation();
								removeRecentSearch(searchResult.title);
								focusInput && focusInput();
							}}
							displayAdditionalMetaWhenHighlightedOnly={query === ''}
						/>
					</PersistRecentSearch>
				);
			},
			resultLimit: query
				? MAX_RECENT_SEARCHES_SHOWN_IN_POST_QUERY
				: MAX_RECENT_SEARCHES_SHOWN_IN_PRE_QUERY,
			useAnalyticsOverride: ({ screen }) =>
				useSearchResultAnalyticsCallbacks({
					screen,
					actionSubject: 'recentSearch',
				}),
		};
	}, [query, recentSearches, removeRecentSearch, focusInput]);

	return recentSearchesSection;
};
