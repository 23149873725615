import React, { type FC } from 'react';
import { IntlProvider, injectIntl, type WrappedComponentProps } from 'react-intl-next';
import { ENGLISH_LOCALES, getMessagesForLocale, type LangCode } from '../../utils/i18n-util';
import english_messages from '../../i18n/en';

export interface Props {
	children: React.ReactChild;
}

class MessagesIntlProvider extends React.Component<
	Props & WrappedComponentProps,
	{
		messages:
			| {
					[key: string]: string;
			  }
			| undefined;
	}
> {
	constructor(props: Props & WrappedComponentProps) {
		super(props);
		this.state = {
			messages: ENGLISH_LOCALES.indexOf(props.intl.locale) !== -1 ? english_messages : undefined,
		};
		this.fetchLocale();
	}
	fetchLocale = () => {
		getMessagesForLocale(this.props.intl.locale as LangCode).then((messages) =>
			this.setState({ messages: messages }),
		);
	};
	render() {
		const { children } = this.props;

		return (
			<IntlProvider messages={this.state.messages} locale={this.props.intl.locale}>
				{children}
			</IntlProvider>
		);
	}
}

const MessagesIntlProviderWithIntl: FC<Props> = injectIntl(MessagesIntlProvider);

export default MessagesIntlProviderWithIntl;
