import React from 'react';

import { EmptyState } from '@atlassian/search-dialog';
import { messages } from '../messages';
import { FormattedMessage } from 'react-intl-next';

export const PrequeryEmptyScreenIcon = React.lazy(
	() =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/search-ai/prequery-empty-screen-icon" */ './image'
		),
);

export const PreQueryEmptyScreen: React.FC = ({}) => {
	return (
		<EmptyState
			Image={PrequeryEmptyScreenIcon}
			title={<FormattedMessage {...messages.quick_find_no_content_header} />}
			content={<FormattedMessage {...messages.quick_find_no_content_body} />}
		/>
	);
};
