import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	rovoTip: {
		id: 'search_dialog_rovo_tip',
		defaultMessage: 'Rovo tip:',
		description: 'Prefix for the Rovo tip message displayed in the search dialog',
	},

	dismissBannerLabel: {
		id: 'search_dialog_dismiss_banner_label',
		defaultMessage: 'Dismiss banner',
		description: 'Label of the button which dismisses the banner and hides it from the user',
	},

	rovoBannerMessage: {
		id: 'search_dialog_rovo_banner_message',
		defaultMessage: `Enter a question like “What’s the vacation policy?” and Rovo will find the answer`,
		description: 'Message displayed in the banner to inform users how to use Rovo search',
	},
});
