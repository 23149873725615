import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	noResultsHeading: {
		id: 'search_dialog_no_results_heading',
		defaultMessage: `We couldn't find any results with that title`,
		description: 'Heading of message shown when no search results are returned',
	},
	noResultsBody: {
		id: 'search_dialog_error_search_body',
		defaultMessage:
			'<link>Search all content across your organization</link> or try again with a different term.',
		description: 'Body text of prompt if entering query in quickfind returns no results',
	},
});
