// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { N40A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const QuickFindAdvancedSearchLink = styled.div({
	borderTop: `1px solid ${token('color.border', N40A)}`,
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span > a': {
		padding: `${token('space.100', '8px')} ${token('space.200', '16px')}`,
	},
});
