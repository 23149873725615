import React, { type ReactNode } from 'react';
import Badge from '@atlaskit/badge';
import {
	SearchResultsSectionTitle,
	SearchResultSectionContainer,
	SearchResultSectionHeading,
	SearchResultSectionTitleBadgeContainer,
} from './search-result-section.styled';

export interface Props {
	children?: ReactNode;
	/**
	 * The title of the section
	 */
	title: string | JSX.Element;
	/**
	 * If provided this will show the total number of results next to the title.
	 */
	totalResults?: number;
}

export const SearchResultSection = ({ title, totalResults, children }: Props) => {
	return (
		<SearchResultSectionContainer>
			<SearchResultSectionHeading>
				<SearchResultsSectionTitle>
					{title}
					{totalResults ? (
						<SearchResultSectionTitleBadgeContainer>
							<Badge testId="search-result-count-badge">{totalResults}</Badge>
						</SearchResultSectionTitleBadgeContainer>
					) : null}
				</SearchResultsSectionTitle>
			</SearchResultSectionHeading>
			{children}
		</SearchResultSectionContainer>
	);
};
