import React from 'react';
import {
	MultiProductDialog,
	type MultiProductDialogPropsComplete,
} from '../products/pre-configured/multi-product-dialog';
import { QuickFindConfluence, type QuickFindConfluenceProps } from './quick-find-confluence';

export type QuickFindFeatures = {
	quickFind?: {
		enabled: boolean;
		postQueryDisabled?: boolean;
	};
	isGriffinNavEnabled?: boolean;
};

type QuickFindProps = Omit<MultiProductDialogPropsComplete, 'children'> &
	Omit<QuickFindConfluenceProps, 'onRetry'> & {
		postQueryDisabled?: boolean;
	};

export const QuickFind: React.FC<QuickFindProps> = ({
	dialogFeatures,
	postQueryDisabled,
	...rest
}) => {
	return (
		<MultiProductDialog
			dialogFeatures={{
				...dialogFeatures,
				quickFind: { enabled: true, postQueryDisabled },
			}}
			{...rest}
		>
			{({ onRetry }) => <QuickFindConfluence {...rest} onRetry={onRetry} />}
		</MultiProductDialog>
	);
};
