import React from 'react';

import { N30, N700 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const ReturnIcon = () => (
	<svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
		<rect width="20" height="20" rx="3" fill={token('color.background.neutral', N30)} />
		<path
			fillRule="nonzero"
			d="M7.99707 14.7949C7.86035 14.7949 7.70312 14.7266 7.59375 14.624L4.47656 11.582C4.35352 11.459 4.29199 11.3154 4.29199 11.165C4.29199 11.0146 4.35352 10.8711 4.47656 10.7549L7.59375 7.71289C7.70312 7.60352 7.86035 7.54199 7.99707 7.54199C8.3252 7.54199 8.53711 7.76074 8.53711 8.08203C8.53711 8.23926 8.48926 8.3623 8.38672 8.45801L6.9375 9.86621L5.95996 10.6797L7.23145 10.6182H13.835C14.3887 10.6182 14.6006 10.3994 14.6006 9.8457V6.71484C14.6006 6.16797 14.3887 5.94922 13.835 5.94922H11.0117C10.6699 5.94922 10.4512 5.70312 10.4512 5.40234C10.4512 5.09473 10.6699 4.84863 11.0117 4.84863H13.835C15.1748 4.84863 15.708 5.375 15.708 6.71484V9.8457C15.708 11.124 15.1748 11.7188 13.835 11.7188H7.23145L5.95996 11.6572L6.9375 12.4707L8.38672 13.8721C8.48926 13.9746 8.53711 14.0908 8.53711 14.248C8.53711 14.5693 8.3252 14.7949 7.99707 14.7949Z"
			fill={token('color.icon.subtle', N700)}
		/>
	</svg>
);
