export const QUICK_FIND_ROVO_TIP_KEY = 'atlassian.unified-search.quick-find-rovo-tip';
export const ANONYMOUS_USER_ID = 'anonymous-user';

type QuickFindRovoTip = {
	[key: string]: { [key: string]: boolean };
};

export const getRovoTip = (productId: string, userId?: string): boolean => {
	const userIdOrAnonymous = userId || ANONYMOUS_USER_ID;
	const quickFindRovoTipRaw = getLocalStorageItem(QUICK_FIND_ROVO_TIP_KEY);
	const rovoTip: QuickFindRovoTip = quickFindRovoTipRaw ? JSON.parse(quickFindRovoTipRaw) : {};
	const rovoTipProducts = rovoTip[userIdOrAnonymous] || {};
	return rovoTipProducts[productId] === undefined ? true : rovoTipProducts[productId];
};

export const setRovoTip = (productId: string, userId?: string): boolean => {
	const userIdOrAnonymous = userId || ANONYMOUS_USER_ID;

	const quickFindRovoTipRaw = getLocalStorageItem(QUICK_FIND_ROVO_TIP_KEY);
	const rovoTip: QuickFindRovoTip = quickFindRovoTipRaw ? JSON.parse(quickFindRovoTipRaw) : {};

	if (!rovoTip[userIdOrAnonymous]) {
		rovoTip[userIdOrAnonymous] = {};
	}

	rovoTip[userIdOrAnonymous][productId] = false;

	setLocalStorageItem(QUICK_FIND_ROVO_TIP_KEY, JSON.stringify(rovoTip));

	return rovoTip[userIdOrAnonymous][productId];
};

const setLocalStorageItem = (key: string, value: string) => {
	window.localStorage.setItem(key, value);
};

const getLocalStorageItem = (key: string): string | null => {
	return window.localStorage.getItem(key);
};
