import React from 'react';

import { useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';

import { messages } from './messages';

const containerStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: 'space.200',
});

export const NoOptions = () => {
	const intl = useIntl();

	return <Box xcss={containerStyles}>{intl.formatMessage(messages.noOptions)}</Box>;
};
