import React from 'react';

import { useIntl } from 'react-intl-next';

import Badge from '@atlaskit/badge';
import FolderOpenIcon from '@atlaskit/icon/core/folder-open';

import { formatAvatarOptionLabel } from '../../../../../common/ui/filters/select-filter/option-label';
import { FilterButton } from '../../../../../common/ui/quick-find/filter-button';
import { FilterPopupSelect } from '../../../../../common/ui/quick-find/filter-popup-select';
import { useSelectFilter } from '../../../../../controllers/filters/base-select-filter';
import { filter as spaceFilter } from '../../../../../controllers/store/search/filters/space';

import { i18n } from './messages';

export const SpaceFilter = () => {
	const { formatMessage } = useIntl();

	const [
		filterData,
		{ loadInitialOptions, setLookupInput, setOption, clearLookupInput, clearOptions },
	] = useSelectFilter({
		filterId: spaceFilter.id,
	});

	if (!filterData) {
		return null;
	}

	const {
		isLoading,
		suggestedOptions,
		selectedOptions,
		options,
		isLookupDefined,
		lookupInput,
		isLookupActive,
		badgeLabel,
		firstSelectedOptionLabel,
	} = filterData;

	const groupedOptions = [
		{
			label: formatMessage(i18n.confluenceFilterLabelSelected),
			options: selectedOptions,
		},
		{
			label: formatMessage(i18n.confluenceFilterLabelUnselected),
			options: suggestedOptions,
		},
	];

	return (
		<FilterPopupSelect
			canClearFilter
			checkboxIndicators
			onDropdownOpen={loadInitialOptions}
			onDropdownClose={clearLookupInput}
			onSearchInputChange={setLookupInput}
			onSelectChange={setOption}
			options={isLookupActive ? options : groupedOptions}
			selectedOptions={selectedOptions}
			label={formatMessage(i18n.space)}
			placeholder={formatMessage(i18n.confluenceFilterSpacePlaceholder)}
			isLoading={isLoading}
			isSearchable={isLookupDefined}
			searchInput={lookupInput}
			formatOptionLabel={formatAvatarOptionLabel}
			onClearOptions={clearOptions}
			target={({ isOpen, ...targetProps }) => (
				<FilterButton
					{...targetProps}
					isSelected={!!selectedOptions.length}
					iconBefore={<FolderOpenIcon label={formatMessage(i18n.confluenceFilterSpaceIcon)} />}
					iconAfter={badgeLabel && <Badge appearance="primary">{badgeLabel}</Badge>}
					minWidthZero={true}
				>
					{firstSelectedOptionLabel ? firstSelectedOptionLabel : formatMessage(i18n.space)}
				</FilterButton>
			)}
		/>
	);
};
