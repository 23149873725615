import { type LinkComponent, SearchFooterLinks } from '@atlassian/search-dialog';
import React, { type FunctionComponent, useCallback } from 'react';
import { type WrappedComponentProps, injectIntl } from 'react-intl-next';
import {
	getTrigger,
	isNewTab,
	onMultiSiteAdvancedSearchSelected,
	useAnalytics,
} from '../analytics';
import { type Products } from '../product-context';
import { messages } from '../../messages';

const MAX_PRIMARY_WORKSPACES = 5;

type WorkspaceWithAdvancedSearchURL = {
	product: Products | string;
	content: string;
	key: string;
	advancedSearchUrl: string;
};

export interface Props {
	workspaces: WorkspaceWithAdvancedSearchURL[];
	linkComponent?: LinkComponent;
	onClick?: (href: string, e: React.MouseEvent) => any;
	isLoading: boolean;
}

const getActionSubjectIdForProduct = (product: Products | string) => {
	return `${product.toLowerCase()}AdvancedSearchLink`;
};

export const MultiWorkspaceAdvancedSearchFooter: FunctionComponent<
	Props & WrappedComponentProps
> = ({ workspaces, linkComponent, onClick, isLoading, intl }) => {
	const { fireAnalyticsEvent } = useAnalytics();

	const links = workspaces.map((workspace) => ({
		content: workspace.content,
		key: workspace.key,
		href: workspace.advancedSearchUrl,
	}));

	const label = intl.formatMessage(messages.multi_site_advanced_search_label);

	const dropdownLabel = intl.formatMessage(messages.multi_site_advanced_search_more_dropdown, {
		count: links.length - MAX_PRIMARY_WORKSPACES,
	});

	const onAdvancedSearchClicked = useCallback(
		({ key }: { key: string }, e: React.MouseEvent<HTMLElement>) => {
			const workspace = workspaces.filter((w) => w.key === key)[0];

			if (!workspace) {
				return;
			}

			fireAnalyticsEvent(
				onMultiSiteAdvancedSearchSelected({
					trigger: getTrigger(e),
					actionSubjectId: getActionSubjectIdForProduct(workspace.product),
					isLoading,
					newTab: isNewTab(e),
					destinationId: key,
					index: workspaces.map((w) => w.key).indexOf(key),
				}),
			);
		},
		[fireAnalyticsEvent, isLoading, workspaces],
	);

	return (
		<SearchFooterLinks
			linkComponent={linkComponent}
			onClick={(link, e) => {
				onAdvancedSearchClicked(link, e);
				onClick && onClick(link.href, e);
			}}
			label={label}
			dropdownTriggerLabel={dropdownLabel}
			links={links}
			maxPrimaryLinks={MAX_PRIMARY_WORKSPACES}
		/>
	);
};

export default injectIntl(MultiWorkspaceAdvancedSearchFooter);
