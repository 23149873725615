import { type AllFilters, SITE_FILTER_TYPE_NAME, type SpaceFilterOption } from './filter-types';
import { type FilterOption } from '../../common/filters/types';
import { useGenericFilters } from './filter-context';

export const useAppliedFilters = (activeProductId: string) => {
	const { filterDefinitions } = useGenericFilters();
	let appliedFilters: AllFilters[] = [];

	// Find all of the filters that apply to the current product
	const filters = filterDefinitions[activeProductId];
	if (filters !== undefined) {
		filters.forEach((filter) => {
			// filters with specified value mapping logic should implement it, else use generic logic
			if (filter.mapSelectionToAppliedValue) {
				const valueToApply = filter.mapSelectionToAppliedValue();
				!!valueToApply && appliedFilters.push(valueToApply);
			} else {
				let applied = filter.store.availableFilters
					.filter((option: FilterOption) => option.isChecked)
					.map((filter: FilterOption) => filter.id);

				// if no site filters are applied, the search should use the top 3 available sites
				if (filter['@type'] === SITE_FILTER_TYPE_NAME && applied.length === 0) {
					applied = filter.store.availableFilters
						.slice(0, 3)
						.map((filter: FilterOption) => filter.id);
				}

				if (filter['@type'] === 'spaces') {
					applied = filter.store.availableFilters
						.filter((option: SpaceFilterOption) => option.isChecked)
						.map((filter: SpaceFilterOption) => filter.spaceKey || '');
				}

				if (applied.length > 0) {
					appliedFilters.push({
						'@type': filter['@type'],
						[filter.fieldName]: applied,
					});
				}
			}
		});
	}

	return appliedFilters;
};
