import React, { type PropsWithChildren } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { token } from '@atlaskit/tokens';
import { useFeaturesContext } from '../features-context/features-context';

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const QuickFindSearchResultWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${token('space.025', '2px')};
	padding: ${token('space.100', '8px')};
`;

export const SearchResultWrapper = ({ children }: PropsWithChildren<{}>) => {
	const { quickFind } = useFeaturesContext();

	return quickFind?.enabled ? (
		<QuickFindSearchResultWrapper>{children}</QuickFindSearchResultWrapper>
	) : (
		<>{children}</>
	);
};
