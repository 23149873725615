import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import React, { Suspense } from 'react';
import { type DropDownLinksProps, type FooterLink } from './drop-down-links.types';
import { ForwardedRefToolTipTag } from './forward-ref-tooltip-tag';
import {
	LabelContainer,
	PrimaryButtonStyledLink,
	PrimaryButtonWrapper,
	SearchFooterLinksContainer,
} from './search-footer-links.styled';

const DropDownLinks = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/search-dialog/search-footer/dropdown-links" */ './drop-down-links'
	).then((module) => {
		return {
			default: module.default,
		};
	}),
);

const DEFAULT_MAX_PRIMARY_LINKS = 5;

export interface SearchFooterLinksProps extends Omit<DropDownLinksProps, 'dropdownLinks'> {
	label: JSX.Element | string;
	links: FooterLink[];
	maxPrimaryLinks?: number;
}

export const SearchFooterLinks: React.FunctionComponent<SearchFooterLinksProps> = ({
	label,
	links,
	dropdownTriggerLabel,
	onClick,
	linkComponent,
	maxPrimaryLinks,
}) => {
	const primaryLinks = links.slice(0, maxPrimaryLinks || DEFAULT_MAX_PRIMARY_LINKS);
	const dropdownLinks = links.slice(maxPrimaryLinks || DEFAULT_MAX_PRIMARY_LINKS, links.length);

	return (
		<SearchFooterLinksContainer>
			<LabelContainer>{label}</LabelContainer>
			{primaryLinks.map((link) => {
				const { key, href, content } = link;

				return (
					<PrimaryButtonWrapper key={key} onClick={(e) => onClick && onClick(link, e)}>
						<PrimaryButtonStyledLink href={href} linkComponent={linkComponent}>
							<Tooltip content={content} tag={ForwardedRefToolTipTag}>
								<Button spacing="compact" tabIndex={-1}>
									{content}
								</Button>
							</Tooltip>
						</PrimaryButtonStyledLink>
					</PrimaryButtonWrapper>
				);
			})}
			{dropdownLinks && dropdownLinks.length > 0 ? (
				<Suspense fallback={<></>}>
					<DropDownLinks
						dropdownLinks={dropdownLinks}
						dropdownTriggerLabel={dropdownTriggerLabel}
						onClick={onClick}
						linkComponent={linkComponent}
					/>
				</Suspense>
			) : null}
		</SearchFooterLinksContainer>
	);
};
