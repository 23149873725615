import React from 'react';

import {
	type QuickFindSearchResultType,
	type ConfluenceGraphQLContentNode,
} from '@atlassian/search-client';
import { ContentIconRenderer } from './content-icon-renderer';
import { transformAriToId } from '@atlassian/search-common';
import { ConfluenceResultMeta } from './confluence-result-meta';
import { EventTypeMessage, WORKED_EVENT } from './event-type-message';

export const quickFindConfluenceContentMapper = ({
	node: item,
}: {
	node?: ConfluenceGraphQLContentNode | null;
}): QuickFindSearchResultType => {
	return {
		ari: item?.id,
		id: transformAriToId(item?.id || '', item?.type || ''),
		title: item?.title || '',
		url: item?.url || '',
		meta: (
			<ConfluenceResultMeta
				resultContentType={item?.type || ''}
				resultAncestors={item?.confluenceEntity?.ancestors}
				resultSpace={item?.space}
			/>
		),
		additionalMeta: item?.lastModified ? (
			<EventTypeMessage
				timestamp={item.lastModified}
				eventType={WORKED_EVENT.UPDATED}
				isMyActivityData={false}
			/>
		) : (
			''
		),
		containerId: transformAriToId(item?.space?.id || 'UNAVAILABLE', 'space'),
		type: `confluence ${item?.type}` || '',
		icon: ContentIconRenderer({
			type: item?.type || '',
			title: item?.title,
		}),
	};
};
