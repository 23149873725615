import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	/**
	 * Extensible
	 */
	extensible_advanced_search_footer: {
		id: 'extensible_search_dialog.advanced_search',
		defaultMessage: 'Advanced search',
		description:
			'If the user has not passed any label for the footer then the footer in the dialog leads to the advanced search of the product.',
	},
	extensible_advanced_search_cross_product_footer: {
		id: 'extensible_search_dialog.advanced_search_cross_product',
		defaultMessage: 'Search across products',
		description: 'Label for footer link that redirects to cross product advanced search',
	},

	extensible_connect_to: {
		id: 'extensible_search_dialog.connect_to',
		defaultMessage: 'Connect to',
		description:
			'When the user has not connected a third party app, they are prompted to connect the third party app e.g. "Connect to Google Drive".',
	},

	extensible_connect: {
		id: 'extensible_search_dialog.connect',
		defaultMessage: 'Connect',
		description: 'The text shown on the button to connect a third party app',
	},
});
