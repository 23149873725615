import Button from '@atlaskit/button';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { grid } from '../style-utils';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// The margin is reduced to account for the Button component's 8px internal padding
// such that it aligns with the SearchResult icon.
// We need the `&&&` as Confluence has some CSS that overrides the styling otherwise
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionLink = styled(Button)`
	margin-left: ${grid.half().px};
	color: ${token('color.link', B400)};
	cursor: pointer !important;
	&:hover {
		text-decoration: underline !important;
	}
`;
