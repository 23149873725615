import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl-next';

import { messages } from '../../../messages';
import { messages as trelloMessages } from './messages';
import { SearchDialogProduct } from '../../product-router';
import type { ResultRendererArgs } from '../../product-router/product/result-types';
import { useDefaultSuppliers } from '../../result-supplier';

import { TrelloSearchResultComponent } from './trello-search-result';
import { TrelloFooter } from './trello-footer';
import { TRELLO_PRODUCT_ID, TrelloScope, type TrelloTabProps } from './types';
import { BoardIcon, CardIcon } from './icons';
import { viewAllLinkGenerator } from './url-generators';

import { useSearchSessionId } from '../../../common/search-session-provider';
import { addQueryParams } from '../../../utils/url-utils';

import type {
	AggregatorTrelloResponse,
	TrelloBoard,
	TrelloCard,
	TrelloSearchResult,
} from './types';

const TrelloBodyAsync = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlassian/product-search-dialog/extensible/trello/trello-body" */ './trello-body'
	).then(({ TrelloBody }) => ({
		default: TrelloBody,
	})),
);

export const TrelloTab: React.FC<TrelloTabProps> = ({
	feedbackUrl,
	preQueryItemSupplier,
	postQueryItemSupplier,
	generateAdvancedSearchUrl,
	features,
	resultOnClick,
	...rest
}) => {
	const searchSessionId = useSearchSessionId();
	const { formatMessage } = useIntl();
	const trelloGenerateAdvancedSearchUrl = useCallback(
		(query: string) => {
			return addQueryParams(viewAllLinkGenerator(query), {
				search_id: searchSessionId,
			});
		},
		[searchSessionId],
	);

	const boardSection = useMemo(() => {
		return {
			id: TrelloScope.Board,
			title: formatMessage(trelloMessages.trello_boards_heading),
			scope: TrelloScope.Board,
			showResultCount: false,
			resultMapper: ({ results }: AggregatorTrelloResponse): TrelloSearchResult[] =>
				(results as TrelloBoard[]).map((board) => ({
					id: board.id,
					title: board.name,
					meta: board.organization?.displayName ?? '',
					closed: board.closed,
					url: board.url,
					prefs: board.prefs,
					icon: <BoardIcon {...board} />,
					containerId: board.idOrganization ?? undefined,
				})),
			resultRenderer: (args: ResultRendererArgs<TrelloSearchResult>) => {
				const { id, title, meta, closed, url, prefs } = args?.searchResult;

				return (
					<TrelloSearchResultComponent
						id={id}
						title={title}
						meta={{
							title: formatMessage(trelloMessages.trello_workspace),
							value: meta,
						}}
						closed={closed}
						url={url}
						prefs={prefs}
						isPreviewOnTrelloSearchResultsEnabled={features?.isPreviewOnTrelloSearchResultsEnabled}
						isColorBlindModeEnabled={features?.isColorBlindModeEnabled}
						screen={args?.screen}
						onResultClick={resultOnClick}
					/>
				);
			},
		};
	}, [
		features?.isColorBlindModeEnabled,
		features?.isPreviewOnTrelloSearchResultsEnabled,
		formatMessage,
		resultOnClick,
	]);

	const cardSection = useMemo(() => {
		return {
			id: TrelloScope.Card,
			title: formatMessage(trelloMessages.trello_cards_heading),
			scope: TrelloScope.Card,
			showResultCount: false,
			resultMapper: ({ results }: AggregatorTrelloResponse): TrelloSearchResult[] =>
				(results as TrelloCard[]).map((card) => ({
					id: card.id,
					title: card.name,
					meta: card.board.name,
					additionalMeta: card.list.name,
					closed: card.closed,
					url: card.url,
					icon: <CardIcon />,
					containerId: card.idBoard,
				})),
			resultRenderer: (args: ResultRendererArgs<TrelloSearchResult>) => {
				const { id, title, meta, additionalMeta, url, closed } = args?.searchResult;

				return (
					<TrelloSearchResultComponent
						id={id}
						title={title}
						meta={{
							title: formatMessage(trelloMessages.trello_board),
							value: meta,
						}}
						additionalMeta={{
							title: formatMessage(trelloMessages.trello_list),
							value: additionalMeta,
						}}
						closed={closed}
						url={url}
						isPreviewOnTrelloSearchResultsEnabled={features?.isPreviewOnTrelloSearchResultsEnabled}
						isColorBlindModeEnabled={features?.isColorBlindModeEnabled}
						screen={args?.screen}
						onResultClick={resultOnClick}
					/>
				);
			},
		};
	}, [
		features?.isColorBlindModeEnabled,
		features?.isPreviewOnTrelloSearchResultsEnabled,
		formatMessage,
		resultOnClick,
	]);

	const sections = useMemo(() => {
		return features?.isCardResultsSectionFirst
			? [
					cardSection,
					{
						...boardSection,
						viewAllLinkGenerator: trelloGenerateAdvancedSearchUrl,
					},
				]
			: [
					boardSection,
					{
						...cardSection,
						viewAllLinkGenerator: trelloGenerateAdvancedSearchUrl,
					},
				];
	}, [
		boardSection,
		cardSection,
		features?.isCardResultsSectionFirst,
		trelloGenerateAdvancedSearchUrl,
	]);

	const itemSuppliers = useDefaultSuppliers<AggregatorTrelloResponse, TrelloScope>(
		TRELLO_PRODUCT_ID,
		sections,
		{
			preQueryItemSupplier,
			postQueryItemSupplier,
		},
	);

	const preQuerySectionTitleGenerator = useCallback(
		(section: { id: string }) =>
			section.id === TrelloScope.Board
				? formatMessage(trelloMessages.trello_recent_boards_heading)
				: '',
		[formatMessage],
	);

	const Footer = useMemo(() => {
		return feedbackUrl
			? () => (
					<TrelloFooter
						feedbackUrl={feedbackUrl}
						urlGeneratorForNoResultsScreen={trelloGenerateAdvancedSearchUrl}
					/>
				)
			: undefined;
	}, [feedbackUrl, trelloGenerateAdvancedSearchUrl]);

	return (
		<SearchDialogProduct
			id={TRELLO_PRODUCT_ID}
			expandedStateInputPlaceholder={formatMessage(
				trelloMessages.trello_expanded_input_placeholder,
			)}
			preQuerySectionTitleGenerator={preQuerySectionTitleGenerator}
			generateAdvancedSearchUrl={trelloGenerateAdvancedSearchUrl}
			sections={sections}
			title={formatMessage(messages.trello_tab_label)}
			{...rest}
			{...itemSuppliers}
		>
			{(props) => {
				return features?.isCardResultsSectionFirst
					? {
							Footer,
							Body: () => <TrelloBodyAsync {...props} />,
						}
					: { Footer };
			}}
		</SearchDialogProduct>
	);
};
