import React, { Suspense } from 'react';
import type { FC, ReactNode, ReactElement } from 'react';

const LoadedAutocorrection = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/product-search-dialog/extensible/async/autocorrection-component" */ './autocorrection-renderer'
	).then(({ LoadedAutocorrection }) => ({
		default: LoadedAutocorrection,
	})),
);

type AutocorrectAsyncWrapperProps = {
	container?: FC;
	fallback?: ReactNode;
};

export const AutocorrectAsyncWrapper = ({ container, fallback }: AutocorrectAsyncWrapperProps) => {
	return (
		<Suspense fallback={null}>
			<LoadedAutocorrection container={container} fallback={fallback as ReactElement} />
		</Suspense>
	);
};
