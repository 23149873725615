import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { Flex, xcss } from '@atlaskit/primitives';

import {
	type QuickFindSearchSpaceType,
	type QuickFindSearchResultType,
} from '@atlassian/search-client';

import Avatar from '@atlaskit/avatar';
import { messages } from '../../messages';

type GenericIconRendererArgs = {
	iconUrl: string;
	size?: 'small' | 'xsmall';
	appearance?: 'square' | 'circle';
};

const negativeMarginStyles = xcss({
	margin: 'space.negative.025',
});

const CONTEXT_PATH = '/wiki';

export const GenericIconRenderer = ({
	iconUrl,
	size = 'small',
	appearance = 'square',
}: GenericIconRendererArgs) => (
	<Flex xcss={negativeMarginStyles}>
		<Avatar
			size={size}
			borderColor="transparent"
			src={iconUrl}
			appearance={appearance}
			testId="result-avatar"
		/>
	</Flex>
);

export const quickFindRecentSpaceResultMapper: (
	item: QuickFindSearchSpaceType | null,
) => QuickFindSearchResultType = (item) => ({
	id: item?.id || '',
	title: item?.name || '',
	meta: <FormattedMessage {...messages.quick_find_confluence_space} />,
	additionalMeta: <FormattedMessage {...messages.quick_find_recent_spaces_additional_meta} />,
	url: `${CONTEXT_PATH}${item?.links?.webui || ''}`,
	containerId: item?.id || undefined,
	icon: GenericIconRenderer({
		iconUrl: `${CONTEXT_PATH}${item?.icon?.path || ''}`,
		size: 'small',
	}),
	isRecentResult: true,
	type: 'confluence space',
});
