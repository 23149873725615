import React from 'react';
import { FormattedMessage, type IntlShape } from 'react-intl-next';
import { messages } from './messages';
import { type SearchResult } from '../../product-router/product/result-types';
import {
	type AggregatorTownsquareResponse,
	type TownsquareOrPeopleResponse,
	TownsquareScope,
} from './types';
import getAtlasAggregatorRedirectUrl from './url-generators';
import getAtlasViewAllRedirectUrl from './url-generators';
import { type Workspace } from '../../../common/search-config-provider';

let defaultTownsquareResultMapper = ({ results }: AggregatorTownsquareResponse): SearchResult[] =>
	results.map((value) => mapAggregatorResponseToSearchItem(value)).slice(0, MAX_ITEMS_PER_SECTION);

export const MAX_ITEMS_PER_SECTION = 3;

const mapAggregatorResponseToSearchItem = (response: TownsquareOrPeopleResponse): SearchResult => {
	if ('account_id' in response) {
		return {
			title: response.name,
			id: response.account_id,
			meta: '',
			url: response.absoluteUrl || '',
			iconUrl: response.picture,
			containerId: '', // no container
		};
	} else {
		return {
			title: response.title,
			id: response.id,
			meta: '',
			url: response.url,
			iconUrl: response.iconUrl,
			containerId: '', // no container
		};
	}
};

export const getSections = (
	intl: IntlShape,
	workspaces?: Workspace[],
	isGlobalExperienceWorkspace?: boolean,
	orgId?: string,
) => [
	{
		id: TownsquareScope.TownsquareProject,
		title: intl.formatMessage(messages.townsquare_projects_heading),
		showResultCount: false,
		scope: TownsquareScope.TownsquareProject,
		resultMapper: defaultTownsquareResultMapper,
		viewAllLinkGenerator: (query: string) => {
			return getAtlasViewAllRedirectUrl(
				'projects',
				query,
				workspaces,
				isGlobalExperienceWorkspace,
				orgId,
			);
		},
		viewAllLinkRenderer: () => <FormattedMessage {...messages.townsquare_projects_view_all} />,
	},
	{
		id: TownsquareScope.TownsquareGoal,
		title: intl.formatMessage(messages.townsquare_goals_heading),
		scope: TownsquareScope.TownsquareGoal,
		resultMapper: defaultTownsquareResultMapper,
		viewAllLinkGenerator: (query: string) =>
			getAtlasAggregatorRedirectUrl('goals', query, workspaces, isGlobalExperienceWorkspace, orgId),
		viewAllLinkRenderer: () => <FormattedMessage {...messages.townsquare_goals_view_all} />,
	},
	{
		id: TownsquareScope.TownsquareHelpLink,
		title: intl.formatMessage(messages.townsquare_help_links_heading),
		scope: TownsquareScope.TownsquareHelpLink,
		resultMapper: defaultTownsquareResultMapper,
	},
	{
		id: TownsquareScope.TownsquareTag,
		title: intl.formatMessage(messages.townsquare_topics_heading),
		scope: TownsquareScope.TownsquareTag,
		resultMapper: defaultTownsquareResultMapper,
		viewAllLinkGenerator: (query: string) =>
			getAtlasAggregatorRedirectUrl(
				'topics',
				query,
				workspaces,
				isGlobalExperienceWorkspace,
				orgId,
			),
		viewAllLinkRenderer: () => <FormattedMessage {...messages.townsquare_topics_view_all} />,
	},
	{
		id: TownsquareScope.TownsquareLearning,
		title: intl.formatMessage(messages.townsquare_learnings_risks_decisions_heading),
		scope: TownsquareScope.TownsquareLearning,
		resultMapper: defaultTownsquareResultMapper,
	},
];
