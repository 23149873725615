import React, { useCallback, useRef, useState } from 'react';

import {
	CheckboxOption,
	type FormatOptionLabelMeta,
	type InputActionMeta,
	type OptionType,
	PopupSelect,
	type PopupSelectProps,
} from '@atlaskit/select';

import { Control } from '../../filters/select-filter/control';
import { MenuList } from '../../filters/select-filter/menu-list';

type Options = OptionType[] | { label: string; options: OptionType[] }[];

export type PopupSelectOption = {
	isSelected: boolean;
	trackingKey: string;
	value: string;
	queryParamValue: string;
	isSuggested: boolean;
	isDefault: boolean;
	avatarUrl?: string;
	label: string;
};

type FilterPopupSelectProps = {
	defaultIsOpen?: boolean;
	isSearchable?: boolean;
	isLoading?: boolean;
	canClearFilter?: boolean;
	checkboxIndicators?: boolean;
	formatOptionLabel?: (
		data: OptionType,
		formatOptionLabelMeta: FormatOptionLabelMeta<OptionType>,
	) => React.ReactNode;
	placeholder?: string;
	searchInput?: string;
	onSearchInputChange?: (value: string) => void;
	onSelectChange: (value: PopupSelectOption | PopupSelectOption[]) => void;
	onDropdownOpen?: () => void;
	onDropdownClose?: () => void;
	options: Options;
	selectedOptions: PopupSelectOption[];
	label: string;
	noOptionsMessage?: string;
	getFirstSelectedLabel?: (selected: OptionType) => string;
	onClearOptions?: () => void;
	minMenuWidth?: number | string;
	testId?: string;
	target?: PopupSelectProps['target'];
};

export const FilterPopupSelect = ({
	defaultIsOpen = false,
	isSearchable = false,
	isLoading = false,
	canClearFilter = false,
	checkboxIndicators = false,
	formatOptionLabel,
	placeholder,
	searchInput,
	onSearchInputChange,
	onSelectChange,
	onDropdownOpen,
	onDropdownClose,
	options,
	label,
	noOptionsMessage,
	onClearOptions,
	minMenuWidth,
	target,
}: FilterPopupSelectProps) => {
	const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

	// TODO: this can be removed if we have debounce in the store when lookup
	const [currentSearchInput, setCurrentSearchInput] = useState(searchInput);

	const handleInputChange = useCallback(
		(value: string, action: InputActionMeta) => {
			if (action.action !== 'input-change') {
				return;
			}
			setCurrentSearchInput(value);

			if (!onSearchInputChange) {
				return;
			}

			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}

			debounceTimeout.current = setTimeout(() => {
				onSearchInputChange(value);
			}, 300);
		},
		[onSearchInputChange],
	);

	const handleChange = useCallback(
		(value: OptionType | readonly OptionType[]) => {
			onSelectChange(value as PopupSelectOption | PopupSelectOption[]);
		},
		[onSelectChange],
	);

	return (
		<PopupSelect
			blurInputOnSelect={false}
			canClearFilter={canClearFilter}
			closeMenuOnSelect={!checkboxIndicators}
			components={{ Control, MenuList, ...(checkboxIndicators ? { Option: CheckboxOption } : {}) }}
			defaultIsOpen={defaultIsOpen}
			// @ts-ignore - https://product-fabric.atlassian.net/browse/DSP-21000
			defaultOptions={false}
			formatOptionLabel={formatOptionLabel}
			inputValue={currentSearchInput}
			isClearable={false}
			isLoading={isLoading}
			isOptionSelected={(option) => option.isSelected}
			isSearchable={isSearchable}
			label={label}
			maxMenuHeight={400}
			maxMenuWidth="22rem"
			menuPlacement="bottom"
			minMenuWidth={minMenuWidth}
			noOptionsMessage={currentSearchInput ? undefined : () => noOptionsMessage}
			onChange={handleChange}
			onClearOptions={onClearOptions}
			onInputChange={handleInputChange}
			onMenuClose={() => {
				setCurrentSearchInput('');
				onDropdownClose?.();
			}}
			onMenuOpen={onDropdownOpen}
			options={options}
			placeholder={placeholder}
			popperProps={{
				strategy: 'fixed',
				modifiers: [
					{ name: 'offset', options: { offset: [0, 8] } },
					{
						name: 'preventOverflow',
						enabled: false,
					},
				],
			}}
			searchThreshold={-1}
			shouldCloseMenuOnTab={false}
			target={target}
		/>
	);
};
