import { type GasPayload } from '@atlaskit/analytics-gas-types';
export const onSearchResultsRendered = ({
	attributes,
	nonPrivacySafeAttributes,
}: {
	attributes: Record<string, any>;
	nonPrivacySafeAttributes: Record<string, any>;
}): GasPayload => ({
	eventType: 'track',
	action: 'rendered',
	actionSubject: `searchResults`,
	actionSubjectId: `postQuerySearchResults`,
	attributes,
	nonPrivacySafeAttributes,
});
