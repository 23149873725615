import { type GasPayload } from '@atlaskit/analytics-gas-types';

export const onProductFilterSelected = ({
	actionSubjectId,
	attributes,
	nonPrivacySafeAttributes,
}: {
	actionSubjectId: string;
	attributes: Record<string, any>;
	nonPrivacySafeAttributes: Record<string, any>;
}): GasPayload => ({
	eventType: 'track',
	action: 'selected',
	actionSubject: 'productFilter',
	actionSubjectId,
	attributes,
	nonPrivacySafeAttributes,
});
