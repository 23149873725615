import { fg } from '@atlaskit/platform-feature-flags';
import {
	type AggResponse,
	type EntityATI,
	type TProductDataRegions as ProductDataRegions,
	type TProductEdition as ProductEdition,
	searchDialogQuery,
	type SearchPageData,
	type SearchPageNode,
	type SearchPageQueryVariables,
	type ThirdPartyFilters,
} from '@atlassian/search-client';
import type { BackendExperiment, SearchExperimentLayer } from '@atlassian/search-experiment';

import { type PrimaryProductKey, ProductKeys3P } from '../../../../common/constants/products';
import { type ThirdPartyConfigsBootstrap } from '../../../../common/constants/schemas/3p-config';
import {
	type ExperimentConfig,
	type SelectedFiltersType,
} from '../../../../common/constants/types';
import { getBackendExperiment } from '../../../../common/utils/bootstrap';
import {
	getEmptySelectedFilters,
	getIntegrationARIsFromConfig,
	getThirdPartyFilters,
} from '../../../../common/utils/filters';
import { isHello } from '../../../../common/utils/is-hello';
import { isProductSmartlink } from '../../../../common/utils/oauth-container';

import { userShardMock } from './mocks';

const experience = 'quick-find';

export const getThirdPartyFiltersForQuickFind = (thirdPartyConfigs: ThirdPartyConfigsBootstrap) => {
	const smartlinkProducts: ProductKeys3P[] = (
		Object.keys(thirdPartyConfigs) as ProductKeys3P[]
	).filter((productKey) => isProductSmartlink({ productKey, thirdPartyConfigs }));
	const quickFind3PProducts: ProductKeys3P[] = [ProductKeys3P.GoogleDrive as ProductKeys3P].concat(
		fg('quickfind-smartlinks') ? [...smartlinkProducts] : [],
	);
	const quickFind3PEntities = quickFind3PProducts
		.map((productKey) => Array.from(thirdPartyConfigs[productKey]?.typeFilterConfig?.keys() ?? []))
		.flat();
	const quickFindIntegrationARIs = getIntegrationARIsFromConfig();
	const titleMatchValue = false;
	return getThirdPartyFilters(
		quickFind3PProducts,
		titleMatchValue,
		quickFind3PEntities,
		thirdPartyConfigs,
		quickFindIntegrationARIs,
	);
};

export const fetchConfluenceAnd3PEntities = async (
	aggAbsoluteUrl: string | undefined,
	query: string,
	sourceProduct: PrimaryProductKey | undefined,
	cloudId: string,
	searchSessionId: string,
	selectedFilters: SelectedFiltersType | null,
	thirdPartyFilters: ThirdPartyFilters | undefined,
	experimentConfig: ExperimentConfig,
	productEdition: ProductEdition | undefined,
	productDataRegions: ProductDataRegions | undefined,
	setRequestedBackendExperiments: (requestedBackendExperiments: BackendExperiment) => void,
	filteredEntities: string[],
) => {
	// TODO: replace all the hard-coded values of filters with the ones passed on from selectedFilters
	// refer to fetchJiraEntities
	const { commonFilters, confluenceFilters } = selectedFilters || getEmptySelectedFilters();

	const supportedEntities = [
		'ati:cloud:confluence:page',
		'ati:cloud:confluence:blogpost',
		'ati:cloud:confluence:attachment',
		'ati:cloud:confluence:whiteboard',
		'ati:cloud:confluence:database',
		...(fg('add_confluence_entities_quickfind')
			? ['ati:cloud:confluence:embed', 'ati:cloud:confluence:folder']
			: []),
		...(thirdPartyFilters ? ['ati:cloud:graph:document'] : []),
		...(fg('quickfind-smartlinks') ? ['ati:cloud:graph:remote-link'] : []),
	] as EntityATI[];

	const entities = supportedEntities.filter((supportedEntity) =>
		filteredEntities.includes(supportedEntity),
	);

	const backendExperiment = getBackendExperiment({
		experimentConfig,
		entities,
		productEdition,
		isHello: isHello(cloudId),
		productDataRegions,
		experience
	});

	setRequestedBackendExperiments(backendExperiment);

	const { experimentLayers, experimentId, shadowExperimentId } = backendExperiment || {};

	// TODO: Remove this code override and add it via an experiment
	const useUserShardIndexForConfluenceQuery = fg('quick_find_user_shard_query');
	const finalExperimentId = useUserShardIndexForConfluenceQuery
		? 'L1-usershard-shosexp_L2-pipelineOnly__default_QR-cloudberry'
		: experimentId;
	const finalExperimentLayers = useUserShardIndexForConfluenceQuery
		? [
				{
					name: 'search_platform_layer_l1',
					layerId: 'L1-usershard-shosexp',
					shadowId: null,
				},
				{
					name: 'search_platform_layer_l2',
					layerId: 'L2-pipelineOnly',
					shadowId: null,
				},
			]
		: experimentLayers;

	const variables = {
		experience,
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit: 7,
		commonFilters,
		confluenceFilters,
		jiraFilters: {
			issueFilter: {
				projectARIs: [],
				assigneeARIs: [],
				issueTypeIDs: [],
			},
		},
		mercuryFilters: {
			owners: [],
			focusAreaTypeIds: [],
		},
		thirdPartyFilters: thirdPartyFilters ?? { titleMatchOnly: true, subtypes: [] },
		entities,
		sort: [],
		searchSessionId,
		sourceProduct,
		experimentId: finalExperimentId,
		shadowExperimentId,
		experimentLayers: mapExperimentLayers(finalExperimentLayers),
	} satisfies SearchPageQueryVariables;

	const response = await searchDialogQuery({
		variables,
		aggAbsoluteUrl,
	});
	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];
	return {
		fetchResults: nodes.map(mapEssentialData),
		fetchEntitiesRequestId: response.extensions?.gateway?.request_id,
	};
};

export const fetchConfluenceSpaces = async (
	aggAbsoluteUrl: string | undefined,
	query: string,
	sourceProduct: PrimaryProductKey | undefined,
	cloudId: string,
	searchSessionId: string,
	selectedFilters: SelectedFiltersType | null,
	experimentConfig: ExperimentConfig,
	productEdition: ProductEdition | undefined,
	productDataRegions: ProductDataRegions | undefined,
) => {
	// TODO: replace all the hard-coded values of filters with the ones passed on from selectedFilters
	// and remove experiementId and shadowExperimentId as these are no longer used
	// refer to fetchJiraEntities
	const { commonFilters, confluenceFilters } = selectedFilters || getEmptySelectedFilters();

	const entities: EntityATI[] = ['ati:cloud:confluence:space'];

	const backendExperiment = getBackendExperiment({
		experimentConfig,
		entities,
		productEdition,
		isHello: isHello(cloudId),
		productDataRegions,
		experience
	});

	const { experimentLayers, experimentId, shadowExperimentId } = backendExperiment || {};

	const variables = {
		experience,
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit: 1,
		commonFilters,
		confluenceFilters,
		jiraFilters: {
			issueFilter: {
				projectARIs: [],
				assigneeARIs: [],
				issueTypeIDs: [],
			},
		},
		mercuryFilters: {
			owners: [],
			focusAreaTypeIds: [],
		},
		thirdPartyFilters: {
			titleMatchOnly: false,
			subtypes: [],
		},
		entities,
		sort: [],
		searchSessionId,
		sourceProduct,
		experimentId,
		shadowExperimentId,
		experimentLayers: mapExperimentLayers(experimentLayers),
	} satisfies SearchPageQueryVariables;

	const response = await searchDialogQuery({
		variables,
		aggAbsoluteUrl,
	});
	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];
	return {
		fetchResults: nodes.map(mapEssentialData),
		fetchSpacesRequestId: response.extensions?.gateway?.request_id,
	};
};

export const fetchAtlasEntities = async (
	aggAbsoluteUrl: string | undefined,
	query: string,
	sourceProduct: PrimaryProductKey | undefined,
	cloudId: string,
	searchSessionId: string,
	selectedFilters: SelectedFiltersType | null,
	experimentConfig: ExperimentConfig,
	productEdition: ProductEdition | undefined,
	productDataRegions: ProductDataRegions | undefined,
	setRequestedBackendExperiments: (requestedBackendExperiments: BackendExperiment) => void,
) => {
	// TODO: replace all the hard-coded values of filters with the ones passed on from selectedFilters
	// and remove experiementId and shadowExperimentId as these are no longer used
	// refer to fetchJiraEntities
	const { commonFilters } = selectedFilters || getEmptySelectedFilters();

	const entities: EntityATI[] = [
		'ati:cloud:townsquare:project',
		'ati:cloud:townsquare:goal',
		'ati:cloud:townsquare:tag',
	];

	const backendExperiment = getBackendExperiment({
		experimentConfig,
		entities,
		productEdition,
		isHello: isHello(cloudId),
		productDataRegions,
		experience
	});

	setRequestedBackendExperiments(backendExperiment);

	const { experimentLayers, experimentId, shadowExperimentId } = backendExperiment || {};

	const variables = {
		experience,
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit: 6,
		commonFilters,
		confluenceFilters: {
			spacesFilter: [],
			contributorsFilter: [],
			ancestorIdsFilter: [],
			range: [],
			owners: [],
			labelsFilter: [],
			titleMatchOnly: true,
			contentStatuses: [],
			containerStatus: [],
		},
		jiraFilters: {
			issueFilter: {
				projectARIs: [],
				assigneeARIs: [],
				issueTypeIDs: [],
			},
		},
		mercuryFilters: {
			owners: [],
			focusAreaTypeIds: [],
		},
		thirdPartyFilters: {
			titleMatchOnly: false,
			subtypes: [],
		},
		entities,
		sort: [],
		searchSessionId,
		sourceProduct,
		experimentId,
		shadowExperimentId,
		experimentLayers: mapExperimentLayers(experimentLayers),
	} satisfies SearchPageQueryVariables;

	const response = await searchDialogQuery({
		variables,
		aggAbsoluteUrl,
	});

	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];
	return {
		fetchResults: nodes.map(mapEssentialData),
		fetchEntitiesRequestId: response.extensions?.gateway?.request_id,
	};
};

export const fetchMercuryEntities = async (
	aggAbsoluteUrl: string | undefined,
	query: string,
	sourceProduct: PrimaryProductKey | undefined,
	cloudId: string,
	searchSessionId: string,
	selectedFilters: SelectedFiltersType | null,
	experimentConfig: ExperimentConfig,
	productEdition: ProductEdition | undefined,
	productDataRegions: ProductDataRegions | undefined,
	setRequestedBackendExperiments: (requestedBackendExperiments: BackendExperiment) => void,
) => {
	// TODO: replace all the hard-coded values of filters with the ones passed on from selectedFilters
	// and remove experiementId and shadowExperimentId as these are no longer used
	// refer to fetchJiraEntities
	const { commonFilters } = selectedFilters || getEmptySelectedFilters();

	const entities: EntityATI[] = [
		'ati:cloud:mercury:focus-area',
		'ati:cloud:mercury:focus-area-status-update',
	];

	const backendExperiment = getBackendExperiment({
		experimentConfig,
		entities,
		productEdition,
		isHello: isHello(cloudId),
		productDataRegions,
		experience
	});

	setRequestedBackendExperiments(backendExperiment);

	const { experimentLayers, experimentId, shadowExperimentId } = backendExperiment || {};

	const variables = {
		experience,
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit: 6,
		commonFilters: commonFilters,
		confluenceFilters: {
			spacesFilter: [],
			contributorsFilter: [],
			ancestorIdsFilter: [],
			range: [],
			owners: [],
			labelsFilter: [],
			titleMatchOnly: true,
			contentStatuses: [],
			containerStatus: [],
		},
		jiraFilters: {
			issueFilter: {
				projectARIs: [],
				assigneeARIs: [],
				issueTypeIDs: [],
			},
		},
		mercuryFilters: {
			owners: [],
			focusAreaTypeIds: [],
		},
		thirdPartyFilters: {
			titleMatchOnly: false,
			subtypes: [],
		},
		entities,
		sort: [],
		searchSessionId,
		sourceProduct,
		experimentId,
		shadowExperimentId,
		experimentLayers: mapExperimentLayers(experimentLayers),
	} satisfies SearchPageQueryVariables;

	const response = await searchDialogQuery({
		variables,
		aggAbsoluteUrl,
	});

	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];
	return {
		fetchResults: nodes.map(mapEssentialData),
		fetchEntitiesRequestId: response.extensions?.gateway?.request_id,
	};
};

/**
 * The experiment layers are mapped to ensure permitted attributes are sent into search requests.
 */
const mapExperimentLayers = (experimentLayers?: SearchExperimentLayer[]) =>
	experimentLayers?.map(({ name, layerId, shadowId, definitions }) => ({
		name,
		layerId,
		shadowId,
		definitions,
	}));

export const fetchJiraEntities = async (
	aggAbsoluteUrl: string | undefined,
	query: string,
	sourceProduct: PrimaryProductKey | undefined,
	cloudId: string,
	searchSessionId: string,
	selectedFilters: SelectedFiltersType | null,
	experimentConfig: ExperimentConfig,
	productEdition: ProductEdition | undefined,
	productDataRegions: ProductDataRegions | undefined,
	setRequestedBackendExperiments: (requestedBackendExperiments: BackendExperiment) => void,
) => {
	const { commonFilters, confluenceFilters, mercuryFilters, thirdPartyFilters, jiraFilters } =
		selectedFilters || getEmptySelectedFilters();

	const entities: EntityATI[] = [
		'ati:cloud:jira:board',
		'ati:cloud:jira:dashboard',
		'ati:cloud:jira:filter',
		'ati:cloud:jira:issue',
		'ati:cloud:jira:project',
	];

	const backendExperiment = getBackendExperiment({
		experimentConfig,
		entities,
		productEdition,
		isHello: isHello(cloudId),
		productDataRegions,
		experience
	});

	setRequestedBackendExperiments(backendExperiment);

	const { experimentLayers } = backendExperiment || {};

	const variables = {
		experience,
		query,
		queryVersion: 1,
		cloudIdARI: `ari:cloud:jira::site/${cloudId}`,
		tenantId: cloudId,
		limit: 7,
		commonFilters,
		confluenceFilters,
		jiraFilters,
		mercuryFilters,
		thirdPartyFilters,
		entities,
		sort: [],
		searchSessionId,
		sourceProduct,
		experimentLayers: mapExperimentLayers(experimentLayers),
	} satisfies SearchPageQueryVariables;
	const response = await searchDialogQuery({
		variables,
		aggAbsoluteUrl,
	});
	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];
	return {
		fetchResults: nodes.map(mapEssentialData),
		fetchEntitiesRequestId: response.extensions?.gateway?.request_id,
	};
};

export const mapEssentialData = (result: SearchPageNode) => ({
	id: result.id,
	title: result.title,
	data: result,
	type: 'search-result' as const,
});

export const fetchConfluenceAndGoogleUserShardMock = async () => {
	const response = (await Promise.resolve(userShardMock)) as unknown as AggResponse<SearchPageData>;
	const nodes = response.data?.search.results.edges.map((edge) => edge.node) || [];

	return {
		results: nodes.map(mapEssentialData),
		fetchEntitiesRequestId: undefined,
		fetchSpacesRequestId: undefined,
	};
};
