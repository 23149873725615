import type { ScopedAggregatorResponse, Site } from '../../../common/clients';
import type { ConfItemResult } from '../../../confluence/clients/response-types';
import type { PostQuerySupplierArgs } from '../../product-router';
import type { ConfluenceGraphQLSearchQueryType } from '@atlassian/search-client';
import { type ResultRendererArgs } from '../../product-router/product/result-types';

export const CONFLUENCE_PRODUCT_ID = 'confluence';
export const NAVIGATION_V3_EXPERIENCE = 'confluence.nav-v3';
export const INPUT_DEBOUNCE_TIME = 250;
export const SPACE_FILTER_LIMIT = 10;
export const PEOPLE_FILTER_LIMIT = 10;

export enum ConfluenceFilters {
	Space = 'spaces',
	People = 'contributors',
	Date = 'lastModified',
}

export enum ConfluenceScope {
	Item = 'confluence.page,blogpost,attachment',
	Space = 'confluence.space',
	People = 'urs.user-confluence',
	Content = 'confluence.page,blogpost,attachment,whiteboard',
}

export interface ConfluenceRecentsClientConfig {
	url: string;
	cloudId: string;
	isUserAnonymous: boolean;
	isCollaborationGraphEnabled: boolean;
	collaborationGraphUrl?: string;
	recentWorkAPIUrl?: string;
	sites?: Site[];
	collabGraphSessionId?: string;
}

export type ConfluenceSearchClientConfig =
	| {
			graphQLEnabled: true;
			graphQLPromise: (args: PostQuerySupplierArgs) => Promise<ConfluenceGraphQLSearchQueryType>;
			cloudId: string;
			userId?: string;
	  }
	| {
			graphQLEnabled: false;
			graphQLPromise?: undefined;
			cloudId?: undefined;
			userId?: undefined;
	  };

export interface ConfItemResponseItem {
	title: string; // this is highlighted
	baseUrl: string;
	url: string;
	content?: {
		id: string;
		type: 'page' | 'blogpost' | 'space' | 'attachment' | 'whiteboard';
		space?: {
			id: string;
		};
	};
	container: {
		title: string;
		id?: string; // This has to be optional because Confluence doesn't return it
		displayUrl?: string;
	};
	space?: {
		key: string; // currently used as instance-unique ID
		icon: {
			path: string;
		};
	};
	iconCssClass: string; // icon-file-* for attachments, otherwise not needed
	lastModified: string; // e.g. "2019-07-08T02:54:38.822Z"
}

export interface ConfSpaceResponseItem extends ConfItemResponseItem {
	id: string; // the id is present at the root for spaces responses
}

export interface ConfPersonResponseItem {
	absoluteUrl?: string; // For any TCS errors this field will not be present in the response.
	avatarUrl: string;
	entityType: string;
	id: string;
	name: string;
	nickname?: string;
}

interface RecentsConfItemResponse {
	results: ConfItemResult[];
}

export type SuppoprtedConfRecentsResponses = RecentsConfItemResponse;

interface AggregatorConfItemResponse
	extends ScopedAggregatorResponse<ConfluenceScope.Item | ConfluenceScope.Content> {
	results: ConfItemResponseItem[];
	size?: number;
}
interface AggregatorConfSpaceResponse extends ScopedAggregatorResponse<ConfluenceScope.Space> {
	results: ConfSpaceResponseItem[];
}
export interface AggregatorConfPeopleResponse
	extends ScopedAggregatorResponse<ConfluenceScope.People> {
	results: ConfPersonResponseItem[];
}

export type SupportedConfScopeResponses =
	| AggregatorConfItemResponse
	| AggregatorConfSpaceResponse
	| AggregatorConfPeopleResponse;

export interface DiscoverAppsInSearch {
	enabled: boolean;
	isAppRecommendationDismissed?: boolean;
	setIsAppRecommendationDismissedFlag?: (isDismissed: boolean) => void;
}

export interface ConfluenceFeatures {
	discoverAppsInSearch?: DiscoverAppsInSearch;
}

export type RendererOverrideTypes = {
	ConfluenceResultRenderer: ({ searchResult, ...rest }: ResultRendererArgs) => JSX.Element;
	contentScope?: string;
};
