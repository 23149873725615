import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import { Inline, xcss } from '@atlaskit/primitives';
import { FeedbackButton } from '@atlassian/search-common';

import FeedbackCollector from '../../../../common/ui/feedback-collector';
import {
	useApplicationMode,
	useCloudId,
	useExperimentConfig,
	useIsHello,
	usePrimaryProduct,
	useSelectedProducts,
	useUser,
} from '../../../../controllers/store';
import { useIsFeedbackModalOpen } from '../../../../controllers/store/quick-find';

import { messages } from './messages';

const footerContainerStyles = xcss({
	paddingBlockStart: 'space.150',
	paddingBlockEnd: 'space.150',
	paddingInlineStart: 'space.200',
	paddingInlineEnd: 'space.200',
	color: 'color.text',
	gap: 'space.150',
});

export const FeedbackFooter = () => {
	const [feedbackIsOpen, { setIsFeedbackModalOpen }] = useIsFeedbackModalOpen();

	const [cloudId] = useCloudId();
	const [experimentConfig] = useExperimentConfig();
	const [isHello] = useIsHello();
	const [primaryProduct] = usePrimaryProduct();
	const [selectedProducts] = useSelectedProducts();
	const [user] = useUser();
	const applicationMode = useApplicationMode();

	return (
		<Inline alignBlock="center" space="space.100" xcss={footerContainerStyles}>
			<FormattedMessage {...messages.feedbackFooter} />
			<FeedbackButton
				onClick={(e) => {
					e.preventDefault();
					setIsFeedbackModalOpen(true);
				}}
			/>
			<FeedbackCollector
				cloudId={cloudId || ''}
				experimentConfig={experimentConfig}
				isHello={isHello}
				isOpen={feedbackIsOpen}
				primaryProduct={primaryProduct || ''}
				product={selectedProducts.length ? selectedProducts.join(', ') : 'no product filter'}
				setIsOpen={setIsFeedbackModalOpen}
				source="quick-find"
				userId={user.id || ''}
				userName={user.name || ''}
				mode={applicationMode}
			/>
		</Inline>
	);
};
