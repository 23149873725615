import { attachJiraReferralIds } from '../../../jira/jira-search-results/jira-search-results-lists/attach-referral-ids';
import {
	type AttributeBoard,
	type AttributeIssue,
	AttributeType,
} from '../../../jira/clients/response-types';
import { type JiraScopeItemResponse } from './types';
import { addQueryParams } from '../../../utils/url-utils';

export type JiraResultWithType = {
	type: AttributeType;
};

export const attachIssueSectionQueryParams = (
	href: string,
	searchResult: JiraScopeItemResponse,
) => {
	const hrefWithReferrals = attachJiraReferralIds(href, {
		searchObjectId: searchResult.id,
		searchContainerId: (searchResult.attributes as AttributeIssue).containerId,
		searchContentType: AttributeType.issue,
	});

	return hrefWithReferrals;
};

export const getProjectBoardFilterPlanQueryParams = (
	href: string,
	searchResult: JiraScopeItemResponse,
) => {
	const containerAttribute =
		searchResult.attributes['@type'] === AttributeType.board
			? {
					searchContainerId: (searchResult.attributes as AttributeBoard).containerId,
				}
			: {};
	const contentTypeAttribute = {
		searchContentType: searchResult.attributes['@type'],
	};

	const hrefWithReferrals = attachJiraReferralIds(href, {
		...contentTypeAttribute,
		...containerAttribute,
		searchObjectId: searchResult.id,
	});

	return hrefWithReferrals;
};

export const useQueryParams = () => {
	return (href: string, searchResult: JiraScopeItemResponse) => {
		if (searchResult.attributes['@type'] === AttributeType.issue) {
			return attachIssueSectionQueryParams(href, searchResult);
		}

		return getProjectBoardFilterPlanQueryParams(href, searchResult);
	};
};

export const appendQueryParams = (url: string, searchSessionId: string) => {
	return addQueryParams(url, { searchSessionId });
};
