import { PLACEHOLDER_EXPERIENCE } from '../aggregator-client-context';
import {
	type AggregatorClient,
	type ScopedAggregatorResponse,
	type SearchContext,
} from '../../common/clients';
import { useSearchSessionId } from '../../common/search-session-provider';
import { type PostQuerySupplierArgs, type ResultSuppliers } from '../product-router/product';
import {
	type SearchResult,
	type SearchResultSection,
	type Section,
} from '../product-router/product/result-types';
import { useTypedAggregatorClient } from '../aggregator-client-context/aggregator-client-context';
import { type AllFilters, type GenericFilter, SITE_FILTER_TYPE_NAME } from '../filters';

const MAX_DEFAULT_SITES = 3;

/**
 * A definition of a {@link Section} in the Search Platform which is backed by
 * xpsearch-aggregator. Enforces the section to have a scope and a more
 * strongly typed resultMapper for transforming results for the specified scope
 * into a common format.
 */
export interface AggregatorSection<
	Scope,
	Response extends ScopedAggregatorResponse<Scope>,
	ExtendedSearchResult = {},
> extends Section<ExtendedSearchResult> {
	scope: Scope;
	resultMapper: (response: Response) => SearchResult<ExtendedSearchResult>[];
}

export const createSearchSupplier =
	<
		Response extends ScopedAggregatorResponse<Scope>,
		Scope = Response['id'],
		ExtendedSearchResult = {},
	>(
		aggregatorClient: AggregatorClient<Response, AllFilters> | undefined,
		sections: AggregatorSection<Scope, Response, ExtendedSearchResult>[],
		context: SearchContext,
		experience: string,
	) =>
	async ({
		query,
		filters: allFilters,
		workspaces,
		sectionIds: allPermissionedScopes,
	}: PostQuerySupplierArgs) => {
		const defaultSites = workspaces?.slice(0, MAX_DEFAULT_SITES).map((workspace) => workspace.id);

		// Extract sites from filters
		const siteFilter = allFilters.find((filter) => filter['@type'] === SITE_FILTER_TYPE_NAME);
		// @ts-ignore - typescript is not smart enough to know `siteFilter` will only ever be the sites filter
		const sites = siteFilter?.values || defaultSites || [];

		// Remove sites from the filters array
		const filters = allFilters
			.filter((filter) => filter['@type'] !== SITE_FILTER_TYPE_NAME)
			.map((f) => f as GenericFilter);

		const permittedSections = sections
			.filter((section) => allPermissionedScopes.indexOf(section.id) >= 0)
			.filter((section) => (section.isEnabled ? section.isEnabled(query, filters) : true));
		const scopes: Scope[] = permittedSections.map((section) => section.scope);

		if (!aggregatorClient) {
			return {
				sections: [],
			};
		}

		const { response } = await aggregatorClient.search({
			query,
			scopes,
			modelParams: [],
			experience,
			context,
			filters,
			sites,
		});

		const sectionsWithResults: SearchResultSection[] = permittedSections.map(
			({ scope, id, title, resultMapper, ...rest }) => {
				const results = response.retrieveScope(scope);

				if (results === null) {
					throw new Error(`Scope ${scope} was not found in the response, despite being requested`);
				}

				return {
					...rest,
					id,
					title,
					size: results.size,
					searchResults: resultMapper(results) || [],
					resultMapper,
				};
			},
		);

		const scopesWithSize = response.rawData.scopes.filter(({ size }) => size);
		const reponseHasSize = scopesWithSize.length > 0;

		const totalSizeAsReportedByAPI = reponseHasSize
			? response.rawData.scopes.reduce((count, scope) => count + (scope.size || 0), 0)
			: undefined;

		return {
			size: totalSizeAsReportedByAPI,
			sections: sectionsWithResults,
		};
	};

/**
 * Returns a {@link ResultSuppliers} object containing suppliers for pre and post query which
 * are backed by the search platform.
 *
 * The suppliers consider registered scopes, that is scopes that have had permissions evaluated for. I.e,
 * these suppliers will not request scopes that the {@link SearchDialogProduct} permission supplier has
 * returned no permission for.
 *
 * @param id The product id to generate suppliers for
 * @param sections the sections in the search dialog to generate suppliers for. These sections are returned in each supplier,
 * with results from the search platform attached to them.
 * @param overrides to override the item suppliers with input props
 * @returns a {@link ResultSuppliers} object containing a pre and post query supplier
 */
export const useDefaultSuppliers = <
	Response extends ScopedAggregatorResponse<Scope>,
	Scope = Response['id'],
	ExtendedSearchResult = {},
>(
	id: string,
	sections: AggregatorSection<Scope, Response, ExtendedSearchResult>[],
	overrides?: Partial<ResultSuppliers>,
): ResultSuppliers => {
	const aggregatorClient = useTypedAggregatorClient<Response, Scope>();

	const sessionId = useSearchSessionId();

	const search = createSearchSupplier(
		aggregatorClient,
		sections,
		{
			sessionId,
			referrerId: '',
		},
		PLACEHOLDER_EXPERIENCE,
	);

	return {
		preQueryItemSupplier:
			overrides?.preQueryItemSupplier ?? ((args) => search({ ...args, query: '', filters: [] })),
		postQueryItemSupplier: overrides?.postQueryItemSupplier ?? search,
	};
};
