import { useCallback } from 'react';

const CONFLUENCE_ADVANCED_SEARCH_URL = '/wiki/search';

const removeDuplicateWikiInUrl = (siteUrl: string) => {
	return siteUrl.replace(/\/wiki(\/)?$/, '');
};

export function useConfluenceAdvancedSearchUrlFactory(
	siteUrl: string,
	filters: {
		spaceFilters: string[];
		peopleFilters: string[];
		dateFilter: string[];
	},
) {
	const trimmedSiteUrl = removeDuplicateWikiInUrl(siteUrl);
	const spaceFilterString =
		filters.spaceFilters.length > 0
			? `&spaces=${encodeURIComponent(filters.spaceFilters.join(','))}`
			: '';
	const peopleFilterString =
		filters.peopleFilters.length > 0
			? `&contributors=${encodeURIComponent(filters.peopleFilters.join(','))}`
			: '';
	const dateFilterString =
		filters.dateFilter.length > 0 ? `&lastModified=${filters.dateFilter[0]}` : '';

	return useCallback(
		(query: string, siteUrl?: string) => {
			const url = siteUrl ? removeDuplicateWikiInUrl(siteUrl) : trimmedSiteUrl;
			const trimmedQuery = query.trim();
			if (!trimmedQuery) {
				return `${url}${CONFLUENCE_ADVANCED_SEARCH_URL}`;
			}
			return `${url}${CONFLUENCE_ADVANCED_SEARCH_URL}?text=${encodeURIComponent(
				trimmedQuery,
			)}${spaceFilterString}${peopleFilterString}${dateFilterString}`;
		},
		[trimmedSiteUrl, spaceFilterString, peopleFilterString, dateFilterString],
	);
}

export const defaultFormatDate = (lastModified: string) => {
	const date = new Date(lastModified);
	return date.toLocaleString();
};
