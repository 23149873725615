import { useCallback } from 'react';

import { onTextEntered } from '../../../common/utils/quick-find/events/text-entered';
import { useDebouncedCallback } from '../../../common/utils/use-debounced-callback';
import {
	useQuickFindAnalytics,
	useQuickFindAttributes,
} from '../../../controllers/quick-find/analytics';
import { useQuickFindOpen } from '../../../controllers/store/quick-find';

const ON_TEXT_ENTERED_ANALYTICS_DEBOUNCE_TIME = 300;

export const useDebouncedOnTextEnteredAnalyticsCallback = () => {
	const quickFindOpen = useQuickFindOpen();

	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	const fireOnTextEnteredAnalyticsCallback = useCallback(() => {
		if (!quickFindOpen) {
			return;
		}

		fireAnalyticsEvent(
			onTextEntered({
				attributes: commonAttributes,
				nonPrivacySafeAttributes,
			}),
		);
	}, [commonAttributes, fireAnalyticsEvent, nonPrivacySafeAttributes, quickFindOpen]);

	return useDebouncedCallback(
		fireOnTextEnteredAnalyticsCallback,
		ON_TEXT_ENTERED_ANALYTICS_DEBOUNCE_TIME,
	);
};
