import {
	ContentType,
	type Scope,
	type ServerResponse,
	type Result,
	type Results,
	AttributeType,
	type AttributeIssue,
} from './response-types';
import { attributeTypeToContentType } from './jira-search-client';
import uniqBy from 'lodash/uniqBy';
import { generatePeopleProfileUrl } from '../../common/clients/multi-site-utils';

export function transformJiraIssueScope(
	response: ServerResponse<Scope.JiraIssue>,
	isCached: boolean,
	timings: number,
): Results<Scope.JiraIssue> {
	const { results, size } = response;
	return {
		items: results
			.filter((item) => item.attributes['@type'] === AttributeType.issue)
			.map<Result<AttributeIssue>>((item) => {
				return {
					resultId: item.id,
					name: item.name,
					href: item.url,
					contentType: ContentType.JiraIssue,
					attributes: {
						...item.attributes,
						isRecentResult: isCached,
					},
					isCached,
				};
			}),
		timings,
		totalSize: size || results.length,
	};
}
export function transformJiraPlanScope(
	results: ServerResponse<Scope.JiraPlan>['results'],
	isCached: boolean,
	timings: number,
): Results<Scope.JiraPlan> {
	return {
		items: results.map((item) => {
			return {
				resultId: item.id,
				name: item.name,
				href: item.url,
				contentType: ContentType.JiraPlan,
				attributes: item.attributes,
				isCached,
			};
		}),
		timings,
		totalSize: results.length,
	};
}

export function transformJiraBoardProjectFilterScope(
	results: ServerResponse<Scope.JiraBoardProjectFilterPlan>['results'],
	isCached: boolean,
	timings: number,
): Results<Scope.JiraBoardProjectFilterPlan> {
	return {
		items: results
			.filter((item) =>
				[
					AttributeType.board,
					AttributeType.project,
					AttributeType.filter,
					AttributeType.plan,
				].includes(item.attributes['@type']),
			)
			.map((item) => {
				const contentType = attributeTypeToContentType(item.attributes['@type']) as
					| ContentType.JiraProject
					| ContentType.JiraBoard
					| ContentType.JiraFilter
					| ContentType.JiraPlan;

				return {
					resultId: item.id,
					name: item.name,
					href: item.url,
					contentType: contentType,
					attributes: item.attributes,
					isCached,
				};
			}),
		timings,
		totalSize: results.length,
	};
}

export function transformJiraProjectScope(
	results: ServerResponse<Scope.JiraProject>['results'],
	isCached: boolean,
	timings: number,
): Results<Scope.JiraProject> {
	return {
		items: results.map((item) => {
			return {
				resultId: item.id,
				name: item.name,
				href: item.url,
				contentType: ContentType.JiraProject,
				attributes: item.attributes,
				isCached,
			};
		}),
		timings,
		totalSize: results.length,
	};
}

export function transformUrsPeople(
	results: ServerResponse<Scope.People>['results'],
	isCached: boolean,
	timings: number,
	isMultiSite: boolean,
): Results<Scope.People> {
	return {
		items: uniqBy(results, (item) => item.id).map((item) => ({
			resultId: item.id,
			name: item.name,
			href: generatePeopleProfileUrl(isMultiSite, item.absoluteUrl, item.id, 'jira'),
			contentType: ContentType.JiraPeople,
			attributes: {
				'@type': AttributeType.people,
				avatarUrl: item.avatarUrl,
				userId: item.id,
			},
			isCached,
		})),
		timings,
		totalSize: results.length,
	};
}
