import { useEffect, useRef } from 'react';

import { bindAll } from 'bind-event-listener';

import { fg } from '@atlaskit/platform-feature-flags';

import {
	useIsBulkConnectModalOpen,
	useIsFeedbackModalOpen,
	useQuickFindActions,
} from '../../../controllers/store/quick-find';

export const useCloseManager = ({
	dialogRef,
	inputBoxRef,
}: {
	dialogRef: React.RefObject<HTMLDivElement>;
	inputBoxRef: React.RefObject<HTMLDivElement>;
}) => {
	const mouseDownInside = useRef(false);

	const [isFeedbackModalOpen] = useIsFeedbackModalOpen();
	const [isBulkConnectModalOpen] = useIsBulkConnectModalOpen();
	const { setQuickFindOpen } = useQuickFindActions();

	useEffect(() => {
		const onMouseDown = (event: MouseEvent | React.MouseEvent) => {
			mouseDownInside.current = false;

			const { target } = event;
			const targetNode = target as Node | null;

			const isClickOnPopup = dialogRef.current && dialogRef.current.contains(targetNode);
			const isClickOnTrigger = inputBoxRef.current && inputBoxRef.current.contains(targetNode);

			if (isClickOnPopup || isClickOnTrigger) {
				mouseDownInside.current = true;
			}
		};

		const onClick = (event: MouseEvent | React.MouseEvent) => {
			const { target } = event;
			const targetNode = target as Node | null;

			if (isFeedbackModalOpen) {
				return;
			}

			if (isBulkConnectModalOpen && fg('3p_bulk_connect')) {
				return;
			}

			const isClickOnPopup = dialogRef.current && dialogRef.current.contains(targetNode);
			const isClickOnTrigger = inputBoxRef.current && inputBoxRef.current.contains(targetNode);

			if (!isClickOnPopup && !isClickOnTrigger && !mouseDownInside.current) {
				setQuickFindOpen(false);
			}
		};

		const onKeyDown = (event: KeyboardEvent | React.KeyboardEvent) => {
			const { key } = event;
			if (key === 'Escape' || key === 'Esc') {
				setQuickFindOpen(false);
			}
		};

		const onBlur = (e: FocusEvent) => {
			const activeElement = document.activeElement;
			const activeElementIsIFrame = activeElement instanceof HTMLIFrameElement;

			// Close Quick Find if the new active element is an iframe, we need
			// to do this check since click events are not fired on iframes
			if (activeElementIsIFrame) {
				setQuickFindOpen(false);
			}
		};

		const unbind = bindAll(window, [
			{
				type: 'mousedown',
				listener: onMouseDown,
				options: { capture: false },
			},
			{
				type: 'click',
				listener: onClick,
				options: {
					capture: true, // Closes the dialog before SPA transitions
				},
			},
			{
				type: 'keydown',
				listener: onKeyDown,
			},
			{
				type: 'blur',
				listener: onBlur,
			},
		]);

		return () => {
			unbind();
		};
	}, [setQuickFindOpen, isFeedbackModalOpen, isBulkConnectModalOpen, dialogRef, inputBoxRef]);
};
