import React, { type PropsWithChildren, useEffect, useState } from 'react';
import { type ProductContextProps, withProductContext } from '../product-router';
import { type Product } from '../product-router-state';
import { type Section } from './result-types';

type SectionProps<T = {}> = {
	sections: Section<T>[];
	allowedSections: string[];
	autocorrectEnabled?: boolean;
};

export type RegisterProductProps<T = {}> = Omit<Product, 'isDisplayed' | 'sectionIds'> &
	SectionProps<T>;

/**
 * It validates that the sections which have been asked to registered are actually present in the allowed list.
 * After validation it calls the Product Router method to register the product.
 */
export const RegisterProduct = ({
	addProduct,
	updateProduct,
	id,
	title,
	children,
	allowedSections,
	sections,
	order,
	generateAdvancedSearchUrl,
	expandedStateInputPlaceholder,
	workspaces,
	autocorrectEnabled,
	showViewAllFiltersLink,
	tabRenderer,
	aiSearchConfig,
	showSearchOnEnter,
	invokePostQuerySearchRef,
}: PropsWithChildren<ProductContextProps & RegisterProductProps>) => {
	const [originalId] = useState(id);
	const [registeredProduct, setRegisteredProduct] = useState(false);

	useEffect(() => {
		if (allowedSections.length > 0) {
			const allowedSectionString = allowedSections.join(' ');
			const allowedRegisteredSections = sections
				.map((sec) => sec.id)
				.filter((sectionId) => {
					const sectionIds = sectionId.replace(id, '').replace('.', '').split(',');
					let allowed = true;

					sectionIds.forEach((section) => {
						if (allowedSectionString.indexOf(section) === -1) {
							allowed = false;
						}
					});
					return allowed;
				});
			if (allowedRegisteredSections.length > 0) {
				addProduct({
					id,
					title,
					sectionIds: sections.map((sec) => sec.id),
					order,
					generateAdvancedSearchUrl,
					expandedStateInputPlaceholder,
					workspaces,
					autocorrectEnabled,
					showViewAllFiltersLink,
					tabRenderer,
					aiSearchConfig,
					showSearchOnEnter,
					invokePostQuerySearchRef,
				});

				setRegisteredProduct(true);
			}
		}
		/**
		 * Updating of state is a side effect. We want it to happen in component did mount stage. Hence replicating this behaviour.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!registeredProduct) {
			return;
		}

		if (id !== originalId) {
			// eslint-disable-next-line no-console
			console.error(
				"product-search-dialog: Cannot update a different product's properties. Did you change accidentally change a product's id?",
			);
			return;
		}
		updateProduct({
			id,
			generateAdvancedSearchUrl,
			tabRenderer,
		});
	}, [registeredProduct, id, originalId, updateProduct, generateAdvancedSearchUrl, tabRenderer]);

	return <>{children}</>;
};

export default withProductContext(RegisterProduct);
