export const PRELOAD_SEARCH_PAGE_RESULTS_EVENT = 'preload-search-page-results';

export type Contributors = { contributors?: string | boolean | string[] };

export type PreloadSearchPageResultsPayload = Contributors & {
	searchQuery?: string;
};

export const preloadSearchPageResults = (detail?: PreloadSearchPageResultsPayload) => {
	window.dispatchEvent(new CustomEvent(PRELOAD_SEARCH_PAGE_RESULTS_EVENT, { detail }));
};
