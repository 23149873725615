export type FocusAreaHierarchyLevels = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export type FocusAreaStatuses =
	| 'pending'
	| 'paused'
	| 'on_track'
	| 'off_track'
	| 'at_risk'
	| 'completed';

export function isFocusAreaHierarchyLevel(level: number): level is FocusAreaHierarchyLevels {
	return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].indexOf(level) !== -1;
}

export function isFocusAreaStatus(status: string): status is FocusAreaStatuses {
	return (
		['pending', 'paused', 'on_track', 'off_track', 'at_risk', 'completed'].indexOf(status) !== -1
	);
}

export type FocusAreaSizeVariations = 'xsmall' | 'small' | 'medium' | 'large';

export type FocusAreaIconMapProps = {
	[key in FocusAreaHierarchyLevels]: (
		color: string,
		size: 'xsmall' | 'small' | 'medium' | 'large',
		testId?: string,
		label?: string,
	) => JSX.Element;
};

export interface FocusAreaIconsProps {
	/**
	 * Determines the size of the Focus Area icon
	 */
	size?: FocusAreaSizeVariations;

	/**
	 * Determines the color of the focus area icon
	 */
	status?: FocusAreaStatuses;

	/**
	 * Determines which icons to show based on the focus area depth level
	 */
	level: FocusAreaHierarchyLevels;

	/**
	 * Test id for container element
	 */
	testId?: string;

	/**
	 * Accessible description for the focus area icon
	 */
	label?: string;
}
