import React, { type PropsWithChildren } from 'react';
import { SearchDialogAnalyticsContext } from '../analytics';

export const QuickFindAnalyticContext = ({
	children,
	isQuickFind = false,
}: PropsWithChildren<{ isQuickFind?: boolean }>) => (
	<SearchDialogAnalyticsContext
		analyticContext={{ isQuickFind: isQuickFind }}
		nonPrivacySafeAnalyticContext={{}}
	>
		{children}
	</SearchDialogAnalyticsContext>
);
