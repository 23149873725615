import React from 'react';

import { useIntl } from 'react-intl-next';

import CustomizeIcon from '@atlaskit/icon/core/customize';
import { Box, xcss } from '@atlaskit/primitives';

import { SEARCH_DIALOG_WIDTH_OBSERVER_ID } from '../../../../common/constants/quick-find';
import { useElementWidth } from '../../../../common/ui/width-observer';
import { useSelectedProduct } from '../../../../controllers/store';

import { InlineAppSelector } from './inline-app-selector';
import { InlineProductFilters } from './inline-product-filters';
import { i18n } from './messages';

const containerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingInline: 'space.200',
	paddingBlockStart: 'space.025',
	paddingBlockEnd: 'space.075',
});

const filterDropdownIconStyles = xcss({
	padding: 'space.050',
	marginInlineEnd: 'space.100',
});

export const QuickFindFilters = () => {
	const { formatMessage } = useIntl();
	const [selectedProduct] = useSelectedProduct();
	const dialogWidth = useElementWidth(SEARCH_DIALOG_WIDTH_OBSERVER_ID);

	const isDialogAbove275px = dialogWidth > 275;
	const displayCustomizeIcon = isDialogAbove275px || !selectedProduct;

	return (
		<Box xcss={containerStyles}>
			{displayCustomizeIcon && (
				<Box xcss={filterDropdownIconStyles}>
					<CustomizeIcon label={formatMessage(i18n.filterByIcon)} />
				</Box>
			)}
			<InlineAppSelector />
			<InlineProductFilters />
		</Box>
	);
};
