import {
	type KeyValues,
	type RequestServiceOptions,
	type ServiceConfig,
	utils,
} from '@atlaskit/util-service-support';
import type { ActivityItem } from '@atlassian/recent-work-client';
import { CancellablePromise } from '@atlassian/search-client';
import { SpaceContainerType } from '../../common/clients/common-types';
import {
	type CollaborationGraphClient,
	type CollaborationGraphResponse,
	type CollaborationGraphContainer,
	timed,
} from '../../common/clients';
import { SimpleCache } from '../../utils/simple-cache';
import {
	type ConfItemResult,
	type ConfItemResults,
	type ConfluenceItemContentType,
	ConfluenceObjectResult,
	ConfluenceSpace,
	type ConfSpaceResult,
	type ConfSpaceResults,
	type ContentType,
	GenericContainerResult,
	RecentConfluence,
} from './response-types';

export const RECENT_PAGES_PATH: string = 'rest/recentlyviewed/1.0/recent';
export const RECENT_SPACE_PATH: string = 'rest/recentlyviewed/1.0/recent/spaces';

const RECENT_PAGES_LIMIT: number = 200;
const RECENT_SPACE_LIMIT: number = 5;

export interface RecentPageResponse {
	available: boolean;
	contentType: ConfluenceItemContentType;
	id: number;
	lastSeen: number;
	space: string;
	spaceKey: string;
	title?: string; // Due to some Confluence bug there is a chance that recent pages come back with NO title
	type: string;
	url: string;
	iconClass: string;
	spaceId: number;
}

export interface RecentSpaceResponse {
	id: string;
	key: string;
	icon: string;
	name: string;
}

export interface ConfluenceRecentsClientConfig {
	url: string;
	isCollaborationGraphEnabled: boolean;
	recentItemsSupplier?: () => Promise<ConfItemResults>;
	useRecentWork?: boolean;
	recentWorkAPIUrl?: string;
}

export class ConfluenceRecentsClient {
	private baseUrl: string;

	private serviceConfig: ServiceConfig;

	private recentItemCache: SimpleCache<Promise<ConfItemResults>>;

	private recentSpaceCache: SimpleCache<Promise<ConfSpaceResults>>;

	private isCollabGraphEnabled: boolean;

	private collaborationGraphClient: CollaborationGraphClient;

	private useRecentWork: boolean;

	private recentWorkAPIUrl?: string;

	constructor(
		{
			url,
			isCollaborationGraphEnabled,
			recentItemsSupplier,
			useRecentWork,
			recentWorkAPIUrl,
		}: ConfluenceRecentsClientConfig,
		collabGraphClient: CollaborationGraphClient,
	) {
		this.serviceConfig = { url: url };
		this.baseUrl = url;
		this.recentItemCache = new SimpleCache(recentItemsSupplier || this.recentItemsSupplier);
		this.recentSpaceCache = new SimpleCache(this.recentSpacesSupplier);
		this.collaborationGraphClient = collabGraphClient;
		this.isCollabGraphEnabled = isCollaborationGraphEnabled;
		this.useRecentWork = useRecentWork || false;
		this.recentWorkAPIUrl = recentWorkAPIUrl;
	}

	protected getRecent<T extends ConfItemResults | ConfSpaceResults>(
		cache: SimpleCache<Promise<T>>,
	) {
		const { fromCache, value } = cache.get();
		return CancellablePromise.from(value.then((results) => ({ ...results, isCached: fromCache })));
	}

	public getRecentItems(): CancellablePromise<ConfItemResults> {
		return this.getRecent(this.recentItemCache);
	}

	public getRecentSpaces(): CancellablePromise<ConfSpaceResults> {
		return this.getRecent(this.recentSpaceCache);
	}

	private recentItemsSupplier: () => Promise<ConfItemResults> = async () => {
		if (this.useRecentWork) {
			const { createActivityClient } = await import(
				/* webpackChunkName: "@atlaskit-internal_product-search-dialog/recent-work-client" */
				'@atlassian/recent-work-client'
			);
			const activityClient = createActivityClient(
				'v3',
				'confluence',
				undefined,
				this.recentWorkAPIUrl,
			);
			const { viewed } = await activityClient.fetchActivities([250], { products: ['confluence'] }, [
				'viewed',
			]);

			const items = viewed
				.filter((item: ActivityItem) => item.object.product === 'confluence')
				.map((item: ActivityItem) => recentWorkItemToResult(item));
			return { items, totalSize: items.length, timings: 0 };
		} else {
			const baseUrl = this.serviceConfig.url;

			const recentPagesPromiseWithTimings = this.createRecentRequestPromise<RecentPageResponse>(
				RECENT_PAGES_PATH,
				{
					limit: RECENT_PAGES_LIMIT,
				},
			);

			const { result, durationMs } = await recentPagesPromiseWithTimings;

			const items = result
				.filter((page) => !!page.title)
				.map((recentPage) => recentPageToResult(recentPage, baseUrl));

			return {
				items,
				totalSize: items.length,
				timings: durationMs,
			};
		}
	};

	private recentSpacesSupplier: () => Promise<ConfSpaceResults> = () => {
		if (this.isCollabGraphEnabled) {
			return this.collaborationGraphClient
				.getContainers([SpaceContainerType])
				.then((response) => mapCollabGraphContainer(response));
		} else {
			const recentSpacesPromise = this.createRecentRequestPromise<RecentSpaceResponse>(
				RECENT_SPACE_PATH,
				{ limit: RECENT_SPACE_LIMIT },
			);

			return recentSpacesPromise.then(({ result, durationMs }) => ({
				items: result.map((recentSpace) =>
					recentSpaceToResult(recentSpace, `${this.baseUrl}/spaces/${recentSpace.key}`),
				),
				timings: durationMs,
			}));
		}
	};

	private createRecentRequestPromise<T>(
		path: string,
		queryParams: KeyValues,
	): Promise<{ result: Array<T>; durationMs: number }> {
		const options: RequestServiceOptions = {
			path: path,
			queryParams: queryParams,
		};

		const requestPromise: Promise<Array<T>> = utils.requestService(this.serviceConfig, options);

		return timed(requestPromise);
	}
}

function recentWorkItemToResult(recentItem: ActivityItem): ConfItemResult {
	const { object, containers } = recentItem;
	return {
		resultId: object.id,
		containerName: containers[0].name,
		containerId: '',
		resultType: ConfluenceObjectResult,
		isRecentResult: true,
		name: object.name || '', // Failsafe. All items should have a name.
		href: object.url,
		contentType: `confluence-${object.type}` as ContentType,
		lastModified: undefined, // not available
		analyticsType: RecentConfluence,
	};
}

function recentPageToResult(recentPage: RecentPageResponse, baseUrl: string): ConfItemResult {
	return {
		resultId: String(recentPage.id),
		name: recentPage.title || '', // This is a failsafe, there should be a filter to drop pages with no titles
		href: `${baseUrl}${recentPage.url}`,
		containerName: recentPage.space,
		analyticsType: RecentConfluence,
		resultType: ConfluenceObjectResult,
		contentType: `confluence-${recentPage.contentType}` as ContentType,
		iconClass: recentPage.iconClass,
		containerId: recentPage.spaceId.toString(),
		isRecentResult: true,
		lastModified: undefined, // not available for recent results
		spaceId: recentPage.spaceId?.toString(),
	};
}

function recentSpaceToResult(recentSpace: RecentSpaceResponse, href: string): ConfSpaceResult {
	return {
		resultId: recentSpace.id,
		name: recentSpace.name,
		key: recentSpace.key,
		href,
		avatarUrl: recentSpace.icon,
		analyticsType: RecentConfluence,
		resultType: GenericContainerResult,
		contentType: ConfluenceSpace,
		id: recentSpace.id,
	};
}

function mapCollabGraphContainer(
	response: CollaborationGraphResponse<CollaborationGraphContainer>,
): ConfSpaceResults {
	const items = response.collaborationGraphEntities
		.map((item) => ({
			id: item.containerDetails?.id,
			key: item.containerDetails?.key,
			icon: item.containerDetails?.iconUrl,
			name: item.containerDetails?.name,
			url: item.containerDetails?.url,
			siteId: item.siteId,
		}))
		.filter((space) => space.id !== undefined)
		.map((space) => recentSpaceToResult(space, space.url));

	return { items, timings: response.timings };
}
