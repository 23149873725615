import React, { useContext, useMemo, type PropsWithChildren } from 'react';
import { type ActiveProductSearchInputFeatures } from '../active-product-search-input';
import { type AggregatorClientFeatures } from '../aggregator-client-context';
import { type QuickFindFeatures } from '../quick-find/quick-find';
import { useQueryParams } from '../query-params-context';

export type DialogFeatures = AggregatorClientFeatures &
	ActiveProductSearchInputFeatures &
	QuickFindFeatures;

type FeaturesProviderProps = PropsWithChildren<{
	dialogFeatures?: DialogFeatures;
}>;

export const FeaturesContext = React.createContext<DialogFeatures>({});

export const FeaturesProvider = ({ dialogFeatures = {}, children }: FeaturesProviderProps) => {
	const { queryParams } = useQueryParams();

	// Do not proceed with griffin navigation if query param props are not available
	const isGriffinNavEnabled = useMemo(
		() => dialogFeatures.isGriffinNavEnabled === true && queryParams !== undefined,
		[dialogFeatures.isGriffinNavEnabled, queryParams],
	);

	return (
		/**
		 * Defaults and platfrom feature flag values can be overriden if explicit props are injected. This serves two purposes:
		 *
		 * 1. Storybook knobs can be provisioned for features that are powered by platfrom feature flags
		 *
		 * 2. Consumer products like Confluence, Jira can run statsig based experiments, where the Statsig evaluation happens in the product's code
		 * but the effect happens inside the code shipped by search platform.
		 */
		<FeaturesContext.Provider value={{ ...dialogFeatures, isGriffinNavEnabled }}>
			{children}
		</FeaturesContext.Provider>
	);
};
export const useFeaturesContext = () => {
	return useContext(FeaturesContext);
};
