/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//
export default {
  'cross_product_search_dialog.atlas.tab_label': 'Atlas',
  'cross_product_search_dialog.bitbucket.tab_label': 'Bitbucket',
  'cross_product_search_dialog.confluence.tab_label': 'Confluence',
  'cross_product_search_dialog.google_drive.tab_label': 'Drive',
  'cross_product_search_dialog.google_drive.unauthenticated_prompt':
    'You can work on all of your Google sheets, docs, slides and more, without ever leaving Atlassian. Only you have this access.',
  'cross_product_search_dialog.jira.tab_label': 'Jira',
  'cross_product_search_dialog.opsgenie.tab_label': 'Opsgenie',
  'cross_product_search_dialog.recently_viewed_title': 'Recently Viewed {sectionTitle}',
  'cross_product_search_dialog.townsquare_globex_tab_label': 'Home',
  'cross_product_search_dialog.townsquare_recent_goals': 'Recent Goals',
  'cross_product_search_dialog.townsquare_recent_help_links': 'Recent Help Links',
  'cross_product_search_dialog.townsquare_recent_learnings_risks_decisions': 'Recent Learnings, Risks and Decisions',
  'cross_product_search_dialog.townsquare_recent_projects': 'Recent Projects',
  'cross_product_search_dialog.townsquare_tab_label': 'Atlas',
  'cross_product_search_dialog.trello.tab_label': 'Trello',
  'cross_product_search_dialog.view_all_results_link': 'View all {sectionTitle}',
  'extensible_search_dialog.advanced_search': 'Advanced search',
  'extensible_search_dialog.advanced_search_cross_product': 'Search across products',
  'extensible_search_dialog.connect': 'Connect',
  'extensible_search_dialog.connect_to': 'Connect to',
  'global_search.jira.advanced_search_boards': 'Boards',
  'global_search.jira.advanced_search_filters': 'Filters',
  'global_search.jira.advanced_search_issues': 'Issues',
  'global_search.jira.advanced_search_issues-issue-term-refresh': 'Work items',
  'global_search.jira.advanced_search_label': 'Go to all:',
  'global_search.jira.advanced_search_people': 'People',
  'global_search.jira.advanced_search_plans': 'Plans',
  'global_search.jira.advanced_search_projects': 'Projects',
  'global_search.jira.search_placeholder': 'Search Jira',
  'global_search.jira.search_placeholder_experiment_jsw_enabled': 'Search for issues, boards, projects, and filters',
  'global_search.jira.search_placeholder_experiment_jsw_enabled-issue-term-refresh':
    'Search for work items, boards, projects, and filters',
  'product_s earch_dialog.confluence.assignee_filters_find_more': 'Find an assignee',
  'product_search_dialog.atlas.search_input_expanded_placeholder': 'Search Atlas',
  'product_search_dialog.autocorrection.label': 'Did you mean',
  'product_search_dialog.bitbucket.bitbucket_advanced_search': 'Search for code in {source}',
  'product_search_dialog.bitbucket.bitbucket_recent_repositories': 'Recently updated repositories',
  'product_search_dialog.bitbucket.bitbucket_repositories_section_title': 'Repositories',
  'product_search_dialog.bitbucket.expanded.input-placeholder': 'Search Bitbucket',
  'product_search_dialog.bitbucket.repository_heading': 'Repositories',
  'product_search_dialog.common.site_filters': 'Filter by site',
  'product_search_dialog.compass.component_type.application': 'Application',
  'product_search_dialog.compass.component_type.capability': 'Capability',
  'product_search_dialog.compass.component_type.cloud_resource': 'Cloud resource',
  'product_search_dialog.compass.component_type.data_pipeline': 'Data pipeline',
  'product_search_dialog.compass.component_type.library': 'Library',
  'product_search_dialog.compass.component_type.machine_learning_model': 'Machine learning model',
  'product_search_dialog.compass.component_type.other': 'Other',
  'product_search_dialog.compass.component_type.service': 'Service',
  'product_search_dialog.compass.component_type.ui_element': 'UI element',
  'product_search_dialog.compass.component_type.website': 'Website',
  'product_search_dialog.compass.components_section_heading': 'Components',
  'product_search_dialog.compass.endpoints_section_heading': 'Endpoints',
  'product_search_dialog.compass.expanded.input-placeholder': 'Search Compass',
  'product_search_dialog.compass.people_section_heading': 'People',
  'product_search_dialog.compass.recently_viewed_components': 'Recently viewed components',
  'product_search_dialog.compass.recently_viewed_endpoints': 'Recently viewed endpoints',
  'product_search_dialog.compass.recently_viewed_teams': 'Recently viewed teams',
  'product_search_dialog.compass.tab_label': 'Compass',
  'product_search_dialog.compass.teams_section_heading': 'Teams',
  'product_search_dialog.compass.upsell_modal_cta_text': 'Get Compass free',
  'product_search_dialog.compass.upsell_modal_description':
    'Catalog your software architecture in one place – so the teams working on it can reduce context switching, find what they need faster, and be more productive. How’s that for a developer experience?',
  'product_search_dialog.compass.upsell_modal_description.bitbucket':
    'See owners, dependencies, and help resources for your repositories by linking them to a central software catalog.',
  'product_search_dialog.compass.upsell_modal_heading':
    'Control your software sprawl, so developers can control their time',
  'product_search_dialog.compass.upsell_modal_heading.bitbucket': 'Bring more context to your code',
  'product_search_dialog.compass.upsell_modal_learn_more_aria_label': 'Learn more about Compass',
  'product_search_dialog.compass.upsell_modal_learn_more_link': 'Learn more',
  'product_search_dialog.compass.upsell_tab_title_lozenge': 'New',
  'product_search_dialog.compass.view_all_components_label': 'View all components',
  'product_search_dialog.compass.view_all_results_label': 'View all results',
  'product_search_dialog.confluence.advanced_search_with_product_prefix': 'Confluence advanced search',
  'product_search_dialog.confluence.clear_filters': 'Clear filters',
  'product_search_dialog.confluence.confluence_availability_in_jira_search.description':
    'Your team is collaborating in Confluence. Organize team knowledge, capture ideas, and share requirements for your Jira projects with pages, whiteboards, and databases.',
  'product_search_dialog.confluence.confluence_availability_in_jira_search.header': 'Your team is now using Confluence',
  'product_search_dialog.confluence.confluence_availability_in_jira_search.join_button': 'Join your team',
  'product_search_dialog.confluence.confluence_availability_in_jira_search.learn_more_button': 'Learn more',
  'product_search_dialog.confluence.confluence_availability_in_jira_search.tab.more_results': 'More results',
  'product_search_dialog.confluence.confluence_reactivation_experiment.inactive_user': 'More results',
  'product_search_dialog.confluence.confluence_reactivation_experiment.never_active_user': 'Free access',
  'product_search_dialog.confluence.content_section_heading': 'Content',
  'product_search_dialog.confluence.contributor_filters': 'Filter by contributor',
  'product_search_dialog.confluence.contributor_filters_find_more': 'Find a contributor',
  'product_search_dialog.confluence.date_filter_title': 'Last updated',
  'product_search_dialog.confluence.date_filter_today': 'Today',
  'product_search_dialog.confluence.date_filter_yesterday': 'Yesterday',
  'product_search_dialog.confluence.date_filters_any': 'Any time',
  'product_search_dialog.confluence.date_filters_month': 'Past 30 days',
  'product_search_dialog.confluence.date_filters_week': 'Past 7 days',
  'product_search_dialog.confluence.date_filters_year': 'Past year',
  'product_search_dialog.confluence.empty_state_body_no_advanced_search':
    'Try different keywords, fewer of them, or ones that are more general.',
  'product_search_dialog.confluence.empty_state_body_prequery_filters':
    'We can’t find anything created or updated in the last 90 days with your selected filters.',
  'product_search_dialog.confluence.empty_state_body_title': "We couldn't find any matches for your search.",
  'product_search_dialog.confluence.filters_applied': 'Looks like you have some filters applied',
  'product_search_dialog.confluence.pages_blogs_attachments_section_heading': 'Pages, blogs and attachments',
  'product_search_dialog.confluence.people_section_heading': 'People',
  'product_search_dialog.confluence.search_input_expanded_placeholder': 'Search Confluence',
  'product_search_dialog.confluence.search_input_expanded_placeholder_ai':
    'Search or ask questions within your Confluence',
  'product_search_dialog.confluence.space_filters': 'Filter by space',
  'product_search_dialog.confluence.space_filters_find_more': 'Find a space',
  'product_search_dialog.confluence.spaces_section_heading': 'Spaces',
  'product_search_dialog.confluence.typing_state_header_text': 'Search for content in Confluence',
  'product_search_dialog.empty.no_results_body':
    'Try again with a different term, or refine your results with <a>Advanced Search</a>',
  'product_search_dialog.empty.no_results_body_with_no_advanced_search': 'Try again with a different term.',
  'product_search_dialog.empty.no_results_heading': "We couldn't find anything matching your search.",
  'product_search_dialog.empty_prequery.error_heading': 'Something went wrong with showing initial results',
  'product_search_dialog.empty_prequery.no_results_heading': 'Search for what you need',
  'product_search_dialog.error.failed_loading_search_body':
    'It might just be a hiccup. Please try refreshing the page or <emptyLink>try again.</emptyLink>',
  'product_search_dialog.error.failed_loading_search_heading':
    "We're having trouble loading your recently viewed content.",
  'product_search_dialog.give_feedback': 'Give feedback',
  'product_search_dialog.google_drive.files_heading': 'Files',
  'product_search_dialog.inline_onboarding_action': 'Ok',
  'product_search_dialog.inline_onboarding_body':
    'Use the Jira and Confluence tabs to search and see recent items in either location.',
  'product_search_dialog.inline_onboarding_title': 'Search across Jira and Confluence',
  'product_search_dialog.jira.assignee_filters': 'Filter by assignee',
  'product_search_dialog.jira.boards_icon_accessibility_text': 'Board icon',
  'product_search_dialog.jira.boards_projects_filters_plans_section_heading': 'Boards, Projects, Filters and Plans',
  'product_search_dialog.jira.boards_projects_filters_section_heading': 'Boards, Projects and Filters',
  'product_search_dialog.jira.date_filter_30': '30d',
  'product_search_dialog.jira.date_filter_60': '60d',
  'product_search_dialog.jira.date_filter_90': '90d',
  'product_search_dialog.jira.date_filter_any': 'Any time',
  'product_search_dialog.jira.date_filter_past_30_days': 'Past 30 days',
  'product_search_dialog.jira.date_filter_past_7_days': 'Past 7 days',
  'product_search_dialog.jira.date_filter_past_year': 'Past year',
  'product_search_dialog.jira.date_filter_today': 'Today',
  'product_search_dialog.jira.date_filter_yesterday': 'Yesterday',
  'product_search_dialog.jira.date_filters': 'Last updated',
  'product_search_dialog.jira.empty_prequery.no_results_heading': 'Start searching to find your work',
  'product_search_dialog.jira.issue_filter_done': 'Done',
  'product_search_dialog.jira.issue_filter_open': 'Open',
  'product_search_dialog.jira.issue_filters': 'Filter by status',
  'product_search_dialog.jira.issue_labels_filters': 'Filter by label',
  'product_search_dialog.jira.issue_labels_filters_options_group_label': 'All labels',
  'product_search_dialog.jira.issue_labels_filters_placeholder': 'Select label',
  'product_search_dialog.jira.issues_section_heading': 'Issues',
  'product_search_dialog.jira.issues_section_heading-issue-term-refresh': 'Work items',
  'product_search_dialog.jira.jira_view_all_issues_new': 'View all issues',
  'product_search_dialog.jira.jira_view_all_issues_new-issue-term-refresh': 'View all work items',
  'product_search_dialog.jira.project_filters': 'Filter by project',
  'product_search_dialog.jira.project_filters_find_more': 'Find a project',
  'product_search_dialog.jira.projects_filters_section_heading': 'Projects and Filters',
  'product_search_dialog.jira.recent_boards_projects_filters_plans_section_heading':
    'Recent Boards, Projects, Filters and Plans',
  'product_search_dialog.jira.recent_boards_projects_filters_section_heading': 'Recent Boards, Projects and Filters',
  'product_search_dialog.jira.recent_projects_filters_section_heading': 'Recent Projects and Filters',
  'product_search_dialog.jira.recently_viewed_issues_section_heading': 'Recently viewed issues',
  'product_search_dialog.jira.recently_viewed_issues_section_heading-issue-term-refresh': 'Recently viewed work items',
  'product_search_dialog.jira.report_by_filters': 'Filter by reporter',
  'product_search_dialog.jira.reported_by_me_filter': 'Reported by me',
  'product_search_dialog.jira.site_filters_find_more': 'Find a site',
  'product_search_dialog.loading_filters': 'Loading',
  'product_search_dialog.more_filters': 'View all filters',
  'product_search_dialog.multi_site_advanced_search_label': 'Search on:',
  'product_search_dialog.multi_site_advanced_search_more_dropdown': '{count} more',
  'product_search_dialog.no_options_filters': 'No options',
  'product_search_dialog.opsgenie.advanced_search': 'Advanced alert search',
  'product_search_dialog.opsgenie.alert_heading': 'Alerts',
  'product_search_dialog.opsgenie.expanded.input-placeholder': 'Search Opsgenie',
  'product_search_dialog.opsgenie.opsgenie_recent_alerts': 'Recent Alerts',
  'product_search_dialog.quick_find_ask_atlassian_intelligence': 'Ask Atlassian Intelligence',
  'product_search_dialog.quick_find_confluence_space': 'Confluence Space',
  'product_search_dialog.quick_find_footer_full_page_search_products_link': 'Search across products',
  'product_search_dialog.quick_find_footer_help_text': 'Help us improve search',
  'product_search_dialog.quick_find_no_content_body_rovo':
    "Search or ask Rovo what you're looking for in your Atlassian and integrated products",
  'product_search_dialog.quick_find_no_content_header': 'Seek and you shall find',
  'product_search_dialog.quick_find_post_query_no_results_body':
    '<SearchContentLink>Search all content across your organization</SearchContentLink> or try again with a different term.',
  'product_search_dialog.quick_find_post_query_no_results_header': "We couldn't find any results with that title",
  'product_search_dialog.quick_find_recent_draft_label': 'DRAFT',
  'product_search_dialog.quick_find_recent_search': 'Recent search',
  'product_search_dialog.quick_find_recent_search_text_dialog': 'From your recent searches',
  'product_search_dialog.quick_find_recent_spaces_additional_meta': 'You recently visited',
  'product_search_dialog.quick_find_recently_viewed': 'Recently Viewed',
  'product_search_dialog.quick_find_result_item_team': 'Team',
  'product_search_dialog.quick_find_results_section': 'Search Results',
  'product_search_dialog.quick_find_rovo_tip':
    'Enter a question like “What’s the vacation policy?” and Rovo will find the answer.',
  'product_search_dialog.quick_find_search_input_rovo_placeholder': 'Search or ask Rovo',
  'product_search_dialog.quick_find_search_results': 'Search Results',
  'product_search_dialog.quick_find_show_filtered_confluence_results':
    'Filter by Confluence spaces, contributors and more',
  'product_search_dialog.quick_find_show_filtered_confluence_results_with_icon':
    'Filter by {confluenceIcon} Confluence spaces, contributors and more',
  'product_search_dialog.quick_find_show_organization_results': 'Search your organization',
  'product_search_dialog.recent_spaces_section_heading': 'Recent spaces',
  'product_search_dialog.recently_viewed_heading': 'Advanced search',
  'product_search_dialog.recently_viewed_section_heading': 'Recently viewed',
  'product_search_dialog.recently_worked_with_section_heading': 'Recently worked with',
  'product_search_dialog.results.remove_button_label': 'Remove this.',
  'product_search_dialog.search_input_collapsed_placeholder': 'Search',
  'product_search_dialog.search_input_enlarged_aria_label': 'Press enter to navigate to advanced search',
  'product_search_dialog.search_people_link': 'Search people',
  'product_search_dialog.show_all_results_link': 'View all results',
  'product_search_dialog.show_more_results_link': 'Show more',
  'product_search_dialog.townsquare.search_input_expanded_placeholder': 'Search Home',
  'product_search_dialog.townsquare_goals_heading': 'Goals',
  'product_search_dialog.townsquare_goals_view_all': 'Search all goals',
  'product_search_dialog.townsquare_help_links_heading': 'Help Links',
  'product_search_dialog.townsquare_learnings_risks_decisions_heading': 'Learnings, Risks and Decisions',
  'product_search_dialog.townsquare_people_heading': 'People',
  'product_search_dialog.townsquare_people_view_all': 'Search all people',
  'product_search_dialog.townsquare_projects_heading': 'Projects',
  'product_search_dialog.townsquare_projects_view_all': 'Search all projects',
  'product_search_dialog.townsquare_recent_people': 'Recent People',
  'product_search_dialog.townsquare_recent_topics': 'Recent Topics',
  'product_search_dialog.townsquare_teams_heading': 'Teams',
  'product_search_dialog.townsquare_teams_view_all': 'Search all teams',
  'product_search_dialog.townsquare_topics_heading': 'Topics',
  'product_search_dialog.townsquare_topics_view_all': 'Search all topics',
  'product_search_dialog.trello.archived': 'Archived',
  'product_search_dialog.trello.archived.card-hover': 'This card is archived',
  'product_search_dialog.trello.board': 'Board',
  'product_search_dialog.trello.boards_heading': 'Boards',
  'product_search_dialog.trello.cards_heading': 'Cards',
  'product_search_dialog.trello.closed': 'Closed',
  'product_search_dialog.trello.closed.board-hover': 'This board is closed',
  'product_search_dialog.trello.expanded.input-placeholder': 'Search Trello',
  'product_search_dialog.trello.feedback_link': 'Help us improve your search results!',
  'product_search_dialog.trello.list': 'List',
  'product_search_dialog.trello.private_workspace': 'Private Workspace',
  'product_search_dialog.trello.recent_boards_heading': 'Recent boards',
  'product_search_dialog.trello.workspace': 'Workspace',
  'product_search_dialog.updated_date_formatter': 'Updated {date}',
  'search_dialog.filters.group.show-more': 'Show more',
  'search_dialog.search_input_clear_label': 'Clear search session',
  'search_dialog.search_input_exit_label': 'Exit Search',
};
