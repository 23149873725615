import React, { useContext } from 'react';
import { type SearchConfig, type SearchConfigProviderProps } from './search-config-types';
import { useSearchConfigClient } from './use-search-config-client';

/**
 * Every product configuration might not choose to have the search config provider.
 * Hence this is explicitly initialised to null.
 */
const SearchConfigContext = React.createContext<SearchConfig>({
	jiraSites: [],
	confluenceSites: [],
	atlasWorkspaces: [],
});

export const SearchConfigProvider = (props: SearchConfigProviderProps) => {
	const { children, skeleton: SkeletonComponent = () => <></> } = props;
	const accessibleProducts = useSearchConfigClient(props);

	return accessibleProducts == null ? (
		<SkeletonComponent />
	) : (
		<SearchConfigContext.Provider value={accessibleProducts}>
			{children}
		</SearchConfigContext.Provider>
	);
};

export function useSearchConfigContext() {
	return useContext(SearchConfigContext);
}
