import React, { type FunctionComponent } from 'react';
import {
	SSRsearchInputField,
	SSRsearchInputContainer,
	SSRdesktopSearchIconWrapper,
	SSRmobileSearchIconWrapper,
	SSRformContainer,
	SSRsearchInputWrapper,
} from './search-input.styled';
import { SearchIcon } from '../icons';
import { script } from './ssr-scripts/min-script-interactive-search-input';
import { useTheme } from '../theme';

export interface SearchInputSkeletonInteractiveSSRProps {
	advancedSearchUrl?: string;
	placeholder?: string;
	customScriptDataAttributes?: {
		[key: string]: string;
	};
	shouldFillContainer?: boolean;
}

/**
 * An interactive search input skeleton that can be used as a placeholder. The
 * state of this input is stored in the browser's window object. This
 * component creates a script tag which attaches event handlers to the
 * rendered input element as it is intended to be used for SSR.
 */
export const SearchInputSkeletonInteractiveSSR: FunctionComponent<
	SearchInputSkeletonInteractiveSSRProps
> = ({ advancedSearchUrl, placeholder, customScriptDataAttributes, shouldFillContainer }) => {
	const theme = useTheme();

	return (
		<SSRsearchInputWrapper>
			<div id="ssr-search-input-popout-container">
				<SSRformContainer id="ssr-form-container">
					<form method="get" action={advancedSearchUrl}>
						<SSRsearchInputContainer
							shouldFillContainer={shouldFillContainer}
							id="ssr-input-container"
							role="search"
						>
							<SSRmobileSearchIconWrapper>
								<SearchIcon theme={theme} isExpanded={false} size="medium" />
							</SSRmobileSearchIconWrapper>
							<SSRdesktopSearchIconWrapper id="ssr-search-icon-wrapper">
								<SearchIcon theme={theme} isExpanded={false} />
							</SSRdesktopSearchIconWrapper>
							<SSRsearchInputField
								id="ssr-search-input"
								name="text"
								placeholder={placeholder}
								autoComplete="off"
								aria-label={placeholder}
							/>
						</SSRsearchInputContainer>
						<script
							data-test-id="ssr-scripts-init"
							dangerouslySetInnerHTML={{ __html: script }}
							{...customScriptDataAttributes}
						/>
					</form>
				</SSRformContainer>
			</div>
		</SSRsearchInputWrapper>
	);
};
