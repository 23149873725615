import React, { useCallback } from 'react';
import type { FC } from 'react';
import { useIntl } from 'react-intl-next';

import { SearchFooter } from '@atlassian/search-dialog';

import { fg } from '@atlaskit/platform-feature-flags';

import { messages } from '../../../messages';
import { SearchDialogProduct, useProductContext } from '../../product-router';
import { useDefaultSuppliers } from '../../result-supplier';
import {
	type ExtensibleJiraTabProps,
	type CurrentProject,
	JIRA_PRODUCT_ID,
	type JiraScope,
	type SupportedJiraScopeResponses,
	type CurrentUser,
} from './types';
import { useGenericFilters } from '../../filters';
import { useFilters } from './filters';
import { useSections } from './sections';
import { useURLFactory } from './url-factory';
import { JiraAdvancedSearch } from './advanced-search';

import { onAdvancedSearchSelected } from '../../../common/analytics/events';
import { useSearchConfigContext } from '../../../common/search-config-provider';
import { useRovoContext } from '../../../common/rovo-context/rovo-context';
import { useUserContext } from '../../../common/user-context';
import { defaultPermissionsSupplier } from './permission-supplier';
import { useDialogExpansionContext } from '../../dialog-expansion-context';
import { useQuery } from '../../query-context';
import { type JiraResultWithType, appendQueryParams } from './jira-analytics-query-params';
import SingleSiteAdvancedSearch from './single-site-advanced-search';
import { ProductStates } from '../../product-state-machine';
import { LoadingSpinner } from '../../../common/loading-spinner';
import { useABTest } from '../../../extensible/ab-test-context/ab-test-context';
import { NoPreQueryResults } from '../../no-results-screen';
import { useJiraDateFilter } from './filter-by-date/use-jira-date-filter';
import {
	AtlassianHomeSearchLink,
	getAtlassianHomeSearchUrl,
} from '../../advanced-search-footer/atlassian-home-search-link';
import { type AdvancedSearchUrlGenerator } from '../../product-router/product-router-state';

let hasLoadedFilters = false;
let currentProjectRef: CurrentProject | undefined;
let currentUserRef: CurrentUser | undefined;

export const setHasLoadedFilters = (value: boolean) => (hasLoadedFilters = value);

const JiraNoPreQueryResults = () => (
	<NoPreQueryResults message={messages.jira_empty_state_prequery_heading} />
);

export const ExtensibleJiraTab: FC<ExtensibleJiraTabProps> = ({
	features,
	children,
	config,
	formatDate,
	autocorrectEnabled,
	currentProject,
	currentUser,
	preQueryItemSupplier,
	postQueryItemSupplier,
	...rest
}) => {
	const { formatMessage } = useIntl();
	const { jiraSites } = useSearchConfigContext();
	const { isExpanded } = useDialogExpansionContext();
	const { getActiveProduct } = useProductContext();
	const { query } = useQuery();
	const hasAdvancedRoadmapsAccess = !!features?.hasAdvancedRoadmapsAccess;
	const hasSoftwareAccess = !!features?.hasSoftwareAccess;
	const isPreviewOnJiraSearchResultsEnabled = !!features?.isPreviewOnJiraSearchResultsEnabled;
	const isReportedByMeFilterEnabled = !!features?.isReportedByMeFilterEnabled;
	const isConsistentIssueDetailsEnabled = !!features?.isConsistentIssueDetailsEnabled;
	const isDateFromFilterEnabled = !!features?.enableDateFromFilter;
	const shouldShowIssueLabelsFilter = !!features?.enableIssueLabelsFilter;
	const isPreQueryFiltersEnabled = true;
	const isSearchWithTextFieldInAllIssuesLinkEnabled =
		!!features?.isSearchWithTextFieldInAllIssuesLinkEnabled;

	const { isRovoEnabled } = useRovoContext();
	const { orgId } = useUserContext();
	const showAtlassianHomeSearchLink = fg('atlassian_home_search_link_jira');
	const { defineFilters } = useGenericFilters();
	const { filtersDefinition, loadFilters, filterValueGetters } = useFilters(
		rest,
		shouldShowIssueLabelsFilter,
		config,
		currentProject,
		currentUser,
		isReportedByMeFilterEnabled,
	);
	const { jiraTabDateFilterDefinition, appliedDateFilterValue } = useJiraDateFilter({
		isDateFromFilterEnabled,
	});

	const abTest = useABTest();
	const isFasterSearchDisabled =
		abTest !== null &&
		abTest !== undefined &&
		(abTest.experimentId === 'durian-without-faster-search' ||
			abTest.experimentId === 'default-without-faster-search');

	if (
		(!hasLoadedFilters ||
			currentProjectRef?.id !== currentProject?.id ||
			currentUserRef?.id !== currentUser?.id ||
			currentUserRef?.avatarUrl !== currentUser?.avatarUrl) &&
		isExpanded &&
		getActiveProduct()?.id === JIRA_PRODUCT_ID &&
		(isPreQueryFiltersEnabled || query !== '')
	) {
		loadFilters();
		currentProjectRef = currentProject;
		currentUserRef = currentUser;
		setHasLoadedFilters(true);
	}

	defineFilters(JIRA_PRODUCT_ID, [
		...(isDateFromFilterEnabled ? jiraTabDateFilterDefinition : []),
		...filtersDefinition,
	]);

	const { getFiltersAdvancedSearchUrl } = useURLFactory(
		filterValueGetters,
		appliedDateFilterValue,
		isPreQueryFiltersEnabled,
		isSearchWithTextFieldInAllIssuesLinkEnabled,
	);

	const generateAdvancedSearchUrl: AdvancedSearchUrlGenerator = useCallback(
		(query, baseUrl, usage) => {
			if (usage === 'onInputEnterModified' && orgId) {
				return getAtlassianHomeSearchUrl(orgId, query);
			}
			return getFiltersAdvancedSearchUrl(query, baseUrl);
		},
		[getFiltersAdvancedSearchUrl, orgId],
	);

	const { recentSectionTitleGenerator, sections } = useSections(
		hasAdvancedRoadmapsAccess,
		hasSoftwareAccess,
		getFiltersAdvancedSearchUrl,
		isPreviewOnJiraSearchResultsEnabled,
		useIntl(),
		formatDate,
		isConsistentIssueDetailsEnabled,
		autocorrectEnabled,
	);

	const itemSuppliers = useDefaultSuppliers<
		SupportedJiraScopeResponses,
		JiraScope,
		JiraResultWithType
	>(JIRA_PRODUCT_ID, sections, {
		preQueryItemSupplier,
		postQueryItemSupplier,
	});

	return (
		<SearchDialogProduct
			id={JIRA_PRODUCT_ID}
			expandedStateInputPlaceholder={formatMessage(messages.jira_search_placeholder)}
			title={formatMessage(messages.jira_tab_label)}
			autocorrectEnabled={autocorrectEnabled}
			sections={sections}
			preQuerySectionTitleGenerator={recentSectionTitleGenerator}
			generateAdvancedSearchUrl={
				showAtlassianHomeSearchLink && isRovoEnabled
					? generateAdvancedSearchUrl
					: getFiltersAdvancedSearchUrl
			}
			{...rest}
			{...itemSuppliers}
			permissionSupplier={
				jiraSites.length > 0 ? defaultPermissionsSupplier : rest.permissionSupplier
			}
			appendQueryParams={appendQueryParams}
			isPreQueryFiltersEnabled={isPreQueryFiltersEnabled}
			debounceTime={300}
		>
			{({ linkComponent, productState }) => {
				return {
					Body: () => {
						if (productState === ProductStates.PostQueryLoading && isFasterSearchDisabled) {
							return <LoadingSpinner />;
						} else if (productState === ProductStates.PreQueryNoResult) {
							return <JiraNoPreQueryResults />;
						}
					},
					SectionFooter:
						jiraSites.length > 1
							? undefined // don't show the footer for multi sites
							: () => (
									<SingleSiteAdvancedSearch
										advancedSearchSelected={onAdvancedSearchSelected}
										linkComponent={linkComponent}
										productState={productState}
										features={features}
									/>
								),
					Footer: () => (
						<>
							<JiraAdvancedSearch
								linkComponent={linkComponent}
								productState={productState}
								features={features}
							/>
							{!!(isRovoEnabled && orgId && showAtlassianHomeSearchLink) && (
								<SearchFooter>
									<AtlassianHomeSearchLink orgId={orgId} linkComponent={linkComponent} />
								</SearchFooter>
							)}
						</>
					),
				};
			}}
		</SearchDialogProduct>
	);
};
