import React, {
	type FC,
	useContext,
	useCallback,
	useState,
	useEffect,
	useRef,
	type MutableRefObject,
} from 'react';

import {
	SEARCH_QUERY_KEY,
	SEARCH_FILTERS_KEY,
	SEARCH_SHOW_AI_DIALOG_KEY,
	getSessionItem,
	setSessionItem,
	deleteSessionItem,
} from '@atlassian/search-dialog';

import {
	useAnalytics,
	SearchDialogAnalyticsContext,
	onStickySearchCleared,
} from '../../common/analytics';
import type { FilterOption } from '../../common/filters/types';

const noop = () => {};
const SESSION_RESET_TIMEOUT_MS = 300000;

interface UserInputContext {
	stickySearchEnabled: boolean;
	query: string;
	showSearchAIDialog: boolean;
	storeQuery: (value: string) => void;
	toggleShowSearchAIDialog: (showSearchAIDialog: boolean) => void;
	readFilters: (productId: string) => any;
	storeFilters: (productId: string, value: any) => void;
	resetSearchSession: () => void;
	resetConfluenceFiltersRef: MutableRefObject<(() => void) | undefined> | undefined;
	resetExtensibleFiltersRef: MutableRefObject<(() => void) | undefined> | undefined;
}

type ConfluenceFiltersType = {
	space: Array<FilterOption>;
	people: Array<FilterOption>;
	site: Array<FilterOption>;
};

const anyConfluenceFiltersChecked = (filters: any) => {
	const confluencefilters = filters.confluence as ConfluenceFiltersType | undefined;
	if (confluencefilters) {
		const hasSpaceFilterChecked = confluencefilters.space?.length
			? confluencefilters.space?.some((filter: FilterOption) => {
					return filter.isChecked;
				})
			: false;
		const hasPeopleFilterChecked = confluencefilters.people?.length
			? confluencefilters.people?.some((filter: FilterOption) => {
					return filter.isChecked;
				})
			: false;
		const hasSiteFilterChecked = confluencefilters.site?.length
			? confluencefilters.site?.some((filter: FilterOption) => {
					return filter.isChecked;
				})
			: false;
		return hasSpaceFilterChecked || hasPeopleFilterChecked || hasSiteFilterChecked;
	}
	return false;
};

export const UserInputContext = React.createContext<UserInputContext>({
	stickySearchEnabled: false,
	query: '',
	showSearchAIDialog: false,
	storeQuery: noop,
	toggleShowSearchAIDialog: noop,
	readFilters: noop,
	storeFilters: noop,
	resetSearchSession: noop,
	resetConfluenceFiltersRef: undefined,
	resetExtensibleFiltersRef: undefined,
});

type QueryContextProviderProps = {
	stickySearchEnabled?: boolean;
	sessionResetIdleTime?: number;
	isExpanded?: boolean;
	resetStickySearchRef?: React.MutableRefObject<() => void>;
	children: React.ReactNode;
};

export const UserInputContextProvider: FC<QueryContextProviderProps> = ({
	stickySearchEnabled = false,
	sessionResetIdleTime = SESSION_RESET_TIMEOUT_MS,
	isExpanded = false,
	resetStickySearchRef,
	children,
}) => {
	const { fireAnalyticsEvent } = useAnalytics();

	const [query, setQuery] = useState<string>(getSessionItem(SEARCH_QUERY_KEY) || '');
	const [isStickyUpdated, setIsStickyUpdated] = useState<boolean>(false);
	const [stickyQueryUpdated, setStickyQueryUpdated] = useState<boolean>(false);
	const queryFirstUpdatedRef = useRef<number | undefined>(undefined);
	const lastExpandRef = useRef<boolean>(false);
	const lastQueryRef = useRef<string>('');
	const timeoutRef = useRef<NodeJS.Timeout>();
	const resetConfluenceFiltersRef = useRef<() => void>();
	const resetExtensibleFiltersRef = useRef<() => void>();

	const sessionShowSearchAIDialogStr = getSessionItem(SEARCH_SHOW_AI_DIALOG_KEY);
	const sessionShowSearchAIDialog = sessionShowSearchAIDialogStr
		? JSON.parse(sessionShowSearchAIDialogStr)
		: false;

	const [showSearchAIDialog, setShowSearchAIDialog] = useState<boolean>(sessionShowSearchAIDialog);

	const resetSearchSession = useCallback(() => {
		deleteSessionItem(SEARCH_QUERY_KEY);
		deleteSessionItem(SEARCH_FILTERS_KEY);
		deleteSessionItem(SEARCH_SHOW_AI_DIALOG_KEY);
		resetConfluenceFiltersRef.current?.();
		resetExtensibleFiltersRef.current?.();
		setQuery('');
		setShowSearchAIDialog(false);
		queryFirstUpdatedRef.current = undefined;
		lastQueryRef.current = '';
	}, [resetConfluenceFiltersRef, resetExtensibleFiltersRef]);

	const readFilters = useCallback((productId: string) => {
		const searchFilterString = getSessionItem(SEARCH_FILTERS_KEY);
		const filters = searchFilterString ? JSON.parse(searchFilterString) : {};
		if (productId in filters) {
			return filters[productId];
		}
		return {};
	}, []);

	const updateSessionResetTimeout = useCallback(() => {
		if (timeoutRef?.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = setTimeout(() => {
			if (timeoutRef?.current) {
				clearTimeout(timeoutRef.current);
			}

			const searchFilterString = getSessionItem(SEARCH_FILTERS_KEY);
			const filters = searchFilterString ? JSON.parse(searchFilterString) : {};
			if (query === '' && !anyConfluenceFiltersChecked(filters)) {
				return; // Skip the reset and analytics event if no filters or query entered
			}

			resetSearchSession();
			fireAnalyticsEvent(onStickySearchCleared({ timeout: sessionResetIdleTime }));
		}, sessionResetIdleTime);
	}, [query, sessionResetIdleTime, resetSearchSession, fireAnalyticsEvent]);

	const storeQuery = useCallback(
		(query: string) => {
			setQuery(query);
			setSessionItem(SEARCH_QUERY_KEY, query);
			updateSessionResetTimeout();
			if (queryFirstUpdatedRef.current === undefined) {
				queryFirstUpdatedRef.current = Date.now();
			}
			if (lastQueryRef.current) {
				setStickyQueryUpdated(query !== lastQueryRef.current);
			}
		},
		[updateSessionResetTimeout],
	);

	const toggleShowSearchAIDialog = useCallback((showSearchAIDialog: boolean) => {
		setShowSearchAIDialog(showSearchAIDialog);
		setSessionItem(SEARCH_SHOW_AI_DIALOG_KEY, showSearchAIDialog.toString());
	}, []);

	const storeFilters = useCallback(
		(productId: string, value: any) => {
			const searchFilterString = getSessionItem(SEARCH_FILTERS_KEY);
			const filters = searchFilterString ? JSON.parse(searchFilterString) : {};
			filters[productId] = value;
			setSessionItem(SEARCH_FILTERS_KEY, JSON.stringify(filters));
			if (lastExpandRef.current) {
				updateSessionResetTimeout;
			}
		},
		[lastExpandRef, updateSessionResetTimeout],
	);

	useEffect(() => {
		if (resetStickySearchRef) {
			resetStickySearchRef.current = resetSearchSession;
		}
	});

	useEffect(() => {
		if (!lastExpandRef.current && isExpanded) {
			updateSessionResetTimeout();
		}
		if (lastExpandRef.current && !isExpanded) {
			updateSessionResetTimeout();
		}
	}, [lastExpandRef, isExpanded, updateSessionResetTimeout]);

	useEffect(() => {
		if (!lastExpandRef.current && isExpanded) {
			setIsStickyUpdated(query !== '');
			lastQueryRef.current = query;
			setStickyQueryUpdated(false);
		}
		lastExpandRef.current = isExpanded;
	}, [isExpanded, lastExpandRef, lastQueryRef, query]);

	return (
		<UserInputContext.Provider
			value={{
				stickySearchEnabled,
				query,
				showSearchAIDialog,
				storeQuery: stickySearchEnabled ? storeQuery : noop,
				toggleShowSearchAIDialog,
				readFilters: stickySearchEnabled ? readFilters : noop,
				storeFilters: stickySearchEnabled ? storeFilters : noop,
				resetSearchSession: stickySearchEnabled ? resetSearchSession : noop,
				resetConfluenceFiltersRef: stickySearchEnabled ? resetConfluenceFiltersRef : undefined,
				resetExtensibleFiltersRef: stickySearchEnabled ? resetExtensibleFiltersRef : undefined,
			}}
		>
			<SearchDialogAnalyticsContext
				analyticContext={
					stickySearchEnabled
						? {
								isSticky: true,
								isStickyUpdated,
								stickyQueryUpdated,
								queryUpdatedAgo: queryFirstUpdatedRef.current
									? Date.now() - queryFirstUpdatedRef.current
									: undefined,
							}
						: { isSticky: false }
				}
			>
				{children}
			</SearchDialogAnalyticsContext>
		</UserInputContext.Provider>
	);
};

export const useSessionUserInput = () => {
	return useContext(UserInputContext);
};
