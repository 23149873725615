import {
	EnlargedSearchResultContentSkeleton,
	EnlargedSearchResultSection,
	ResultContainer,
	ResultContainerOuter,
	SearchDialogContent,
} from '@atlassian/search-dialog';
import React, { useCallback } from 'react';
import { ProductStates } from '../../product-state-machine/product-state-machine-types';
import { useIsMobileView } from '../../utils/use-is-mobile-view';
import { type ResultRendererPropsComplete } from '../../product-router/product/result-provider/result-renderer/result-renderer-types';
import { GenericSearchResult } from '../../search-result/search-result';
import { SearchFooter, FilterFooter, FeedbackFooter } from '../quick-find-footer';
import { PreQueryEmptyScreen } from '../quick-find-screens';
import { FormattedMessage } from 'react-intl-next';
import { messages } from '../messages';
import { SearchErrorScreen } from '../../search-error-screen/search-error-screen';
import { SearchFooterPersistRecentSearch } from '../quick-find-footer/search-footer';
import { useProductContext } from '../../product-router';
import { QuickFindRovoTip } from '../quick-find-rovo-tip/quick-find-rovo-tip';
import { useRovoContext } from '../../../common/rovo-context/rovo-context';
import { ProductKeys } from '../../quick-find';
import { NoSearchResults } from '../../no-results-screen';
import { useSearchSessionId } from '../../../common/search-session-provider';
import { addQuery } from '../../../utils/url-utils';

export const QuickFindResultRenderer: React.FC<ResultRendererPropsComplete> = ({
	productState,
	preQueryItems,
	postQueryItems,
	linkComponent,
	appendQueryParams,
	onRetryPreQuery,
	onRetryPostQuery,
}) => {
	const isMobileView = useIsMobileView();
	const { isRovoEnabled } = useRovoContext();
	const searchSessionId = useSearchSessionId();
	const { getActiveProduct } = useProductContext();
	const activeProduct = getActiveProduct();
	const productId = activeProduct?.id;

	const renderResults = useCallback(() => {
		switch (productState) {
			case ProductStates.PreQuerySuccess:
			case ProductStates.PreQueryLoading:
				return (
					<GenericSearchResult
						searchItems={preQueryItems}
						linkComponent={linkComponent}
						screen="preQuerySearchResults"
						productState={productState}
						isCollapsed={isMobileView}
						appendQueryParams={appendQueryParams}
					/>
				);
			case ProductStates.PreQueryNoResult:
				return <PreQueryEmptyScreen />;

			case ProductStates.PreQueryError:
				return <SearchErrorScreen onRetry={onRetryPreQuery} />;

			case ProductStates.PostQueryTyping:
			case ProductStates.PostQueryLoading:
				return (
					<GenericSearchResult
						searchItems={postQueryItems}
						linkComponent={linkComponent}
						screen="cachedResults"
						productState={productState}
						isCollapsed={isMobileView}
						appendQueryParams={appendQueryParams}
					>
						<EnlargedSearchResultSection
							title={<FormattedMessage {...messages.quick_find_search_results} />}
						>
							<EnlargedSearchResultContentSkeleton />
							<EnlargedSearchResultContentSkeleton />
							<EnlargedSearchResultContentSkeleton />
							<EnlargedSearchResultContentSkeleton />
							<EnlargedSearchResultContentSkeleton />
						</EnlargedSearchResultSection>
					</GenericSearchResult>
				);

			case ProductStates.PostQueryError:
				return <SearchErrorScreen onRetry={onRetryPostQuery} />;
			case ProductStates.PostQueryNoResult:
				const urlGeneratorForNoResultsScreen = (query: string) => {
					const generateUrl = activeProduct?.generateAdvancedSearchUrl || (() => '');
					const advancedSearchUrl = generateUrl(query);
					return addQuery(advancedSearchUrl, 'search_id', searchSessionId);
				};
				return (
					<NoSearchResults
						urlGeneratorForNoResultsScreen={urlGeneratorForNoResultsScreen}
						fromQuickFindPostQueryNoResults={true}
					/>
				);
			case ProductStates.PostQuerySuccess:
				return (
					<GenericSearchResult
						searchItems={postQueryItems}
						linkComponent={linkComponent}
						screen="postQuerySearchResults"
						productState={productState}
						isCollapsed={isMobileView}
						appendQueryParams={appendQueryParams}
					/>
				);
		}
	}, [
		productState,
		preQueryItems,
		linkComponent,
		isMobileView,
		appendQueryParams,
		postQueryItems,
		onRetryPreQuery,
		onRetryPostQuery,
		activeProduct?.generateAdvancedSearchUrl,
		searchSessionId,
	]);

	const showSearchFooter =
		productState === ProductStates.PreQuerySuccess ||
		productState === ProductStates.PreQueryLoading ||
		productState === ProductStates.PreQueryNoResult ||
		(productId === ProductKeys.Confluence &&
			(productState === ProductStates.PostQuerySuccess ||
				productState === ProductStates.PostQueryLoading ||
				productState === ProductStates.PostQueryNoResult));

	const showFilterFooter =
		productId === ProductKeys.Confluence &&
		(productState === ProductStates.PostQuerySuccess ||
			productState === ProductStates.PostQueryLoading ||
			productState === ProductStates.PostQueryNoResult);

	const showFeedbackFooter =
		productState === ProductStates.PreQuerySuccess ||
		productState === ProductStates.PostQuerySuccess ||
		productState === ProductStates.PostQueryLoading ||
		productState === ProductStates.PostQueryNoResult;

	return (
		<>
			{isRovoEnabled && <QuickFindRovoTip />}
			<SearchDialogContent>
				<ResultContainerOuter>
					<ResultContainer aria-live="polite">{renderResults()}</ResultContainer>
				</ResultContainerOuter>
			</SearchDialogContent>
			{showSearchFooter && productId === ProductKeys.Confluence ? (
				<SearchFooterPersistRecentSearch linkComponent={linkComponent} />
			) : (
				<SearchFooter linkComponent={linkComponent} />
			)}
			{showFilterFooter && <FilterFooter />}
			{showFeedbackFooter && <FeedbackFooter />}
		</>
	);
};
