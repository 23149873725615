import { fg } from '@atlaskit/platform-feature-flags';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { type TitleRendererArgs } from '../../product-router/product/result-types';
import { messages } from '../../../messages';
import { ProductStates } from '../../product-state-machine/product-state-machine-types';
import { LoadingSkeleton } from '../../autocorrection/autocorrection.styled';
import { AutocorrectAsyncWrapper } from '../../autocorrection';

export const sectionTitleRenderer = (autocorrectEnabled: boolean) => (args: TitleRendererArgs) => {
	const { productState } = args;

	const recentTitle = <FormattedMessage {...messages.common_recently_viewed_section_heading} />;
	const issuesTitleMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.jira_issues_section_headingIssueTermRefresh
		: messages.jira_issues_section_heading;
	const issuesTitle = <FormattedMessage {...issuesTitleMessage} />;

	switch (productState) {
		case ProductStates.PreQuerySuccess:
			return recentTitle;
		case ProductStates.PostQueryLoading:
			if (autocorrectEnabled) {
				return <LoadingSkeleton data-testid={'autocorrection-loading-skeleton'} />;
			}
			return issuesTitle;
		case ProductStates.PostQuerySuccess:
			if (autocorrectEnabled) {
				return <AutocorrectAsyncWrapper fallback={issuesTitle} />;
			}
			return issuesTitle;
		default:
			return issuesTitle;
	}
};
