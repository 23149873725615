import React from 'react';
import { EmptyState, Link, type LinkComponentProps } from '@atlassian/search-dialog';
import { FormattedMessage, injectIntl, type WrappedComponentProps } from 'react-intl-next';
import { messages } from '../../messages';
import { Button } from './search-error.styled';

const Image = React.lazy(
	() =>
		import(
			/* webpackChunkName: "@atlaskit-internal_product-search-dialog/async-chunk/search-error-image" */ './image'
		),
);

interface Props {
	onRetry: () => void;
}

export class SearchError extends React.Component<Props & WrappedComponentProps> {
	getContent = () => {
		const { onRetry } = this.props;

		const linkComponent = (props: LinkComponentProps) => (
			<Button
				{...props}
				role="button"
				tabIndex={0}
				onClick={() => onRetry()}
				onKeyDown={(event: React.KeyboardEvent) => (event.key === 'Enter' ? onRetry() : undefined)}
			/>
		);

		return (
			<FormattedMessage
				{...messages.common_error_state_failed_search_body}
				values={{
					emptyLink: (s: React.ReactNode[]) => (
						// eslint-disable-next-line jsx-a11y/anchor-is-valid
						<Link linkComponent={linkComponent} href={''}>
							{s}
						</Link>
					),
				}}
			/>
		);
	};

	render() {
		const { intl } = this.props;
		return (
			<EmptyState
				title={intl.formatMessage(messages.common_error_state_failed_search_heading)}
				Image={Image}
				content={this.getContent()}
			/>
		);
	}
}

export default injectIntl(SearchError);
