import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	/*
	 * Opsgenie
	 */

	opsgenie_expanded_input_placeholder: {
		id: 'product_search_dialog.opsgenie.expanded.input-placeholder',
		defaultMessage: 'Search Opsgenie',
		description: 'The placeholder which is visible to the user when opsgenie tab is active ',
	},
	opsgenie_recent_alerts: {
		id: 'product_search_dialog.opsgenie.opsgenie_recent_alerts',
		defaultMessage: 'Recent Alerts',
		description: '',
	},

	opsgenie_alerts_heading: {
		id: 'product_search_dialog.opsgenie.alert_heading',
		defaultMessage: 'Alerts',
		description: '',
	},

	opsgenie_advanced_search: {
		id: 'product_search_dialog.opsgenie.advanced_search',
		defaultMessage: 'Advanced alert search',
		description: 'Advanced search link text when inside Opsgenie tab',
	},
});
