/*
 * Minimalistic version of the search dialog, that provides a ready-only view
 * to be used as a loading component in async components and SSR.
 */
import type { SearchCSS } from '@atlaskit/atlassian-navigation';
import { SearchInputSkeleton, ThemeProvider } from '@atlassian/search-dialog/skeleton';
import React from 'react';

export interface ProductInputSearchSkeletonFeatures {
	interactiveSkeleton?: {
		enabled: boolean;
		advancedSearchUrl: string;
		placeholder: string;
		customScriptDataAttributes?: {
			[key: string]: string;
		};
	};
}

export interface Props {
	theme?: SearchCSS;
	features?: ProductInputSearchSkeletonFeatures;
	shouldFillContainer?: boolean;
	placeholder?: string;
	ariaLabel?: string;
}

export const ProductSearchInputSkeleton = ({ theme, ...rest }: Props) => {
	if (theme) {
		return (
			<ThemeProvider partialSearchCSS={theme}>
				<SearchInputSkeleton {...rest} />
			</ThemeProvider>
		);
	} else {
		return <SearchInputSkeleton {...rest} />;
	}
};
