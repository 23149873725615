import React, { type PropsWithChildren } from 'react';
import { useABTest } from '../ab-test-context';

export const DisableXPSAB = ({ children }: PropsWithChildren<{}>) => {
	const abTest = useABTest();
	if (abTest?.experimentId === 'xps-hidden') {
		// This experiment is a permanent FE experiment inside aggregator.
		return null;
	}

	return <>{children}</>;
};
