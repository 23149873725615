import { useEffect } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import {
	findQueryFilterKeyword,
	getFilterLookupQuery,
	getQueryWithoutSelectedQueryFilters,
	useFilterKeywordToFilterActions,
	useFilterKeywordToFilterId,
} from '../../../../controllers/quick-find/query-filters/utils';
import {
	useQuickFindActions,
	useSelectedQueryFilters,
} from '../../../../controllers/store/quick-find';

export const useQueryFilters = (localQuery: string, postQueryDisabled: boolean | undefined) => {
	const filterKeywordToFilterId = useFilterKeywordToFilterId();
	const filterKeywordToFilterActions = useFilterKeywordToFilterActions();
	const selectedQueryFilters = useSelectedQueryFilters();
	const { setQuickFindQuery, deleteSelectedQueryFilter, setCurrentQueryFilter } =
		useQuickFindActions();

	const [debouncedSetLookupInput, cancelDebouncedSetLookupInput] = useDebouncedCallback(
		(lookupInput: string, setLookupInput: (query: string) => void | Promise<void>) => {
			setLookupInput(lookupInput);
		},
		300,
	);

	useEffect(() => {
		const queryWithoutSelectedQueryFilters = getQueryWithoutSelectedQueryFilters(
			localQuery,
			selectedQueryFilters,
		);

		const trimmedQuery = queryWithoutSelectedQueryFilters.trim();

		setQuickFindQuery(trimmedQuery);
	}, [localQuery, selectedQueryFilters, setQuickFindQuery]);

	useEffect(() => {
		if (postQueryDisabled) {
			return;
		}

		// Deselect select query filters if their display text is no longer in the query
		for (const selectedQueryFilter of selectedQueryFilters) {
			if (!localQuery.includes(selectedQueryFilter.displayText)) {
				const filterActions = filterKeywordToFilterActions[selectedQueryFilter.keyword];

				if (filterActions && selectedQueryFilter.option) {
					filterActions.setOption({ ...selectedQueryFilter.option, isSelected: true });
					deleteSelectedQueryFilter(selectedQueryFilter);
				}
			}
		}

		// To determine if a user is accessing a query filter, we use the query without
		// selected query filters to allow for multiple query filters to be used at once
		const queryWithoutSelectedQueryFilters = getQueryWithoutSelectedQueryFilters(
			localQuery,
			selectedQueryFilters,
		);

		// Determine if the user is using a query filter
		const filterKeywords = Object.keys(filterKeywordToFilterId);
		const keyword = findQueryFilterKeyword(queryWithoutSelectedQueryFilters, filterKeywords);

		// Reset current query filter if keyword is not found
		if (!keyword) {
			cancelDebouncedSetLookupInput();
			setCurrentQueryFilter(undefined);
			return;
		}

		// Get the filter id and actioins for the filter corresponding to the keyword
		const filterId = filterKeywordToFilterId[keyword];
		const filterActions = filterKeywordToFilterActions[keyword];
		const filterLookupQuery = getFilterLookupQuery(queryWithoutSelectedQueryFilters, keyword);

		// Load the options for the filter
		if (filterId && filterActions && filterLookupQuery !== undefined) {
			setCurrentQueryFilter({ filterId, keyword });
			cancelDebouncedSetLookupInput();

			if (filterLookupQuery === '') {
				filterActions.loadInitialOptions();
				filterActions.setLookupInput('');
			} else {
				debouncedSetLookupInput(filterLookupQuery, filterActions.setLookupInput);
			}
		}
	}, [
		selectedQueryFilters,
		filterKeywordToFilterId,
		filterKeywordToFilterActions,
		deleteSelectedQueryFilter,
		setCurrentQueryFilter,
		localQuery,
		cancelDebouncedSetLookupInput,
		debouncedSetLookupInput,
		postQueryDisabled,
	]);
};
