import React, { type PropsWithChildren, useCallback, useState, Suspense } from 'react';

import { useTrelloCard } from './use-trello-card';
import { type HoverPreviewsResultProps, type TrelloPopperResult } from './types';

const TrelloPopperAsync = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/product-search-dialog/extensible/trello/trello-hover-previews/trello-popper" */ './trello-popper'
	).then(({ TrelloPopper }) => ({
		default: TrelloPopper,
	})),
);

export const HoverPreviewsResultWrapper = ({
	id,
	title,
	prefs,
	meta,
	additionalMeta,
	closed,
	type,
	children,
	isColorBlindModeEnabled,
	isPreviewOnTrelloSearchResultsEnabled,
	screen,
}: PropsWithChildren<HoverPreviewsResultProps>) => {
	const { getCard } = useTrelloCard();

	const [result, setResult] = useState<TrelloPopperResult>();

	const onFetchCard = useCallback(async () => {
		const card = await getCard(id);
		if (card) {
			setResult({
				...card,
				type: 'card',
				id,
				title,
				closed,
				meta,
				additionalMeta,
				screen,
			});
		}
	}, [getCard, id, title, closed, meta, screen, additionalMeta]);

	const onFetchBoard = useCallback(() => {
		if (prefs) {
			setResult({
				type: 'board',
				id,
				title,
				prefs,
				meta,
				closed,
				screen,
			});
		}
	}, [id, title, prefs, meta, closed, screen]);

	if (!isPreviewOnTrelloSearchResultsEnabled) {
		return <>{children}</>;
	}

	return (
		<Suspense fallback={null}>
			<TrelloPopperAsync
				type={type}
				result={result}
				onFetchCard={onFetchCard}
				onFetchBoard={onFetchBoard}
				isColorBlindModeEnabled={isColorBlindModeEnabled}
				screen={screen}
			>
				{children}
			</TrelloPopperAsync>
		</Suspense>
	);
};
