import { useCallback } from 'react';
import uniqBy from 'lodash/uniqBy';
import memo from 'memoize-one';
import deepEqual from 'deep-equal';

import { utils } from '@atlaskit/util-service-support';
import { type ActivityItem } from '@atlassian/recent-work-client';

import {
	type AggregatorConfPeopleResponse,
	type ConfluenceRecentsClientConfig,
	ConfluenceScope,
	type ConfPersonResponseItem,
	type SupportedConfScopeResponses,
} from './types';
import {
	type ConfluenceItemContentType,
	type ConfItemResult,
	type ConfItemResults,
	type ConfSpaceResult,
	type ConfSpaceResults,
	type ConfPeopleResults,
	type ContentType,
	ConfluenceObjectResult,
	ConfluenceSpace,
	GenericContainerResult,
	RecentConfluence,
	PersonResult,
	Person,
	Scope,
	ResultPerson,
} from '../../../confluence/clients/response-types';
import {
	type AggregatorClient,
	CollaborationGraphClient,
	type CollaborationGraphConfig,
	responseErrorToError,
	timed,
} from '../../../common/clients';
import type {
	CollaborationGraphContainer,
	CollaborationGraphResponse,
} from '../../../common/clients';
import { SpaceContainerType } from '../../../common/clients/common-types';
import { useTypedAggregatorClient } from '../../aggregator-client-context';
import { generatePeopleProfileUrl } from '../../../common/clients/multi-site-utils';
import { SimpleCache } from '../../../utils/simple-cache';
import { type AllFilters } from '../../filters';

const NAVIGATION_V3_EXPERIENCE: string = 'confluence.nav-v3';
const RECENT_PAGES_PATH: string = 'rest/recentlyviewed/1.0/recent';
const RECENT_SPACE_PATH: string = 'rest/recentlyviewed/1.0/recent/spaces';
const DEFAULT_COLLAB_GRAPH_URL: string = 'gateway/api/collaboration';
const ACTIVITY_CLIENT_RETRY_MS: number = 250;
const RECENT_PAGES_LIMIT: number = 200;
const RECENT_SPACE_LIMIT: number = 10;
const RECENT_PEOPLE_LIMIT: number = 10;

interface RecentPageResponse {
	available: boolean;
	contentType: ConfluenceItemContentType;
	id: number;
	lastSeen: number;
	space: string;
	spaceKey: string;
	title?: string; // Due to some Confluence bug there is a chance that recent pages come back with NO title
	type: string;
	url: string;
	iconClass: string;
	spaceId: number;
}

interface RecentSpaceResponse {
	id: string;
	key: string;
	icon: string;
	name: string;
}

function recentWorkItemToResult(recentItem: ActivityItem): ConfItemResult {
	const { object, containers } = recentItem;
	return {
		resultId: object.id,
		containerName: containers[0].name,
		containerId: '',
		resultType: ConfluenceObjectResult,
		isRecentResult: true,
		name: object.name || '', // Failsafe. All items should have a name.
		href: object.url,
		contentType: `confluence-${object.type}` as ContentType,
		lastModified: undefined, // not available
		analyticsType: RecentConfluence,
	};
}

function recentPageToResult(recentPage: RecentPageResponse, baseUrl: string): ConfItemResult {
	return {
		resultId: String(recentPage.id),
		name: recentPage.title || '', // This is a failsafe, there should be a filter to drop pages with no titles
		href: `${baseUrl}${recentPage.url}`,
		containerName: recentPage.space,
		analyticsType: RecentConfluence,
		resultType: ConfluenceObjectResult,
		contentType: `confluence-${recentPage.contentType}` as ContentType,
		iconClass: recentPage.iconClass,
		containerId: recentPage.spaceId.toString(),
		isRecentResult: true,
		lastModified: undefined, // not available for recent results
		spaceId: recentPage.spaceId?.toString(),
	};
}

function mapCollabGraphContainer(
	response: CollaborationGraphResponse<CollaborationGraphContainer>,
): ConfSpaceResults {
	const items = response.collaborationGraphEntities
		.map((item) => ({
			id: item.containerDetails?.id,
			key: item.containerDetails?.key,
			icon: item.containerDetails?.iconUrl,
			name: item.containerDetails?.name,
			url: item.containerDetails?.url,
		}))
		.filter((space) => space.id !== undefined)
		.map((space) => recentSpaceToResult(space, space.url));

	return { items, timings: response.timings };
}

function recentSpaceToResult(recentSpace: RecentSpaceResponse, href: string): ConfSpaceResult {
	return {
		resultId: recentSpace.id,
		name: recentSpace.name,
		key: recentSpace.key,
		href,
		avatarUrl: recentSpace.icon,
		analyticsType: RecentConfluence,
		resultType: GenericContainerResult,
		contentType: ConfluenceSpace,
		id: recentSpace.id,
	};
}

function mapUrsPeople(
	response: AggregatorConfPeopleResponse | null,
	timings: number,
	isMultiSite: boolean,
): ConfPeopleResults {
	if (!response) {
		throw new Error(`Expected a response but did not get any for scope: ${Scope.UserConfluence}`);
	}
	if (response.error) {
		throw responseErrorToError(response.error);
	}
	const items = response.results.map((item: ConfPersonResponseItem) => ({
		resultType: PersonResult,
		resultId: `people-${item.id}`,
		userId: item.id,
		name: item.name,
		href: generatePeopleProfileUrl(isMultiSite, item.absoluteUrl, item.id),
		avatarUrl: item.avatarUrl,
		contentType: Person,
		analyticsType: ResultPerson,
		mentionName: item.nickname || '',
		presenceMessage: '',
	}));
	return {
		items: uniqBy(items, (item) => item.resultId),
		timings,
	};
}

type RecentsCacheProps = ConfluenceRecentsClientConfig & {
	aggregatorClient?: AggregatorClient<SupportedConfScopeResponses, AllFilters, ConfluenceScope>;
	getCollabGraphContainers: () => Promise<ConfSpaceResults>;
};
class RecentsCache {
	recentCacheProps: RecentsCacheProps;
	recentItemCache: SimpleCache<Promise<ConfItemResults>>;
	recentSpaceCache: SimpleCache<Promise<ConfSpaceResults>>;
	recentPeopleCache: SimpleCache<Promise<ConfPeopleResults>>;

	constructor(props: RecentsCacheProps) {
		this.recentCacheProps = props;
		this.recentItemCache = new SimpleCache<Promise<ConfItemResults>>(this.recentItemSupplier);
		this.recentSpaceCache = new SimpleCache<Promise<ConfSpaceResults>>(this.recentSpaceSupplier);
		this.recentPeopleCache = new SimpleCache<Promise<ConfPeopleResults>>(this.recentPeopleSupplier);
	}

	recentItemSupplier: () => Promise<ConfItemResults> = async () => {
		if (!!this.recentCacheProps.recentWorkAPIUrl) {
			try {
				const { createActivityClient } = await import(
					/* webpackChunkName: "@atlaskit-internal_product-search-dialog/recent-work-client" */
					'@atlassian/recent-work-client'
				);
				const activityClient = createActivityClient(
					'v3',
					'confluence',
					undefined,
					this.recentCacheProps.recentWorkAPIUrl,
				);
				const { viewed } = await activityClient.fetchActivities(
					[ACTIVITY_CLIENT_RETRY_MS],
					{ products: ['confluence'] },
					['viewed'],
				);
				const items = viewed
					.filter((item: ActivityItem) => item.object.product === 'confluence')
					.map((item: ActivityItem) => recentWorkItemToResult(item));
				return { items, totalSize: items.length, timings: 0 };
			} catch (e) {
				return { items: [], totalSize: 0, timings: -1 };
			}
		} else {
			try {
				const { result, durationMs } = await timed(
					utils.requestService(
						{ url: this.recentCacheProps.url },
						{
							path: RECENT_PAGES_PATH,
							queryParams: { limit: RECENT_PAGES_LIMIT },
						},
					),
				);
				const items = (result as RecentPageResponse[])
					.filter((page) => !!page.title)
					.map((recentPage) => recentPageToResult(recentPage, this.recentCacheProps.url));
				return {
					items,
					totalSize: items.length,
					timings: durationMs,
				};
			} catch (e) {
				return {
					items: [],
					totalSize: 0,
					timings: -1,
				};
			}
		}
	};

	recentSpaceSupplier: () => Promise<ConfSpaceResults> = async () => {
		if (this.recentCacheProps.isUserAnonymous) {
			return Promise.resolve({
				items: [],
				timings: 0,
			});
		}

		if (this.recentCacheProps.isCollaborationGraphEnabled) {
			return this.recentCacheProps.getCollabGraphContainers();
		} else {
			try {
				const { result, durationMs } = await timed(
					utils.requestService(
						{ url: this.recentCacheProps.url },
						{
							path: RECENT_SPACE_PATH,
							queryParams: { limit: RECENT_SPACE_LIMIT },
						},
					),
				);
				return {
					items: (result as RecentSpaceResponse[]).map((recentSpace) =>
						recentSpaceToResult(
							recentSpace,
							`${this.recentCacheProps.url}/spaces/${recentSpace.key}`,
						),
					),
					timings: durationMs,
				};
			} catch (e) {
				return {
					items: [],
					timings: -1,
				};
			}
		}
	};

	recentPeopleSupplier: () => Promise<ConfPeopleResults> = async () => {
		if (this.recentCacheProps.isUserAnonymous || !this.recentCacheProps.aggregatorClient) {
			return Promise.resolve({
				items: [],
				timings: 0,
			});
		}

		return this.recentCacheProps.aggregatorClient
			.search({
				query: '',
				context: {
					sessionId: '',
					referrerId: null,
				},
				scopes: [ConfluenceScope.People],
				modelParams: [],
				resultLimit: RECENT_PEOPLE_LIMIT,
				experience: NAVIGATION_V3_EXPERIENCE,
				sites: this.recentCacheProps.sites?.map((s) => s.cloudId),
			})
			.then(({ response, requestDurationMs }) =>
				mapUrsPeople(
					response.retrieveScope(ConfluenceScope.People),
					requestDurationMs,
					(this.recentCacheProps.sites || []).length > 0,
				),
			)
			.catch((e) => ({ items: [], timings: -1 }));
	};
}

const getRecentsCache = memo(
	(recentsCacheProps: RecentsCacheProps) => new RecentsCache(recentsCacheProps),
	deepEqual,
);

const getCollabGraphClientWithConfig = memo(
	(config: CollaborationGraphConfig) => new CollaborationGraphClient(config),
	deepEqual,
);

export const useRecentsClient = (props: ConfluenceRecentsClientConfig) => {
	const aggregatorClient = useTypedAggregatorClient<SupportedConfScopeResponses, ConfluenceScope>();
	const collabGraphClient = getCollabGraphClientWithConfig({
		collaborationGraphUrl: props.collaborationGraphUrl || DEFAULT_COLLAB_GRAPH_URL,
		cloudId: props.cloudId,
		isMultiSite: (props.sites || []).length > 1,
		sites: props.sites || [],
		collabGraphSessionId: props.collabGraphSessionId,
	});

	const { isUserAnonymous } = props;
	const getCollabGraphContainers: () => Promise<ConfSpaceResults> = useCallback(async () => {
		return isUserAnonymous
			? Promise.resolve({
					items: [],
					timings: 0,
				})
			: collabGraphClient
					.getContainers([SpaceContainerType])
					.then((response) => mapCollabGraphContainer(response))
					.catch((e) => ({ items: [], timings: -1 }));
	}, [collabGraphClient, isUserAnonymous]);

	const recentsCache = getRecentsCache({
		...props,
		aggregatorClient,
		getCollabGraphContainers,
	});

	const getRecentItems = useCallback(() => {
		return recentsCache.recentItemCache.get().value;
	}, [recentsCache]);

	const getRecentSpaces = useCallback(() => {
		return recentsCache.recentSpaceCache.get().value;
	}, [recentsCache]);

	const getRecentPeople = useCallback(() => {
		return recentsCache.recentPeopleCache.get().value;
	}, [recentsCache]);

	return {
		getRecentItems,
		getRecentSpaces,
		getRecentPeople,
		getCollabGraphContainers,
		searchClient: aggregatorClient,
		collabGraphClient,
	};
};
