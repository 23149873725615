import React from 'react';

export interface LinkComponentProps {
	href?: string;
	target?: '_blank' | '_self' | '_parent' | '_top';
	className?: string;
	isKeyboardHighlighted?: boolean;
	children?: React.ReactNode;
}

export type LinkComponent<T extends LinkComponentProps = LinkComponentProps> =
	React.ComponentType<T>;

export interface LinkProps<T extends {}> {
	linkComponent?: LinkComponent<T>;
}

export const Link = <T extends LinkComponentProps>({
	linkComponent,
	...props
}: (React.AnchorHTMLAttributes<HTMLAnchorElement> & T) & LinkProps<T>) => {
	// Hack?
	const Component: any = linkComponent || 'a';
	return <Component {...props} />;
};
