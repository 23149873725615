import type React from 'react';
import { type SearchItemsTransformerProps } from '../../../product-router/product/result-provider/result-provider-types';
import { useRecentSearchesSection } from '../sections/recent-search-section';

/**
 * This component injects recent searches section into pre query items.
 */
export const RecentSearchesTransformer: React.FC<SearchItemsTransformerProps> = ({
	preQueryItems,
	postQueryItems,
	productState,
	children,
}) => {
	const recentSearchesSection = useRecentSearchesSection();

	const preQueryItemsWithRecentSearches = {
		sections: [recentSearchesSection, ...preQueryItems.sections],
		size: preQueryItems.size,
	};

	return children({
		preQueryItems: preQueryItemsWithRecentSearches,
		postQueryItems,
		productState,
	});
};
