// Extracted from @atlassian/recent-work-ui activity-event-type-message to create this
// helper function to convert timestamp to readable metadata
import React from 'react';
import { FormattedMessage, FormattedDate, FormattedRelativeTime } from 'react-intl-next';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import subWeeks from 'date-fns/subWeeks';

import { selectUnit, useEventTypeMessage } from '@atlassian/recent-work-ui';

export enum VIEWED_EVENT {
	VIEWED = 'viewed',
}

export enum WORKED_EVENT {
	COMMENTED = 'commented',
	CREATED = 'created',
	EDITED = 'edited',
	PUBLISHED = 'published',
	UPDATED = 'updated',
}

export type ActivityEventType = VIEWED_EVENT | WORKED_EVENT;

export function isMoreThanOneWeekAgo(date: string | Date) {
	return isBefore(new Date(date), startOfOneWeekAgo());
}

function startOfOneWeekAgo() {
	return startOfDay(subWeeks(new Date(), 1));
}

// TODO: we could replace this component with EventTypeMessage from @atlassian/recent-work-ui
export const EventTypeMessage = ({
	eventType,
	timestamp,
	isMyActivityData = true,
}: {
	eventType: ActivityEventType;
	timestamp: string;
	isMyActivityData?: boolean;
}) => {
	const isAbsolute = isMoreThanOneWeekAgo(timestamp);

	const message = useEventTypeMessage(eventType, {
		isMyActivityData,
		isAbsolute,
	});

	const { value, unit } = selectUnit(new Date(timestamp).getTime(), new Date(), {
		day: 7,
	});

	const values = isAbsolute
		? {
				// Show the absolute time if the activity was more than a week ago
				// e.g: You viewed on January 1, 2021
				absoluteTime: (
					<FormattedDate value={timestamp} month="short" day="2-digit" year="numeric" />
				),
			}
		: {
				// Show the relative time if the activity was within a week ago
				// e.g: You viewed 2 hours ago
				relativeTime: <FormattedRelativeTime value={value} unit={unit} />,
			};

	return <FormattedMessage {...message} values={values} />;
};
