import { type ReactElement, useCallback } from 'react';

import { type Product, useProductContext } from '..';

import { useSessionUserInput } from '../../..';

export const ActiveProductProvider = ({
	children,
}: {
	children: ({
		activeProduct,
		showSearchAIDialog,
		onCloseAIDialog,
		defaultAIConfig,
	}: {
		activeProduct?: Product;
		showSearchAIDialog: boolean;
		onCloseAIDialog: () => void;
		defaultAIConfig?: Product['aiSearchConfig'];
	}) => ReactElement | null;
}) => {
	const { getActiveProduct, getDefaultAIConfig } = useProductContext();
	const { showSearchAIDialog, toggleShowSearchAIDialog } = useSessionUserInput();
	const onCloseAIDialog = useCallback(() => {
		toggleShowSearchAIDialog(false);
	}, [toggleShowSearchAIDialog]);
	const defaultAIConfig = getDefaultAIConfig();
	return children({
		activeProduct: getActiveProduct(),
		showSearchAIDialog,
		onCloseAIDialog,
		defaultAIConfig,
	});
};
