import LightbulbIcon from '@atlaskit/icon/core/lightbulb';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { EnlargedReturnIconContainer, RemoveIcon } from '@atlassian/search-dialog';
import { IconButton } from '@atlaskit/button/new';
import { messages } from '../messages';
import { useQuickFindRovoTip } from './use-quick-find-rovo-tip';
import { useInputContext } from '../../input-context';

const RovoTipMessageStyle = xcss({
	width: '95%',
	marginLeft: 'space.050',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	paddingLeft: 'space.050',
});

const RovoTipContainerStyle = xcss({
	paddingTop: 'space.075',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});

const RovoTipStyle = xcss({
	display: 'flex',
	width: '100%',
	alignItems: 'center',
	position: 'relative',
	boxSizing: 'border-box',
	minHeight: `${token('space.500', '40px')}`,
	paddingLeft: 'space.150',
	paddingRight: 'space.100',
	borderRadius: `${token('space.050', '4px')}`,
	backgroundColor: 'color.background.accent.purple.subtlest',
	color: 'color.text.accent.purple',
});

export const QuickFindRovoTip = () => {
	const { showRovoTip, dismissRovoTip } = useQuickFindRovoTip();
	const { focusInput } = useInputContext();

	if (!showRovoTip) {
		return null;
	}

	return (
		<Box xcss={RovoTipContainerStyle}>
			<Box xcss={RovoTipStyle}>
				<LightbulbIcon label="blank" color={token('color.text.accent.purple', '#5E4DB2')} />
				<Box xcss={RovoTipMessageStyle}>
					<b>Rovo tip:</b> <FormattedMessage {...messages.quick_find_rovo_tip} />
				</Box>
				<EnlargedReturnIconContainer>
					<IconButton
						icon={RemoveIcon}
						spacing={'compact'}
						onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
							event.preventDefault();
							event.stopPropagation();
							dismissRovoTip();
							focusInput && focusInput();
						}}
						label={<FormattedMessage {...messages.remove_button_label} />}
						appearance={'subtle'}
					/>
				</EnlargedReturnIconContainer>
			</Box>
		</Box>
	);
};
