/* eslint-disable import/extensions */
import React, { type FC, useRef, useLayoutEffect } from 'react';
import {
	SSRsearchInputField,
	SSRsearchInputContainer,
	SSRdesktopSearchIconWrapper,
	SSRmobileSearchIconWrapper,
	SSRformContainer,
	SSRsearchInputWrapper,
} from './search-input.styled';
// @ts-ignore
import { script } from './ssr-scripts/min-script-interactive-search-input';

import { SearchIcon } from '../icons';
import type { SearchCSS } from '../theme';

export interface Props {
	advancedSearchUrl?: string;
	placeholder?: string;
	customScriptDataAttributes?: {
		[key: string]: string;
	};
	theme: SearchCSS;
	shouldFillContainer?: boolean;
}
export const SSRsearchInput: FC<Props> = ({
	advancedSearchUrl,
	placeholder,
	customScriptDataAttributes,
	theme,
	shouldFillContainer,
}) => {
	const scriptRef = useRef(null);

	useLayoutEffect(() => {
		const current: any = scriptRef?.current;
		if (current) {
			// eslint-disable-next-line
			eval(current.innerHTML);
		}
	});

	return (
		<SSRsearchInputWrapper>
			<div id="ssr-search-input-popout-container">
				<SSRformContainer id="ssr-form-container">
					<form method="get" action={advancedSearchUrl}>
						<SSRsearchInputContainer
							shouldFillContainer={shouldFillContainer}
							id="ssr-input-container"
							role="search"
						>
							<SSRmobileSearchIconWrapper>
								<SearchIcon theme={theme} isExpanded={false} size="medium" />
							</SSRmobileSearchIconWrapper>
							<SSRdesktopSearchIconWrapper id="ssr-search-icon-wrapper">
								<SearchIcon theme={theme} isExpanded={false} />
							</SSRdesktopSearchIconWrapper>
							<SSRsearchInputField
								id="ssr-search-input"
								name="text"
								placeholder={placeholder}
								autoComplete="off"
								aria-label={placeholder}
							/>
						</SSRsearchInputContainer>
						<script
							data-test-id="ssr-scripts-init"
							ref={scriptRef}
							dangerouslySetInnerHTML={{ __html: script }}
							{...customScriptDataAttributes}
						/>
					</form>
				</SSRformContainer>
			</div>
		</SSRsearchInputWrapper>
	);
};
