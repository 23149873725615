import { type GasPayload } from '@atlaskit/analytics-gas-types';

export const onRecentQuerySelected = ({
	actionSubjectId,
	attributes,
	nonPrivacySafeAttributes,
}: {
	actionSubjectId: string;
	attributes: Record<string, any>;
	nonPrivacySafeAttributes: Record<string, any>;
}): GasPayload => ({
	source: 'searchDialog',
	eventType: 'track',
	action: 'selected',
	actionSubject: 'recentQuery',
	actionSubjectId,
	attributes,
	nonPrivacySafeAttributes,
});
