import { useCallback, useEffect, useState } from 'react';
import { getRovoTip, setRovoTip } from '@atlassian/search-common';
import { useUserContext } from '../../../common/user-context';
import { useProductContext } from '../../product-router';

export const useQuickFindRovoTip = () => {
	const { user } = useUserContext();
	const { getActiveProduct } = useProductContext();
	const product = getActiveProduct();
	const [showRovoTip, setShowRovoTip] = useState<boolean>(true);

	useEffect(() => {
		if (!product) {
			return;
		}

		const rovoTipFromLocalStorage = getRovoTip(product.id, user?.id);
		setShowRovoTip(rovoTipFromLocalStorage);
	}, [user.id, product]);

	const dismissRovoTip = useCallback(() => {
		if (product?.id) {
			const rovoTipFromLocalStorage = setRovoTip(product.id, user?.id);
			setShowRovoTip(rovoTipFromLocalStorage);
		}
	}, [user.id, product]);

	return {
		showRovoTip,
		dismissRovoTip,
	};
};
