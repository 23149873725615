import { type SearchItems, type SearchResult } from '../result-types';
import { highlightMatchedText } from '@atlassian/search-common';
import { RECENT_SEARCHES_SECTION_ID } from '../../../quick-find/quick-find-confluence/sections/recent-search-section';
import uniqBy from 'lodash/uniqBy';

export const filterFirstSectionItems = (
	searchItems: SearchItems,
	query: string,
	numberOfResultItems = 3,
): SearchItems => {
	if (!searchItems.sections[0]) {
		return searchItems;
	}
	const filteredSearchResults = searchItems.sections[0].searchResults
		.filter((item) => {
			return item.title.toLowerCase().includes(query.toLowerCase());
		})
		.slice(0, numberOfResultItems);
	return {
		size: filteredSearchResults.length,
		sections: [{ ...searchItems.sections[0], searchResults: filteredSearchResults }],
	};
};

export const filterAllSectionItems = (
	searchItems: SearchItems,
	query: string,
	numberOfResultItems = 3,
): SearchItems => {
	if (query === '') {
		return searchItems;
	}
	const wordsInQuery = query.split(' ');
	const itemScoreMap: {
		[key: string]: number;
	} = {};

	const filteredSearchSections = searchItems.sections.map((section) => {
		return {
			...section,
			searchResults: section.searchResults
				.map((item) => {
					// 1st pass
					const doAllWordsMatch = wordsInQuery.reduce((acc, word) => {
						if (!acc) {
							return acc;
						}

						return item.title.toLowerCase().includes(word.toLowerCase());
					}, true);

					if (doAllWordsMatch) {
						// 2nd and 3rd Pass
						const {
							highlightedTitle,
							negatedHighlightedTitle,
							score = 0,
						} = highlightMatchedText(item.title, wordsInQuery);
						itemScoreMap[item.id] = score;
						return {
							...item,
							highlightedTitle:
								section.id !== RECENT_SEARCHES_SECTION_ID
									? highlightedTitle
									: negatedHighlightedTitle,
						};
					}

					return null;
				})
				.filter((item) => item)
				.sort((item, secondItem) =>
					item && secondItem ? itemScoreMap[secondItem.id] - itemScoreMap[item.id] : 0,
				)
				.slice(0, numberOfResultItems) as Array<SearchResult>,
		};
	});

	const size = filteredSearchSections
		.map((section) => section.searchResults.length)
		.reduce((acc, b) => acc + b, 0);

	return {
		size,
		sections: filteredSearchSections,
	};
};

export const highlightPostQueryResultTitles = (
	postQueryItems: SearchItems,
	query: string,
): SearchItems => {
	const wordsInQuery = query.split(' ');

	const highlightedSearchSections = postQueryItems.sections.map((section) => {
		return {
			...section,
			searchResults: section.searchResults.map((item) => {
				const { highlightedTitle } = highlightMatchedText(item.title, wordsInQuery);
				return {
					...item,
					highlightedTitle,
				};
			}),
		};
	});

	return {
		size: postQueryItems.size,
		sections: highlightedSearchSections,
	};
};

export const mergeFirstSectionSearchItems = (
	intermediateSearchItems: SearchItems,
	postQuerySearchItems: SearchItems,
) => {
	if (
		!intermediateSearchItems.sections[0] ||
		intermediateSearchItems.sections[0]?.searchResults.length === 0
	) {
		return postQuerySearchItems;
	}

	if (
		!postQuerySearchItems.sections[0] ||
		postQuerySearchItems.sections[0]?.searchResults.length === 0
	) {
		return intermediateSearchItems;
	}

	const fullMergedResultsList = [
		...intermediateSearchItems.sections[0].searchResults.map((result) => ({
			...result,
			isRecentResult: true,
		})),
		...postQuerySearchItems.sections[0].searchResults,
	];
	const deduplicatedList = uniqBy(fullMergedResultsList, (item) => item.id);
	const sections = [
		{
			...postQuerySearchItems.sections[0],
			searchResults: deduplicatedList,
		},
	];
	return {
		size: sections.reduce(
			(accumulator, currentElem) => accumulator + currentElem.searchResults.length,
			0,
		),
		sections,
	};
};
