import { useEffect, useRef } from 'react';

import { useAppContext } from '../../../common/ui/app-context';
import { getInitialQuery } from '../../../common/ui/quick-find/textfield/input-skeleton';
import { onPreQueryDwelled } from '../../../common/utils/quick-find/events/search-results-dwelled';
import { useTimer } from '../../../common/utils/use-timer';
import {
	useQuickFindAnalytics,
	useQuickFindAttributes,
} from '../../../controllers/quick-find/analytics';
import { useQuickFindOpen, useQuickFindQuery } from '../../../controllers/store/quick-find';

export const usePreQueryDwellTimeAnalytics = () => {
	const { queryParams } = useAppContext();
	const quickFindOpen = useQuickFindOpen();
	const quickFindQuery = useQuickFindQuery();

	const hadInitialQuery = useRef(Boolean(getInitialQuery() || queryParams.text));

	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	const {
		start: startTimer,
		stop: stopTimer,
		cancel: cancelTimer,
	} = useTimer({
		onTimerEnd: (ms) =>
			fireAnalyticsEvent(
				onPreQueryDwelled({
					attributes: { ...commonAttributes, duration: ms },
					nonPrivacySafeAttributes,
				}),
			),
	});

	// Analytics to track the first transition from pre-query to post-query
	useEffect(() => {
		// Do nothing if the page loaded with a query
		if (hadInitialQuery.current) {
			return;
		}

		// Start the timer when the dialog is opened
		if (quickFindOpen) {
			startTimer();
		}

		// Cancel the timer when the dialog is closed
		if (!quickFindOpen) {
			cancelTimer(); // no-ops if the timer hasn't started or has already finished
		}

		// Stop the timer when the user starts typing
		if (quickFindQuery) {
			stopTimer();
		}
	}, [cancelTimer, quickFindOpen, quickFindQuery, startTimer, stopTimer]);
};
