import React, { type PropsWithChildren, useEffect } from 'react';
import { useAnalytics } from '../analytics';
import { onPaneLoadComplete } from '../analytics/events';

export const TimeLazyLoad = ({ children, product }: PropsWithChildren<{ product: string }>) => {
	const { fireAnalyticsEvent } = useAnalytics();

	useEffect(() => {
		const start = Date.now();

		return () => {
			fireAnalyticsEvent(onPaneLoadComplete(product, Date.now() - start));
		};
	}, [fireAnalyticsEvent, product]);

	return <>{children}</>;
};
