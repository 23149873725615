/* eslint-disable @atlaskit/ui-styling-standard/no-nested-selectors */

// Copied from confluence/next/packages/navigation-search-dialog/src/suppliers/utils/ConfluenceResultMeta.tsx

import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';
import { N300 } from '@atlaskit/theme/colors';
import { Inline, Text } from '@atlaskit/primitives';
import { SpacerWrapper, capitalizeFirstLetter } from './quick-find-meta';

type BreadCrumb = {
	id: string;
	title: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfluenceResultMetaWrapper = styled.span({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfluenceTypeWrapper = styled.span({
	color: token('color.text.subtle', N300),
});

type ConfluenceResultAncestor = {
	id: string;
	title: string | null;
};

type ConfluenceResultSpace = {
	id: string | null;
	name?: string | null;
};

type ConfluenceResultMetaProps = {
	resultContentType: string;
	resultAncestors?: ConfluenceResultAncestor[] | null;
	resultSpace?: ConfluenceResultSpace | null;
};

const BreadCrumbs = ({ breadCrumbs = [] }: { breadCrumbs: BreadCrumb[] }) => {
	if (breadCrumbs.length === 0) {
		return null;
	}

	// only allow 2 breadcrumbs
	// take the first and the last (if different)
	let twoBreadcrumbs = [];
	if (breadCrumbs.length > 2) {
		twoBreadcrumbs = [breadCrumbs[0], breadCrumbs[breadCrumbs.length - 1]];
	} else {
		twoBreadcrumbs = breadCrumbs;
	}

	return (
		<Inline separator="/" space="space.100">
			{twoBreadcrumbs.map(({ id, title }, i) => (
				<Text color="color.text.subtle" size="small" key={id}>
					{title}
				</Text>
			))}
		</Inline>
	);
};

export const ConfluenceResultMeta = ({
	resultContentType,
	resultAncestors,
	resultSpace,
}: ConfluenceResultMetaProps) => {
	const breadcrumbs = getBreadcrumbs(resultAncestors, resultSpace);

	return (
		<ConfluenceResultMetaWrapper>
			<ConfluenceTypeWrapper>Confluence</ConfluenceTypeWrapper>
			<SpacerWrapper>•</SpacerWrapper>
			<ConfluenceTypeWrapper>{`${capitalizeFirstLetter(resultContentType)}`}</ConfluenceTypeWrapper>
			{breadcrumbs?.length && (
				<>
					<SpacerWrapper>•</SpacerWrapper>
					<BreadCrumbs breadCrumbs={breadcrumbs} />
				</>
			)}
		</ConfluenceResultMetaWrapper>
	);
};

export const getBreadcrumbs = (
	ancestors: ConfluenceResultAncestor[] | null | undefined,
	space: ConfluenceResultSpace | null | undefined,
) => {
	let breadcrumbs: { id?: string | null; title?: string | null }[] = [];

	if (space) {
		const spaceBreadcrumb = { id: space?.id, title: space?.name };

		if (!ancestors || ancestors.length === 0) {
			breadcrumbs = [spaceBreadcrumb];
		} else if (ancestors.length >= 1) {
			// The first ancestor is a root node that shouldn't be rendered
			breadcrumbs = [spaceBreadcrumb, ...ancestors.slice(1)];
		}
	}

	// Filter out invalid breadcrumbs
	return breadcrumbs.filter((breadCrumb) => breadCrumb.id && breadCrumb.title) as BreadCrumb[];
};
