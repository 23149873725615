import { type OptionType } from '@atlassian/search-dialog';

import type { FilterInterface } from '../../common/clients/common-types';
import type { FilterOption } from '../../common/filters/types';
import type { SupportedAction } from '../../common/filters/use-filters';

export const SITE_FILTER_TYPE_NAME = 'sites';
export const DEFAULT_OPTION = 'default';

export enum FilterUIVariant {
	CHECKBOX = 'CHECKBOX',
	STATIC_SELECT = 'STATIC_SELECT',
	MULTI_SELECT = 'MULTI_SELECT',
}

export enum LastModifiedValues {
	DEFAULT = 'default',
	TODAY = 'today',
	YESTERDAY = 'yesterday',
	PAST_SEVEN_DAYS = 'past7Days',
	PAST_THIRTY_DAYS = 'past30Days',
	PAST_YEAR = 'pastYear',
}

export type DateRange = {
	from: string;
	to: string;
};

export type DateFilterOptionsAndValues = {
	[key: string]: {
		filter: GenericFilterOption;
		dateRange: DateRange;
	};
} | null;

export interface GenericFilterOption extends FilterOption {
	avatarUrl?: string;
	label: string;
}

export interface SpaceFilterOption extends GenericFilterOption {
	spaceKey?: string;
}

export interface SpaceFilters extends FilterInterface {
	'@type': 'spaces';
	spaceKeys: string[];
}

export interface UserFilters extends FilterInterface {
	'@type': 'contributors';
	accountIds: string[];
}

export interface AppliedProjectFilters extends FilterInterface {
	'@type': 'projects';
	projectIds: string[];
}

export interface AppliedAssigneeFilters extends FilterInterface {
	'@type': 'assignees';
	accountIds: string[];
}

export interface AppliedBinaryStatusCategoryFilter extends FilterInterface {
	'@type': 'binary_status_categories';
	binaryStatusCategories: string[];
}

export interface AppliedIssueLabelsFilter extends FilterInterface {
	'@type': 'issueLabels';
	issueLabels: string[];
}

export interface LastModifiedFilter extends FilterInterface {
	'@type': 'lastModified';
	from?: string;
	to?: string;
}

export interface ReportedByFilter extends FilterInterface {
	'@type': 'reportedBy';
	accountIds: string[];
}

export interface LastViewedFilter extends FilterInterface {
	'@type': 'lastViewed';
	from?: string;
}

export type ConfluenceFilters = SpaceFilters | UserFilters | LastModifiedFilter;

export type JiraFilters =
	| AppliedProjectFilters
	| AppliedAssigneeFilters
	| AppliedBinaryStatusCategoryFilter
	| AppliedIssueLabelsFilter
	| LastModifiedFilter
	| LastViewedFilter
	| ReportedByFilter;

export interface SiteFilter extends FilterInterface {
	'@type': 'sites';
	values: string[];
}

export interface GenericFilter extends FilterInterface {
	'@type': string;
	[key: string]: string[] | string;
}

export type AddFilters<T extends FilterOption> = (filters: T[]) => void;
export type UpdateFilter = (id: string, isChecked: boolean, isVisible?: boolean) => void;
export type UpdateSingleValueFilter = (id: string) => void;
export type ClearEnabledFilter = () => void;
export type ResetFilterState = () => void;

export interface FilterStore<T extends FilterOption> {
	availableFilters: T[];
	isLoading?: boolean;
	loadDefaultFilters: (load: Promise<T[]>) => void;
	addFilters: AddFilters<T>;
	updateFilter: UpdateFilter;
	updateSingleValueFilter: UpdateSingleValueFilter;
	clearFilter: ClearEnabledFilter;
	reset: ResetFilterState;
	dispatch: React.Dispatch<SupportedAction<T>>;
	setDefaultFilters: (filters: T[]) => void;
}

export type FilterLoader = (query: string) => Promise<OptionType<GenericFilterOption>[]>;

export interface FilterDefinition<T extends FilterOption> {
	'@type': string;
	fieldName: string;
	store: FilterStore<T>;
	loadFilterOptions: FilterLoader;
	sectionLabel: string;
	findMoreLabel: string;
	placeholderText?: string;
	uiVariant?: string;
	mapSelectionToAppliedValue?: () => AllFilters | null;
	filterConfig?: FilterConfig;
}

export type FilterConfig = {
	filterOptionIconShape?: 'circle' | 'square';
	filterGroupedBy?: 'column' | 'row';
	usePreloadedOptions?: boolean;
	hideIcon?: boolean;
	labelComponent?: React.ElementType;
	mainGroupLabel?: string;
};

export type AllFilters = GenericFilter | SiteFilter | ConfluenceFilters | JiraFilters;
