import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	recentSearch: {
		id: 'search_dialog_recent_search',
		defaultMessage: 'Recent search',
		description: 'Label for recent search queries',
	},
	removeRecentSearch: {
		id: 'search_dialog_remove_recent_search_label',
		defaultMessage: 'Remove recent search',
		description: 'Label of the button that has remove function and appearance.',
	},
});
