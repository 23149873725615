import type { IntlShape } from 'react-intl-next';

import { getUserRecommendations } from '@atlaskit/smart-user-picker';

const MAX_RESULTS_USERS = 2;
export const ACTIVE_AND_NON_BOT_USER_SEARCH_FILTER =
	'account_status:"active" AND (NOT email_domain:"connect.atlassian.com") AND (NOT account_type:app)';

type userRecommendationsArgType = {
	query: string;
	cloudId: string;
	orgId: string;
	principalId?: string;
	intl: IntlShape;
};

export const getUserAndTeamSearchResults = ({
	query,
	cloudId,
	orgId,
	principalId = 'Context',
	intl,
}: userRecommendationsArgType) =>
	getUserRecommendations(
		{
			searchQueryFilter: ACTIVE_AND_NON_BOT_USER_SEARCH_FILTER,
			includeUsers: true,
			includeGroups: false,
			includeTeams: true,
			context: {
				contextType: 'confluence_search-dialog',
				principalId,
				productKey: 'confluence',
				siteId: cloudId,
				organizationId: orgId,
			},
			maxNumberOfResults: MAX_RESULTS_USERS,
			query,
		},
		intl,
	);
