import React from 'react';
import {
	SearchDialogAnchor,
	AbsoluteContainer,
	EnlargedSearchAnchorWrapper,
} from './search-anchor.styled';
import { type Props as SearchAnchorProps } from './search-anchor';

export const EnlargedSearchAnchor = ({
	children,
	onBlur,
	onFocus,
	onKeyDown,
	isExpanded,
	shouldFillContainer,
}: React.PropsWithChildren<SearchAnchorProps>) => (
	<EnlargedSearchAnchorWrapper isExpanded={isExpanded} shouldFillContainer={shouldFillContainer}>
		<AbsoluteContainer isExpanded={isExpanded} shouldFillContainer={shouldFillContainer}>
			<SearchDialogAnchor
				onBlur={onBlur}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				isExpanded={isExpanded}
				shouldFillContainer={shouldFillContainer}
			>
				{children}
			</SearchDialogAnchor>
		</AbsoluteContainer>
	</EnlargedSearchAnchorWrapper>
);
