import { createElement, type ReactElement } from 'react';

import Archive16Icon from '@atlaskit/icon-file-type/glyph/archive/16';
import Archive24Icon from '@atlaskit/icon-file-type/glyph/archive/24';
import Audio16Icon from '@atlaskit/icon-file-type/glyph/audio/16';
import Audio24Icon from '@atlaskit/icon-file-type/glyph/audio/24';
import ExcelSpreadsheet16Icon from '@atlaskit/icon-file-type/glyph/excel-spreadsheet/16';
import ExcelSpreadsheet24Icon from '@atlaskit/icon-file-type/glyph/excel-spreadsheet/24';
import Generic16Icon from '@atlaskit/icon-file-type/glyph/generic/16';
import Generic24Icon from '@atlaskit/icon-file-type/glyph/generic/24';
import Gif16Icon from '@atlaskit/icon-file-type/glyph/gif/16';
import Gif24Icon from '@atlaskit/icon-file-type/glyph/gif/24';
import Image16Icon from '@atlaskit/icon-file-type/glyph/image/16';
import Image24Icon from '@atlaskit/icon-file-type/glyph/image/24';
import PdfDocument16Icon from '@atlaskit/icon-file-type/glyph/pdf-document/16';
import PdfDocument24Icon from '@atlaskit/icon-file-type/glyph/pdf-document/24';
import PowerpointPresentation16Icon from '@atlaskit/icon-file-type/glyph/powerpoint-presentation/16';
import PowerpointPresentation24Icon from '@atlaskit/icon-file-type/glyph/powerpoint-presentation/24';
import SourceCode16Icon from '@atlaskit/icon-file-type/glyph/source-code/16';
import SourceCode24Icon from '@atlaskit/icon-file-type/glyph/source-code/24';
import Video16Icon from '@atlaskit/icon-file-type/glyph/video/16';
import Video24Icon from '@atlaskit/icon-file-type/glyph/video/24';
import WordDocument16Icon from '@atlaskit/icon-file-type/glyph/word-document/16';
import WordDocument24Icon from '@atlaskit/icon-file-type/glyph/word-document/24';

const AttachmentFileExtensionMatchers = [
	{
		regexp: /\.(pdf)$/i,
		icon: [PdfDocument16Icon, PdfDocument24Icon],
	},
	{
		regexp: /\.(gif)$/i,
		icon: [Gif16Icon, Gif24Icon],
	},
	{
		regexp: /\.(jpeg|jpg|png)$/i,
		icon: [Image16Icon, Image24Icon],
	},
	{
		regexp: /\.(xml|html|js|css|java|jar|war|ear)$/i,
		icon: [SourceCode16Icon, SourceCode24Icon],
	},
	{
		regexp: /\.(zip|tar|gz|bz2|rar)$/i,
		icon: [Archive16Icon, Archive24Icon],
	},
	{
		regexp: /\.(xlt|xls|xlsm|xlsx|xlst)$/i,
		icon: [ExcelSpreadsheet16Icon, ExcelSpreadsheet24Icon],
	},
	{
		regexp: /\.(pptx|ppsx|potx|pot|ppt|pptm)$/i,
		icon: [PowerpointPresentation16Icon, PowerpointPresentation24Icon],
	},
	{
		regexp: /\.(docx|dotx|doc|dot)$/i,
		icon: [WordDocument16Icon, WordDocument24Icon],
	},
	{
		regexp: /\.(wma|wmv|ram|mp3)$/i,
		icon: [Audio16Icon, Audio24Icon],
	},
	{
		regexp: /\.(mov|mpeg|mpg|mp4|avi)$/i,
		icon: [Video16Icon, Video24Icon],
	},
];

const getIconArray = (title: string) => {
	const extension = AttachmentFileExtensionMatchers.find((extensionMatcher) => {
		const matches = extensionMatcher.regexp.exec(title);
		return matches && matches.length > 0;
	});

	if (extension) {
		return extension.icon;
	}

	return [Generic16Icon, Generic24Icon];
};

/**
 * Defines the mapping for the icon array.
 * Follows the same size types specified by @atlaskit/icon-object:
 * https://atlassian.design/components/icon-object/icon-explorer
 */
export const iconSizeMap = {
	small: 0,
	medium: 1,
} as const;

export type IconSizeMap = keyof typeof iconSizeMap;

type AttachmentIconProps = {
	title: string;
	size: keyof typeof iconSizeMap;
	label: string;
};

export const AttachmentIcon = ({ title, label, size }: AttachmentIconProps): ReactElement => {
	const iconArray = getIconArray(title);
	const sizeIndex = iconSizeMap[size];
	const icon = iconArray[sizeIndex];

	return createElement(icon, {
		label,
		testId: icon.name,
	});
};
