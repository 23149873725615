import { useEffect, useMemo, useRef } from 'react';

import debounce from 'lodash/debounce';

type Callback = (...args: any[]) => any;

/**
 * Returns a debounced version of the callback function.
 *
 * The returned debounce function is memoized and will only change if the
 * delay changes. This means that even if the callback changes, the same
 * debounce function will be used. The callback can still be updated as
 * it is stored in a ref.
 */
export const useDebouncedCallback = (callback: Callback, debounceTime: number) => {
	const ref = useRef<Callback>();

	useEffect(() => {
		ref.current = callback;
	}, [callback]);

	const debouncedCallback = useMemo(() => {
		const func = () => {
			ref.current?.();
		};
		return debounce(func, debounceTime);
	}, [debounceTime]);
	return debouncedCallback;
};
