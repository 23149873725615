import React, { type ReactNode, useCallback, useRef, useState } from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { Show } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { useKeyboardNavigation } from '@atlassian/search-dialog';

import { useAppContext } from '../../app-context';

const resultStyles = xcss({
	paddingBlockStart: 'space.100',
	paddingBlockEnd: 'space.100',
	paddingInlineStart: 'space.200',
	paddingInlineEnd: 'space.200',
	display: 'flex',
	alignItems: 'center',
});

const resultHoveredStlyes = xcss({
	backgroundColor: 'color.background.neutral.subtle.hovered',
});

const keyboardHighlightedStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle.hovered',
});

const iconAreaStyles = xcss({
	width: 'size.200',
	height: 'size.200',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const middleAreaStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	minWidth: '0',
	flex: 1,
	marginLeft: 'space.150',
});

const titleStyles = xcss({
	overflow: 'clip',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	font: 'font.body',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '16px',
});

const textOverflowStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const rightSectionStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body.small',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	marginLeft: 'space.150',
});

const linkStyles = xcss({
	color: 'color.text',
	textDecoration: 'none',
	display: 'inline-block',
	width: '100%',

	':focus-visible': {
		outlineStyle: 'solid',
		outlineColor: 'color.border.accent.blue',
		outlineWidth: 'space.025',
		// @ts-ignore
		outlineOffset: token('space.negative.025'),
	},

	':hover': {
		textDecoration: 'none',
		color: 'color.text',
	},

	':visited': {
		textDecoration: 'none',
		color: 'color.text',
	},
});

type SearchResultProps = {
	icon: ReactNode;
	title: ReactNode;
	url: string;
	rightMetadata: ReactNode;
	onResultClicked?: (e: React.MouseEvent<HTMLDivElement>) => void;
	onResultContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const SearchFooter = ({
	icon,
	title,
	rightMetadata,
	url,
	onResultClicked,
	onResultContextMenu,
}: SearchResultProps) => {
	const ref = useRef<HTMLDivElement>();

	const [isHovered, setIsHovered] = useState(false);

	const { linkComponent: Link } = useAppContext();

	const [isKeyboardHighlighted, setRef] = useKeyboardNavigation<HTMLDivElement>({
		onKeydownCallback: () => {
			ref.current?.click();
		},
		onHighlightedCallback: () => {},
	});

	const onClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			onResultClicked?.(event);
		},
		[onResultClicked],
	);

	const onContextMenu = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			onResultContextMenu?.(event);
		},
		[onResultContextMenu],
	);

	return (
		<Box onClick={onClick} onContextMenu={onContextMenu} testId="search-dialog-footer">
			<Link xcss={[linkStyles]} href={url}>
				<Box
					xcss={[
						resultStyles,
						isHovered && resultHoveredStlyes,
						isKeyboardHighlighted && keyboardHighlightedStyles,
					]}
					ref={(node: HTMLDivElement) => {
						ref.current = node;
						setRef(node);
					}}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				>
					<Box xcss={iconAreaStyles}>{icon}</Box>
					<Box xcss={middleAreaStyles}>
						<Box xcss={[titleStyles, textOverflowStyles]}>{title}</Box>
					</Box>
					<Box xcss={rightSectionStyles}>
						<Show above="xs">{rightMetadata}</Show>
					</Box>
				</Box>
			</Link>
		</Box>
	);
};
