import React from 'react';

import { Box, Pressable, type PressableProps, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const defaultFilterButtonStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.075',
	color: 'color.text.subtle',
	borderRadius: '4px',
	paddingInline: 'space.150',
	paddingBlock: 'space.050',
	minHeight: '32px', // As specified on Figma
	maxWidth: '164px', // As specified on Figma
	backgroundColor: 'color.background.neutral.subtle',
	border: `1px solid ${token('color.border')}`,
	whiteSpace: 'nowrap',
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});

const compactContentFitStyles = xcss({
	maxWidth: '113px', // As specified on Figma
});

const minWidthZeroStyles = xcss({
	minWidth: '0', // Allow the button to shrink as much as possible
});

const filterButtonSelectedStateStyles = xcss({
	backgroundColor: 'color.background.selected',
	border: `1px solid ${token('color.border.selected')}`,
	color: 'color.text.selected',
	':hover': {
		backgroundColor: 'color.background.selected.hovered',
	},
});

const compactSpacingStyles = xcss({
	paddingInline: 'space.050',
});

const filterButtonContentStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

export type FilterButtonProps = {
	children: React.ReactNode;
	isSelected?: boolean;
	iconBefore?: React.ReactNode;
	iconAfter?: React.ReactNode;
	/** Allow the button to shrink as much as possible */
	minWidthZero?: boolean;
	/** Controls the amount of padding in the button. */
	spacing?: 'default' | 'compact';
	/** Controls the amount of truncation (aka `maxWidth`) of the content */
	contentFit?: 'default' | 'compact';
};

export const FilterButton = React.forwardRef<HTMLButtonElement, PressableProps & FilterButtonProps>(
	function FilterButton(
		{
			children,
			contentFit = 'default',
			iconAfter,
			iconBefore,
			isSelected,
			minWidthZero = false,
			spacing = 'default',
			...pressableProps
		},
		ref,
	) {
		return (
			<Pressable
				{...pressableProps}
				ref={ref}
				xcss={[
					defaultFilterButtonStyles,
					isSelected && filterButtonSelectedStateStyles,
					spacing === 'compact' && compactSpacingStyles,
					contentFit === 'compact' && compactContentFitStyles,
					minWidthZero && minWidthZeroStyles,
				]}
			>
				{iconBefore}
				{children && <Box xcss={filterButtonContentStyles}>{children}</Box>}
				{iconAfter}
			</Pressable>
		);
	},
);
