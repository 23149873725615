import React, { useCallback, useContext, type PropsWithChildren } from 'react';

type Input = {
	focusInput?: () => void;
};

type Props = PropsWithChildren<{
	forwardRef?: React.Ref<HTMLInputElement>;
}>;

const Context = React.createContext<Input>({
	focusInput: undefined,
});

export const InputContext = ({ children, forwardRef }: Props) => {
	const focusInput = useCallback(() => {
		if (typeof forwardRef === 'object') {
			forwardRef?.current?.focus();
		}
	}, [forwardRef]);

	return <Context.Provider value={{ focusInput }}>{children}</Context.Provider>;
};

export const useInputContext = () => {
	return useContext(Context);
};
