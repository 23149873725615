import { type GasPayload } from '@atlaskit/analytics-gas-types';

export const onTextEntered = ({
	attributes,
	nonPrivacySafeAttributes,
}: {
	attributes: Record<string, any>;
	nonPrivacySafeAttributes: Record<string, any>;
}): GasPayload => ({
	eventType: 'track',
	action: 'entered',
	actionSubject: 'text',
	actionSubjectId: 'searchDialogTextField',
	attributes,
	nonPrivacySafeAttributes,
});
