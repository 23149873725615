import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	externalLinkIconLabel: {
		id: 'inline-app-selector.external-link-icon.label',
		defaultMessage: 'External Link Icon',
		description: 'Label for external link icon',
	},
	appTooltipFor3PApps: {
		id: 'inline-app-selector.3p-app-tooltip',
		defaultMessage: 'Open a full page search for this term filtered by {productName} results',
		description: 'Tooltip for 3P App',
	},
});
