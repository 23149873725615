import Tabs, { Tab, TabList } from '@atlaskit/tabs';
import React from 'react';
import { onTabSelected, useAnalytics } from '../../../common/analytics';
import { type Products } from '../../../common/product-context';
import { type ABTestContext, withABTestContext } from '../../ab-test-context';
import { DisableXPSAB } from '../../ab-test-context/experiment-hoc';
import {
	type DialogExpansionContextProps,
	withDialogExpansionContext,
} from '../../dialog-expansion-context/dialog-expansion-context';
import { type ProductContextProps, withProductContext } from '../product-router';
import { TabControlsWrapper } from './tab-controls.styled';

/**
 * Empty interface required to get withProductContext HOC to type properly.
 */
interface Props {}

const CustomTab = ({ index, title, tabRenderer }: any) => {
	return (
		<Tab key={index} testId={`search-dialog-product-tab-${index}`}>
			{tabRenderer ? tabRenderer({ title }) : title}
		</Tab>
	);
};

export const ProductTabsInner = (
	props: ProductContextProps & Props & DialogExpansionContextProps & ABTestContext,
) => {
	const { products, showProduct, getActiveProduct, isExpanded, abTest } = props;
	const { fireAnalyticsEvent } = useAnalytics();

	const onTabClicked = (index: number) => {
		const id = products[index].id;
		showProduct(products[index].id);
		fireAnalyticsEvent(onTabSelected({ tabName: id as Products }));
	};

	const activeProductId = getActiveProduct()?.id;
	const activeProductIndex = products.findIndex((product) => product.id === activeProductId);

	const primaryProduct = products.find((product) => product.order === 0);

	if (activeProductIndex === -1 && isExpanded && primaryProduct && abTest) {
		showProduct(primaryProduct.id);
		return null;
	}

	if (!isExpanded || products.length === 1 || !abTest || !primaryProduct) {
		return null;
	}
	// Tabs has unwanted flex-basis: 100% set, div wrapper gets around this

	return (
		<DisableXPSAB>
			<TabControlsWrapper>
				<Tabs
					selected={activeProductIndex}
					onChange={onTabClicked}
					id="tab-controls"
					shouldUnmountTabPanelOnChange
				>
					<TabList>
						{products.map((product, index) => (
							<CustomTab
								key={product.id}
								index={index}
								title={product.title}
								tabRenderer={product.tabRenderer}
							/>
						))}
					</TabList>
				</Tabs>
			</TabControlsWrapper>
		</DisableXPSAB>
	);
};

export const ProductTabs = withABTestContext(
	withProductContext(withDialogExpansionContext(ProductTabsInner)),
);
