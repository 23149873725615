import React, { type FunctionComponent, type Ref, useCallback, useEffect, useRef } from 'react';

import { useTheme } from '@atlassian/search-dialog';
import { useProductContext } from '../product-router';
import { mergeRefCallback } from '../../utils/merge-ref-callback';
import { useQuery } from '../query-context';
import { useDialogExpansionContext } from '../dialog-expansion-context';
import { useABTest } from '../ab-test-context';
import {
	ProductSearchInputSkeleton,
	ProductSearchInputSkeletonInteractive,
} from '../../common/product-search-input-skeleton';
import { GenericProductSearchInput } from './generic-product-search-input';
import { type PageLoadMetricsInfo } from '../products/pre-configured/types';
import { useSessionUserInput } from '../user-input-provider';
import { useIntl } from 'react-intl-next';
import { messages } from '../../messages';

interface CommonProps {
	forwardRef: Ref<HTMLInputElement>;
	shouldFillContainer?: boolean;
}

interface NavigationRelatedProps {
	/**
	 * A generic implementation of the navigation control function for internal use within the dialog.
	 */
	onNavigateGeneric: (
		productId: string,
		href: string,
		event: React.MouseEvent | KeyboardEvent,
	) => void;
}

export interface ActiveProductSearchInputFeatures {
	interactiveSkeleton?: {
		onEnter?: (e: React.KeyboardEvent) => void;
		placeholder: string;
	};
}
interface ActiveProductSearchInputProps extends CommonProps {
	/**
	 * Provides override capabilities to the ProductInputSearchSkeleton
	 */
	features?: ActiveProductSearchInputFeatures;
	pageLoadMetrics?: PageLoadMetricsInfo;
}

interface ActiveProductSearchInputContextProps extends CommonProps {
	activeProductId?: string;
	query: string;
	setQuery: (query: string) => void;
	setIsExpanded: (isExpanded: boolean) => void;
	isExpanded: boolean;
	isLoading: boolean;
	pageLoadMetrics?: PageLoadMetricsInfo;
}

export interface ChildrenProps
	extends Omit<ActiveProductSearchInputContextProps, 'forwardRef'>,
		NavigationRelatedProps {
	onOpen: () => void;
	onBack: () => void;
	forwardRef: Ref<HTMLInputElement>;
}

export type ExternalProps = ActiveProductSearchInputProps & NavigationRelatedProps;

export const ActiveProductSearchInputStateless: FunctionComponent<
	ActiveProductSearchInputContextProps & NavigationRelatedProps
> = ({
	forwardRef,
	isExpanded,
	setIsExpanded,
	activeProductId,
	query,
	setQuery,
	onNavigateGeneric,
	isLoading,
	pageLoadMetrics,
	shouldFillContainer,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (pageLoadMetrics) {
			const { metric, customDataGetter } = pageLoadMetrics;
			metric.stop({ customData: customDataGetter() });
		}
	}, [pageLoadMetrics]);

	useEffect(() => {
		if (isExpanded) {
			inputRef?.current?.focus();
		}
	}, [isExpanded, inputRef, activeProductId]);

	const ref = mergeRefCallback(forwardRef, inputRef);

	const onOpen = useCallback(() => setIsExpanded(true), [setIsExpanded]);

	const onBack = useCallback(() => {
		setIsExpanded(false);
	}, [setIsExpanded]);

	const inputComponentProps = {
		activeProductId,
		query,
		setQuery,
		forwardRef: ref,
		isExpanded,
		setIsExpanded,
		onOpen,
		onNavigateGeneric,
		onBack,
		isLoading,
		shouldFillContainer,
	};

	return <GenericProductSearchInput {...inputComponentProps} />;
};

/**
 * A search input component made for a cross product experience. This input
 * changes based off the active product's registered properties.
 */
export const ActiveProductSearchInput: FunctionComponent<ExternalProps> = ({
	features,
	shouldFillContainer,
	...rest
}) => {
	const { getActiveProduct } = useProductContext();
	const { query, setQuery, isLoading } = useQuery();
	const { isExpanded, setIsExpanded } = useDialogExpansionContext();
	const { stickySearchEnabled } = useSessionUserInput();
	const abTest = useABTest();
	const theme = useTheme();
	const activeProduct = getActiveProduct();
	const intl = useIntl();

	if (!abTest) {
		return features?.interactiveSkeleton ? (
			<ProductSearchInputSkeletonInteractive
				theme={theme}
				shouldFillContainer={shouldFillContainer}
				stickySearchEnabled={stickySearchEnabled}
				{...features.interactiveSkeleton}
			/>
		) : (
			<ProductSearchInputSkeleton
				shouldFillContainer={shouldFillContainer}
				theme={theme}
				placeholder={intl.formatMessage(messages.common_search_input_collapsed_placeholder)}
			/>
		);
	}

	return (
		<ActiveProductSearchInputStateless
			{...rest}
			shouldFillContainer={shouldFillContainer}
			activeProductId={activeProduct?.id}
			query={query}
			setQuery={setQuery}
			isExpanded={isExpanded}
			setIsExpanded={setIsExpanded}
			isLoading={isLoading}
		/>
	);
};
