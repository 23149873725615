import type { GraphQLError } from 'graphql';

import { GraphQLNoResultsWithErrorResponseError } from './errors';

/**
 * Parse partial Apollo results if any are returned, throw an exception otherwise.
 * This should be used in conjunction with the query errorPolicy set to 'all'
 * since both data and errors are returned.
 */
export function parsePartialApolloResults<T>(
	data: T,
	graphQLErrors: ReadonlyArray<GraphQLError> | undefined,
): T {
	// Return data if there are no errors
	if (!graphQLErrors?.length) {
		return data;
	}

	// Return data if there are errors, but there is also partial data
	if (graphQLErrors?.length && data) {
		console.warn('Returning partial data despite GraphQL errors: ', graphQLErrors);
		return data;
	}

	// Throw an exception only if there are errors and NO data
	throw new GraphQLNoResultsWithErrorResponseError(graphQLErrors);
}
