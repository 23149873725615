import {
	type LinkComponent,
	SearchResultSection,
	EnlargedSearchResultSection,
} from '@atlassian/search-dialog';
import React, { type PropsWithChildren } from 'react';
import { injectIntl, type IntlShape, type WrappedComponentProps } from 'react-intl-next';
import {
	type NonPrivacySafeContext,
	type ScreenType,
	SearchDialogAnalyticsContext,
	type SearchResultContextPayload,
} from '../../common/analytics';
import {
	type SearchItems,
	type SearchResultSection as SearchResultSectionType,
} from '../product-router/product/result-types';
import { hasSearchResults } from '../product-router/product/utils';
import { type ProductStates } from '../product-state-machine';
import { SearchResultItem } from './search-result-item';
import { ViewAllLink } from './view-all-link';
import { PersistRecentSearch } from '../recent-searches/persist-recent-search';
import { useFeaturesContext } from '../features-context/features-context';
import { SearchResultWrapper } from './search-result-wrapper';

export const INITIAL_ITEM_LIMIT = 10;

export interface SearchResultProps {
	/**
	 * Override the section's showResultCount preference. Used to hide result
	 * counts in the pre-query and faster first search states.
	 * @default false
	 */
	forceHideResultCount?: boolean;

	/**
	 * Override the default query params appended to the href of each search item
	 * @param url the href of the search item
	 * @param searchSessionId the search session id
	 * @param type the type of the search item
	 * @param id the id of the search item
	 * @returns an updated url
	 */
	appendQueryParams?: (
		url: string,
		searchSessionId: string,
		type?: string,
		id?: string,
		additionalAnalytics?: object,
	) => string;

	/**
	 * DEPRECATED - This customisation should belong in each section.
	 *
	 * A function that can generate each section's title.
	 */
	generateSectionTitle?: (section: SearchResultSectionType, intl: IntlShape) => string;

	/**
	 * Toggle for whether to show the search results in a collapsed mode. The
	 * collapsed mode is used when width is restricted (e.g. filter pane enabled,
	 * mobile view). This mode will take up more vertical width.
	 */
	isCollapsed?: boolean;

	/**
	 * This will replace all links with the given component. This will be used to
	 * ensure that results provided will be properly SPA transitioned.
	 *
	 * Do not use this for styling.
	 */
	linkComponent?: LinkComponent;

	/**
	 * The current product state.
	 */
	productState: ProductStates;

	/**
	 * Show or hide the view all link under a section. A section must have a
	 * viewAllLinkGenerator for the view all link to be shown.
	 */
	renderSectionViewAll?: boolean;

	/**
	 * The search items containing all the sections to display.
	 */
	searchItems: SearchItems;

	/**
	 * Current screen type, used for analytics.
	 */
	screen: ScreenType;

	/**
	 * The search specific analytics to attach to the analytic events
	 * (privacy safe).
	 */
	analyticContext?: SearchResultContextPayload;

	/**
	 * The search specific analytics to attach to the analytic events
	 * (not privacy safe).
	 */
	nonPrivacySafeAnalyticContext?: NonPrivacySafeContext;

	/**
	 * TODO: Does any know what this is used for?
	 */
	siteUrl?: string;
}

export const getTotalNumberOfItemsInPreviousSections = (
	searchResultSections: SearchResultSectionType[],
	sectionIndex: number,
) =>
	searchResultSections
		.slice(0, sectionIndex)
		.reduce(
			(accumulator, currentElem) =>
				accumulator +
				Math.min(currentElem.searchResults.length, currentElem.resultLimit || INITIAL_ITEM_LIMIT),
			0,
		);

const getTotalResultCount = (forceHideResultCount: boolean, section: SearchResultSectionType) => {
	// resultCount is only shown when a size is specified. We changed this
	// from using searchResults.length as we want to indicate the number of
	// possible search results rather than just the ones shown.
	const resultCount =
		!forceHideResultCount && section.showResultCount && section.size && section.searchResults.length
			? Math.max(section.size, section.searchResults.length)
			: undefined;

	// totalResults considers the count returned from a section's resultMapper
	// to ensure rendered results align with the displayed count, in case results shown
	// are limited by the resultMapper
	const totalResults = Boolean(
		resultCount &&
			section.searchResults.length < (section.resultLimit || INITIAL_ITEM_LIMIT) &&
			resultCount >= section.searchResults.length,
	)
		? section.searchResults.length
		: resultCount;

	return totalResults;
};

const GenericSearchResultWithIntl = ({
	children,
	forceHideResultCount = false,
	generateSectionTitle = ({ title }) => title,
	intl,
	isCollapsed,
	linkComponent,
	productState,
	renderSectionViewAll,
	searchItems,
	screen,
	nonPrivacySafeAnalyticContext,
	appendQueryParams,
}: PropsWithChildren<SearchResultProps & WrappedComponentProps>) => {
	const { quickFind } = useFeaturesContext();

	if (!searchItems || !hasSearchResults(searchItems)) {
		// This is mainly used to handle the loading state (e.g. a spinner or a skeleton)
		if (children) {
			return <SearchResultWrapper>{children}</SearchResultWrapper>;
		}
		return null;
	}

	const ResultSection = quickFind?.enabled ? EnlargedSearchResultSection : SearchResultSection;

	const QuickFindPersistRecentSearch = quickFind?.enabled ? React.Fragment : PersistRecentSearch;

	const ItemSection = (props: { section: SearchResultSectionType; sectionIndex: number }) => {
		const { section, sectionIndex } = props;
		const itemLimit =
			typeof section.resultLimit === 'number' ? section.resultLimit : INITIAL_ITEM_LIMIT;

		const searchResults = section.searchResults?.slice(0, itemLimit) || [];
		const totalResults = getTotalResultCount(forceHideResultCount, section);

		const totalNumberOfItemsInPreviousSections = getTotalNumberOfItemsInPreviousSections(
			searchItems.sections,
			sectionIndex,
		);

		if (searchResults.length === 0) {
			return null;
		}

		return (
			<ResultSection
				totalResults={totalResults}
				title={
					section.titleRenderer
						? section.titleRenderer({ isCollapsed, productState })
						: generateSectionTitle(section, intl)
				}
			>
				{searchResults.map((result, index) => {
					const analyticContext = {
						sectionIndex,
						sectionId: section.id,
						containerId: result.containerId || null,
						indexWithinSection: index,
						globalIndex: totalNumberOfItemsInPreviousSections + index,
						isCached: result.isRecentResult || false,
						metadata: {
							contentId: result.id,
							containerId: result.containerId || null,
							contentType: result.type || null,
						},
					};

					return (
						<SearchDialogAnalyticsContext
							key={result.id}
							analyticContext={analyticContext}
							nonPrivacySafeAnalyticContext={nonPrivacySafeAnalyticContext || {}}
						>
							<QuickFindPersistRecentSearch>
								<SearchResultItem
									isCollapsed={isCollapsed}
									linkComponent={linkComponent}
									productState={productState}
									resultRenderer={section.resultRenderer}
									resultWrapper={section.resultWrapper}
									screen={screen}
									searchResult={result}
									appendQueryParams={appendQueryParams}
									useAnalyticsOverride={section.useAnalyticsOverride}
								/>
							</QuickFindPersistRecentSearch>
						</SearchDialogAnalyticsContext>
					);
				})}

				{renderSectionViewAll && section.viewAllLinkGenerator && (
					<ViewAllLink
						linkComponent={linkComponent}
						section={section}
						totalResults={totalResults}
					/>
				)}
			</ResultSection>
		);
	};

	return (
		<SearchResultWrapper>
			{searchItems.sections.map((section, index) => (
				<ItemSection section={section} sectionIndex={index} key={section.id} />
			))}
			{children}
		</SearchResultWrapper>
	);
};

export const GenericSearchResult = injectIntl(GenericSearchResultWithIntl);
