import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	/**
	 * Trello
	 */
	trello_archived: {
		id: 'product_search_dialog.trello.archived',
		defaultMessage: 'Archived',
		description: 'Will show on a search result if a Trello card is archived',
	},
	trello_archived_card_hover: {
		id: 'product_search_dialog.trello.archived.card-hover',
		defaultMessage: 'This card is archived',
		description: 'Hover text for archived status of card search result',
	},
	trello_board: {
		id: 'product_search_dialog.trello.board',
		defaultMessage: 'Board',
		description: 'A Trello Board',
	},
	trello_closed: {
		id: 'product_search_dialog.trello.closed',
		defaultMessage: 'Closed',
		description: 'Will show on a search result if a Trello board is closed',
	},
	trello_closed_board_hover: {
		id: 'product_search_dialog.trello.closed.board-hover',
		defaultMessage: 'This board is closed',
		description: 'Hover text for closed status of board search result',
	},
	trello_list: {
		id: 'product_search_dialog.trello.list',
		defaultMessage: 'List',
		description: 'A list on a Trello board',
	},
	trello_workspace: {
		id: 'product_search_dialog.trello.workspace',
		defaultMessage: 'Workspace',
		description: 'A Trello Workspace',
	},
	trello_private_workspace: {
		id: 'product_search_dialog.trello.private_workspace',
		defaultMessage: 'Private Workspace',
		description: 'A Trello private workspace',
	},
	trello_expanded_input_placeholder: {
		id: 'product_search_dialog.trello.expanded.input-placeholder',
		defaultMessage: 'Search Trello',
		description: 'The expanded placeholder text when the Trello tab is active.',
	},
	trello_recent_boards_heading: {
		id: 'product_search_dialog.trello.recent_boards_heading',
		defaultMessage: 'Recent boards',
		description: 'Heading for Recent boards, a Trello search result type',
	},
	trello_boards_heading: {
		id: 'product_search_dialog.trello.boards_heading',
		defaultMessage: 'Boards',
		description: 'Heading for Boards, a Trello search result type',
	},
	trello_cards_heading: {
		id: 'product_search_dialog.trello.cards_heading',
		defaultMessage: 'Cards',
		description: 'Heading for Cards, a Trello search result type',
	},
	trello_feedback_link: {
		id: 'product_search_dialog.trello.feedback_link',
		defaultMessage: 'Help us improve your search results!',
		description: 'Link redirecting to a feedback form',
	},
});
