import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';

import { SearchDialogHeaderAnalyticsWrapper } from '../../../utils/quick-find/analytics';

const containerStyles = xcss({
	paddingInlineStart: 'space.100',
	paddingInlineEnd: 'space.100',
});

const headingStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body.small',
	fontWeight: 'font.weight.semibold',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	width: '100%',
	padding: 'space.100',
	userSelect: 'none',
});

type HeadingProps = {
	id: string;
	text: string;
	includeInAnalytics?: boolean;
};

export const Heading = ({
	id,
	text,
	includeInAnalytics = true,
}: React.PropsWithChildren<HeadingProps>) => {
	const isUserShardEnabled = fg('quick_find_user_shard_query');

	// user shard demo merges recents and search results
	if (isUserShardEnabled) {
		return null;
	}

	const headingWithoutAnalyticsWrapper = (
		<Box xcss={containerStyles}>
			<Box xcss={headingStyles}>{text}</Box>
		</Box>
	);

	return includeInAnalytics ? (
		<SearchDialogHeaderAnalyticsWrapper id={id}>
			{headingWithoutAnalyticsWrapper}
		</SearchDialogHeaderAnalyticsWrapper>
	) : (
		headingWithoutAnalyticsWrapper
	);
};
