import React, { useEffect, useState, useRef, type PropsWithChildren } from 'react';
import { PLACEHOLDER_EXPERIENCE, useAggregatorClient } from '../../aggregator-client-context';
import { type ProductContextProps, withProductContext } from '../product-router';
import RegisterProduct, { type RegisterProductProps } from './register-product';

export type PermissionSupplier = () => Promise<string[]>;

export type CheckProductPermissionsProps<T = object> = Omit<
	RegisterProductProps<T>,
	'allowedSections'
> & {
	/**
	 * A promise which resolves a list of sectionIds accessible by the user.
	 * This will override the aggregator scopes API check.
	 */
	permissionSupplier?: PermissionSupplier;
};

/**
 * A component which invokes the permissionSupplier, if supplied or uses the Search Platform's default permission checker.
 * The calls to the default API are batched.
 * In response it expects the permissible sections that should be shown to the user.
 */
export const CheckProductPermissions = ({
	children,
	permissionSupplier,
	id,
	title,
	sections,
	order,
	generateAdvancedSearchUrl,
	expandedStateInputPlaceholder,
	workspaces,
	autocorrectEnabled,
	showViewAllFiltersLink,
	tabRenderer,
	aiSearchConfig,
	showSearchOnEnter,
}: PropsWithChildren<CheckProductPermissionsProps & ProductContextProps>) => {
	const invokePostQuerySearchRef = useRef<() => void>(() => {});
	const [allowedSections, setAllowedSections] = useState<string[]>([]);
	const searchClient = useAggregatorClient();

	useEffect(() => {
		if (allowedSections.length === 0) {
			// For Jira we need to improve performance by not rendering all the search dialog tabs before the
			// dialog is expanded. However, in order to ensure that the search component is interactive there needs
			// to be at least one allowed section. This test is a workaround to ensure that when there is only this
			// single dummy section that it is included without a permission check. This hook will be called again
			// when the search dialog is expanded and the real sections are provided.
			if (sections.length === 1 && sections[0].id === PERFORMANCE_IMPROVEMENT_DUMMY_SECTION) {
				setAllowedSections([PERFORMANCE_IMPROVEMENT_DUMMY_SECTION]);
				return;
			} else {
				const promise = permissionSupplier
					? permissionSupplier()
					: searchClient
						? searchClient.batchedGetExtensibleProductPermission(id, {
								productIds: [id],
								experience: PLACEHOLDER_EXPERIENCE,
							})
						: Promise.resolve([]);
				promise.then((response) => {
					setAllowedSections(response);
				});
			}
		}
		/**
		 * We want to replicate componentDidMount behaviour to avoid invoking the API multiple times and we don't care if any other deps changed.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return allowedSections.length > 0 ? (
		<>
			<RegisterProduct
				allowedSections={allowedSections}
				sections={sections}
				id={id}
				title={title}
				order={order}
				generateAdvancedSearchUrl={generateAdvancedSearchUrl}
				expandedStateInputPlaceholder={expandedStateInputPlaceholder}
				workspaces={workspaces}
				autocorrectEnabled={autocorrectEnabled}
				showViewAllFiltersLink={showViewAllFiltersLink}
				tabRenderer={tabRenderer}
				aiSearchConfig={aiSearchConfig}
				showSearchOnEnter={showSearchOnEnter}
				invokePostQuerySearchRef={invokePostQuerySearchRef}
			>
				{children}
			</RegisterProduct>
		</>
	) : (
		<></>
	);
};

export const PERFORMANCE_IMPROVEMENT_DUMMY_SECTION = '__PERFORMANCE_IMPROVEMENT_DUMMY_SECTION__';
export default withProductContext(CheckProductPermissions);
