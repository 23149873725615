import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import type { SearchCSS } from '@atlaskit/atlassian-navigation';

import { QuickFind } from '@atlassian/product-search-dialog/beta';

import { markErrorAsHandled } from '@confluence/graphql';
import { CONTEXT_PATH } from '@confluence/named-routes';
import type { ParsedUrlQuery } from '@confluence/route-manager-utils';

import type { UserContextDataPayload } from './UserContext';
import type { LinkComponent } from './NavigationSearchDialog';

type ConfluenceQuickFindProps = {
	cloudId: string;
	orgId?: string;
	aggregatorUrl: string;
	formatDate: (lastModified: string) => JSX.Element;
	forwardRef: React.RefObject<HTMLInputElement>;
	isExpanded: boolean;
	onNavigateGeneric: (id: string, href: string) => void;
	setIsExpanded: (expanded: boolean) => void;
	theme?: SearchCSS;
	user: UserContextDataPayload;
	linkComponent: LinkComponent;
	shouldFillContainer?: boolean;
	isRovoEnabled?: boolean;

	/**
	 * Used to support Griffin navigation (nav4)
	 */
	isGriffinNavEnabled?: boolean;
	queryParams?: ParsedUrlQuery;
};

export const ConfluenceQuickFind = ({
	cloudId,
	orgId, // required for Team results
	theme,
	isExpanded,
	setIsExpanded,
	aggregatorUrl,
	user,
	forwardRef,
	onNavigateGeneric,
	linkComponent,
	shouldFillContainer,
	isRovoEnabled,
	isGriffinNavEnabled,
	queryParams,
}: ConfluenceQuickFindProps) => {
	const confCloudClient = useApolloClient();

	return (
		<QuickFind
			theme={theme}
			linkComponent={linkComponent}
			isExpanded={isExpanded}
			setIsExpanded={setIsExpanded}
			abTestCloudId={cloudId}
			aggregatorUrl={aggregatorUrl}
			user={user}
			forwardRef={forwardRef}
			onNavigateGeneric={onNavigateGeneric}
			recentClientConfig={{
				url: CONTEXT_PATH,
				cloudId,
				isCollaborationGraphEnabled: false,
				collaborationGraphUrl: '/gateway/api/collaboration',
				isUserAnonymous: !user?.id,
			}}
			confCloudClient={confCloudClient}
			onPreQueryErrorCallback={markErrorAsHandled}
			cloudId={cloudId}
			orgId={orgId}
			primaryProduct="confluence"
			shouldFillContainer={shouldFillContainer}
			isRovoEnabled={isRovoEnabled}
			dialogFeatures={{
				isGriffinNavEnabled,
			}}
			queryParams={queryParams}
		/>
	);
};
