import React, { type PropsWithChildren } from 'react';
import { SearchDialog } from '@atlassian/search-dialog';
import { useDialogExpansionContext } from '../../dialog-expansion-context';
import { useFeaturesContext } from '../../features-context/features-context';
import { token } from '@atlaskit/tokens';

export const ProductSearchDialogWrapper = ({
	children,
	shouldFillContainer,
}: PropsWithChildren<{ shouldFillContainer?: boolean }>) => {
	const { isExpanded } = useDialogExpansionContext();
	const { quickFind } = useFeaturesContext();

	return (
		<SearchDialog
			shouldFillContainer={shouldFillContainer}
			isExpanded={isExpanded}
			style={{
				boxShadow: isExpanded ? undefined : 'none',
				borderRadius: quickFind?.enabled ? token('space.100', '8px') : undefined,
			}}
		>
			{children}
		</SearchDialog>
	);
};
