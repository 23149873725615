import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	recentlyWorkedOn: {
		id: 'navigation-search-dialog.suppliers.recentlyWorkedOn',
		defaultMessage: 'Recently worked on',
		description: 'Section label for recently worked on in quick search dialog',
	},
	recentlyViewed: {
		id: 'navigation-search-dialog.suppliers.recentlyViewed',
		defaultMessage: 'Recently viewed',
		description: 'Section label for recently viewed in quick search dialog',
	},
	contents: {
		id: 'navigation-search-dialog.suppliers.contents',
		defaultMessage: 'Content',
		description:
			'Section label for pages, blogs, attachments, and whiteboards in quick search dialog',
	},
	spaces: {
		id: 'navigation-search-dialog.suppliers.spaces',
		defaultMessage: 'Spaces',
		description: 'Section label for spaces in quick search dialog',
	},
	people: {
		id: 'navigation-search-dialog.suppliers.people',
		defaultMessage: 'People',
		description: 'Section label for people in quick search dialog',
	},
	confluenceSpace: {
		id: 'navigation-search-dialog.quickfind.confluenceSpace',
		defaultMessage: 'Confluence Space',
		description: 'Metadata for Confluence Space results',
	},
	team: {
		id: 'navigation-search-dialog.team',
		defaultMessage: 'Team',
		description: 'Label for team search results',
	},
});
