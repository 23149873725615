import React, { Fragment } from 'react';
import { withAnalytics } from '../../common/analytics';
import { onAdvancedSearchSelected } from '../../common/analytics/events';
import {
	NoResults,
	NoResultsQuickFindPostQuery,
	type NoResultsProps,
} from '../../common/no-results';
import { type ScreenSpecificProps } from '../product-router/product/result-provider/result-renderer';
import { useQuery } from '../query-context';
import { useAutocorrectionEnabled } from '../autocorrection/autocorrection-helper-functions';
import { AutocorrectAsyncWrapper } from '../autocorrection';
import { NoResultsAutocorrectWrapper } from '../autocorrection/autocorrection.styled';

const NoResultsWithHooks = ({
	urlGeneratorForNoResultsScreen = () => '',
	advancedSearchSelected,
	fromQuickFindPostQueryNoResults,
}: Pick<ScreenSpecificProps, 'urlGeneratorForNoResultsScreen'> &
	Pick<NoResultsProps, 'advancedSearchSelected'> & {
		fromQuickFindPostQueryNoResults?: boolean;
	}) => {
	const { query } = useQuery();
	const { autocorrectionEnabled } = useAutocorrectionEnabled();
	const advancedSearchUrl = urlGeneratorForNoResultsScreen(query); // TODO: needs filters here

	return (
		<Fragment>
			{autocorrectionEnabled && <AutocorrectAsyncWrapper container={NoResultsAutocorrectWrapper} />}
			{fromQuickFindPostQueryNoResults ? (
				<NoResultsQuickFindPostQuery
					advancedSearchUrl={advancedSearchUrl}
					advancedSearchSelected={advancedSearchSelected}
				/>
			) : (
				<NoResults
					advancedSearchUrl={advancedSearchUrl}
					advancedSearchSelected={advancedSearchSelected}
				/>
			)}
		</Fragment>
	);
};

export default withAnalytics({
	advancedSearchSelected: onAdvancedSearchSelected,
})(NoResultsWithHooks);
