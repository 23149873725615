import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	space: {
		id: 'search-page.filters.names.space',
		defaultMessage: 'Space',
		description: 'Label for filtering by space',
	},
	confluenceFilterSpacePlaceholder: {
		id: 'search-page.confluence.filter.space.placeholder',
		defaultMessage: 'Find spaces',
		description: 'Placeholder for space filter search input',
	},
	confluenceFilterLabelSelected: {
		id: 'search-page.confluence.filter.label.selected',
		defaultMessage: 'Selected',
		description: 'Confluence filter label selected',
	},
	confluenceFilterLabelUnselected: {
		id: 'search-page.confluence.filter.label.unselected',
		defaultMessage: 'Suggested',
		description: 'Confluence filter label unselected',
	},
	confluenceFilterSpaceIcon: {
		id: 'search-page.confluence.filter.space.icon',
		defaultMessage: 'Space Icon',
		description: 'Confluence space filter icon',
	},
});
