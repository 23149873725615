import { useCallback } from 'react';
import { type DateRange } from '../../filters';
import { type FilterValueGetters, ISSUE_NAVIGATOR_URL } from './types';
import { fg } from '@atlaskit/platform-feature-flags';

const DEFAULT_ORDER_BY = 'ORDER BY created DESC';

/**
 * Builds a JQL query for text search input using summary and description
 */
const buildTextSearchInputJql = (query: string) => {
	if (!query) {
		return '';
	}

	const encodedQuery = encodeURIComponent(query);

	return `textfields ~ "${encodedQuery}*"`;
};

/**
 * Add default order by clause to a JQL query
 */
const addOrderBy = (jql: string) => {
	/**
	 * We are going to add order by later on after comparing performance between `text` vs `textFields`
	 * (more info https://atlassian.slack.com/archives/C07CGB3HQDT/p1729556063830479?thread_ts=1729214801.628459&cid=C07CGB3HQDT)
	 */
	if (fg('nin-text-fields-new-text-search-field')) {
		return jql;
	}

	// if not jql is provided return just order by
	if (!jql) {
		return DEFAULT_ORDER_BY;
	}

	return `${jql} ${DEFAULT_ORDER_BY}`;
};

const buildJQLAdvancedIssueUrl = (jql: string) => `/issues/?jql=${jql}`;

const buildJiraSearchUrl = (query: string) =>
	`/jira/search?searchString=${encodeURIComponent(query)}`;

const buildSmartQueryAdvancedSearchUrl = (query: string) =>
	`/secure/QuickSearch.jspa?searchString=${encodeURIComponent(query)}`;

const escapeAndQuoteLabels = (label: string) => {
	// Escape backslashes and double quotes
	const escapedLabel = label.replace(/([\\"])/g, '\\$1');
	// Wrap the escaped label in double quotes
	return `"${escapedLabel}"`;
};

const buildJqlQuery = (
	query: string,
	projectIds: string[],
	accountIds: string[],
	reporterIds: string[],
	binaryStatusCategoryValues: string[],
	issueLabels: string[],
	appliedDateFilterValue: DateRange | null,
	isSearchWithTextFieldInAllIssuesLinkEnabled: boolean,
) => {
	let textJQL;
	if (isSearchWithTextFieldInAllIssuesLinkEnabled) {
		textJQL = query
			? `text ~ "${encodeURIComponent(query)}${fg('nin-text-fields-new-text-search-field') ? '*' : ''}"`
			: '';
	} else {
		textJQL = query ? buildTextSearchInputJql(query) : '';
	}

	const projectJQL =
		projectIds.length > 0 ? `project IN (${encodeURIComponent(projectIds.join(','))})` : '';
	const assigneeJQL =
		accountIds.length > 0 ? `assignee IN (${encodeURIComponent(accountIds.join(','))})` : '';
	const issueLabelsJQL =
		issueLabels.length > 0
			? `labels IN (${encodeURIComponent(
					issueLabels.map((label: string) => escapeAndQuoteLabels(label)).join(','),
				)})`
			: '';
	const reporterJQL =
		reporterIds.length > 0 ? `reporter IN (${encodeURIComponent(reporterIds.join(','))})` : '';

	const updateDateJQL =
		appliedDateFilterValue !== null
			? `updated >= "${appliedDateFilterValue.from}" AND updated <= "${appliedDateFilterValue.to}"`
			: '';

	const binaryStatusCategoryJQL =
		binaryStatusCategoryValues?.length === 1
			? `statusCategory IN (${encodeURIComponent(
					getStatusesByStatusCategory(binaryStatusCategoryValues),
				)})`
			: '';

	return fg('nin-text-fields-new-text-search-field')
		? addOrderBy(
				[
					textJQL,
					projectJQL,
					assigneeJQL,
					reporterJQL,
					binaryStatusCategoryJQL,
					issueLabelsJQL,
					updateDateJQL,
				]
					.filter(Boolean)
					.join(' AND '),
			)
		: [
				textJQL,
				projectJQL,
				assigneeJQL,
				reporterJQL,
				binaryStatusCategoryJQL,
				issueLabelsJQL,
				updateDateJQL,
			]
				.filter(Boolean)
				.join(' AND ');
};

const getStatusesByStatusCategory = (values: string[]): string => {
	if (!!values.find((v) => v === 'open')) {
		return '"undefined", "In Progress", "To Do"';
	}
	if (!!values.find((v) => v === 'done')) {
		return '"Done"';
	}
	return '';
};

export const useURLFactory = (
	filterValueGetters: FilterValueGetters,
	appliedDateFilterValue: DateRange | null,
	isPreQueryFiltersEnabled?: boolean,
	isSearchWithTextFieldInAllIssuesLinkEnabled: boolean = false,
) => {
	const buildJiraAdvancedSearchPath = useCallback(
		(query: string) => {
			const trimmedQuery = query.trim();

			const projectIds = filterValueGetters.projects();
			const accountIds = filterValueGetters.assignees();
			const reporterIds = filterValueGetters.reportedBy();
			const binaryStatusCategories = filterValueGetters.statuses();
			const issueLabels = filterValueGetters.issueLabels();

			// If there is a filter applied, and either a query is provided or isPreQueryFiltersEnabled is true, then we build the JQL
			if (
				(projectIds.length !== 0 ||
					accountIds.length !== 0 ||
					reporterIds.length !== 0 ||
					binaryStatusCategories.length === 1 ||
					issueLabels.length !== 0 ||
					appliedDateFilterValue !== null) &&
				(trimmedQuery || isPreQueryFiltersEnabled)
			) {
				return buildJQLAdvancedIssueUrl(
					buildJqlQuery(
						trimmedQuery,
						projectIds,
						accountIds,
						reporterIds,
						binaryStatusCategories,
						issueLabels,
						appliedDateFilterValue,
						isSearchWithTextFieldInAllIssuesLinkEnabled,
					),
				);
			}

			// Otherwise we determine next action based on whether a query is provided or not

			if (!trimmedQuery) {
				// If query is empty, we utilise a spa transition with ISSUE_NAVIGATOR_URL
				return query === '' ? ISSUE_NAVIGATOR_URL : buildSmartQueryAdvancedSearchUrl(query);
			}

			return buildJiraSearchUrl(trimmedQuery);
		},
		[
			filterValueGetters,
			appliedDateFilterValue,
			isPreQueryFiltersEnabled,
			isSearchWithTextFieldInAllIssuesLinkEnabled,
		],
	);

	const useFiltersAdvancedSearchUrlFactory = useCallback(
		(query: string, siteUrl?: string) => `${siteUrl ?? ''}${buildJiraAdvancedSearchPath(query)}`,
		[buildJiraAdvancedSearchPath],
	);

	return {
		getFiltersAdvancedSearchUrl: useFiltersAdvancedSearchUrlFactory,
	};
};
