import { type GasPayload } from '@atlaskit/analytics-gas-types';

export const onSearchResultsShown = ({
	attributes,
	nonPrivacySafeAttributes,
	resultsType,
}: {
	attributes: Record<string, any>;
	nonPrivacySafeAttributes: Record<string, any>;
	resultsType: 'preQuerySearchResults' | 'cachedResults' | 'postQuerySearchResults';
}): GasPayload => ({
	eventType: 'ui',
	action: 'shown',
	actionSubject: 'searchResults',
	actionSubjectId: resultsType,
	attributes,
	nonPrivacySafeAttributes,
});
