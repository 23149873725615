import { type GasPayload } from '@atlaskit/analytics-gas-types';

export const onSearchDialogDismissed = ({
	attributes,
	nonPrivacySafeAttributes,
}: {
	attributes: Record<string, any>;
	nonPrivacySafeAttributes: Record<string, any>;
}): GasPayload => ({
	source: 'searchDialog',
	eventType: 'ui',
	action: 'dismissed',
	actionSubject: `searchDialog`,
	attributes,
	nonPrivacySafeAttributes,
});
