import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	googleDocsLabel: {
		id: 'document-search-result.google-docs-label',
		defaultMessage: 'Docs',
		description: 'Google Docs subtype label',
	},
	googleSlidesLabel: {
		id: 'document-search-result.google-slides-label',
		defaultMessage: 'Slides',
		description: 'Google Slides subtype label',
	},
	googleSheetsLabel: {
		id: 'document-search-result.google-sheets-label',
		defaultMessage: 'Sheets',
		description: 'Google Sheets subtype label',
	},
	driveLabel: {
		id: 'document-search-result.drive-label',
		defaultMessage: 'Drive',
		description: 'Default subtype label for drive documents when no subtype is available',
	},
	sharepointLabel: {
		id: 'document-search-result.sharepoint-label',
		defaultMessage: 'SharePoint',
		description: 'Default subtype label for sharepoint documents when no subtype is available',
	},
	onedriveLabel: {
		id: 'document-search-result.onedrive-label',
		defaultMessage: 'OneDrive',
		description: 'Default subtype label for onedrive documents when no subtype is available',
	},
	msWordLabel: {
		id: 'document-search-result.ms-word-label',
		defaultMessage: 'Word',
		description: 'Microsoft Word subtype label',
	},
	msPowerpointLabel: {
		id: 'document-search-result.ms-powerpoint-label',
		defaultMessage: 'PowerPoint',
		description: 'Microsoft PowerPoint subtype label',
	},
	msExcelLabel: {
		id: 'document-search-result.ms-excel-label',
		defaultMessage: 'Excel',
		description: 'Microsoft Excel subtype label',
	},
});
