const SearchResultAtlasProject = `
... on SearchResultAtlasProject {
  project {
    __typename
    key
  }
}
`;

const SearchResultAtlasGoal = `
... on SearchResultAtlasGoal {
  goal {
    __typename
    key
  }
}
`;

const SearchConfluencePageBlogAttachment = `
... on SearchConfluencePageBlogAttachment {
  __typename
  lastModified
  iconCssClass
  space {
    __typename
    id
    name
  }
  confluenceEntity @include(if: $includeAncestors) {
    ... on ConfluencePage {
      __typename
      ancestors {
        __typename
        id
        title
      }
    }
  }
}`;

export const operationName = 'QuickFindSearchQuery';
export const quickFindSearchGraphQuery = `
  query ${operationName}(
    $query: String!
    $cloudIdARI: String!
    $resultsLimit: Int!
    $spaceLimit: Int!
    $contentEntities: [String!]!
    $confluenceFilters: SearchConfluenceFilter
    $queryVersion: Int
    $searchSessionId: String
    $includeSpaces: Boolean!
    $includeAncestors: Boolean!
    $experimentId: String
    $shadowExperimentId: String
    $experimentLayers: [SearchExperimentLayer]
  ) {
    search {
      results: search(
        experience: "quick-find"
        query: $query
        first: $resultsLimit
        filters: {
          entities: $contentEntities
          locations: [$cloudIdARI]
          confluenceFilters: $confluenceFilters
        }
        analytics: {
          queryVersion: $queryVersion
          searchSessionId: $searchSessionId
        }
        experimentContext: {
          experimentId: $experimentId
          shadowExperimentId: $shadowExperimentId
          experimentLayers: $experimentLayers
        }
      ) {
        __typename
        edges {
          __typename
          node {
            id
            title
            url
            type
            iconUrl
            __typename
            ${SearchConfluencePageBlogAttachment}
            ${SearchResultAtlasProject}
            ${SearchResultAtlasGoal}
          }
        }
        abTest {
          __typename
          abTestId
          controlId
          experimentId
        }
        totalCount
      }
      spaces: search(
        experience: "searchDialog"
        query: $query
        first: $spaceLimit
        filters: {
          entities: ["ati:cloud:confluence:space"]
          locations: [$cloudIdARI]
        }
        analytics: {
          queryVersion: $queryVersion
          searchSessionId: $searchSessionId
        }
      ) @include(if: $includeSpaces) {
        __typename
        edges {
          __typename
          node {
            ... on SearchConfluenceSpace {
              __typename
              id
              key
              title
              type
              url
              iconPath
            }
          }
        }
      }
    }
  }
`;
