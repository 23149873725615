import { type ScopedAggregatorResponse } from '../../../common/clients';
import { type ScreenSpecificProps } from '../../product-router/product';

export enum BitbucketScope {
	BitbucketRepostitory = 'bitbucket.repository',
}

export interface BitbucketLink {
	href: string;
}

interface BitbucketWorkspaceLinksResponse {
	self: string;
	html: string;
	avatar: string;
}

interface BitbucketWorkspaceResponse {
	name: string;
	uuid: string;
	links: BitbucketWorkspaceLinksResponse;
}

export interface BitbucketRepositoryResponse {
	name: string;
	uuid: string;
	description?: string;
	links: {
		html: BitbucketLink;
		avatar: BitbucketLink;
		self: BitbucketLink;
	};
	workspace: BitbucketWorkspaceResponse;
}

export interface AggregatorBitbucketResponse extends ScopedAggregatorResponse<BitbucketScope> {
	results: BitbucketRepositoryResponse[];
}

export type BitbucketURLGenerators = {
	viewAllLinkGenerator: (query: string) => string;
	codeSearchUrlGenerator: (query: string) => string;
	codeSearchContextualUrlGenerator: (query: string, context: BitbucketContext) => string;
} & Required<Pick<ScreenSpecificProps, 'urlGeneratorForNoResultsScreen'>>;

export type BitbucketProject = {
	created_on?: string;
	description?: string;
	is_private?: boolean;
	key: string;
	links?: {
		avatar: BitbucketLink;
		html: BitbucketLink;
		repositories?: BitbucketLink;
		self: BitbucketLink;
	};
	name: string;
	type: 'project';
	updated_on?: string;
	uuid: string;
	workspace?: BitbucketWorkspace;
	has_publicly_visible_repos?: boolean;
};

export type BitbucketRepository = {
	created_on?: string;
	description?: string;
	fork_policy?: string;
	full_name: string;
	has_issues?: boolean;
	has_wiki?: boolean;
	is_private?: boolean;
	landing_page?: string;
	language?: string;
	links: {
		avatar: BitbucketLink;
		branches?: BitbucketLink;
		clone: [BitbucketLink & { name: 'https' }, BitbucketLink & { name: 'ssh' }];
		commits?: BitbucketLink;
		downloads?: BitbucketLink;
		forks?: BitbucketLink;
		hooks?: BitbucketLink;
		html: BitbucketLink;
		pullrequests?: BitbucketLink;
		self: BitbucketLink;
		tags?: BitbucketLink;
		watchers?: BitbucketLink;
	};
	name: string;
	parent?: BitbucketRepository;
	project?: BitbucketProject;
	size?: number;
	slug?: string;
	type: 'repository';
	updated_on?: string;
	uuid: string;
	website?: string | null;
	workspace?: BitbucketWorkspace;
};

export type BitbucketWorkspace = {
	created_on?: string;
	is_private?: boolean;
	display_name?: string;
	extra?: {
		has_premium?: boolean;
		admin_status?: string;
	};
	links: {
		avatar: BitbucketLink;
		html: BitbucketLink;
		members?: BitbucketLink;
		owners?: BitbucketLink;
		projects?: BitbucketLink;
		repositories?: BitbucketLink;
		self: BitbucketLink;
	};
	name: string;
	slug: string;
	type: 'workspace';
	updated_on?: string;
	uuid: string;
};

export type BitbucketContext = {
	repository?: BitbucketRepository;
	workspace?: BitbucketWorkspace;
};

export interface BitbucketFeatures {}

export const BITBUCKET_PRODUCT_ID = 'bitbucket';
