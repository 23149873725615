import React, { useCallback, useEffect, useRef } from 'react';
import {
	SearchAnchor,
	EnlargedSearchAnchor,
	KeyboardHighlightProvider,
} from '@atlassian/search-dialog';
import { useDialogExpansionContext } from '../dialog-expansion-context';
import { mergeRefCallback } from '../../utils/merge-ref-callback';
import { useFeaturesContext } from '../features-context/features-context';

interface ContextProps {
	isExpanded: boolean;
	onClose: () => void;
	isQuickFind?: boolean;
}
interface Props {
	shouldFillContainer?: boolean;
	children?: (setRef: (ref: HTMLInputElement | null) => void) => React.ReactElement | null;
}
interface State {
	ref: HTMLElement | null;
}

export class KeyboardWrapperStateless extends React.Component<Props & ContextProps, State> {
	state = {
		ref: null as HTMLElement | null,
	};

	/**
	 * Hold a timeout id for the onBlur and onFocus handlers
	 */
	timeout: number | undefined = undefined;

	handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Escape' && !event.defaultPrevented && this.props.isExpanded) {
			event.preventDefault();
			this.close();
		}
	};

	onBlurHandler = () => {
		this.timeout = window.setTimeout(() => {
			this.close();
		});
	};

	onFocusHandler = () => {
		window.clearTimeout(this.timeout);
	};

	setRef = (ref: HTMLInputElement | null) => {
		if (this.state.ref !== ref) {
			this.setState({
				ref,
			});
		}
	};

	close = () => {
		const { ref } = this.state;
		ref && ref.blur();
		this.props.onClose();
	};

	render() {
		const { isExpanded, isQuickFind, shouldFillContainer, children } = this.props;
		const SearchAnchorComponent = isQuickFind ? EnlargedSearchAnchor : SearchAnchor;

		return (
			<SearchAnchorComponent
				onBlur={this.onBlurHandler}
				onFocus={this.onFocusHandler}
				onKeyDown={this.handleKeyDown}
				isExpanded={isExpanded}
				shouldFillContainer={shouldFillContainer}
			>
				<KeyboardHighlightProvider listenerNode={this.state.ref}>
					{children ? children(this.setRef) : null}
				</KeyboardHighlightProvider>
			</SearchAnchorComponent>
		);
	}
}

export interface ExternalProps {
	forwardRef: React.Ref<HTMLInputElement>;
	keepExpandedInTab?: boolean;
	children: (arg: KeyboardWrapperExternalChildren) => React.ReactElement | null;
	shouldFillContainer?: boolean;
}

export interface KeyboardWrapperExternalChildren {
	ref: React.Ref<HTMLInputElement>;
	onRetry: () => void;
}

export const KeyboardWrapper: React.FC<ExternalProps> = ({
	children,
	keepExpandedInTab = false,
	forwardRef,
	shouldFillContainer,
	...rest
}) => {
	const { isExpanded, setIsExpanded, allowChangeExpand } = useDialogExpansionContext();

	const inputRef = useRef<HTMLInputElement>(null);
	const mergedForwardref = mergeRefCallback(inputRef, forwardRef);
	const isQuickFind = useFeaturesContext().quickFind?.enabled;

	const onClose = useCallback(() => {
		setIsExpanded(false);
	}, [setIsExpanded]);

	const onTabChange = useCallback(() => {
		if (document.hidden) {
			allowChangeExpand(false);
		} else {
			if (isExpanded) {
				inputRef?.current?.focus?.();
			}
			allowChangeExpand(true);
		}
	}, [allowChangeExpand, isExpanded]);

	useEffect(() => {
		if (keepExpandedInTab) {
			document.addEventListener('visibilitychange', onTabChange);
			return () => {
				document.removeEventListener('visibilitychange', onTabChange);
			};
		}
	}, [onTabChange, keepExpandedInTab]);

	return (
		<KeyboardWrapperStateless
			{...rest}
			shouldFillContainer={shouldFillContainer}
			onClose={onClose}
			isExpanded={isExpanded}
			isQuickFind={isQuickFind}
		>
			{(setRef) => {
				const ref = mergeRefCallback(setRef, mergedForwardref);
				const onRetry = () => {
					inputRef?.current?.focus?.();
				};

				return children({ ref, onRetry });
			}}
		</KeyboardWrapperStateless>
	);
};
