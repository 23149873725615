import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { QuickFind } from './QuickFind';

export const QuickFindNav3 = () => (
	<Box testId="quick-find-container" xcss={searchBarWrapper}>
		<Box xcss={searchBarContainer}>
			<QuickFind />
		</Box>
	</Box>
);

const searchBarWrapper = xcss({
	position: 'relative',
	width: '200px',
	height: token('space.500'),
});

const searchBarContainer = xcss({
	position: 'absolute',
	top: 'space.0',
	right: 'space.0',
	bottom: 'space.0',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	zIndex: 'dialog',
});
