import React, { useContext, useEffect, useCallback, useState, type PropsWithChildren } from 'react';
import { useSessionUserInput } from '../user-input-provider';
import {
	type AnalyticsContextAction,
	SearchDialogAnalyticsContext,
	useAnalyticsContext,
} from '../../common/analytics';
import { useDialogExpansionContext } from '../dialog-expansion-context';

interface QueryContext {
	query: string;
	queryVersion: number;
	isLoading: boolean;
	setQuery: (value: string) => void;
	setQueryOnly: (value: string) => void;
	setAdditionalAnalyticsContext: React.Dispatch<AnalyticsContextAction>;
}

const QueryContext = React.createContext<QueryContext>({
	query: '',
	queryVersion: 0,
	isLoading: false,
	setQuery: (ignore: string) => {},
	setQueryOnly: (ignore: string) => {},
	setAdditionalAnalyticsContext: (ignore: AnalyticsContextAction) => ({}),
});

export const QueryContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const [stateQuery, setStateQuery] = useState('');
	const {
		stickySearchEnabled,
		query: sessionQuery,
		showSearchAIDialog,
		toggleShowSearchAIDialog,
		storeQuery,
	} = useSessionUserInput();
	const query = stickySearchEnabled ? sessionQuery : stateQuery;
	const setQuery = useCallback(
		(value: string) => {
			if (showSearchAIDialog) {
				toggleShowSearchAIDialog(false);
			}
			stickySearchEnabled ? storeQuery(value) : setStateQuery(value);
		},
		[showSearchAIDialog, toggleShowSearchAIDialog, stickySearchEnabled, storeQuery],
	);

	const setQueryOnly = useCallback(
		(value: string) => {
			stickySearchEnabled ? storeQuery(value) : setStateQuery(value);
		},
		[stickySearchEnabled, storeQuery],
	);

	const { isExpanded } = useDialogExpansionContext();
	const {
		isLoading,
		setAdditionalAnalyticsContext,
		addAnalyticContext,
		nonPrivacySafeContext,
		queryVersion,
	} = useAnalyticsContext(query);

	useEffect(() => {
		if (!isExpanded) {
			if (!stickySearchEnabled) {
				setQuery('');
			}
			setAdditionalAnalyticsContext({ type: 'reset' });
		}
	}, [isExpanded, setAdditionalAnalyticsContext, stickySearchEnabled, setQuery]);

	return (
		<QueryContext.Provider
			value={{
				query,
				queryVersion,
				setQuery,
				setQueryOnly,
				setAdditionalAnalyticsContext,
				isLoading,
			}}
		>
			<SearchDialogAnalyticsContext
				analyticContext={addAnalyticContext}
				nonPrivacySafeAnalyticContextGenerator={nonPrivacySafeContext}
			>
				{children}
			</SearchDialogAnalyticsContext>
		</QueryContext.Provider>
	);
};

export const useQuery = () => {
	return useContext(QueryContext);
};
