import React, { type ReactElement, type FunctionComponent, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl-next';
import {
	CollapsedResultContentWrapper,
	IconContainer,
	ResultContentWrapper,
	ResultTitleContainer,
} from '@atlassian/search-dialog';
import { messages } from '../messages';
import { type ScreenType } from '../../../../common/analytics/events';
import {
	CollapsedResultDetailWrapper,
	BoardAndListCol,
	BoardAndListDetail,
	ArchivedCol,
} from './trello-search-result.styled';
import { type TrelloBoardPrefs } from '../types';
import { CardIcon, BoardIcon } from '../icons';
import { HoverPreviewsResultWrapper } from '../trello-hover-previews';
interface Metadata {
	title: string;
	value?: string | ReactElement;
}

export interface TrelloSearchResultProps {
	id: string;
	title: string;
	url: string;
	/**
	 * Either the Board for a Card search result, or the Workspace for a Board search result.
	 */
	meta: Metadata;
	/**
	 * Either the list for a Card search result, or undefined for a Board result.
	 */
	additionalMeta?: Metadata;
	/**
	 * A string that is evaluated in the parent trello-tab component.  Either 'closed' (board),
	 * 'archived' (card), or null if the search result is neither 'closed' or 'archived'.
	 */
	closed?: boolean | null;
	prefs?: TrelloBoardPrefs;
	isPreviewOnTrelloSearchResultsEnabled?: boolean;
	isColorBlindModeEnabled?: boolean;
	screen?: ScreenType;
	onResultClick?: () => void;
}

/**
 * Custom search result for Trello. Consisting of an amalgam of components from the search-dialog package and custom
 * created components at the Trello tab level.  Additionally, TrelloSearchResult leverages the collapsed view, the view
 * when a filter is present on the right side of the panel, even though no panel is rendered.  This attribute kicks
 * the metadata to the second level, aligning with Trello designs.
 */
export const TrelloSearchResultComponent: FunctionComponent<TrelloSearchResultProps> = ({
	id,
	title,
	url,
	prefs,
	meta,
	additionalMeta,
	closed,
	isPreviewOnTrelloSearchResultsEnabled,
	isColorBlindModeEnabled,
	screen,
	onResultClick,
}) => {
	const { formatMessage } = useIntl();
	const isBoard = Boolean(prefs);
	const type = useMemo(() => (isBoard ? 'board' : 'card'), [isBoard]);
	const metaValue = useMemo(
		() => (isBoard && !meta.value ? formatMessage(messages.trello_private_workspace) : meta.value),
		[formatMessage, isBoard, meta.value],
	);
	const resultContentWrapperOnClick = useCallback(() => {
		if (onResultClick !== undefined) {
			onResultClick();
		}
	}, [onResultClick]);
	return (
		<HoverPreviewsResultWrapper
			id={id}
			title={title}
			url={url}
			prefs={prefs}
			meta={meta}
			additionalMeta={additionalMeta}
			closed={closed}
			type={type}
			isPreviewOnTrelloSearchResultsEnabled={isPreviewOnTrelloSearchResultsEnabled}
			isColorBlindModeEnabled={isColorBlindModeEnabled}
			screen={screen}
		>
			<ResultContentWrapper isCollapsed onClick={resultContentWrapperOnClick}>
				<IconContainer>
					{isBoard && prefs ? (
						<BoardIcon id={id} name={title} url={url} prefs={prefs} />
					) : (
						<CardIcon />
					)}
				</IconContainer>
				<CollapsedResultContentWrapper>
					<ResultTitleContainer isCollapsed>
						<span title={!isPreviewOnTrelloSearchResultsEnabled ? title : undefined}>{title}</span>
					</ResultTitleContainer>
					<CollapsedResultDetailWrapper>
						<BoardAndListCol>
							<BoardAndListDetail
								title={
									!isPreviewOnTrelloSearchResultsEnabled ? `${meta.title}: ${metaValue}` : undefined
								}
							>
								{metaValue}
							</BoardAndListDetail>

							{additionalMeta ? (
								<BoardAndListDetail
									title={
										!isPreviewOnTrelloSearchResultsEnabled
											? `${additionalMeta.title}: ${additionalMeta.value}`
											: undefined
									}
								>
									: {additionalMeta.value}
								</BoardAndListDetail>
							) : null}
						</BoardAndListCol>

						{closed ? (
							<ArchivedCol
								title={
									!isPreviewOnTrelloSearchResultsEnabled
										? isBoard
											? formatMessage(messages.trello_closed_board_hover)
											: formatMessage(messages.trello_archived_card_hover)
										: undefined
								}
							>
								{'• '}
								{isBoard
									? formatMessage(messages.trello_closed)
									: formatMessage(messages.trello_archived)}
							</ArchivedCol>
						) : null}
					</CollapsedResultDetailWrapper>
				</CollapsedResultContentWrapper>
			</ResultContentWrapper>
		</HoverPreviewsResultWrapper>
	);
};
