import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const dividerStyles = xcss({
	height: '0',
	width: '100%',
	borderTop: '1px solid',
	borderColor: 'color.border',
});

export const Divider = () => {
	return <Box xcss={dividerStyles} />;
};
