import React, { useContext, useRef, useEffect, type PropsWithChildren } from 'react';
import { useIntl } from 'react-intl-next';

import { useDialogExpansionContext } from '../../dialog-expansion-context';
import { useSessionUserInput } from '../../user-input-provider';
import { FilterOptionSource } from '../../../common/filters/types';
import { useFilterStore } from '../filter-store';
import { SITE_FILTER_TYPE_NAME } from '../filter-types';
import type { FilterDefinition, GenericFilterOption } from '../filter-types';
import type { Workspace } from '../../../common/search-config-provider/search-config-types';
import { messages } from '../../../messages';

export interface GlobalFilterDefinitions {
	[key: string]: FilterDefinition<GenericFilterOption>[];
}

interface FilterContextProps {
	filterDefinitions: GlobalFilterDefinitions;
	defineFilters: (productId: string, filters: FilterDefinition<GenericFilterOption>[]) => void;
}

export const ProductFilterContext = React.createContext<FilterContextProps>({
	filterDefinitions: {},
	defineFilters: () => {},
});

export const FilterContextHOC = ({ children }: PropsWithChildren<{}>) => {
	const filterDefinitions: GlobalFilterDefinitions = useRef({}).current;
	const { isExpanded } = useDialogExpansionContext();
	const { stickySearchEnabled, resetExtensibleFiltersRef } = useSessionUserInput();
	const previousExpandState = useRef(isExpanded);

	const defineFilters = (productId: string, filters: FilterDefinition<GenericFilterOption>[]) => {
		filterDefinitions[productId] = filters;
	};

	const resetFilters = () => {
		Object.keys(filterDefinitions).forEach((productId) => {
			filterDefinitions[productId].forEach((filter) => {
				filter.store.reset();
			});
		});
	};

	useEffect(() => {
		if (previousExpandState.current && !isExpanded && !stickySearchEnabled) {
			Object.keys(filterDefinitions).forEach((productId) => {
				filterDefinitions[productId].forEach((filter) => {
					filter.store.reset();
				});
			});
		}
		previousExpandState.current = isExpanded;
	}, [isExpanded, stickySearchEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (resetExtensibleFiltersRef) {
			resetExtensibleFiltersRef.current = resetFilters;
		}
	}); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<ProductFilterContext.Provider value={{ filterDefinitions, defineFilters }}>
			{children}
		</ProductFilterContext.Provider>
	);
};

export const useGenericFilters = () => useContext(ProductFilterContext);

export const useSiteFilterDefinition = (
	workspaces: Workspace[],
	productId: string,
): FilterDefinition<GenericFilterOption>[] => {
	const isMultiWorkspace = workspaces && workspaces.length > 1;
	const { formatMessage } = useIntl();
	const siteFilterStore = useFilterStore<GenericFilterOption>(
		workspaces?.map((workspace, index) => ({
			label: workspace.name,
			id: workspace.id,
			isChecked: false,
			isVisible: index < 3,
			filterSource: FilterOptionSource.EXTERNAL,
			avatarUrl: '',
		})),
		productId,
		SITE_FILTER_TYPE_NAME,
	);
	if (isMultiWorkspace) {
		return [
			{
				'@type': SITE_FILTER_TYPE_NAME,
				fieldName: 'values',
				store: siteFilterStore,
				loadFilterOptions: async () => [],
				sectionLabel: formatMessage(messages.site_filters_title),
				findMoreLabel: formatMessage(messages.site_filters_find_more),
			},
		];
	} else {
		return [];
	}
};
