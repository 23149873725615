// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { N900, N20, N0, B100 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { Link, type LinkComponentProps } from '@atlassian/search-dialog';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CenteredAdvancedSearchGroup = styled.div({
	display: 'flex',
	alignItems: 'center',
});

const outlineWidth = 2;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledAdvancedSearch = styled(Link)<
	LinkComponentProps & {
		onClick?: (e: React.MouseEvent | KeyboardEvent) => any;
	}
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
>((props) => ({
	alignItems: 'center',
	color: token('color.text', N900),
	cursor: 'pointer',
	display: 'flex',
	width: '100%',
	justifyContent: 'space-between',
	padding: `${token('space.075', '6px')} ${2 * 8 - outlineWidth}px`,
	border: `${outlineWidth}px solid transparent`,
	'&:hover': {
		color: token('color.text', N900),
		backgroundColor: token('color.background.neutral', N20),
		textDecoration: 'none',
	},
	'&:visited': {
		color: token('color.text', N900),
	},
	'&:focus': {
		outline: 'none',
		border: `${outlineWidth}px solid ${token('color.border.focused', B100)}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundColor: props.isKeyboardHighlighted
		? token('color.background.neutral', N20)
		: token('color.background.neutral.subtle', N0),
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AdvancedSearchContent = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	paddingLeft: token('space.200', '16px'),
	display: 'flex',
	alignItems: 'center',
});
