import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	/*
	 * Atlas, formerly known as Team Central or Townsquare
	 */

	atlas_search_input_expanded_placeholder: {
		id: 'product_search_dialog.atlas.search_input_expanded_placeholder',
		defaultMessage: 'Search Atlas',
		description: 'Input placeholder shown when searching for items in the Atlas product',
	},
	townsquare_search_input_expanded_placeholder: {
		id: 'product_search_dialog.townsquare.search_input_expanded_placeholder',
		defaultMessage: 'Search Home',
		description: 'Input placeholder shown when searching for items in Atlassian Home',
	},
	townsquare_tab_label: {
		id: 'cross_product_search_dialog.townsquare_tab_label',
		defaultMessage: 'Atlas',
		description: '',
	},
	townsquare_globex_tab_label: {
		id: 'cross_product_search_dialog.townsquare_globex_tab_label',
		defaultMessage: 'Home',
		description: '',
	},
	townsquare_recent_projects: {
		id: 'cross_product_search_dialog.townsquare_recent_projects',
		defaultMessage: 'Recent Projects',
		description: '',
	},
	townsquare_recent_goals: {
		id: 'cross_product_search_dialog.townsquare_recent_goals',
		defaultMessage: 'Recent Goals',
		description: '',
	},
	townsquare_recent_help_links: {
		id: 'cross_product_search_dialog.townsquare_recent_help_links',
		defaultMessage: 'Recent Help Links',
		description: '',
	},
	townsquare_recent_learnings_risks_decisions: {
		id: 'cross_product_search_dialog.townsquare_recent_learnings_risks_decisions',
		defaultMessage: 'Recent Learnings, Risks and Decisions',
		description:
			'Section heading for all types of project learnings: learnings, risks and decisions',
	},
	townsquare_recent_topics: {
		id: 'product_search_dialog.townsquare_recent_topics',
		defaultMessage: 'Recent Topics',
		description:
			'Topic: A collection of projects, goals, teams and help curated through tagging objects.',
	},
	townsquare_recent_people: {
		id: 'product_search_dialog.townsquare_recent_people',
		defaultMessage: 'Recent People',
		description: '',
	},
	townsquare_projects_heading: {
		id: 'product_search_dialog.townsquare_projects_heading',
		defaultMessage: 'Projects',
		description: '',
	},
	townsquare_projects_view_all: {
		id: 'product_search_dialog.townsquare_projects_view_all',
		defaultMessage: 'Search all projects',
		description: 'Link to send the user to atlas extended project search',
	},
	townsquare_goals_heading: {
		id: 'product_search_dialog.townsquare_goals_heading',
		defaultMessage: 'Goals',
		description: '',
	},
	townsquare_goals_view_all: {
		id: 'product_search_dialog.townsquare_goals_view_all',
		defaultMessage: 'Search all goals',
		description: 'Link to send the user to atlas extended goal search',
	},
	townsquare_help_links_heading: {
		id: 'product_search_dialog.townsquare_help_links_heading',
		defaultMessage: 'Help Links',
		description: '',
	},
	townsquare_topics_heading: {
		id: 'product_search_dialog.townsquare_topics_heading',
		defaultMessage: 'Topics',
		description:
			'Topic: A collection of projects, goals, teams and help curated through tagging objects.',
	},
	townsquare_topics_view_all: {
		id: 'product_search_dialog.townsquare_topics_view_all',
		defaultMessage: 'Search all topics',
		description: 'Link to send the user to atlas extended topic search',
	},
	townsquare_learnings_risks_decisions_heading: {
		id: 'product_search_dialog.townsquare_learnings_risks_decisions_heading',
		defaultMessage: 'Learnings, Risks and Decisions',
		description:
			'Section heading for all types of project learnings: learnings, risks and decisions',
	},
	townsquare_people_heading: {
		id: 'product_search_dialog.townsquare_people_heading',
		defaultMessage: 'People',
		description: '',
	},
	townsquare_people_view_all: {
		id: 'product_search_dialog.townsquare_people_view_all',
		defaultMessage: 'Search all people',
		description: 'Link to send the user to atlas extended people search',
	},
	townsquare_teams_heading: {
		id: 'product_search_dialog.townsquare_teams_heading',
		defaultMessage: 'Teams',
		description: '',
	},
	townsquare_teams_view_all: {
		id: 'product_search_dialog.townsquare_teams_view_all',
		defaultMessage: 'Search all teams',
		description: 'Link to send the user to atlas extended team search',
	},
});
