import React from 'react';
import type { FC } from 'react';

import { FilterShowMoreItem } from '@atlassian/search-dialog';
import Avatar from '@atlaskit/avatar';
import type { AvatarPropTypes } from '@atlaskit/avatar';

type FilterOptionItemProps = {
	label: string;
	iconProps?: AvatarPropTypes;
};

export const FilterOptionItem: FC<FilterOptionItemProps> = ({ label, iconProps }) => {
	return (
		<FilterShowMoreItem
			icon={<Avatar name={label} size={'small'} {...iconProps} />}
			label={label}
		/>
	);
};
