import type { ScopedAggregatorResponse } from '../../../common/clients';
import { type AdvancedSearchAnalyticsProps } from '../../../common/analytics/events';
import { type ExtensibleTabProps } from '../extensible-tab-types';
import { type MessageDescriptor } from 'react-intl-next';
import { type LinkComponent } from '@atlassian/search-dialog';
import { type ProductStates } from '../../product-state-machine/product-state-machine-types';
import { type AttributeType } from '../../../jira/clients';
export const JIRA_PRODUCT_ID = 'jira';
export const NAVIGATION_V3_EXPERIENCE = 'jira.nav-v3';
export const FILTER_LIST_LIMIT = 10;
export const ISSUE_LIST_LIMIT = 10;
export const OTHERS_LIST_LIMIT_NO_ADVANCED_ROADMAPS_ACCESS = 6;
export const OTHERS_LIST_LIMIT_ADVANCED_ROADMAPS_ACCESS = 8;
export const DEFAULT_COLLAB_GRAPH_URL: string = 'gateway/api/collaboration';
export const DEFAULT_ISSUE_LABELS_SERVICE_URL: string = '/rest/api/1.0/labels/suggest';

export interface JiraCollabGraphConfig {
	cloudId: string;
	collaborationGraphUrl?: string;
}
export interface JiraFeatures {
	hasSoftwareAccess?: boolean;
	hasAdvancedRoadmapsAccess?: boolean;
	isMultiSite?: boolean;
	enableDateFromFilter?: boolean;
	isPreviewOnJiraSearchResultsEnabled?: boolean;
	isReportedByMeFilterEnabled?: boolean;
	isConsistentIssueDetailsEnabled?: boolean;
	isPreQueryFiltersEnabled?: boolean;
	/**
	 * Should only be used and set to true in Jira
	 */
	enableIssueLabelsFilter?: boolean;
	/**
	 * Use text field when searching using all issues search link
	 */
	isSearchWithTextFieldInAllIssuesLinkEnabled?: boolean;
}

export type CurrentProject = {
	id: string;
	avatarUrl?: string;
	label: string;
};

export type CurrentUser = {
	id: string;
	avatarUrl?: string;
	name: string;
};
export interface ExtensibleJiraTabProps extends ExtensibleTabProps {
	features?: JiraFeatures;
	config?: JiraCollabGraphConfig;
	formatDate?: (updated: string) => JSX.Element;
	autocorrectEnabled?: boolean;
	currentProject?: CurrentProject;
	currentUser?: CurrentUser;
}

export type SectionDetailsByAccess = {
	scope: JiraScope;
	recentHeading: MessageDescriptor;
	searchHeading: MessageDescriptor;
};

export const ISSUE_NAVIGATOR_URL = `/issues/?jql=${encodeURIComponent(
	'created >= -30d order by created DESC',
)}`;

export enum JiraScope {
	Issue = 'jira.issue',
	ProjectFilter = 'jira.project,filter',
	BoardProjectFilter = 'jira.board,project,filter',
	BoardProjectFilterPlans = 'jira.board,project,filter,plan',
	Project = 'jira.project',
	Assignee = 'urs.user-jira',
}

export enum JiraFilters {
	Projects = 'projects',
	Assignees = 'assignees',
	Status = 'binary_status_categories',
	Date = 'lastModified',
	ReportedBy = 'reportedBy',
	IssueLabels = 'issueLabels',
}

export interface IssueLabel {
	label: string;
	[key: string]: string;
}

export type Avatar = {
	url: string;
};

export type SupportedJiraScopes =
	| JiraScope.Issue
	| JiraScope.ProjectFilter
	| JiraScope.BoardProjectFilter
	| JiraScope.BoardProjectFilterPlans;

type Container = {
	title: string;
	id: string;
};

export interface AttributeIssue {
	'@type': AttributeType.issue;
	key: string;
	issueTypeId: string;
	issueTypeName: string;
	containerId: string;
	container?: Container;
	avatar: Avatar;
	updated?: string; // e.g. "2019-07-08T02:54:38.822Z"
}

export interface AttributeBoard {
	'@type': AttributeType.board;
	containerId: string;
	container?: Container;
	containerName: string;
	avatar?: Avatar;
}

export interface AttributeProject {
	'@type': AttributeType.project;
	projectKey: string;
	avatar: Avatar;
}

export interface AttributeFilter {
	'@type': AttributeType.filter;
	ownerId?: string;
	ownerName?: string;
	avatar?: Avatar;
}

export type SupportedAttributes =
	| AttributeIssue
	| AttributeBoard
	| AttributeProject
	| AttributeFilter;

export interface JiraScopeItemResponse {
	id: string;
	name: string;
	url?: string;
	avatarUrl?: string;
	attributes: SupportedAttributes;
}

export interface SupportedJiraScopeResponses extends ScopedAggregatorResponse<JiraScope> {
	results: JiraScopeItemResponse[];
	size?: number;
}

export interface AdvancedSearchLinksProps {
	advancedSearchSelected: (props: AdvancedSearchAnalyticsProps) => void;
	features?: JiraFeatures;
	linkComponent?: LinkComponent;
	productState: ProductStates;
}

export type FilterValueGetters = {
	[key: string]: () => string[];
};
