import { useCallback, useContext, useMemo } from 'react';
import type { ReactElement } from 'react';
import { useIntl } from 'react-intl-next';
import { useApolloClient } from '@apollo/react-hooks';

import type {
	ConfluenceGraphQLSearchQueryType,
	ConfluenceGraphQLSearchVariables,
} from '@atlassian/search-client';
import {
	confluenceGraphQLSearchVariablesMapper,
	ConfluenceQuickSearchQuery,
} from '@atlassian/search-client';
import {
	type SearchResultSection,
	type SearchResult,
	type PostQuerySupplierArgs,
	useExperiments,
} from '@atlassian/product-search-dialog';
import type { SearchExperimentLayer } from '@atlassian/search-experiment';

import { fg } from '@confluence/feature-gating';
import { getAGGClient, markErrorAsHandled } from '@confluence/graphql';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { ExperienceTrackerContext, QUICK_SEARCH_PRE_QUERY } from '@confluence/experience-tracker';

import { useDynamicBackendExperiment } from '../useDynamicBackendExperiment';

import { GenericIconRenderer } from './IconRenderer';
import { ConfluenceResultRenderer } from './ResultRenderer';
import { RecentQuickSearchQuery } from './RecentQuickSearchQuery.graphql';
import { i18n } from './messages';
import type {
	RecentQuickSearchQuery as RecentQuickSearchType,
	RecentQuickSearchQuery_activities_myActivities_workedOn_edges as RecentQuickSearchActivityEdgeType,
	RecentQuickSearchQuery_myVisitedSpaces_nodes_spaces as RecentQuickSearchSpaceType,
	RecentQuickSearchQueryVariables,
	ActivitiesObjectType,
} from './__types__/RecentQuickSearchQuery';
import { ConfluenceTitleRenderer } from './TitleRenderer';

const RECENT_CONTENTS_FETCH_LIMIT = 100;
const RECENT_SPACE_FETCH_LIMIT = 10;
const MAX_RESULTS_CONTENTS = 10;
const MAX_RESULTS_SPACES = 3;

const WORKED_ON_API_FF = 'confluence.frontend.search.quicksearch.workedon.api';

const recentContentsCCResultMapper: (
	edge: RecentQuickSearchActivityEdgeType | null,
) => SearchResult = (edge) => {
	const item = edge?.node?.object?.content;
	return {
		id: item?.id || '',
		title: item?.title || '',
		type: item?.type || '',
		url: `${CONTEXT_PATH}${item?.links?.webui || ''}`,
		meta: item?.space?.name || '',
		additionalMeta: item?.type || '',
		containerId: item?.space?.id || '',
		icon: item?.type || '',
		isRecentResult: true,
	};
};

const recentSpaceCCResultMapper: (item: RecentQuickSearchSpaceType | null) => SearchResult = (
	item,
) => ({
	id: item?.id || '',
	title: item?.name || '',
	type: 'space',
	meta: '',
	url: `${CONTEXT_PATH}${item?.links?.webui || ''}`,
	containerId: item?.id || undefined,
	icon: GenericIconRenderer({
		iconUrl: `${CONTEXT_PATH}${item?.icon?.path || ''}`,
		size: 'xsmall',
	}),
	isRecentResult: true,
});

type useConfluenceSuppliersArgs = {
	cloudId: string;
	formatDate: (date: string) => ReactElement;
	userId: string | null;
	principalId?: string;
	autocorrectEnabled?: boolean;
	isQuickSearchForWhiteboardsEnabled: boolean;
	isQuickSearchForDatabasesEnabled: boolean;
	objectTypes: ActivitiesObjectType[];
};

type useConfluenceSuppliersType = {
	preQueryItemSupplier: () => Promise<{ sections: SearchResultSection[] }>;
	postQueryItemSupplier?: (args: PostQuerySupplierArgs) => Promise<{
		sections: SearchResultSection[];
	}>;
	searchClientConfig?: {
		graphQLEnabled: true;
		graphQLPromise: (args: PostQuerySupplierArgs) => Promise<ConfluenceGraphQLSearchQueryType>;
		cloudId: string;
		userId?: string;
	};
	experimentLayers?: SearchExperimentLayer[];
};

export const useConfluenceItemSuppliers = ({
	cloudId,
	userId,
	principalId = 'context',
	autocorrectEnabled = false,
	isQuickSearchForWhiteboardsEnabled,
	isQuickSearchForDatabasesEnabled,
	objectTypes,
}: useConfluenceSuppliersArgs): useConfluenceSuppliersType => {
	const intl = useIntl();
	const confCloudClient = useApolloClient();
	const aggClient = getAGGClient();
	const workedOnAPIEnabled = useBooleanFeatureFlag(WORKED_ON_API_FF);

	const experienceTracker = useContext(ExperienceTrackerContext);

	const contentEntities = useMemo(
		() => [
			'ati:cloud:confluence:page',
			'ati:cloud:confluence:blogpost',
			'ati:cloud:confluence:attachment',
			'ati:cloud:confluence:embed',
			'ati:cloud:confluence:folder',
			...(isQuickSearchForWhiteboardsEnabled ? ['ati:cloud:confluence:whiteboard'] : []),
			...(isQuickSearchForDatabasesEnabled ? ['ati:cloud:confluence:database'] : []),
		],
		[isQuickSearchForWhiteboardsEnabled, isQuickSearchForDatabasesEnabled],
	);

	const preQueryItemSupplier = useCallback(async (): Promise<{
		sections: SearchResultSection[];
	}> => {
		experienceTracker.start({
			name: QUICK_SEARCH_PRE_QUERY,
		});

		const {
			recentContents,
			recentSpaces,
		}: { recentContents: SearchResult[]; recentSpaces: SearchResult[] } = !userId
			? {
					recentContents: [],
					recentSpaces: [],
				}
			: await confCloudClient
					.query<RecentQuickSearchType, RecentQuickSearchQueryVariables>({
						query: RecentQuickSearchQuery,
						variables: {
							cloudId,
							contentItemsLimit: RECENT_CONTENTS_FETCH_LIMIT,
							spaceLimit: RECENT_SPACE_FETCH_LIMIT,
							objectTypes,
							workedOnAPIEnabled,
						},
					})
					.then((response) => {
						const rawSpaces = response.data?.myVisitedSpaces?.nodes?.spaces;
						const rawContent = workedOnAPIEnabled
							? response.data.activities?.myActivities?.workedOn?.edges
							: response.data.activities?.myActivities?.viewed?.edges;
						const filteredRawContent =
							rawContent &&
							rawContent.filter(
								// #help-work-platform suggested handling null content in FE while they investigate why some results are being returned null
								(edge) =>
									!(edge?.node?.object?.content?.status === 'trashed') &&
									!(edge?.node?.object?.content === null),
							);
						return {
							recentContents: filteredRawContent?.map(recentContentsCCResultMapper) || [],
							recentSpaces: rawSpaces?.map(recentSpaceCCResultMapper) || [],
						};
					})
					.catch((error) => {
						experienceTracker.fail({
							name: QUICK_SEARCH_PRE_QUERY,
							error,
						});

						// error is handled by the platform PSD component
						markErrorAsHandled(error);

						// The error will bubble up and the parent component will catch the error and handle it
						throw error;
					});

		experienceTracker.succeed({
			name: QUICK_SEARCH_PRE_QUERY,
		});

		return {
			sections: [
				{
					id: 'recent-confluence-items',
					title: workedOnAPIEnabled
						? intl.formatMessage(i18n.recentlyWorkedOn)
						: intl.formatMessage(i18n.recentlyViewed),
					searchResults: recentContents,
					resultLimit: MAX_RESULTS_CONTENTS,
					titleRenderer: ConfluenceTitleRenderer(
						autocorrectEnabled,
						workedOnAPIEnabled,
						intl.formatMessage,
					),
					resultRenderer: ConfluenceResultRenderer,
				},
				{
					id: 'recent-confluence-spaces',
					title: intl.formatMessage(i18n.spaces),
					searchResults: recentSpaces,
					resultLimit: MAX_RESULTS_SPACES,
				},
			],
		};
	}, [
		confCloudClient,
		cloudId,
		userId,
		intl,
		autocorrectEnabled,
		experienceTracker,
		workedOnAPIEnabled,
		objectTypes,
	]);

	const { backendExperiment: staticBackendExperiment } = useExperiments();

	const { backendExperiment: dynamicBackendExperiment } =
		useDynamicBackendExperiment({
			cloudId,
			entities: contentEntities,
			userId: userId || undefined,
			experience: 'quick-search',
		}) || {};

	const backendExperiment = fg('confluence_search-scalable-statsig-layers')
		? dynamicBackendExperiment
		: staticBackendExperiment;

	const { experimentId, shadowExperimentId, experimentLayers } = backendExperiment || {};

	const searchClientConfig = {
		graphQLEnabled: true as true,
		graphQLPromise: (args: PostQuerySupplierArgs) =>
			aggClient
				.query<ConfluenceGraphQLSearchQueryType, ConfluenceGraphQLSearchVariables>({
					query: ConfluenceQuickSearchQuery,
					variables: confluenceGraphQLSearchVariablesMapper({
						...args,
						cloudId,
						contentEntities,
						experimentId,
						shadowExperimentId,
						experimentLayers: experimentLayers?.map(({ name, layerId, shadowId, definitions }) => ({
							name,
							layerId,
							shadowId,
							definitions,
						})),
						isLivePagesEnabled: fg('confluence_live_pages_open_beta_trait_opted_in'),
					}),
				})
				.then((response) => response.data),
		cloudId,
		userId: principalId,
	};

	return {
		experimentLayers: !fg('confluence_search-scalable-statsig-layers')
			? undefined
			: backendExperiment?.experimentLayers?.map((layer) => {
					const { analyticsDefinitions, definitions, ...rest } = layer;

					return {
						...rest,
						definitions: analyticsDefinitions,
					};
				}),
		preQueryItemSupplier,
		searchClientConfig,
	};
};
