// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { gridSize } from '@atlaskit/theme/constants';
import { N40, N800 } from '@atlaskit/theme/colors';
import { token, getTokenValue } from '@atlaskit/tokens';

import { type TrelloBoard } from '../types';

const baseStyle = {
	display: 'flex', // hack around IconContainer styles
	flexShrink: 0, // hack around IconContainer styles
	borderRadius: '2px',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
};

export const getCardIconUrl = () => {
	const iconColor = getTokenValue('color.icon', N800);

	return `data:image/svg+xml;utf8,${encodeURIComponent(
		`<svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.66667 0C1.19391 0 0 1.19391 0 2.66667V16C0 17.4728 1.19391 18.6667 2.66667 18.6667H21.3333C22.8061 18.6667 24 17.4728 24 16V2.66667C24 1.19391 22.8061 0 21.3333 0H2.66667ZM21.3333 2.66667H2.66667V10.6667H21.3333V2.66667ZM18.6667 14.6667C18.6667 15.403 19.2636 16 20 16C20.7364 16 21.3333 15.403 21.3333 14.6667C21.3333 13.9303 20.7364 13.3333 20 13.3333C19.2636 13.3333 18.6667 13.9303 18.6667 14.6667ZM4 16C3.26362 16 2.66667 15.403 2.66667 14.6667C2.66667 13.9303 3.26362 13.3333 4 13.3333H9.33333C10.0697 13.3333 10.6667 13.9303 10.6667 14.6667C10.6667 15.403 10.0697 16 9.33333 16H4Z" fill="${iconColor}"/></svg>`,
	)}`;
};

export const getBoardIconUrl = () => {
	const iconColor = getTokenValue('color.icon', N800);

	return `data:image/svg+xml;utf8,${encodeURIComponent(
		`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.77778C0 0.795938 0.795938 0 1.77778 0H14.2222C15.2041 0 16 0.795938 16 1.77778V14.2222C16 15.2041 15.2041 16 14.2222 16H1.77778C0.795938 16 0 15.2041 0 14.2222V1.77778ZM1.77778 2.66667C1.77778 2.17575 2.17575 1.77778 2.66667 1.77778H6.22222C6.71314 1.77778 7.11111 2.17575 7.11111 2.66667V11.5556C7.11111 12.0465 6.71314 12.4444 6.22222 12.4444H2.66667C2.17575 12.4444 1.77778 12.0465 1.77778 11.5556V2.66667ZM9.77778 1.77778C9.28686 1.77778 8.88889 2.17575 8.88889 2.66667V8C8.88889 8.49092 9.28686 8.88889 9.77778 8.88889H13.3333C13.8243 8.88889 14.2222 8.49092 14.2222 8V2.66667C14.2222 2.17575 13.8243 1.77778 13.3333 1.77778H9.77778Z" fill="${iconColor}"/></svg>`,
	)}`;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BoardIcon = styled.span(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ prefs: { backgroundImageScaled, backgroundImage, backgroundColor } }: TrelloBoard) => {
		const boardUrl = backgroundImageScaled?.[0]?.url || backgroundImage;

		if (boardUrl) {
			return {
				...baseStyle,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				height: `${gridSize() * 3}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				width: `${gridSize() * 3}px`,
				backgroundImage: `url(${boardUrl})`,
			};
		}

		return {
			...baseStyle,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			height: `${gridSize() * 3}px`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			width: `${gridSize() * 3}px`,
			backgroundColor: backgroundColor || token('color.background.neutral', N40),
		};
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const CardIcon = styled.span(() => {
	return {
		...baseStyle,
		height: `19px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		width: `${gridSize() * 3}px`,
		marginBottom: token('space.100', '8px'),
		backgroundImage: `url(${getCardIconUrl()})`,
	};
});
