import { useMemo, useCallback } from 'react';
import { JiraFilters, JIRA_PRODUCT_ID } from '../types';
import { FilterOptionSource } from '../../../../common/filters/types';
import {
	type GenericFilterOption,
	DEFAULT_OPTION,
	useFilterStore,
	FilterUIVariant,
	type DateFilterOptionsAndValues,
	LastModifiedValues,
} from '../../../filters';
import { messages } from '../messages';
import { messages as commonMessages } from '../../../../messages';
import { useIntl } from 'react-intl-next';

// The toOffset default is -1 because the 'to' date is not inclusive,
//  so we set it to the tomorrow's date to include results from today
const getDateRange = (fromOffset: number, toOffset: number = -1) => {
	const from = new Date();
	const to = new Date();

	from.setDate(from.getDate() - fromOffset);
	to.setDate(to.getDate() - toOffset);

	return {
		from: from.toISOString().split('T')[0],
		to: to.toISOString().split('T')[0],
	};
};

export const useJiraDateFilter = ({
	isDateFromFilterEnabled,
}: {
	isDateFromFilterEnabled: boolean;
}) => {
	const { formatMessage } = useIntl();

	const dateFilterOptionsAndValues: DateFilterOptionsAndValues = useMemo(() => {
		return !isDateFromFilterEnabled
			? null
			: {
					[LastModifiedValues.DEFAULT]: {
						filter: {
							id: LastModifiedValues.DEFAULT,
							label: formatMessage(commonMessages.date_filter_any_time_label),
							filterSource: FilterOptionSource.STATIC,
							avatarUrl: '',
							isChecked: true,
							isVisible: true,
						},
						dateRange: { from: '', to: '' },
					},
					[LastModifiedValues.TODAY]: {
						filter: {
							id: LastModifiedValues.TODAY,
							label: formatMessage(messages.date_filter_today_label),
							filterSource: FilterOptionSource.STATIC,
							avatarUrl: '',
							isChecked: false,
							isVisible: true,
						},
						dateRange: getDateRange(0),
					},
					[LastModifiedValues.YESTERDAY]: {
						filter: {
							id: LastModifiedValues.YESTERDAY,
							label: formatMessage(messages.date_filter_yesterday_label),
							filterSource: FilterOptionSource.STATIC,
							avatarUrl: '',
							isChecked: false,
							isVisible: true,
						},
						dateRange: getDateRange(1, 0),
					},
					[LastModifiedValues.PAST_SEVEN_DAYS]: {
						filter: {
							id: LastModifiedValues.PAST_SEVEN_DAYS,
							label: formatMessage(messages.date_filter_past_7_days_label),
							filterSource: FilterOptionSource.STATIC,
							avatarUrl: '',
							isChecked: false,
							isVisible: true,
						},
						dateRange: getDateRange(7),
					},
					[LastModifiedValues.PAST_THIRTY_DAYS]: {
						filter: {
							id: LastModifiedValues.PAST_THIRTY_DAYS,
							label: formatMessage(messages.date_filter_past_30_days_label),
							filterSource: FilterOptionSource.STATIC,
							avatarUrl: '',
							isChecked: false,
							isVisible: true,
						},
						dateRange: getDateRange(30),
					},
					[LastModifiedValues.PAST_YEAR]: {
						filter: {
							id: LastModifiedValues.PAST_YEAR,
							label: formatMessage(messages.date_filter_past_year_label),
							filterSource: FilterOptionSource.STATIC,
							avatarUrl: '',
							isChecked: false,
							isVisible: true,
						},
						dateRange: getDateRange(365),
					},
				};
	}, [isDateFromFilterEnabled, formatMessage]);

	const dateFilterOptions = useMemo(
		() =>
			dateFilterOptionsAndValues
				? Object.values(dateFilterOptionsAndValues).map((value) => value.filter)
				: [],
		[dateFilterOptionsAndValues],
	);

	const dateFilterStore = useFilterStore<GenericFilterOption>(
		dateFilterOptions,
		JIRA_PRODUCT_ID,
		JiraFilters.Date,
	);

	const appliedDateFilter = dateFilterStore?.availableFilters?.find(
		(f) => f.isChecked && f.id !== DEFAULT_OPTION,
	)?.id;

	const appliedDateFilterValue =
		appliedDateFilter && dateFilterOptionsAndValues && dateFilterOptionsAndValues[appliedDateFilter]
			? dateFilterOptionsAndValues[appliedDateFilter].dateRange
			: null;

	const getLastModifiedValue = useCallback(() => {
		return appliedDateFilterValue
			? {
					'@type': JiraFilters.Date,
					from: appliedDateFilterValue.from,
					to: appliedDateFilterValue.to,
				}
			: null;
	}, [appliedDateFilterValue]);

	const jiraTabDateFilterDefinition = useMemo(() => {
		return isDateFromFilterEnabled
			? [
					{
						['@type']: JiraFilters.Date,
						uiVariant: FilterUIVariant.STATIC_SELECT,
						fieldName: 'from',
						store: dateFilterStore,
						loadFilterOptions: async () => [],
						sectionLabel: formatMessage(commonMessages.date_filter_filters_title),
						findMoreLabel: '',
						mapSelectionToAppliedValue: () => getLastModifiedValue(),
					},
				]
			: [];
	}, [isDateFromFilterEnabled, dateFilterStore, formatMessage, getLastModifiedValue]);

	return {
		appliedDateFilterValue,
		jiraTabDateFilterDefinition,
		dateFilterOptionsAndValues,
	};
};
