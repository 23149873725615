import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { type LinkComponent, SearchResultSectionLink } from '@atlassian/search-dialog';
import { messages } from '../../messages';
import { useQuery } from '../query-context';
import { type SearchResultSection as SearchResultSectionType } from '../product-router/product/result-types';
import { useAnalytics } from '../../common/analytics';
import { onSearchResultViewAllLinkClicked } from '../../common/analytics/events';

export interface ViewAllLinkProps {
	linkComponent?: LinkComponent;
	section: SearchResultSectionType;
	/**
	 * It is the total number of results which match the query. This value gets shown on the result count badge.
	 */
	totalResults?: number;
}

export const ViewAllLink: React.FC<ViewAllLinkProps> = ({
	linkComponent,
	section,
	totalResults = 0,
}) => {
	const { query } = useQuery();
	const { fireAnalyticsEvent } = useAnalytics();
	const { viewAllLinkGenerator, viewAllLinkRenderer } = section;
	if (!viewAllLinkGenerator) {
		return null;
	}
	return (
		<div
			onClick={() => {
				fireAnalyticsEvent(onSearchResultViewAllLinkClicked(section.id));
			}}
			role="none"
		>
			<SearchResultSectionLink
				linkComponent={linkComponent}
				href={viewAllLinkGenerator(query)}
				testId="search-result-section-link"
			>
				{viewAllLinkRenderer ? (
					viewAllLinkRenderer({ section, totalResults })
				) : (
					<FormattedMessage {...messages.common_show_all_results_link} />
				)}
			</SearchResultSectionLink>
		</div>
	);
};
