import type React from 'react';

import { RECENT_SPACES_SECTION_ID } from '@atlassian/search-client';

import { type SearchItemsTransformerProps } from '../../../product-router/product/result-provider/result-provider-types';
import { useQuery } from '../../../query-context';

/**
 * This component performs any filtering on pre-query sections.
 */
export const PreQueryFilterTransformer: React.FC<SearchItemsTransformerProps> = ({
	preQueryItems,
	postQueryItems,
	productState,
	children,
}) => {
	const { query } = useQuery();

	const filteredPreQuerySections = query
		? preQueryItems.sections
		: preQueryItems.sections.filter((section) => section.id !== RECENT_SPACES_SECTION_ID);

	const filteredPreQueryItems = {
		sections: filteredPreQuerySections,
		size: filteredPreQuerySections
			.map((section) => section.searchResults.length)
			.reduce((acc, b) => acc + b, 0),
	};

	return children({
		preQueryItems: filteredPreQueryItems,
		postQueryItems,
		productState,
	});
};
