import { transformAnalyticsToQueryParam } from '@atlassian/search-common';

import { addQueryParams, addQuery } from '../../../utils/url-utils';
import { useQuery } from '../../query-context';

export const useQueryParams = () => {
	const { query } = useQuery();

	return (
		url: string,
		searchSessionId: string,
		type?: string,
		resultId?: string,
		additionalAnalytics?: object,
	) => {
		// legacy QF/QS have different types/have confluence prefixed or not
		return transformAnalyticsToQueryParam({
			query,
			url,
			searchSessionId,
			additionalAnalytics,
			resultId,
			type,
			addQueryParams,
			addQuery,
		});
	};
};
