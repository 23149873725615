import { useIntl } from 'react-intl-next';
import { type ButtonProps } from '@atlaskit/button/new';
import { type SearchResultSection, useDefaultSuppliers } from '../..';
import {
	usePeopleAndTeamsQuery,
	type UserTeamConfig,
} from '../../../common/clients/people-and-teams-supplier';
import { messages } from './messages';
import {
	type PostQuerySupplierArgs,
	type PreQuerySupplierArgs,
	type SearchItems,
} from '../../product-router';
import { type AggregatorSection } from '../../result-supplier/default-suppliers';
import type { ExtensibleTabProps } from '../extensible-tab-types';
import { getTeamData } from './teams-client';
import { type AggregatorCompassResponse, CompassScope } from './types';
import { compassURLGenerators } from './url-generators';
import { peopleAndTeamsResultRenderer } from './result-renderer';

export const COMPASS_PRODUCT_ID = 'compass';
const NO_OWNER_TEAM = 'ari:cloud:teams::team/unidentified';

export type CompassTabProps = ExtensibleTabProps & {
	/**
	 * Optional cloud URL to use as a base URL for certain links like advanced search.
	 * If not provided, the current window's origin will be used.
	 * Useful when this component is used on domains like bitbucket.org
	 */
	baseUrl?: string;
	crossFlowEnabled?: boolean;
	isCompassActivated?: boolean;
	isUserAnonymous?: boolean;
	sourceContext?: string;
	/**
	 * Optional button props to pass to the CTA button to override defaults.
	 */
	upsellCtaButtonProps?: ButtonProps;
	userTeamConfig?: UserTeamConfig;
};

export const useCompassSuppliers = (
	{
		userTeamConfig,
		preQueryItemSupplier,
		postQueryItemSupplier,
	}: Pick<CompassTabProps, 'userTeamConfig' | 'preQueryItemSupplier' | 'postQueryItemSupplier'>,
	sections: AggregatorSection<CompassScope, AggregatorCompassResponse>[],
) => {
	const intl = useIntl();
	const itemSuppliers = useDefaultSuppliers<AggregatorCompassResponse, CompassScope>(
		COMPASS_PRODUCT_ID,
		sections,
		{
			preQueryItemSupplier,
			postQueryItemSupplier,
		},
	);

	const peopleAndTeamSupplier = usePeopleAndTeamsQuery(
		{
			people: {
				id: CompassScope.People,
				title: intl.formatMessage(messages.compass_people_section_heading),
			},
			teams: {
				id: CompassScope.Teams,
				title: intl.formatMessage(messages.compass_teams_section_heading),
			},
		},
		3,
	);

	const requestTeamMeta = async (teamId: string) => {
		if (!userTeamConfig?.context.siteId || !teamId || teamId === NO_OWNER_TEAM) {
			return '';
		}
		try {
			const team = await getTeamData({
				url: userTeamConfig?.baseUrl || '',
				teamAri: teamId,
				siteId: userTeamConfig?.context.siteId,
			});
			return team.displayName;
		} catch (e) {
			// Ignore
			return '';
		}
	};

	const userTeamConfigOverride = {
		context: userTeamConfig!.context,
		baseUrl: userTeamConfig!.baseUrl,
		resolveTeamUrl: userTeamConfig!.resolveTeamUrl ?? compassURLGenerators().teamUrlGenerator,
		resolveUserUrl: userTeamConfig!.resolveUserUrl ?? compassURLGenerators().userUrlGenerator,
	};

	const hydrateOwnerMeta = async (items: SearchItems): Promise<SearchItems> => {
		const promises: Promise<string>[] = [];
		const compSection = items.sections.find((s) => s.id === CompassScope.Component);
		if (compSection) {
			compSection.searchResults.map(async (res) => {
				promises.push(
					requestTeamMeta(res.meta as string).then((teamName) => (res.meta = teamName)),
				);
			});
		}
		return Promise.all(promises).then(() => items);
	};

	const preQueryItemSupplierWithTeamName = async (
		args: PreQuerySupplierArgs,
	): Promise<SearchItems> => {
		const existingSections = await hydrateOwnerMeta(
			await itemSuppliers.preQueryItemSupplier!(args),
		);

		const teamSection = (await peopleAndTeamSupplier('', userTeamConfigOverride)).sections.find(
			(section) => section.id === CompassScope.Teams,
		);

		return window.location.pathname.startsWith('/compass')
			? existingSections
			: {
					size: existingSections.size,
					sections: [
						...existingSections.sections,
						...(teamSection
							? [
									{
										...teamSection,
										resultRenderer: peopleAndTeamsResultRenderer,
									},
								]
							: []),
					],
				};
	};

	const postQueryItemSupplierWithPeopleAndTeams = async (
		args: PostQuerySupplierArgs,
	): Promise<SearchItems> => {
		const existingSections = await hydrateOwnerMeta(
			await itemSuppliers.postQueryItemSupplier!(args),
		);

		const people = userTeamConfigOverride
			? await peopleAndTeamSupplier(args.query, {
					...userTeamConfigOverride,
					context: {
						...userTeamConfigOverride.context,
						siteId: userTeamConfigOverride.context.siteId,
					},
				})
			: null;

		return {
			size: existingSections.size,
			sections: [
				...existingSections.sections,
				...(people?.sections.map((section) => ({
					...section,
					resultRenderer: peopleAndTeamsResultRenderer,
				})) ?? []),
			],
		};
	};

	const preQuerySectionTitleGenerator = (section: SearchResultSection) => {
		switch (section.id) {
			case CompassScope.Component:
				return window.location.pathname.startsWith('/compass')
					? intl.formatMessage(messages.compass_recently_viewed_components)
					: intl.formatMessage(messages.compass_components_section_heading);
			case CompassScope.Teams:
				return window.location.pathname.startsWith('/compass')
					? intl.formatMessage(messages.compass_recently_viewed_teams)
					: intl.formatMessage(messages.compass_teams_section_heading);
			case CompassScope.ApiEndpoint:
				return window.location.pathname.startsWith('/compass')
					? intl.formatMessage(messages.compass_recently_viewed_endpoints)
					: intl.formatMessage(messages.compass_endpoints_section_heading);
			default:
				return '';
		}
	};

	return {
		preQueryItemSupplier: preQueryItemSupplierWithTeamName,
		postQueryItemSupplier: postQueryItemSupplierWithPeopleAndTeams,
		preQuerySectionTitleGenerator,
	};
};
