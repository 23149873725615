// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { nowrapEllipsis, grid } from '../../style-utils';
import { B50, B100, B400, N20, N500, N900 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const WIDTH = grid.multiple(22);
export const HALF_WIDTH = WIDTH.half();

const IRREMOVABLE_AVATAR_PADDING_SIZE = 2;

// The +4px is to ensure consistency with the Atlaskit avatar which has a 2px padding around the 24px icon
const ICON_WIDTH = grid.multiple(3).unitless + IRREMOVABLE_AVATAR_PADDING_SIZE * 2;
const ICON_HEIGHT = grid.multiple(3).unitless + IRREMOVABLE_AVATAR_PADDING_SIZE * 2;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${ICON_HEIGHT}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${ICON_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `0 ${grid.unitless - IRREMOVABLE_AVATAR_PADDING_SIZE}px 0 ${
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		grid.half().unitless - IRREMOVABLE_AVATAR_PADDING_SIZE
	}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LabelWrapper = styled.span({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	// Offset the label's 4px padding-left
	marginLeft: token('space.negative.050', '-4px'),
	// The -2px is to offset the 28px container (see IconWrapper)
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${grid.unitless - 2} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const FilterColLabelText = styled.span(nowrapEllipsis, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: WIDTH.px,
	color: token('color.text', N900),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const FilterRowLabelText = styled.span(nowrapEllipsis, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingLeft: grid.half().px,
	color: token('color.text', N900),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: HALF_WIDTH.px,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CheckboxWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${grid.unitless - 2}px 0`,
	display: 'flex',
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PillButton = styled.button<{
	selected?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${grid.multiple(3).unitless + 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: props.selected ? token('color.text.brand', B400) : token('color.text.subtlest', N500),
	outline: 'none',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${grid.half().px} ${grid.px}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundColor: props.selected
		? token('color.background.selected', B50)
		: token('color.background.accent.gray.subtlest', N20),
	border: 'none',
	borderRadius: '14px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
	lineHeight: `${grid.multiple(2).unitless + 2}px`,
	cursor: 'pointer',
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus-visible': {
		outline: `2px solid ${token('color.border.focused', B100)}`,
		outlineOffset: token('space.negative.025', '-2px'),
	},
}));
