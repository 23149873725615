import React, { useCallback } from 'react';

import { type TriggerProps } from '@atlaskit/popup';
import { Box, Inline, Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { addRecentSearch, RECENT_SEARCH_KEY } from '@atlassian/search-common';

import { ProductKeys, useProductConfigs } from '../../../../../common/constants/products';
import { type TriggerComponentProps } from '../../../../../common/types';
import { useAppContext } from '../../../../../common/ui/app-context';
import { OAuthWarningWrapper } from '../../../../../common/ui/oauth-warning-wrapper';
import { ProductOrNounIcon } from '../../../../../common/ui/product-icon';
import { isProductSmartlink } from '../../../../../common/utils/oauth-container';
import { onProductFilterSelected } from '../../../../../common/utils/quick-find/events/product-filter-selected';
import { ErrorBoundary } from '../../../../../controllers/error-boundary';
import { useIsUserOAuthed, useOAuthHandlers } from '../../../../../controllers/oauth';
import {
	useQuickFindAnalytics,
	useQuickFindAttributes,
} from '../../../../../controllers/quick-find/analytics';
import { useBootstrap } from '../../../../../controllers/store';
import {
	useQuickFindActions,
	useQuickFindQuery,
	useUpdateRecentQueries,
} from '../../../../../controllers/store/quick-find';

const baseStyles = xcss({
	height: 'size.300',
	paddingInline: 'space.150',
	borderRadius: 'border.radius.100',
	color: 'color.text',
	backgroundColor: 'color.background.neutral',

	':hover': {
		backgroundColor: 'color.background.neutral.hovered',
	},
});

const buttonWrapperStyles = xcss({
	maxWidth: '280px', // matches dropdown of 312px (and padding)
});

const textStyles = xcss({
	// ellipsis for overflow text
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
});

export interface HeaderProductButtonProps {
	otherProps: { [key: string]: any };
	triggerProps?: TriggerProps;
	productKey: ProductKeys;
	label: React.ReactNode;
	onClick?: () => void;
	testId?: string;
}

const HeaderProductButton = ({
	otherProps,
	triggerProps,
	productKey,
	label,
	onClick,
	testId,
}: HeaderProductButtonProps) => {
	return (
		<Box xcss={buttonWrapperStyles} {...triggerProps}>
			<Pressable onClick={onClick} {...otherProps} testId={testId} xcss={baseStyles}>
				<Inline alignInline="start" space="space.100" spread="space-between" alignBlock="center">
					<Inline alignBlock="center" alignInline="start" xcss={textStyles} space="space.100">
						<ProductOrNounIcon label="" size="small" productOrNounKey={productKey} />
						{label && <Box xcss={textStyles}>{label}</Box>}
					</Inline>
				</Inline>
			</Pressable>
		</Box>
	);
};

type HeaderProductButtonFinalProps = Omit<HeaderProductButtonProps, 'label'>;

export const HeaderProductButtonFinal = ({
	otherProps,
	productKey,
	onClick,
	testId,
}: HeaderProductButtonFinalProps) => {
	const { userNeedsOAuth } = useIsUserOAuthed(productKey);
	const products = useProductConfigs();
	const productLabel = products[productKey].displayName;

	const [{ thirdPartyConfigs }] = useBootstrap();
	const isSmartlink = isProductSmartlink({ productKey, thirdPartyConfigs });

	const { generateSearchUrl, onNavigate, queryParams } = useAppContext();

	const [{ primaryProduct, user }] = useBootstrap();
	const quickFindQuery = useQuickFindQuery();
	const updateRecentQueries = useUpdateRecentQueries();

	const { setQuickFindOpen } = useQuickFindActions();

	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	const onClickNoOauth = useCallback(() => {
		if (!primaryProduct) {
			return;
		}

		// Store search in recent searches
		if (quickFindQuery.trim() !== '' && user && user.id && primaryProduct) {
			addRecentSearch(quickFindQuery.trim(), user.id, primaryProduct, RECENT_SEARCH_KEY);
			updateRecentQueries?.();
		}

		setQuickFindOpen(false);

		const onNavigateCallback = typeof onNavigate === 'function' ? onNavigate : onNavigate.callback;
		onNavigateCallback(
			generateSearchUrl({
				...queryParams,
				product: productKey,
				text: quickFindQuery,
			}),
			'push',
		);

		fireAnalyticsEvent(
			onProductFilterSelected({
				actionSubjectId: productKey,
				attributes: commonAttributes,
				nonPrivacySafeAttributes,
			}),
		);
	}, [
		primaryProduct,
		quickFindQuery,
		user,
		setQuickFindOpen,
		onNavigate,
		generateSearchUrl,
		queryParams,
		productKey,
		fireAnalyticsEvent,
		commonAttributes,
		nonPrivacySafeAttributes,
		updateRecentQueries,
	]);

	if (!userNeedsOAuth) {
		return (
			<HeaderProductButton
				otherProps={otherProps}
				productKey={productKey}
				label={productLabel}
				onClick={onClickNoOauth}
				testId={testId}
			/>
		);
	} else {
		return (
			<ErrorBoundary>
				<OAuthWarningWrapper
					key={productKey}
					product={productKey}
					trigger={({ isDisabled, oAuthWarning, triggerProps }: TriggerComponentProps) => (
						<HeaderProductButton
							otherProps={otherProps}
							triggerProps={triggerProps}
							productKey={productKey}
							label={productLabel}
							onClick={onClick}
							testId={testId}
						/>
					)}
					isOnSearchDialog={true}
					useOAuthHandlers={useOAuthHandlers}
					useIsUserOAuthed={useIsUserOAuthed}
					isSmartlink={isSmartlink}
					searchQuery={quickFindQuery}
				/>
			</ErrorBoundary>
		);
	}
};
