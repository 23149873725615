import {
	SearchInput,
	type SearchInputProps,
	getInputSkeletonFocus,
	getInputSkeletonSelection,
	getInputSkeletonQuery,
	removeInputSkeletonState,
	getSessionItem,
	SEARCH_QUERY_KEY,
	EnlargedSearchInput,
} from '@atlassian/search-dialog';
import React, { type FC, Fragment, useCallback, Suspense, useMemo } from 'react';
import { injectIntl, type WrappedComponentProps } from 'react-intl-next';
import { fg } from '@atlaskit/platform-feature-flags';

import { KeyboardShortcut, KeyboardShortcutContainer } from './product-search-input.styled';

import { sha256Hash, TextEnteredHandler, useAnalytics } from '../analytics';
import {
	onAdvancedSearchSelected,
	onPreQueryScreenViewed,
	onNonDebouncedSearchTriggered,
	type OnPreQueryScreenViewedProps,
	onXButtonClicked,
	Trigger,
} from '../analytics/events';
import { useSearchSessionId } from '../search-session-provider';
import { messages } from '../../messages';
import { mergeRefCallback } from '../../utils/merge-ref-callback';
import { useSessionUserInput } from '../../extensible/user-input-provider';
import { useDialogExpansionContext } from '../../extensible/dialog-expansion-context';
import { useProductContext } from '../../extensible/product-router';
import { useQuery } from '../../extensible/query-context';
import { usePersistRecentSearches } from '../../extensible/recent-searches/persist-recent-search';
import { useFeaturesContext } from '../../extensible/features-context/features-context';
import { type ParsedUrlQuery } from '../shared/types';
import { useQueryParams } from '../../extensible/query-params-context';

const SearchAIPromptComponent = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/search-ai/prompt" */ '@atlassian/search-ai/prompt'
	).then(({ SearchAIPrompt }) => ({
		default: SearchAIPrompt,
	})),
);

const AskAIButton = () => {
	const { query } = useQuery();
	const queryHash = sha256Hash(query);
	const { getActiveProduct } = useProductContext();
	const activeProduct = getActiveProduct();
	const searchSessionId = useSearchSessionId();
	const { showSearchAIDialog, toggleShowSearchAIDialog } = useSessionUserInput();
	const { isExpanded, setIsExpanded } = useDialogExpansionContext();
	const { isGriffinNavEnabled } = useFeaturesContext();

	const handleToggleShowSearchAIDialog = useCallback(
		(show: boolean) => {
			if (isGriffinNavEnabled) {
				setIsExpanded(show);
			}

			toggleShowSearchAIDialog(show);
		},
		[isGriffinNavEnabled, setIsExpanded, toggleShowSearchAIDialog],
	);

	return !!activeProduct?.aiSearchConfig ||
		(!!showSearchAIDialog && isExpanded) ||
		isGriffinNavEnabled ? (
		<Suspense fallback={<Fragment />}>
			<SearchAIPromptComponent
				query={query}
				queryHash={queryHash}
				searchSessionId={searchSessionId}
				onToggleShowSearchAIDialog={handleToggleShowSearchAIDialog}
				source="searchDialog"
			/>
		</Suspense>
	) : null;
};

export type InputProps = Omit<SearchInputProps, 'value' | 'onNavigate' | 'onOpen'> & {
	value: string;
};

interface AdditionalProps {
	collapsedPlaceholder: string;
	debounceTime: number;
	expandedPlaceholder: string;
	forwardRef: React.Ref<HTMLInputElement>;
	isLoading: boolean;
	onNavigate: (href: string, event: React.MouseEvent | KeyboardEvent) => void;
	onOpen: () => any;
	preQueryScreenViewed: (props: OnPreQueryScreenViewedProps) => any;
	searchSessionId: string;
	stickySearchEnabled?: boolean;
	showSearchOnEnter?: boolean;
	showAIToggleButton?: boolean;
	isQuickFind?: boolean;
	isGriffinNavEnabled?: boolean;
	queryParams?: ParsedUrlQuery;
}

export type Props = InputProps & AdditionalProps;

const getTooltipContent = (message: string) => (
	<KeyboardShortcutContainer>
		{message}
		<KeyboardShortcut>{'/'}</KeyboardShortcut>
	</KeyboardShortcutContainer>
);

// Visible for testing.
export class BaseProductSearchInput extends React.Component<Props & WrappedComponentProps> {
	private ref: HTMLInputElement | null = null;

	componentDidMount() {
		const { onInput, stickySearchEnabled } = this.props;

		const skeletonFocused = getInputSkeletonFocus();
		const skeletonQuery = getInputSkeletonQuery();
		const { selectionStart, selectionEnd, selectionDirection } = getInputSkeletonSelection();
		const stickySearchQuery = getSessionItem(SEARCH_QUERY_KEY) || '';

		if (skeletonFocused) {
			this.expand(Trigger.CLICK);
			onInput?.(skeletonQuery);

			if (this.ref) {
				this.ref.value = skeletonQuery;
				this.ref.focus();
				this.ref?.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
			}
		}

		removeInputSkeletonState();

		if (stickySearchEnabled && stickySearchQuery) {
			onInput?.(stickySearchQuery);
		}
	}

	onInput = (value: string) => {
		if (value !== this.props.value) {
			this.expand(Trigger.TEXT_ENTERED);
			this.props.onInput?.(value);
		}
	};

	expand = (trigger: Trigger) => {
		const { isExpanded, onOpen, preQueryScreenViewed, searchSessionId } = this.props;
		if (!isExpanded) {
			onOpen();
			preQueryScreenViewed({ searchSessionId, trigger });
		}
	};

	close = (e: React.MouseEvent<HTMLElement>) => {
		const { isExpanded, onBack } = this.props;
		if (isExpanded) {
			onBack?.(e);
		}
	};

	onInputClick = () => {
		setTimeout(() => this.ref?.focus());
		this.expand(Trigger.CLICK);
	};

	onEnter = (event: React.KeyboardEvent<Element>) => {
		const { isExpanded, onEnter } = this.props;

		if (isExpanded) {
			onEnter?.(event);
		}
	};

	onClear = () => {
		setTimeout(() => this.ref?.focus());
		this.props.onClear?.();
	};

	onRef = mergeRefCallback(this.props.forwardRef, (ref: HTMLInputElement | null) => {
		this.ref = ref;
	});

	render() {
		const {
			value,
			isExpanded,
			expandedPlaceholder,
			collapsedPlaceholder,
			debounceTime,
			intl,
			showAIToggleButton,
			isQuickFind,
			...rest
		} = this.props;

		const placeholder: string = isExpanded ? expandedPlaceholder : collapsedPlaceholder;

		const tooltipContent = getTooltipContent(
			intl.formatMessage(messages.common_search_input_collapsed_placeholder),
		);

		const SearchInputComponent = isQuickFind ? EnlargedSearchInput : SearchInput;

		return (
			<>
				{isExpanded ? <TextEnteredHandler query={value} debounceTime={debounceTime} /> : null}
				<SearchInputComponent
					{...rest}
					onClick={this.onInputClick}
					onInput={this.onInput}
					ref={this.onRef}
					isExpanded={isExpanded}
					placeholder={placeholder}
					value={value}
					onEnter={this.onEnter}
					onClear={this.onClear}
					toggleAISearchButton={showAIToggleButton ? <AskAIButton /> : undefined}
					tooltipContent={tooltipContent}
					exitSearchLabel={intl.formatMessage(messages.common_search_input_exit_button_label)}
					clearSearchLabel={intl.formatMessage(messages.common_search_input_clear_button_label)}
					{...(isQuickFind && {
						ariaLabelContent: intl.formatMessage(messages.common_search_input_enlarged_aria_label),
					})}
				/>
			</>
		);
	}
}

export const ProductSearchInputWithIntl: FC<Props> = injectIntl(BaseProductSearchInput);

interface AnalyticsAdvancedSearchProps {
	actionSubjectId: string;
	advancedSearchURL: string;
	secondaryAdvancedSearchURL?: string;
}
export const ProductSearchInput: React.FC<
	Omit<InputProps, 'onEnter' | 'onOpen' | 'onNavigate'> &
		Omit<AdditionalProps, 'preQueryScreenViewed' | 'searchSessionId'> &
		AnalyticsAdvancedSearchProps
> = (props) => {
	const [_ignore, persistRecentSearchOnEnter] = usePersistRecentSearches();
	const {
		actionSubjectId,
		advancedSearchURL,
		secondaryAdvancedSearchURL,
		isLoading,
		onNavigate,
		...rest
	} = props;
	const { fireAnalyticsEvent } = useAnalytics();
	const searchSessionId = useSearchSessionId();
	const { stickySearchEnabled, query, resetSearchSession, showSearchAIDialog } =
		useSessionUserInput();
	const { allowChangeExpand } = useDialogExpansionContext();

	const { getActiveProduct } = useProductContext();
	const { quickFind, isGriffinNavEnabled } = useFeaturesContext();
	const showSearchOnEnter = !!getActiveProduct()?.showSearchOnEnter;
	const invokePostQuerySearchRef = getActiveProduct()?.invokePostQuerySearchRef;
	const activeProduct = getActiveProduct();

	const showAskAIButton = useMemo(() => {
		if (activeProduct) {
			return !!activeProduct.aiSearchConfig;
		}
		return query.length > 0;
	}, [query, activeProduct]);

	const aiSearchEnabled = isGriffinNavEnabled
		? showAskAIButton
		: !!getActiveProduct()?.aiSearchConfig || !!showSearchAIDialog;

	const isQuickFind = quickFind?.enabled;
	const { queryParams } = useQueryParams();

	const onEnter = useCallback(
		(event: any) => {
			if (fg('psd_stop_propagation_on_enter')) {
				event.stopPropagation();
			}

			persistRecentSearchOnEnter(event);

			if (showSearchAIDialog) {
				return;
			}

			if (showSearchOnEnter && !event?.shiftKey) {
				if (invokePostQuerySearchRef) {
					fireAnalyticsEvent(onNonDebouncedSearchTriggered());
					invokePostQuerySearchRef.current();
				}
				return;
			}

			const modifierKeyPressed = navigator.userAgent.includes('Mac')
				? event.metaKey
				: event.ctrlKey;

			fireAnalyticsEvent(
				onAdvancedSearchSelected({
					actionSubjectId,
					isLoading,
					newTab: false,
					trigger: modifierKeyPressed ? Trigger.SHORTCUT_MODIFIED : Trigger.SHORTCUT,
				}),
			);
			if (stickySearchEnabled && advancedSearchURL && !isGriffinNavEnabled) {
				resetSearchSession();
			}

			if (isGriffinNavEnabled) {
				event.currentTarget.blur();
			}

			onNavigate(
				modifierKeyPressed && secondaryAdvancedSearchURL
					? secondaryAdvancedSearchURL
					: advancedSearchURL,
				event,
			);
		},
		[
			persistRecentSearchOnEnter,
			showSearchAIDialog,
			showSearchOnEnter,
			fireAnalyticsEvent,
			actionSubjectId,
			isLoading,
			stickySearchEnabled,
			advancedSearchURL,
			onNavigate,
			secondaryAdvancedSearchURL,
			invokePostQuerySearchRef,
			resetSearchSession,
			isGriffinNavEnabled,
		],
	);

	const onClear = useCallback(() => {
		fireAnalyticsEvent(
			onXButtonClicked({
				actionSubjectId,
				searchSessionId,
			}),
		);
		resetSearchSession();
		allowChangeExpand(true);
	}, [fireAnalyticsEvent, actionSubjectId, searchSessionId, resetSearchSession, allowChangeExpand]);

	const onPreQueryScreenViewedCB = useCallback(
		(preQueryProps: OnPreQueryScreenViewedProps) =>
			fireAnalyticsEvent({
				...onPreQueryScreenViewed(preQueryProps),
				actionSubject: actionSubjectId,
			}),
		[fireAnalyticsEvent, actionSubjectId],
	);

	return (
		<ProductSearchInputWithIntl
			{...rest}
			onEnter={onEnter}
			searchSessionId={searchSessionId}
			preQueryScreenViewed={onPreQueryScreenViewedCB}
			onClear={onClear}
			showClearButton={stickySearchEnabled && query !== ''}
			allowChangeExpand={allowChangeExpand}
			stickySearchEnabled={stickySearchEnabled}
			showSearchOnEnter={showSearchOnEnter}
			showAIToggleButton={aiSearchEnabled}
			isLoading={isLoading}
			onNavigate={onNavigate}
			isQuickFind={isQuickFind}
			isGriffinNavEnabled={isGriffinNavEnabled}
			queryParams={queryParams}
		/>
	);
};
