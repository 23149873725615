import gql from 'graphql-tag';

const RecentQuickSearchSpaceFragment = gql`
	fragment RecentQuickSearchSpaceFragment on Space {
		id
		key
		name
		icon {
			path
		}
		links {
			webui
		}
	}
`;

const MyActivitiesFragment = gql`
	fragment MyActivitiesFragment on ActivitiesConnection {
		edges {
			node {
				id
				object {
					id
					content {
						id
						title
						links {
							webui
						}
						type
						space {
							id
							name
						}
						status
					}
				}
			}
		}
	}
`;

export const RecentQuickSearchQuery = gql`
	query RecentQuickSearchQuery(
		$cloudId: ID!
		$contentItemsLimit: Int!
		$spaceLimit: Int!
		$objectTypes: [ActivitiesObjectType!]
		$workedOnAPIEnabled: Boolean!
	) {
		activities {
			myActivities {
				viewed(
					filters: [
						{
							type: AND
							arguments: { products: [CONFLUENCE], objectTypes: $objectTypes, cloudIds: [$cloudId] }
						}
					]
					first: $contentItemsLimit
				) @skip(if: $workedOnAPIEnabled) {
					...MyActivitiesFragment
				}
				workedOn(
					filters: [
						{
							type: AND
							arguments: { products: [CONFLUENCE], objectTypes: $objectTypes, cloudIds: [$cloudId] }
						}
					]
					first: $contentItemsLimit
				) @include(if: $workedOnAPIEnabled) {
					...MyActivitiesFragment
				}
			}
		}
		myVisitedSpaces(limit: $spaceLimit) {
			nodes {
				spaces {
					...RecentQuickSearchSpaceFragment
				}
			}
		}
	}
	${RecentQuickSearchSpaceFragment}
	${MyActivitiesFragment}
`;
