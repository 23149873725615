import { useEffect, useRef } from 'react';
import { useKeyboardNavigation } from '../search-keyboard';

/**
 * Callback which simulates a click on the target element when the enter key
 * is pressed whilst the target element is active.
 *
 * @param e Event from key down
 * @param target Active element when key was pressed
 */
const onKeyDown = (e: KeyboardEvent, target: HTMLElement) => {
	// Only navigate if Enter was pressed without any modifiers
	if (e.key === 'Enter' && !e.shiftKey && !e.metaKey && !e.ctrlKey) {
		e.preventDefault();

		// Find the deepest child element in the DOM
		let clickTarget = target;
		while (
			clickTarget.firstElementChild instanceof HTMLElement &&
			clickTarget.firstElementChild?.click
		) {
			clickTarget = clickTarget.firstElementChild;
		}

		clickTarget.click();
	}
};

type useKeyboardHighlightingArgs = {
	searchResultId?: string;
	onHighlighted?: () => void;
};

export const useKeyboardHighlighting = ({
	searchResultId,
	onHighlighted,
}: useKeyboardHighlightingArgs) => {
	const [isKeyboardHighlighted, ref] = useKeyboardNavigation<HTMLDivElement>({
		onKeydownCallback: onKeyDown,
		navigationTrackingId: searchResultId ?? null,
	});

	// Ref for whether or not this search result was last highlighted
	const previousHighlight = useRef(false);

	// Call the onHighlighted callback when this search result is highlighted
	useEffect(() => {
		if (!previousHighlight.current && isKeyboardHighlighted) {
			onHighlighted?.();
		}

		previousHighlight.current = isKeyboardHighlighted;
	}, [isKeyboardHighlighted, previousHighlight, onHighlighted]);

	return { isKeyboardHighlighted, ref };
};
