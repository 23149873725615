/*
 * An interactive version of the search input skeleton which allows users to
 * type into the skeleton's input. The state of the skeleton input is saved in
 * the browser's storage (specifically within window.inputSkeletonState).
 *
 * This component is intended to be used for server side rendering only as
 * the event listeners for the input are attached via a HTML script tag.
 */

import React, { type FC } from 'react';
import type { SearchCSS } from '@atlaskit/atlassian-navigation';
import {
	SearchInputSkeletonInteractiveSSR,
	type SearchInputSkeletonInteractiveSSRProps,
	ThemeProvider,
} from '@atlassian/search-dialog/skeleton';

type ProductSearchInputSkeletonInteractiveSSRProps = SearchInputSkeletonInteractiveSSRProps & {
	theme?: SearchCSS;
	shouldFillContainer?: boolean;
};

export const ProductSearchInputSkeletonInteractiveSSR: FC<
	ProductSearchInputSkeletonInteractiveSSRProps
> = (props) => {
	return (
		<ThemeProvider partialSearchCSS={props.theme}>
			<SearchInputSkeletonInteractiveSSR {...props} />
		</ThemeProvider>
	);
};
