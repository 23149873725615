// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { token } from '@atlaskit/tokens';
import { grid, enlargedInputFieldHeight, inputFieldHeight } from '../style-utils';
import { N500, N50A, N60A } from '@atlaskit/theme/colors';

import type { SearchCSS } from '../theme';
import {
	ClearIconWrapper,
	DesktopSearchIconWrapper,
	type IsExpandedProp,
	SearchInputField,
} from './search-input.styled';

// FIXME: convert-props-syntax rule doesn't catch this
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EnlargedDesktopSearchIconWrapper = styled(DesktopSearchIconWrapper)<
	SearchCSS & IsExpandedProp
>`
	width: ${(props) =>
		props.isExpanded ? enlargedInputFieldHeight.half().px : grid.multiple(2).px};
	height: ${(props) =>
		props.isExpanded ? enlargedInputFieldHeight.half().px : grid.multiple(2).px};
	top: ${(props) => (props.isExpanded ? grid.multiple(1.5).px : grid.px)};
	left: ${(props) => (props.isExpanded ? grid.multiple(2).px : grid.px)};
	display: flex;
	align-items: center;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EnlargedClearIconWrapper = styled(ClearIconWrapper)<SearchCSS & IsExpandedProp>`
	width: ${token('space.300', '24px')};
	height: ${token('space.300', '24px')};
	padding-top: 0;
	padding-bottom: 0;
	padding-left: ${({ isExpanded }) => (isExpanded ? `${token('space.150', '12px')}` : '0')};
	padding-right: ${({ isExpanded }) => (isExpanded ? `${token('space.200', '16px')}` : '2px')};
	color: ${token('color.text.subtle', N500)};
`;

// FIXME: convert-props-syntax rule doesn't catch this
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EnlargedSearchInputField = styled(SearchInputField)<SearchCSS & IsExpandedProp>`
	display: flex;
	align-items: center;

	height: ${(props) => (props.isExpanded ? enlargedInputFieldHeight.px : inputFieldHeight.px)};

	border-radius: ${(props) => (props.isExpanded ? '8px' : '5px')};

	padding: ${(props) =>
		props.isExpanded
			? `${token('space.150', '12px')} 52px ${token('space.150', '12px')} 52px`
			: `0px ${token('space.300', '24px')} 0px 30px`};

	:focus {
		border: none;

		box-shadow: ${token(
			'elevation.shadow.overlay',
			'0 ' + grid.multiple(0.5).px + ' ' + grid.px + ' ' + N50A + ', 0 0 1px ' + N60A,
		)};
	}
`;
