import { hashData } from './search-result-utils';
import { attributeSeparator, valueSeparator } from './transformAnalyticsQueryParamToObject';

type transformAnalyticsToQueryParamProps = {
	url: string;
	query?: string;
	searchSessionId: string;
	additionalAnalytics?: object;
	resultId?: string;
	type?: string;
	addQueryParams: (url: string, params: object) => string;
	addQuery: (url: string, key: string, unencodedValue: string) => string;
};
export const transformAnalyticsToQueryParam = ({
	url,
	query,
	searchSessionId,
	additionalAnalytics,
	resultId,
	addQueryParams,
	addQuery,
	type,
}: transformAnalyticsToQueryParamProps) => {
	const isConfluenceContent =
		(typeof resultId === 'string' && resultId.includes('confluence')) ||
		(typeof type === 'string' &&
			[
				'confluence page',
				'confluence blogpost',
				'confluence space',
				'confluence attachment',
				'confluence whiteboard',
			].some((contentType) => contentType.includes(type)));
	const hasAdditionalAnalytics =
		typeof additionalAnalytics === 'object' && Object.keys(additionalAnalytics).length > 0;
	if (!isConfluenceContent || !(query || hasAdditionalAnalytics)) {
		return addQuery(url, 'search_id', searchSessionId);
	}

	let events = [];
	if (query) {
		events.push(`queryHash${valueSeparator}${hashData(query)}`);
	}

	if (hasAdditionalAnalytics) {
		Object.entries(additionalAnalytics).forEach(([attribute, value]) => {
			events.push(`${attribute}${valueSeparator}${value}`);
		});
	}

	if (events.length === 0) {
		return addQuery(url, 'search_id', searchSessionId);
	}

	const analyticsFormattedForUrlParsing = encodeURIComponent(events.join(attributeSeparator));

	return addQueryParams(url, {
		search_id: searchSessionId,
		additional_analytics: analyticsFormattedForUrlParsing,
	});
};
