export const transformAriToId = (id: string, type: string) => {
	switch (type) {
		case 'page':
			return id.split(':page/')[1];
		case 'blogpost':
			return id.split(':blogpost/')[1];
		case 'attachment':
			return id.split(':attachment/')[1];
		case 'space':
			return id.split(':space/')[1];
		case 'whiteboard':
			return id.split(':whiteboard/')[1];
		case 'embed':
			// embed ari url contains activation id so we split it differently here
			const embedAri = id.split('/');
			return embedAri[embedAri.length - 1];
		case 'database':
			// Databases ari url contains activation id so we split it differently here
			const databaseAri = id.split('/');
			return databaseAri[databaseAri.length - 1];
		default:
			return id;
	}
};
