import { useCallback, useMemo } from 'react';
import type { ReactElement } from 'react';
import { useIntl } from 'react-intl-next';

import {
	type ConfItemResponseItem,
	ConfluenceScope,
	type ConfPersonResponseItem,
	type ConfSpaceResponseItem,
	type SupportedConfScopeResponses,
	type RendererOverrideTypes,
} from './types';
import { messages } from '../../../messages';
import { type SearchResult } from '../../product-router';
import { defaultFormatDate } from './utils';
import { ConfluenceItemIconRenderer, GenericConfluenceIconRenderer } from './result-icons';
import { type GenericFilter, DEFAULT_OPTION } from '../../filters/filter-types';
import { ConfluenceItemResultRenderer, sectionTitleRenderer } from './title-and-result-renderer';

const ITEM_LIMIT_SIZE = 10;
const SPACE_LIMIT_SIZE = 3;
const PEOPLE_LIMIT_SIZE = 3;

type getSectionsArgType = {
	formatDate?: (lastModified: string) => ReactElement;
	autocorrectEnabled?: boolean;
	dateFilterEnabled?: boolean;
	additionalContentTypesEnabled: boolean;
	rendererOverride?: RendererOverrideTypes;
};

export const useConfluenceSections = ({
	formatDate,
	autocorrectEnabled,
	additionalContentTypesEnabled,
	rendererOverride,
}: getSectionsArgType) => {
	const { formatMessage } = useIntl();

	const confluenceItemResultMapper = useCallback(
		({ results }: SupportedConfScopeResponses): SearchResult[] =>
			(results as ConfItemResponseItem[])
				.map((result) => ({
					id: result.content!.id,
					title: result.title,
					type: result.content!.type,
					meta: result.container.title,
					additionalMeta: result.lastModified
						? formatDate
							? formatDate(result.lastModified)
							: defaultFormatDate(result.lastModified)
						: '',
					url: `${result.baseUrl}${result.url}`,
					containerId: result.content?.space?.id || result.container?.id || 'UNAVAILABLE',
					iconRenderer: ConfluenceItemIconRenderer(result, true),
				}))
				.slice(0, ITEM_LIMIT_SIZE),
		[formatDate],
	);

	const confluenceSpaceResultMapper = useCallback(
		({ results }: SupportedConfScopeResponses): SearchResult[] =>
			(results as ConfSpaceResponseItem[])
				.map((result) => ({
					id: result.id,
					title: result.title,
					meta: '',
					url: `${result.baseUrl || ''}${result.container.displayUrl}`,
					containerId: result.id,
					icon: GenericConfluenceIconRenderer(`${result.baseUrl}${result.space!.icon.path}`, true),
				}))
				.slice(0, SPACE_LIMIT_SIZE),
		[],
	);

	const confluencePeopleResultMapper = useCallback(
		({ results }: SupportedConfScopeResponses): SearchResult[] =>
			(results as ConfPersonResponseItem[])
				.map((result) => ({
					id: result.id,
					title: result.name,
					meta: '',
					url: result.absoluteUrl?.replace('/people', '/wiki/people') || '',
					containerId: '',
					icon: GenericConfluenceIconRenderer(result.avatarUrl, true),
				}))
				.slice(0, PEOPLE_LIMIT_SIZE),
		[],
	);

	const enableSpaceAndPeopleScope = (query: string, filters: GenericFilter[]) => {
		return !filters.find(
			(filter) =>
				(filter['@type'] === 'spaces' && filter['spaceKeys'].length > 0) ||
				(filter['@type'] === 'contributors' && filter['accountIds'].length > 0) ||
				(filter['@type'] === 'lastModified' && filter.from && filter.from !== DEFAULT_OPTION),
		);
	};
	const scopeId =
		additionalContentTypesEnabled && rendererOverride?.contentScope
			? rendererOverride.contentScope
			: ConfluenceScope.Item;

	return useMemo(
		() => [
			{
				id: scopeId,
				title: formatMessage(messages.confluence_content_section_heading),
				titleRenderer: sectionTitleRenderer(!!autocorrectEnabled),
				resultRenderer:
					additionalContentTypesEnabled && !!rendererOverride?.ConfluenceResultRenderer
						? rendererOverride.ConfluenceResultRenderer
						: ConfluenceItemResultRenderer,
				scope: scopeId,
				resultMapper: confluenceItemResultMapper,
				showResultCount: true,
				resultLimit: ITEM_LIMIT_SIZE,
			},
			{
				id: ConfluenceScope.Space,
				title: formatMessage(messages.confluence_spaces_section_heading),
				scope: ConfluenceScope.Space,
				resultMapper: confluenceSpaceResultMapper,
				isEnabled: enableSpaceAndPeopleScope,
			},
			{
				id: ConfluenceScope.People,
				title: formatMessage(messages.confluence_people_section_heading),
				scope: ConfluenceScope.People,
				resultMapper: confluencePeopleResultMapper,
				isEnabled: enableSpaceAndPeopleScope,
			},
		],
		[
			formatMessage,
			autocorrectEnabled,
			confluenceItemResultMapper,
			confluenceSpaceResultMapper,
			confluencePeopleResultMapper,
			scopeId,
			additionalContentTypesEnabled,
			rendererOverride,
		],
	);
};
