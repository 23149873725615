import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	/**
	 * Compass upsell tab
	 */
	compass_upsell_tab_lozenge: {
		id: 'product_search_dialog.compass.upsell_tab_title_lozenge',
		defaultMessage: 'New',
		description: 'Lozenge next to Compass tab title indicating new functionality',
	},
	compass_upsell_heading: {
		id: 'product_search_dialog.compass.upsell_modal_heading',
		defaultMessage: 'Control your software sprawl, so developers can control their time',
		description: 'Upsell modal heading presented to users who do not have Compass activated',
	},
	compass_upsell_heading_bitbucket: {
		id: 'product_search_dialog.compass.upsell_modal_heading.bitbucket',
		defaultMessage: 'Bring more context to your code',
		description:
			'Upsell modal heading presented to Bitbucket users who do not have Compass activated',
	},
	compass_upsell_description: {
		id: 'product_search_dialog.compass.upsell_modal_description',
		defaultMessage:
			'Catalog your software architecture in one place – so the teams working on it can reduce context switching, find what they need faster, and be more productive. How’s that for a developer experience?',
		description: 'Upsell modal description presented to users who don not have Compass activated',
	},
	compass_upsell_description_bitbucket: {
		id: 'product_search_dialog.compass.upsell_modal_description.bitbucket',
		defaultMessage:
			'See owners, dependencies, and help resources for your repositories by linking them to a central software catalog.',
		description:
			'Upsell modal description presented to Bitbucket users who don not have Compass activated',
	},
	compass_upsell_learn_more_link: {
		id: 'product_search_dialog.compass.upsell_modal_learn_more_link',
		defaultMessage: 'Learn more',
		description: 'Text for the learn more about Compass link',
	},
	compass_upsell_learn_more_aria_label: {
		id: 'product_search_dialog.compass.upsell_modal_learn_more_aria_label',
		defaultMessage: 'Learn more about Compass',
		description: 'Arial label text for the learn more about Compass link',
	},
	compass_upsell_cta_button: {
		id: 'product_search_dialog.compass.upsell_modal_cta_text',
		defaultMessage: 'Get Compass free',
		description: 'Text for the button clicking which will navigate user to the sign up page',
	},
});
