import React from 'react';

import {
	isSearchResultAtlasGoalPartial,
	isSearchResultAtlasProjectPartial,
	type QuickFindSearchAtlasResult,
	type QuickFindSearchResultType,
} from '@atlassian/search-client';
import { transformAriToId } from '@atlassian/search-common';
import Image from '@atlaskit/image';
import { capitalizeFirstLetter } from './quick-find-meta';

const getIcon = (result?: QuickFindSearchAtlasResult) => {
	return result?.iconUrl ? (
		<Image height={24} width={24} title={result.type} src={result.iconUrl} />
	) : (
		<></>
	);
};

const getKey = (item: QuickFindSearchAtlasResult) => {
	if (isSearchResultAtlasProjectPartial(item)) {
		return item.project?.key;
	} else if (isSearchResultAtlasGoalPartial(item)) {
		return item.goal?.key;
	} else {
		return null;
	}
};
const getTitle = (item?: QuickFindSearchAtlasResult) => {
	if (!item) {
		return '';
	}
	let entityKey = getKey(item);
	if (entityKey) {
		return `${entityKey}: ${item.title || ''}`;
	}
	return item.title || '';
};

export const quickFindAtlasContentMapper = ({
	node: item,
}: {
	node?: QuickFindSearchAtlasResult;
}): QuickFindSearchResultType => {
	return {
		id: transformAriToId(item?.id || '', item?.type || ''),
		title: getTitle(item),
		url: item?.url || '',
		meta: capitalizeFirstLetter(item?.type || ''),
		additionalMeta: '',
		containerId: '',
		type: `atlas ${item?.type}` || '',
		icon: getIcon(item),
	};
};
