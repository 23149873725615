import React, { useCallback, type FC, useMemo } from 'react';
import { type WrappedComponentProps, injectIntl } from 'react-intl-next';
import { useDefaultSuppliers } from '../..';

import { messages } from '../../../messages';
import { messages as opsgenieMessages } from './messages';
import { SearchDialogProduct } from '../../product-router';
import { type ScreenSpecificProps } from '../../product-router/product';
import { type SearchResult } from '../../product-router/product/result-types';
import type { ExtensibleTabProps } from '../extensible-tab-types';
import {
	type AggregatorOpsgenieResponse,
	type OpsgenieAlertResponse,
	OpsgenieScope,
	type OpsgenieURLGenerators,
} from './types';
import { type OpsgenieUrlGeneratorProps, useOpsgenieURLGenerators } from './url-generators';

const OPSGENIE_PRODUCT_ID = 'opsgenie';

// visible for testing
export const mapAggregatorResponseToSearchItem = (alert: OpsgenieAlertResponse): SearchResult => {
	return {
		title: alert.message,
		id: alert.id,
		meta: alert.status.charAt(0).toUpperCase() + alert.status.slice(1),
		url: alert.link,
		iconUrl: alert.iconURI,
		containerId: '', // no conatiner
	};
};

export type OpsgenieTabProps = ExtensibleTabProps & OpsgenieUrlGeneratorProps;

export const OpsgenieTabWithIntl: React.FC<WrappedComponentProps & OpsgenieTabProps> = ({
	intl,
	cloudId,
	generateAdvancedSearchUrl: overriddenGenerateAdvancedSearchUrl,
	hostUrl,
	postQueryItemSupplier,
	preQueryItemSupplier,
	...rest
}) => {
	const urlGeneratorArguments = useMemo(() => ({ cloudId, hostUrl }), [cloudId, hostUrl]);

	const { viewAllLinkGenerator, urlGeneratorForNoResultsScreen }: OpsgenieURLGenerators =
		useOpsgenieURLGenerators(urlGeneratorArguments);

	const sections = [
		{
			id: OpsgenieScope.OpsgenieAlert,
			title: intl.formatMessage(opsgenieMessages.opsgenie_alerts_heading),
			scope: OpsgenieScope.OpsgenieAlert,
			showResultCount: true,
			viewAllLinkGenerator,
			resultMapper: ({ results }: AggregatorOpsgenieResponse): SearchResult[] =>
				results.map((value) => mapAggregatorResponseToSearchItem(value)),
		},
	];

	const itemSuppliers = useDefaultSuppliers<AggregatorOpsgenieResponse, OpsgenieScope>(
		OPSGENIE_PRODUCT_ID,
		sections,
		{
			preQueryItemSupplier,
			postQueryItemSupplier,
		},
	);

	const preQuerySectionTitleGenerator = useCallback<
		NonNullable<ScreenSpecificProps['preQuerySectionTitleGenerator']>
	>(
		(section) =>
			section.id === OpsgenieScope.OpsgenieAlert
				? intl.formatMessage(opsgenieMessages.opsgenie_recent_alerts)
				: '',
		[intl],
	);

	return (
		<SearchDialogProduct
			expandedStateInputPlaceholder={intl.formatMessage(
				opsgenieMessages.opsgenie_expanded_input_placeholder,
			)}
			generateAdvancedSearchUrl={overriddenGenerateAdvancedSearchUrl || viewAllLinkGenerator}
			id={OPSGENIE_PRODUCT_ID}
			preQuerySectionTitleGenerator={preQuerySectionTitleGenerator}
			searchFooterLabel={intl.formatMessage(opsgenieMessages.opsgenie_advanced_search)}
			sections={sections}
			title={intl.formatMessage(messages.opsgenie_tab_label)}
			urlGeneratorForNoResultsScreen={urlGeneratorForNoResultsScreen}
			{...rest}
			{...itemSuppliers}
		/>
	);
};

/**
 * The canonical implementation of a tab to show Opsgenie search results inside of the search dialog
 *
 * Can be imported across multiple Atlassian products and will show Opsgenie results there
 */
export const OpsgenieTab: FC<OpsgenieTabProps> = injectIntl(OpsgenieTabWithIntl);
