const getBaseUrl = () => {
	const hostname = window?.location?.hostname;
	if (
		hostname?.includes('jira-dev.com') ||
		hostname?.startsWith('trellis.coffee') ||
		hostname?.startsWith('localhost') ||
		hostname?.startsWith('team.stg')
	) {
		return 'https://trellis.coffee';
	}
	return 'https://trello.com';
};

export const viewAllLinkGenerator = (query: string) =>
	query ? `${getBaseUrl()}/search?q=${encodeURIComponent(query)}` : `${getBaseUrl()}/search`;
