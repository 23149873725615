import React, { Suspense } from 'react';

import { FormattedMessage } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';

import { ApplicationModes } from '../../../../common/constants/application-modes';
import { useAppContext } from '../../../../common/ui/app-context';
import { useApplicationMode } from '../../../../controllers/store';

import { messages } from './messages';

const Image = React.lazy(
	() =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/search-page/search-error-image" */ './image'
		),
);

const containerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: 'space.200',
	color: 'color.text',
});

const imageFallbackStyles = xcss({
	width: '124px',
	height: '124px',
});

const headingStyles = xcss({
	font: 'font.heading.small',
	fontWeight: 'font.weight.medium',
	marginTop: 'space.100',
});

const bodyStyles = xcss({
	marginTop: 'space.100',
	font: 'font.body',
});

export const LandingScreen = () => {
	const applicationMode = useApplicationMode();
	const { isAdminHubAIEnabled, isRovoLLMEnabled } = useAppContext();

	const bodyCopy =
		applicationMode === ApplicationModes.Rovo
			? messages.quickFindNoContentBodyWithRovo
			: isAdminHubAIEnabled
				? messages.quickFindNoContentBodyWithAtlassianIntelligence
				: messages.quickFindNoContentBodyDefault;

	const newBodyCopy =
		(applicationMode === ApplicationModes.Rovo && isRovoLLMEnabled) || isAdminHubAIEnabled
			? messages.quickFindNoContentBodyWithAI
			: messages.quickFindNoContentBody;

	return (
		<Box xcss={containerStyles}>
			<Suspense fallback={<Box xcss={imageFallbackStyles} />}>
				<Image />
			</Suspense>
			<Box xcss={headingStyles}>
				<FormattedMessage
					{...(fg('rovo_search_updated_entitlement_checks_for_team_25') ? newBodyCopy : bodyCopy)}
				/>
			</Box>
			<Box xcss={bodyStyles}>
				<FormattedMessage {...messages.quickFindNoContentHeading} />
			</Box>
		</Box>
	);
};
