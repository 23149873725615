import { useEffect, useRef } from 'react';

/**
 * Syncs two states together by calling the respective setState of the state that
 * requires an update. StateA takes precedence in the following scenarios:
 * - initial mount of this hook
 * - when both states updated in the same render cycle
 *
 * NOTE: Do not use this hook if undefined is a valid value for the states. This
 * choice is made to allow for the hook to be used conditionally, i.e. it only
 * syncs if all of the arguments are defined.
 */
export function useSyncStates<T>(
	stateA?: T,
	setStateA?: (s: T) => void,
	stateB?: T,
	setStateB?: (s: T) => void,
) {
	const prevA = useRef<T>();
	const prevB = useRef<T>();

	useEffect(() => {
		// Do nothing if any of the arguments are undefined
		if (
			stateA === undefined ||
			setStateA === undefined ||
			stateB === undefined ||
			setStateB === undefined
		) {
			return;
		}

		// On mount, use stateA as the source of truth
		if (prevA.current === undefined && prevB.current === undefined) {
			prevA.current = stateA;
			prevB.current = stateA;
			setStateB(stateA);
			return;
		}

		// If updates to both states are updated to the same value in the
		// same render cycle, ensure previous values are updated
		if (stateA === stateB) {
			prevA.current = stateA;
			prevB.current = stateB;
			return;
		}

		// If stateA has changed, update stateB to match
		if (prevA.current !== stateA) {
			prevA.current = stateA;
			prevB.current = stateA;
			setStateB(stateA);

			// Exit here as only one state should be updated, otherwise an infinite
			// loop will occur. This means that stateA takes precedence if both
			// states are changed in the same render cycle.
			return;
		}

		// If stateB has changed, update stateA to match
		if (prevB.current !== stateB) {
			prevA.current = stateB;
			prevB.current = stateB;
			setStateA(stateB);
		}
	}, [setStateA, setStateB, stateA, stateB]);
}
