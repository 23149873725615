import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import Link from '@atlaskit/link';
import { Box, Text, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';

import { useQuickFindActions } from '../../../../../controllers/store/quick-find';

import { messages } from './messages';

export const AssetsUnavailableBanner = () => {
	const { setQuickFindOpen } = useQuickFindActions();

	return (
		<Box xcss={containerStyles}>
			<SectionMessage>
				<FormattedMessage
					{...messages.assetsUnavailableMessage}
					values={{
						bold: (chunks) => <Text weight={'bold'}>{chunks}</Text>,
						searchLink: (chunks) => (
							<Link href={'/jira/assets?showSearch=true'} onClick={() => setQuickFindOpen(false)}>
								{chunks}
							</Link>
						),
					}}
				/>
			</SectionMessage>
		</Box>
	);
};

const containerStyles = xcss({
	paddingBottom: 'space.075',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});
