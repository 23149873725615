import React, { type PropsWithChildren } from 'react';
import { useProductContext } from '../product-router';

/**
 * Displays the product when the product is marked as displayed
 */
export const ActiveProduct = ({ children, id }: PropsWithChildren<{ id: string }>) => {
	const { getProduct } = useProductContext();

	const product = getProduct(id);

	if (!product?.isDisplayed) {
		return <></>;
	}

	return <>{children}</>;
};
