// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { token } from '@atlaskit/tokens';
import { N30, N90 } from '@atlaskit/theme/colors';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentWrapper = styled.div({
	display: 'flex',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchFooterLabelWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	font: token('font.body.UNSAFE_small'),
	gap: token('space.050', '4px'),
	color: token('color.text.subtle', N90),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchFooterLabel = styled.div({
	backgroundColor: token('color.background.neutral', N30),
	padding: `0px ${token('space.025', '2px')}`,
	borderRadius: '4px',
});
