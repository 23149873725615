import { type WrappedComponentProps, injectIntl } from 'react-intl-next';
import { ProductStates } from '../../../..';
import { type SearchItemsTransformerProps } from '../../../product-router/product/result-provider/result-provider-types';
import { messages } from '../../messages';
import { RECENT_SEARCHES_SECTION_ID } from '../sections/recent-search-section';

const RECENTLY_VIEWED_ITEMS_SHOWN = 5;

/**
 * This transformer assumes that preQueryItems contains recently viewed search
 * results and adds them to the postQueryItems.
 */
const PostQueryRecentlyViewedTransformerWithIntl: React.FC<
	SearchItemsTransformerProps & WrappedComponentProps
> = ({ intl, preQueryItems, postQueryItems, productState, children }) => {
	// Create an array of recently viewed sections. These sections exclude recent
	// searches and have a combined result limit of 10.
	let recentlyViewedCount = 0;

	const recentlyViewedSections = preQueryItems.sections
		.filter((section) => section.id !== RECENT_SEARCHES_SECTION_ID)
		.filter((section) => section.searchResults.length > 0)
		.map((section, i) => {
			const newSection = {
				...section,
				resultLimit: Math.min(
					section.searchResults.length,
					RECENTLY_VIEWED_ITEMS_SHOWN - recentlyViewedCount,
				),
				title: i === 0 ? intl.formatMessage(messages.quick_find_recently_viewed) : '',
			};

			recentlyViewedCount = Math.min(
				recentlyViewedCount + section.searchResults.length,
				RECENTLY_VIEWED_ITEMS_SHOWN,
			);

			return newSection;
		});

	// Add recent searches to the end of postQueryItems after post query returns
	const recentSearchesSection = preQueryItems.sections.find(
		(section) => section.id === RECENT_SEARCHES_SECTION_ID,
	);

	const postQueryItemsSectionsWithRecentSearches =
		recentSearchesSection && productState === ProductStates.PostQuerySuccess
			? [...postQueryItems.sections, recentSearchesSection]
			: [...postQueryItems.sections];

	// Create the new postQueryItems object
	const postQueryItemsWithRecentlyViewed = {
		size: [...recentlyViewedSections, ...postQueryItems.sections]
			.map((section) => section.searchResults.length)
			.reduce((a, b) => a + b, 0),
		sections: [...recentlyViewedSections, ...postQueryItemsSectionsWithRecentSearches],
	};

	return children({
		preQueryItems: preQueryItems,
		postQueryItems: postQueryItemsWithRecentlyViewed,
		productState,
	});
};

export const PostQueryRecentlyViewedTransformer: React.FC<SearchItemsTransformerProps> = injectIntl(
	PostQueryRecentlyViewedTransformerWithIntl,
);
