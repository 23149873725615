import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import Avatar, { type AvatarPropTypes } from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NegativeMargin = styled.div({
	margin: token('space.negative.025', '-2px'),
	display: 'flex',
});

export const ResultAvatar = (props: AvatarPropTypes) => (
	<NegativeMargin>
		<Avatar {...props} />
	</NegativeMargin>
);
