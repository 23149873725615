import { useCallback, useRef } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

import { onSearchRequestCompleted } from '../../../../common/utils/quick-find/events/search-request-completed';
import { useIsHello, usePrimaryProduct, useSearchActions } from '../../../store';
import { useQuickFindAnalytics, useQuickFindAttributes } from '../../analytics';

export const useQuickFindResultsCallbacks = () => {
	const [primaryProduct] = usePrimaryProduct();
	const is3PinQFEnabled = fg('enable_quickfind_3p_search_results_confluence');
	const { incrementQueryVersion } = useSearchActions();

	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();
	const [isHello] = useIsHello();

	const ufoPreQueryLoadExperience = useRef<UFOExperience>(
		new UFOExperience(`${primaryProduct}-pre-query-results-load`, {
			type: ExperienceTypes.Experience,
			performanceType: ExperiencePerformanceTypes.InlineResult,
			platform: { component: 'search-dialog' },
		}),
	);

	const ufoPostQueryLoadExperience = useRef<UFOExperience>(
		new UFOExperience(`${primaryProduct}-post-query-results-load`, {
			type: ExperienceTypes.Experience,
			performanceType: ExperiencePerformanceTypes.InlineResult,
			platform: { component: 'search-dialog' },
		}),
	);

	const onPreQueryStart = useCallback(() => {
		ufoPreQueryLoadExperience.current.start();
	}, []);

	const onPreQuerySuccess = useCallback(() => {
		ufoPreQueryLoadExperience.current.success({
			metadata: { isQuickFind: true },
		});
	}, []);

	const onPreQueryFailure = useCallback(() => {
		ufoPreQueryLoadExperience.current.failure();
	}, []);

	const onPostQueryStart = useCallback(() => {
		const restartMetric = async () => {
			await ufoPostQueryLoadExperience.current.abort();
			await ufoPostQueryLoadExperience.current.start();
		};
		restartMetric();
	}, []);

	const onPostQuerySuccess = useCallback(
		(duration: number, hasThirdPartyResults: boolean) => {
			incrementQueryVersion();

			ufoPostQueryLoadExperience.current.success({
				metadata: {
					isQuickFind: true,
					isHello,
					use3PResults: is3PinQFEnabled,
					hasThirdPartyResults,
				},
			});

			fireAnalyticsEvent(
				onSearchRequestCompleted({
					attributes: {
						...commonAttributes,
						postQueryRequestDurationMs: duration,
						hasThirdPartyResults,
					},
					nonPrivacySafeAttributes,
				}),
			);
		},
		[
			commonAttributes,
			fireAnalyticsEvent,
			incrementQueryVersion,
			nonPrivacySafeAttributes,
			isHello,
			is3PinQFEnabled,
		],
	);

	const onPostQueryFailure = useCallback(() => {
		ufoPostQueryLoadExperience.current.failure();
	}, []);

	const onPostQueryCancel = useCallback(() => {
		ufoPostQueryLoadExperience.current.abort();
	}, []);

	return {
		onPreQueryStart,
		onPreQuerySuccess,
		onPreQueryFailure,
		onPostQueryStart,
		onPostQuerySuccess,
		onPostQueryFailure,
		onPostQueryCancel,
	};
};
