import gql from 'graphql-tag';

export const CurrentSpaceQuery = gql`
	query CurrentSpaceQuery($spaceKey: String) {
		space(key: $spaceKey) {
			id
			name
			icon {
				path(type: ABSOLUTE)
			}
		}
	}
`;
