import { getRecentSearches, RECENT_SEARCH_KEY } from '@atlassian/search-common';

import { type PrimaryProductKey } from '../../../../common/constants/products';

export const getRecentQueries = (primaryProduct: PrimaryProductKey, userId: string) => {
	const recentSearches = getRecentSearches(userId, primaryProduct, RECENT_SEARCH_KEY);
	return recentSearches.map(mapEssentialData);
};

export const mapEssentialData = (query: string) => ({
	id: query,
	title: query,
	data: query,
	type: 'recent-query' as const,
});
