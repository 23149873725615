import React, { useCallback } from 'react';

import { FormattedMessage, type MessageDescriptor } from 'react-intl-next';

import CompassIcon from '@atlaskit/icon/core/compass';
import { Anchor, Box, xcss } from '@atlaskit/primitives';
import { N900 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import type { State } from '../../../../../common/utils/quick-find/state';
import { type QuickFindFooterEvent } from '../../../../../controllers/quick-find/utils';
import { useQuickFindQuery } from '../../../../../controllers/store/quick-find';

import { messages } from './messages';

const styles = xcss({ height: '100%', display: 'inline-block' });
const singleSiteContainer = xcss({
	padding: 'space.100',
});

const iconContainer = xcss({
	marginLeft: 'space.150',
	marginRight: 'space.150',
});

const labelContainer = xcss({
	marginRight: 'space.100',
	marginLeft: 'space.050',
	color: 'color.text',
	':hover': {
		color: 'color.text',
	},
	':focus': {
		color: 'color.text',
	},
});

const styleLink = xcss({
	WebkitBoxAlign: 'baseline',
	alignItems: 'center',
	boxSizing: 'border-box',
	display: 'inline-flex',
	justifyContent: 'center',
	fontWeight: 'font.weight.medium',
	textAlign: 'center',
	whiteSpace: 'nowrap',
	border: `2px solid ${token('color.border', N900)}`,
	borderRadius: '4px',
	paddingTop: 'space.025',
	paddingBottom: 'space.025',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	marginRight: 'space.100',
	color: 'color.text.subtle',
	textDecoration: 'none',
	transition: 'background 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	':hover': {
		color: 'color.text.subtle',
		backgroundColor: 'color.background.neutral.hovered',
		textDecoration: 'none',
		transition: 'background 0s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	},
	':visited': {
		color: 'color.text.subtle',
	},
	':focus-visible': {
		color: 'color.text.subtle',
	},
	':focus': {
		color: 'color.text.subtle',
	},
});

type SearchConfiguration = {
	href: string;
	message: MessageDescriptor;
	actionSubjectId: string;
};

enum JiraFooterLinkActionSubjectId {
	JIRA_BOARDS = 'jiraBoardsSearchLink',
	JIRA_FILTERS = 'jiraFiltersSearchLink',
	JIRA_PROJECTS = 'jiraProjectsSearchLink',
	JIRA_PLANS = 'jiraPlansSearchLink',
	JIRA_PEOPLE = 'jiraPeopleSearchLink',
}

export const getSearchConfiguration: (query: string) => { [key: string]: SearchConfiguration } = (
	query,
) => {
	const allLinks: { [key: string]: SearchConfiguration } = {
		board: {
			href: `/secure/ManageRapidViews.jspa?contains=${query}`,
			message: messages.jira_quick_find_boards,
			actionSubjectId: JiraFooterLinkActionSubjectId.JIRA_BOARDS,
		},
		project: {
			href: `/projects?contains=${query}`,
			message: messages.jira_quick_find_projects,
			actionSubjectId: JiraFooterLinkActionSubjectId.JIRA_PROJECTS,
		},
		filters: {
			href: `/secure/ManageFilters.jspa?Search=Search&filterView=search&name=${query}`,
			message: messages.jira_quick_find_filters,
			actionSubjectId: JiraFooterLinkActionSubjectId.JIRA_FILTERS,
		},
		plans: {
			href: `/jira/plans`,
			message: messages.jira_quick_find_plans,
			actionSubjectId: JiraFooterLinkActionSubjectId.JIRA_PLANS,
		},
		people: {
			href: `/jira/people/search?q=${query}`,
			message: messages.jira_quick_find_people,
			actionSubjectId: JiraFooterLinkActionSubjectId.JIRA_PEOPLE,
		},
	};

	return allLinks;
};

type Props = {
	state: State;
	onClick: (props: QuickFindFooterEvent) => void;
};

export const JiraSingleSiteSearch = ({ state, onClick }: Props) => {
	const quickFindQuery = useQuickFindQuery();

	const searchConfiguration = getSearchConfiguration(quickFindQuery);

	const onFilterClick = useCallback(
		(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, actionSubjectId: string) => {
			onClick({ state, event, actionSubjectId });
		},
		[onClick, state],
	);

	return (
		<Box xcss={singleSiteContainer}>
			<Box as="span" xcss={iconContainer}>
				{/* For now we are using CompassIcon until a custom navigation icon is made for this */}
				<CompassIcon label="navigate-single-site-icon" />
			</Box>
			<Box as="span" xcss={labelContainer}>
				<FormattedMessage {...messages.jira_quick_find_single_site_label} />
			</Box>
			{Object.keys(searchConfiguration).map((key) => {
				const { href, message, actionSubjectId } = searchConfiguration[key];
				return (
					<Box key={`single_site_${key}`} role="none" xcss={styles}>
						<Anchor
							xcss={styleLink}
							href={href}
							onClick={(event) => onFilterClick(event, actionSubjectId)}
						>
							<FormattedMessage {...message} />
						</Anchor>
					</Box>
				);
			})}
		</Box>
	);
};
