import React, { type FC, useMemo } from 'react';
import { type WrappedComponentProps, injectIntl } from 'react-intl-next';
import { useDefaultSuppliers } from '../..';
import { messages } from './messages';
import { messages as commonMessages } from '../../../messages';
import { AdvancedSearch } from '../../advanced-search/advanced-search';
import { SearchDialogProduct } from '../../product-router';
import { type SearchResult } from '../../product-router/product/result-types';
import type { ExtensibleTabProps } from '../extensible-tab-types';
import { useBitbucketPermissionSupplier } from './bitbucket-permission-supplier';
import {
	type AggregatorBitbucketResponse,
	type BitbucketFeatures,
	type BitbucketRepositoryResponse,
	BitbucketScope,
	type BitbucketURLGenerators,
	BITBUCKET_PRODUCT_ID,
	type BitbucketContext,
} from './types';
import { bitbucketURLGenerators } from './url-generators';

const mapAggregatorResponseToSearchItem = (repo: BitbucketRepositoryResponse): SearchResult => {
	return {
		title: repo.name,
		id: repo.uuid,
		meta: repo.workspace.name,
		url: repo.links.html.href,
		iconUrl: repo.links.avatar.href,
		containerId: repo.workspace.uuid,
	};
};

export interface BitbucketTabProps extends ExtensibleTabProps, Partial<BitbucketURLGenerators> {
	features?: BitbucketFeatures;
	currentContext?: BitbucketContext;
}

export const BitbucketTabWithIntl: React.FC<WrappedComponentProps & BitbucketTabProps> = ({
	intl,
	viewAllLinkGenerator: overriddenViewAllLinkGenerator,
	codeSearchUrlGenerator: overriddenCodeSearchUrlGenerator,
	codeSearchContextualUrlGenerator: overriddenCodeSearchContextualUrlGenerator,
	urlGeneratorForNoResultsScreen: overriddenUrlGeneratorForNoResultsScreen,
	permissionSupplier: overriddenPermissionsSupplier,
	preQueryItemSupplier,
	postQueryItemSupplier,
	currentContext,
	features,
	...rest
}) => {
	const {
		viewAllLinkGenerator,
		codeSearchUrlGenerator,
		codeSearchContextualUrlGenerator,
		urlGeneratorForNoResultsScreen,
	}: BitbucketURLGenerators = useMemo(() => {
		return bitbucketURLGenerators({
			viewAllLinkGenerator: overriddenViewAllLinkGenerator,
			codeSearchUrlGenerator: overriddenCodeSearchUrlGenerator,
			codeSearchContextualUrlGenerator: overriddenCodeSearchContextualUrlGenerator,
			urlGeneratorForNoResultsScreen: overriddenUrlGeneratorForNoResultsScreen,
		});
	}, [
		overriddenCodeSearchUrlGenerator,
		overriddenCodeSearchContextualUrlGenerator,
		overriddenUrlGeneratorForNoResultsScreen,
		overriddenViewAllLinkGenerator,
	]);

	const sections = [
		{
			id: BitbucketScope.BitbucketRepostitory,
			title: intl.formatMessage(messages.bitbucket_repository_heading),
			scope: BitbucketScope.BitbucketRepostitory,
			showResultCount: true,
			viewAllLinkGenerator,
			resultMapper: ({ results }: AggregatorBitbucketResponse): SearchResult[] =>
				results.map((value) => mapAggregatorResponseToSearchItem(value)),
		},
	];

	const itemSuppliers = useDefaultSuppliers<AggregatorBitbucketResponse, BitbucketScope>(
		BITBUCKET_PRODUCT_ID,
		sections,
		{
			preQueryItemSupplier,
			postQueryItemSupplier,
		},
	);

	const permissionSupplier = useBitbucketPermissionSupplier(overriddenPermissionsSupplier);

	const formattedSearchForCodeLabel = (context?: BitbucketContext) => {
		const { repository, workspace } = context || {};

		let source = 'Bitbucket';
		if (repository) {
			source = repository.name;
		} else if (workspace) {
			source = workspace.name;
		}

		return intl.formatMessage(messages.bitbucket_advanced_search, {
			source: source,
		});
	};

	return (
		<SearchDialogProduct
			expandedStateInputPlaceholder={intl.formatMessage(
				messages.bitbucket_expanded_input_placeholder,
			)}
			generateAdvancedSearchUrl={viewAllLinkGenerator}
			id={BITBUCKET_PRODUCT_ID}
			permissionSupplier={permissionSupplier}
			preQuerySectionTitleGenerator={(section) =>
				section.id === BitbucketScope.BitbucketRepostitory
					? intl.formatMessage(messages.bitbucket_recent_repositories)
					: ''
			}
			sections={sections}
			title={intl.formatMessage(commonMessages.bitbucket_tab_label)}
			urlGeneratorForNoResultsScreen={urlGeneratorForNoResultsScreen}
			{...rest}
			{...itemSuppliers}
		>
			{({ linkComponent, query }) => {
				const codeSearchUrl = currentContext
					? codeSearchContextualUrlGenerator(query, currentContext)
					: codeSearchUrlGenerator(query);

				const contextualSearchMessage = formattedSearchForCodeLabel(currentContext);

				return {
					Header: () => (
						<AdvancedSearch
							advancedSearchMessage={contextualSearchMessage}
							advancedSearchUrl={codeSearchUrl}
							linkComponent={linkComponent}
						/>
					),
					Footer: () => <></>,
				};
			}}
		</SearchDialogProduct>
	);
};

/**
 * The canonical implementation of a tab to show Bitbucket search results inside of the search dialog
 *
 * Can be imported across multiple Atlassian products and will show Bitbucket results there
 */
export const BitbucketTab: FC<BitbucketTabProps> = injectIntl(BitbucketTabWithIntl);
