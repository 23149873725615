import type { PropsWithChildren } from 'react';
import React, { useCallback } from 'react';

import { FabricChannel } from '@atlaskit/analytics-listeners';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsListener } from '@atlaskit/analytics-next';

import type { SearchExperimentLayer } from '@atlassian/search-experiment';

import { fg } from '@confluence/feature-gating';

type ExperimentLayersAnalyticsListenerProps = {
	experimentLayers?: SearchExperimentLayer[];
};

/**
 * This analytics listener mutates searchResults shown postQuerySearchResults
 * analytics events fired from Quick Search. It replaces the abTest attribute
 * with the backendExperiments attribute.
 *
 * This code exists here rather than in platform code as this logic is specific
 * to the Confluence product.
 */
export const ExperimentLayersAnalyticsListener = ({
	experimentLayers,
	children,
}: PropsWithChildren<ExperimentLayersAnalyticsListenerProps>) => {
	const handleEvent = useCallback(
		(event: UIAnalyticsEvent) => {
			// Do not update the event if dynamic config is not being used
			if (!fg('confluence_search-scalable-statsig-layers')) {
				return;
			}

			const action = event.payload.action;
			const actionSubject = event.payload.actionSubject;
			const actionSubjectId = event.payload.actionSubjectId;
			const isQuickFind = event.payload.attributes?.isQuickFind;

			// Do not update the event if its not a searchResults shown event on Quick Search
			if (
				actionSubject !== 'searchResults' ||
				action !== 'shown' ||
				actionSubjectId !== 'postQuerySearchResults' ||
				isQuickFind
			) {
				return;
			}

			event.update((payload) => {
				return {
					...payload,
					attributes: {
						...payload.attributes,
						backendExperiments: {
							experimentLayers,
						},
						abTest: undefined,
					},
				};
			});
		},
		[experimentLayers],
	);

	return (
		<AnalyticsListener onEvent={handleEvent} channel={FabricChannel.elements}>
			{children}
		</AnalyticsListener>
	);
};
