import { useCallback, useState } from 'react';

interface TimerState {
	startTime?: Date;
	endTime?: Date;
	status: 'idle' | 'running' | 'finished' | 'cancelled';
	milliseconds?: number;
}

/**
 * An idempotent React hook used to measure the time between two events. Only the very first start()
 * and end() events are used to measure the duration, any other events are ignored. If the timer is
 * cancelled, the duration is not measured.
 */
export function useTimer({ onTimerEnd }: { onTimerEnd?: (milliseconds: number) => void }) {
	const [timer, setTimer] = useState<TimerState>({ status: 'idle' });

	const start = useCallback(() => {
		setTimer((state) => {
			if (state.status !== 'idle') {
				return state;
			}

			return { ...state, startTime: new Date(), status: 'running' };
		});
	}, []);

	const stop = useCallback(() => {
		setTimer((state) => {
			if (state.status !== 'running') {
				return state;
			}

			const end = new Date().getTime() - (timer.startTime?.getTime() ?? 0);
			onTimerEnd?.(end);
			return { ...state, endTime: new Date(), milliseconds: end, status: 'finished' };
		});
	}, [onTimerEnd, timer.startTime]);

	const cancel = useCallback(() => {
		setTimer((state) => {
			if (state.status !== 'running') {
				return state;
			}

			return { ...state, status: 'cancelled' };
		});
	}, []);

	return {
		start,
		stop,
		cancel,
		status: timer.status,
		milliseconds: timer.milliseconds,
	};
}
