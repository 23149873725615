import uuid from 'uuid/v4';

/**
 * Measure the duration of an asynchronous function. There is no error handling hence if the
 * function throws an error, it will be thrown to the caller.
 * @param asyncFunction
 * @returns
 */
export async function measureAsyncFunction<T>(
	asyncFunction: () => Promise<T>,
): Promise<{ result: T; duration: number }> {
	const markName = uuid();

	performance.mark(`${markName}-start`);
	const result = await asyncFunction();
	performance.mark(`${markName}-end`);
	performance.measure(markName, `${markName}-start`, `${markName}-end`);

	const measure = performance.getEntriesByName(markName)[0];
	const duration = measure.duration;

	// Clear the marks and measure to avoid memory leaks
	performance.clearMarks(`${markName}-start`);
	performance.clearMarks(`${markName}-end`);
	performance.clearMeasures(markName);

	return { result, duration };
}
