import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	jira_quick_find_single_site_label: {
		id: 'jira_single_site_label',
		defaultMessage: 'Navigate to',
		description:
			'Label for when the user wants to navigate to a single site on Jira via the search query',
	},
	jira_quick_find_projects: {
		id: 'jira_single_site_projects',
		defaultMessage: 'Projects',
		description: 'Plural of project',
	},
	jira_quick_find_boards: {
		id: 'jira_single_site_boards',
		defaultMessage: 'Boards',
		description: 'Plural of board',
	},
	jira_quick_find_filters: {
		id: 'jira_single_site_filters',
		defaultMessage: 'Filters',
		description: 'Plural of filter',
	},
	jira_quick_find_plans: {
		id: 'jira_single_site_plans',
		defaultMessage: 'Plans',
		description: 'Plural of plan',
	},
	jira_quick_find_people: {
		id: 'jira_single_site_people',
		defaultMessage: 'People',
		description: 'Plural of person',
	},
});
