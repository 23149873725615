import { useIntl } from 'react-intl-next';
import React, { useCallback, useMemo } from 'react';
import { messages } from './messages';
import { messages as commonMessages } from '../../../messages';
import {
	JIRA_PRODUCT_ID,
	type JiraCollabGraphConfig,
	JiraFilters,
	JiraScope,
	type CurrentProject,
	type CurrentUser,
	type FilterValueGetters,
} from './types';
import {
	type FilterDefinition,
	type FilterLoader,
	FilterUIVariant,
	type GenericFilterOption,
	useFilterStore,
	useSiteFilterDefinition,
} from '../../filters';
import { useFiltersClient } from './filters-client';
import { FilterOptionItem } from '../../product-router/product/result-provider/result-renderer/filter-section';
import { type ExtensibleTabProps } from '../extensible-tab-types';
import { FilterOptionSource } from '../../../common/filters/types';
import { FilterColLabText } from './filters.styled';

export const getProjectFilterOptions = (
	projects: GenericFilterOption[],
	project?: CurrentProject,
) => {
	if (!project) {
		return projects;
	}
	const newOption = {
		...project,
		isChecked: false,
		isVisible: true,
		filterSource: FilterOptionSource.EXTERNAL,
	};

	const existingItem = projects.find((s) => s.id === project.id);
	if (!existingItem?.isVisible) {
		const lastVisibleItemIdx = projects.findIndex((s) => !s.isVisible) - 1;
		if (lastVisibleItemIdx > 0) {
			projects[lastVisibleItemIdx].isVisible = false;
		}
	}
	return [newOption].concat(projects.filter((s) => s.id !== project.id));
};

export const getAssigneeFilterOptions = (
	assignees: GenericFilterOption[],
	currentUser?: CurrentUser,
) => {
	if (!currentUser) {
		return assignees;
	}
	const newOption: GenericFilterOption = {
		id: currentUser.id,
		label: currentUser.name,
		avatarUrl: currentUser.avatarUrl,
		isChecked: false,
		isVisible: true,
		filterSource: FilterOptionSource.CURRENT_USER,
	};

	const existingItem = assignees.find((assignee) => assignee.id === currentUser.id);
	if (!existingItem?.isVisible) {
		const lastVisibleItemIdx = assignees.findIndex((assignee) => !assignee.isVisible) - 1;
		if (lastVisibleItemIdx > 0) {
			assignees[lastVisibleItemIdx].isVisible = false;
		}
	}
	return [newOption].concat(assignees.filter((assignee) => assignee.id !== currentUser.id));
};

export const useFilters = (
	rest: ExtensibleTabProps,
	shouldShowIssueLabelsFilter: boolean,
	config?: JiraCollabGraphConfig,
	currentProject?: CurrentProject,
	currentUser?: CurrentUser,
	isReportedByMeFilterEnabled?: boolean,
) => {
	const { formatMessage } = useIntl();

	const {
		getFilterItemsByJiraScope,
		getProjectOptions,
		fetchIssueLabelOptions,
		statusFilterOptions,
	} = useFiltersClient(config);

	const siteFilterDefinition = useSiteFilterDefinition(rest.workspaces || [], JIRA_PRODUCT_ID);

	const projectsFilterStore = useFilterStore<GenericFilterOption>(
		[],
		JIRA_PRODUCT_ID,
		JiraFilters.Projects,
	);

	const assigneesFilterStore = useFilterStore<GenericFilterOption>(
		[],
		JIRA_PRODUCT_ID,
		JiraFilters.Assignees,
	);

	const reportedByFilterStore = useFilterStore<GenericFilterOption>(
		[],
		JIRA_PRODUCT_ID,
		JiraFilters.ReportedBy,
	);

	const statusFilterStore = useFilterStore<GenericFilterOption>(
		statusFilterOptions,
		JIRA_PRODUCT_ID,
		JiraFilters.Status,
	);

	const issueLabelsFilterStore = useFilterStore<GenericFilterOption>(
		[],
		JIRA_PRODUCT_ID,
		JiraFilters.IssueLabels,
	);

	const loadFilters = useCallback(() => {
		projectsFilterStore.loadDefaultFilters(
			getProjectOptions().then((results) => getProjectFilterOptions(results, currentProject)),
		);
		assigneesFilterStore.loadDefaultFilters(
			getFilterItemsByJiraScope(JiraScope.Assignee, '').then((results) =>
				getAssigneeFilterOptions(results, currentUser),
			),
		);
		if (shouldShowIssueLabelsFilter) {
			issueLabelsFilterStore.loadDefaultFilters(fetchIssueLabelOptions(''));
		}

		const defaultReportByOption =
			currentUser && currentUser.id
				? {
						id: currentUser.id,
						label: formatMessage(messages.reported_by_me_filter_option),
						avatarUrl: '',
						isChecked: false,
						isVisible: true,
						filterSource: FilterOptionSource.CURRENT_USER,
					}
				: undefined;
		if (defaultReportByOption && isReportedByMeFilterEnabled) {
			reportedByFilterStore.setDefaultFilters([defaultReportByOption]);
		}
	}, [
		projectsFilterStore,
		getProjectOptions,
		assigneesFilterStore,
		getFilterItemsByJiraScope,
		shouldShowIssueLabelsFilter,
		currentUser,
		formatMessage,
		isReportedByMeFilterEnabled,
		currentProject,
		issueLabelsFilterStore,
		fetchIssueLabelOptions,
		reportedByFilterStore,
	]);

	const searchProject: FilterLoader = useCallback(
		async (query: string) => {
			const result = await getFilterItemsByJiraScope(JiraScope.Project, query);

			return (
				result?.map((item: GenericFilterOption) => ({
					value: item,
					label: (
						<FilterOptionItem
							label={item.label}
							iconProps={{
								src: item.avatarUrl,
								appearance: 'square',
							}}
						/>
					),
				})) || []
			);
		},
		[getFilterItemsByJiraScope],
	);

	const searchAssignee: FilterLoader = useCallback(
		async (query: string) => {
			const result = await getFilterItemsByJiraScope(JiraScope.Assignee, query);

			return (
				result?.map((item: GenericFilterOption) => ({
					value: item,
					label: <FilterOptionItem label={item.label} iconProps={{ src: item.avatarUrl }} />,
				})) || []
			);
		},
		[getFilterItemsByJiraScope],
	);

	const searchIssueLabels: FilterLoader = useCallback(
		async (query: string) => {
			if (shouldShowIssueLabelsFilter) {
				const result = await fetchIssueLabelOptions(query);

				return (
					result?.map((item: GenericFilterOption) => ({
						value: item,
						label: item.label,
					})) || []
				);
			}
			return [];
		},
		[fetchIssueLabelOptions, shouldShowIssueLabelsFilter],
	);

	const defaultValueGetter: (
		availableFilters: GenericFilterOption[],
		valueKey?: keyof GenericFilterOption,
	) => string[] = useCallback(
		(availableFilters, valueKey = 'id') =>
			availableFilters
				.filter((f: GenericFilterOption) => f.isChecked && f[valueKey])
				.map((f: GenericFilterOption) => f[valueKey] as string),
		[],
	);

	const appliedReportedByFilter = reportedByFilterStore?.availableFilters?.find(
		(f) => f.isChecked,
	)?.id;

	const getReportedByMe = useCallback(() => {
		return appliedReportedByFilter
			? {
					'@type': JiraFilters.ReportedBy,
					accountIds: [appliedReportedByFilter],
				}
			: null;
	}, [appliedReportedByFilter]);

	const reportedByDefinition: FilterDefinition<GenericFilterOption>[] = isReportedByMeFilterEnabled
		? [
				{
					['@type']: JiraFilters.ReportedBy,
					fieldName: 'accountIds',
					store: reportedByFilterStore,
					loadFilterOptions: async () => [],
					sectionLabel: formatMessage(messages.report_by_filters_title),
					findMoreLabel: '',
					mapSelectionToAppliedValue: getReportedByMe,
					filterConfig: {
						hideIcon: true,
						usePreloadedOptions: true,
						labelComponent: FilterColLabText,
					},
				},
			]
		: [];

	const appliedIssueLabelsFilters = useMemo(
		() => defaultValueGetter(issueLabelsFilterStore.availableFilters, 'label'),
		[defaultValueGetter, issueLabelsFilterStore.availableFilters],
	);

	const getAppliedIssueLabels = useCallback(() => {
		if (appliedIssueLabelsFilters.length > 0) {
			return {
				'@type': JiraFilters.IssueLabels,
				issueLabels: appliedIssueLabelsFilters,
			};
		}
		return null;
	}, [appliedIssueLabelsFilters]);

	const issueLabelsFilterDefinition: FilterDefinition<GenericFilterOption>[] =
		shouldShowIssueLabelsFilter
			? [
					{
						['@type']: JiraFilters.IssueLabels,
						uiVariant: FilterUIVariant.MULTI_SELECT,
						fieldName: 'issueLabels',
						store: issueLabelsFilterStore,
						loadFilterOptions: searchIssueLabels,
						sectionLabel: formatMessage(messages.issue_labels_filters_title),
						findMoreLabel: '',
						placeholderText: formatMessage(messages.issue_labels_filters_placeholder),
						mapSelectionToAppliedValue: getAppliedIssueLabels,
						filterConfig: {
							mainGroupLabel: formatMessage(messages.issue_labels_filters_options_group_label),
						},
					},
				]
			: [];

	const filtersDefinition: FilterDefinition<GenericFilterOption>[] = [
		...siteFilterDefinition,
		{
			['@type']: JiraFilters.Projects,
			fieldName: 'projectIds',
			store: projectsFilterStore,
			loadFilterOptions: searchProject,
			sectionLabel: formatMessage(commonMessages.project_filters_title),
			findMoreLabel: formatMessage(commonMessages.project_filters_find_more),
			filterConfig: {
				filterOptionIconShape: 'square',
			},
		},
		{
			['@type']: JiraFilters.Assignees,
			fieldName: 'accountIds',
			store: assigneesFilterStore,
			loadFilterOptions: searchAssignee,
			sectionLabel: formatMessage(commonMessages.assignee_filters_title),
			findMoreLabel: formatMessage(commonMessages.assignee_filters_find_more),
			filterConfig: {
				filterOptionIconShape: 'circle',
			},
		},
		...reportedByDefinition,
		{
			['@type']: JiraFilters.Status,
			fieldName: 'binaryStatusCategories',
			store: statusFilterStore,
			loadFilterOptions: async () => [],
			sectionLabel: formatMessage(commonMessages.binary_status_category_filters_title),
			findMoreLabel: '',
			filterConfig: {
				filterGroupedBy: 'row',
				hideIcon: true,
				usePreloadedOptions: true,
			},
		},
		...issueLabelsFilterDefinition,
	];

	const filterValueGetters: FilterValueGetters = useMemo(
		() => ({
			projects: () => defaultValueGetter(projectsFilterStore.availableFilters),
			assignees: () => defaultValueGetter(assigneesFilterStore.availableFilters),
			statuses: () => defaultValueGetter(statusFilterStore.availableFilters),
			reportedBy: () => defaultValueGetter(reportedByFilterStore.availableFilters),
			issueLabels: () => (shouldShowIssueLabelsFilter ? appliedIssueLabelsFilters : []),
		}),
		[
			defaultValueGetter,
			projectsFilterStore.availableFilters,
			assigneesFilterStore.availableFilters,
			statusFilterStore.availableFilters,
			reportedByFilterStore.availableFilters,
			appliedIssueLabelsFilters,
			shouldShowIssueLabelsFilter,
		],
	);

	return {
		filterValueGetters,
		filtersDefinition,
		loadFilters,
	};
};
