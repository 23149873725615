import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	/**
	 * Confluence reactivation experiment tab nudge
	 */
	never_active_user: {
		id: 'product_search_dialog.confluence.confluence_reactivation_experiment.never_active_user',
		defaultMessage: 'Free access',
		description:
			'Lozenge next to Confluence tab title indicating the user (who has never been active in Confluence) has free access to Confluence',
	},
	inactive_user: {
		id: 'product_search_dialog.confluence.confluence_reactivation_experiment.inactive_user',
		defaultMessage: 'More results',
		description:
			'Lozenge next to Confluence tab title indicating the user (who has been inactive in Confluence) has more results in Confluence',
	},
});
