import React, { useContext, type PropsWithChildren } from 'react';
import { type ParsedUrlQuery } from '../../common/shared/types';

type Props = PropsWithChildren<{
	queryParams?: ParsedUrlQuery;
}>;

const Context = React.createContext<{ queryParams?: ParsedUrlQuery }>({
	queryParams: undefined,
});

export const QueryParamsProvider = ({ queryParams, children }: Props) => {
	return <Context.Provider value={{ queryParams }}>{children}</Context.Provider>;
};
export const useQueryParams = () => {
	return useContext(Context);
};
