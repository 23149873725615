import React, { useEffect } from 'react';
import { useProductContext } from '../../../extensible/product-router';
import { onShown, type OnShownProps } from '../events';
import { withAnalytics } from '../with-analytics';

interface Props {
	isExpanded: boolean;
	onDialogShown: (props: OnShownProps) => any;
}

export const DialogShownHandlerComponent: React.FC<Props> = ({ isExpanded, onDialogShown }) => {
	const { products } = useProductContext();

	useEffect(() => {
		const emitAnalyticsEvent = () => {
			const visibleProducts = products.map((product) => product.title);
			const visibleTabsCount = visibleProducts.length > 1 ? products.length : 0;
			onDialogShown({ visibleProducts, visibleTabsCount });
		};

		isExpanded && emitAnalyticsEvent();
	}, [isExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

	return <></>;
};

export const DialogShownHandler = withAnalytics({
	onDialogShown: onShown,
})(DialogShownHandlerComponent);
