import React from 'react';

import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';

import { AddAttributesWrapper } from './AddAttributesWrapper';

export const AddActivationIdWrapper = ({
	channel,
	targetActionSubjects,
	children,
}: {
	channel?: string;
	targetActionSubjects?: string[];
	children: React.ReactNode;
}) => {
	const { activationId } = useSessionData();
	const eventUpdater = (payload: AnalyticsEventPayload): AnalyticsEventPayload => {
		let data = payload.data || payload;
		if (
			!targetActionSubjects ||
			targetActionSubjects.includes(data.actionSubjectId) ||
			targetActionSubjects.includes(data.actionSubject)
		) {
			data = {
				...data,
				attributes: {
					...data.attributes,
					activationId,
				},
			};
		}
		if (payload.data) {
			if (payload.data !== data) {
				return {
					...payload,
					data,
				};
			}
			return payload;
		}
		return data;
	};
	return (
		<AddAttributesWrapper eventUpdater={eventUpdater} channel={channel || '*'}>
			{children}
		</AddAttributesWrapper>
	);
};
