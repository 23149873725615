import React from 'react';
import EmojiFrequentIcon from '@atlaskit/icon/core/migration/clock--emoji-frequent';
import { N30, N600 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.span({
	background: token('color.background.neutral', N30),
	height: token('space.300', '24px'),
	borderRadius: token('border.radius.400', '4px'),
	border: "'1px solid transparent'",
});

export const QuickFindIcon = () => (
	<IconContainer>
		<EmojiFrequentIcon
			color={token('color.icon.accent.gray', N600)}
			LEGACY_size="medium"
			spacing="spacious"
			label="recent-search"
		/>
	</IconContainer>
);
