import type { ScopedAggregatorResponse } from '../../../common/clients';
import type { SearchResult } from '../../product-router/product/result-types';
import type { ExtensibleTabProps } from '../extensible-tab-types';
import type { WithTrelloFooterProps } from './trello-footer';

export const TRELLO_PRODUCT_ID = 'trello';

export enum TrelloScope {
	Board = 'trello.board',
	Card = 'trello.card',
}

export interface TrelloOrganization {
	id: string;
	name: string;
	displayName: string;
}

export interface TrelloBoardPrefs {
	backgroundImage?: string;
	backgroundColor?: string;
	backgroundImageScaled?: Array<{
		width: number;
		height: number;
		url: string;
	}> | null;
}

export interface TrelloBoard {
	id: string;
	idOrganization?: string | null;
	name: string;
	url: string;
	closed?: boolean;
	prefs: TrelloBoardPrefs;
	organization?: TrelloOrganization | null;
}

export interface TrelloCardList {
	id: string;
	name: string;
}
export interface TrelloCard {
	id: string;
	idBoard: string;
	name: string;
	url: string;
	closed: boolean;
	dateLastActivity?: string | null;
	board: Pick<TrelloBoard, 'id' | 'name' | 'url'>;
	list: TrelloCardList;
}

export type TrelloSearchResult = SearchResult & {
	closed?: boolean | null;
	prefs?: TrelloBoardPrefs;
};

export interface AggregatorTrelloResponse extends ScopedAggregatorResponse<TrelloScope> {
	results: TrelloBoard[] | TrelloCard[];
}

export interface ExtensibleTrelloTabFeatures {
	isPreviewOnTrelloSearchResultsEnabled?: boolean;
	isColorBlindModeEnabled?: boolean;
	isCardResultsSectionFirst?: boolean;
}

export interface TrelloTabProps extends ExtensibleTabProps, WithTrelloFooterProps {
	features?: ExtensibleTrelloTabFeatures;
	resultOnClick?: () => void;
}
