/* eslint-disable @repo/internal/react/no-class-components */
import React, { type ErrorInfo } from 'react';

interface ErrorBoundaryProps {
	children: React.ReactNode;
	fallback?: React.ReactNode;
	onError?: (error: Error) => void;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(_: Error): ErrorBoundaryState {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.props.onError && this.props.onError(error);
	}

	render() {
		if (this.state.hasError) {
			return this.props.fallback ? <>{this.props.fallback}</> : null;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
