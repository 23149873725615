import { useCallback, useRef, useEffect, useState } from 'react';
import { type FilterOption, FilterOptionSource } from '../../common/filters/types';
import {
	addFilters as addFiltersAction,
	replaceAllFilters as replaceAllFiltersAction,
	updateFilterState,
	useFilter,
} from '../../common/filters/use-filters';
import {
	type AddFilters,
	type UpdateFilter,
	type UpdateSingleValueFilter,
	type ClearEnabledFilter,
	type ResetFilterState,
	type FilterStore,
} from './filter-types';
import { useSessionUserInput } from '../user-input-provider';

export const useFilterStore = <T extends FilterOption>(
	defaultFilters: T[] = [],
	productId: string,
	filterType: string,
): FilterStore<T> => {
	const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
	const { stickySearchEnabled, readFilters, storeFilters } = useSessionUserInput();
	const storedFilters = readFilters(productId) ? readFilters(productId)[filterType] : [];
	const defaultFiltersRef = useRef<T[]>(
		stickySearchEnabled && storedFilters && storedFilters.length > 0
			? storedFilters
			: defaultFilters,
	);
	const { filters, dispatch } = useFilter<T>(defaultFiltersRef.current);

	const addFilters: AddFilters<T> = useCallback(
		(newFilters) =>
			dispatch(
				addFiltersAction(newFilters, (a, b) => {
					if (
						a.filterSource === FilterOptionSource.CURRENT_USER &&
						b.filterSource !== FilterOptionSource.CURRENT_USER
					) {
						return -1;
					}

					if (
						a.filterSource !== FilterOptionSource.CURRENT_USER &&
						b.filterSource === FilterOptionSource.CURRENT_USER
					) {
						return 1;
					}
					return 0;
				}),
			),
		[dispatch],
	);

	const loadDefaultFilters = (load: Promise<T[]>) => {
		setIsLoading(true);
		load.then((result) => setDefaultFilters(result)).finally(() => setIsLoading(false));
	};

	const updateFilter: UpdateFilter = useCallback(
		(id, isChecked, isVisible = true) => dispatch(updateFilterState(id, isChecked, isVisible)),
		[dispatch],
	);

	const updateSingleValueFilter: UpdateSingleValueFilter = useCallback(
		(id) => {
			filters.forEach((f) => dispatch(updateFilterState(f.id, f.id === id)));
		},
		[filters, dispatch],
	);

	const clearFilter: ClearEnabledFilter = useCallback(() => {
		filters.forEach((f) => dispatch(updateFilterState(f.id, false)));
	}, [filters, dispatch]);

	const resetFilter: ResetFilterState = useCallback(() => {
		dispatch(
			replaceAllFiltersAction(defaultFilters.length ? defaultFilters : defaultFiltersRef.current),
		);
	}, [defaultFilters, dispatch]);

	const setDefaultFilters = useCallback(
		(filters: T[]) => {
			defaultFiltersRef.current = filters;
			resetFilter();
		},
		[defaultFiltersRef, resetFilter],
	);

	useEffect(() => {
		if (stickySearchEnabled) {
			const stickyFilter = readFilters(productId);
			stickyFilter[filterType] = filters;
			storeFilters(productId, stickyFilter);
		}
	}, [stickySearchEnabled, readFilters, storeFilters, productId, filterType, filters]);

	return {
		availableFilters: filters,
		isLoading: isLoading && defaultFilters.length === 0,
		loadDefaultFilters,
		addFilters,
		updateFilter,
		updateSingleValueFilter,
		clearFilter,
		reset: resetFilter,
		dispatch,
		setDefaultFilters,
	};
};
