import React, { useEffect, useState } from 'react';
import { useDialogExpansionContext } from '../../dialog-expansion-context';
import { useQuery } from '../../query-context';

/**
 * The idea of this component is to trigger the fetch of certain bundles which
 * must be available only after the query is entered.
 * This ensures that bundles are loaded only when the user has a high chances of interacting with them i.e. once the user has gone past prequery phase.
 * This preloading ensures that there is no jank when the user interacts with these components.
 */

export const AsyncLoadOnQuery = () => {
	const { query } = useQuery();
	const { isExpanded } = useDialogExpansionContext();

	const [isFetched, setIsFetched] = useState(false);
	useEffect(() => {
		if (query && isFetched === false && isExpanded) {
			import(
				/* webpackPrefetch: true */
				/* webpackChunkName: "@atlaskit-internal_@atlassian/product-search-dialog/smart-user-filter/smart-user-picker" */ '@atlaskit/smart-user-picker'
			);
			import(
				/* webpackPrefetch: true */
				/* webpackChunkName: "@atlaskit-internal_@atlassian/search-dialog/show-more/select" */ '@atlaskit/select/AsyncSelect'
			);
			setIsFetched(true);
		}
	}, [query, isFetched, setIsFetched, isExpanded]);
	return <></>;
};
