import { type UserSearchItem } from '@atlaskit/smart-common';
import { type ActivityItemEdge, type SearchPageNode } from '@atlassian/search-client';

type ErrorItem = {
	id: string;
	type: string;
};

export const isErrorItem = (item: Item): item is ErrorItem => {
	return item.type === 'error';
};

type LandingItem = {
	id: string;
	type: string;
};

export const isLandingItem = (item: Item): item is LandingItem => {
	return item.type === 'landing';
};

type NoResultsItem = {
	id: string;
	type: string;
};

export const isNoResultsItem = (item: Item): item is NoResultsItem => {
	return item.type === 'no-results';
};

type HeadingItem = {
	id: string;
	type: string;
	data: string;
	includeInAnalytics?: boolean;
};

export const isHeadingItem = (item: Item): item is HeadingItem => {
	return item.type === 'heading';
};

type SkeletonItem = {
	id: string;
	type: string;
};

export const isSkeletonItem = (item: Item): item is SkeletonItem => {
	return item.type === 'skeleton';
};

type RecentQueryItem = {
	id: string;
	type: string;
	data: string;
};

export const isRecentQueryItem = (item: Item): item is RecentQueryItem => {
	return item.type === 'recent-query';
};

type RecentActivityItem = {
	id: string;
	type: string;
	data: ActivityItemEdge;
};

export const isRecentActivityItem = (item: Item): item is RecentActivityItem => {
	return item.type === 'recent-activity';
};

type SearchResultItem = {
	id: string;
	type: string;
	data: SearchPageNode;
};

export const isSearchResultItem = (item: Item): item is SearchResultItem => {
	return item.type === 'search-result';
};

type PeopleAndTeamsItem = {
	id: string;
	type: string;
	data: UserSearchItem;
};

export const isPeopleAndTeamsItem = (item: Item): item is PeopleAndTeamsItem => {
	return item.type === 'people-and-teams';
};

export type Item =
	| ErrorItem
	| LandingItem
	| NoResultsItem
	| HeadingItem
	| SkeletonItem
	| RecentQueryItem
	| RecentActivityItem
	| SearchResultItem
	| PeopleAndTeamsItem;
