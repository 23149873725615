import React from 'react';

import { FormattedMessage } from 'react-intl-next';
import { type QuickFindSearchResultType } from '@atlassian/search-client';
import { GenericIconRenderer } from './content-icon-renderer';
import { messages } from '../../messages';
import { getTeamPath, getUserPath } from '@atlassian/search-common';

interface PeopleAndTeamsResult {
	avatarUrl: string;
	entityType: string;
	id: string;
	name: string;
	nickname?: string;
	title?: string;
	type: string;
}

export const getQuickFindPeopleAndTeamsResultMapper = (
	primaryProduct: string,
	orgId: string,
	cloudId: string,
) => {
	return (result: PeopleAndTeamsResult): QuickFindSearchResultType => {
		if (result.type === 'team') {
			return {
				title: result.name,
				id: result.id,
				type: result.type,
				meta: <FormattedMessage {...messages.quick_find_results_team} />,
				url: `${window.location.origin}${getTeamPath(result.id, primaryProduct, orgId, cloudId)}`,
				icon: GenericIconRenderer({ iconUrl: result.avatarUrl }),
			};
		} else {
			return {
				title: result.name,
				id: result.id,
				type: result.type,
				meta: result.title || '',
				url: `${window.location.origin}${getUserPath(result.id, primaryProduct, orgId, cloudId)}`,
				icon: GenericIconRenderer({
					iconUrl: result.avatarUrl,
					appearance: 'circle',
				}),
			};
		}
	};
};
