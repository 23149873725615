import React, { type PropsWithChildren, useCallback, useRef } from 'react';
import { useUserContext } from '../../common/user-context';
import { addRecentSearch, RECENT_SEARCH_KEY } from '@atlassian/search-common';
import { useProductContext } from '../product-router';
import { useQuery } from '../query-context';

export const usePersistRecentSearches = (overrideQuery?: string) => {
	const previousQuery = useRef<string | null>(null);

	const { user } = useUserContext();
	const { query } = useQuery();
	const { getActiveProduct } = useProductContext();
	const product = getActiveProduct();

	const persistRecentSearch = useCallback(() => {
		if (user?.id && product?.id) {
			if (overrideQuery && overrideQuery.trim() !== '') {
				addRecentSearch(overrideQuery, user.id, product.id, RECENT_SEARCH_KEY);
			} else if (previousQuery.current !== query && query.trim() !== '') {
				addRecentSearch(query, user.id, product.id, RECENT_SEARCH_KEY);
				previousQuery.current = query;
			}
		}
	}, [product?.id, query, user.id, overrideQuery]);

	const onClick = useCallback(() => {
		persistRecentSearch();
	}, [persistRecentSearch]);

	const onEnter = useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === 'Enter') {
				persistRecentSearch();
			}
		},
		[persistRecentSearch],
	);

	return [onClick, onEnter] as const;
};

/**
 * This component is used in {@link MetaContextProvider} to intercept
 * clicks and enter hit of any kind (eg - result, filter, feedback, input etc).
 */
export const PersistRecentSearch = ({
	children,
	overrideQuery,
}: PropsWithChildren<{ overrideQuery?: string }>) => {
	const [onClick, onEnter] = usePersistRecentSearches(overrideQuery);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			onClick={onClick}
			onKeyDown={onEnter}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ width: '100%' }}
			data-testid="persist-recent-search"
		>
			{children}
		</div>
	);
};
