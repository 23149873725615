import { type GasPayload } from '@atlaskit/analytics-gas-types';

export const onPreQueryDwelled = ({
	attributes,
	nonPrivacySafeAttributes,
}: {
	attributes: Record<string, any>;
	nonPrivacySafeAttributes: Record<string, any>;
}): GasPayload => ({
	source: 'searchDialog',
	eventType: 'track',
	action: 'dwelled',
	actionSubject: 'searchResults',
	actionSubjectId: 'preQuerySearchResults',
	attributes,
	nonPrivacySafeAttributes,
});
