import type { FC, MutableRefObject } from 'react';
import React, { useEffect, useState } from 'react';

import type { SearchCSS } from '@atlaskit/atlassian-navigation';

/**
 * Components required to compose the extensible cross product search dialog
 */
import type { ConfluenceFeatures, JiraFeatures } from '@atlassian/product-search-dialog';
import {
	BitbucketTab,
	CompassTab,
	ConfluenceFilterContextProvider,
	ExtensibleConfluenceTab,
	JiraFilterContextProvider,
	MultiProductDialog,
	OpsgenieTab,
	TownsquareTab,
	TrelloTab,
	UserInputContextProvider,
	ExtensibleJiraTab,
} from '@atlassian/product-search-dialog';

import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { useFeatureDiscovery } from '@confluence/feature-discovery';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { useSessionData } from '@confluence/session-data';
import { useIsWhiteboardFeatureEnabled } from '@confluence/whiteboard-utils';
import { withRoutesContext } from '@confluence/route-manager';
import { isContentTypeEnabledInCurrentEnv } from '@confluence/content-types-utils';

import { ConfluenceQuickFind } from './ConfluenceQuickFind';
import type { LinkComponent } from './NavigationSearchDialog';
import type { UserContextDataPayload } from './UserContext';
import { useQuickFindEnabled } from './experiment/QuickFindExperiment';
import { ConfluenceResultRenderer } from './suppliers/ResultRenderer';
import { ActivitiesObjectType } from './suppliers/__types__/RecentQuickSearchQuery';
import { useAISearchSupplier } from './suppliers/useAISearchSupplier';
import { useConfluenceItemSuppliers } from './suppliers/useConfluenceItemSuppliers';
import { useConfluenceScopes } from './suppliers/useConfluenceScopes';
import { ExperimentLayersAnalyticsListener } from './ExperimentLayersAnalyticsListener';

interface Features {
	confluenceFeatures?: ConfluenceFeatures;
	jiraFeatures?: JiraFeatures;
	interactiveSkeleton?: {
		onEnter: (e: React.KeyboardEvent) => void;
		placeholder: string;
	};
}

interface Props {
	cloudId: string;
	aggregatorUrl: string;
	formatDate: (lastModified: string) => JSX.Element;
	forwardRef: React.RefObject<HTMLInputElement>;
	isExpanded: boolean;
	linkComponent: LinkComponent;
	onNavigate: (url: string) => void;
	onNavigateSecondary: (url: string) => void;
	setIsExpanded: (expanded: boolean) => void;
	theme?: SearchCSS;
	user: UserContextDataPayload;
	features: Features;
	clearStickySearchRef?: MutableRefObject<() => void>;
	shouldFillContainer?: boolean;
}

const TOWNSQUARE_SMARTS_PRODUCT_IDENTIFIER = 'watermelon';

const COMPASS_PRODUCT_IDENTIFIER = 'compass';

const AI_SEARCH_PREQUERY_BANNER_MESSAGE_ID =
	'confluence.frontend.search.sain.prequery.banner.message';

export const ExtensibleCrossProductSearchDialog: FC<Props> = withRoutesContext((props) => {
	const {
		cloudId,
		aggregatorUrl,
		formatDate,
		isExpanded,
		forwardRef,
		linkComponent,
		onNavigate,
		onNavigateSecondary,
		setIsExpanded,
		theme,
		user,
		features: { jiraFeatures, interactiveSkeleton },
		clearStickySearchRef,
		shouldFillContainer,
		getQueryParams,
	} = props;
	const isNav4Enabled = useIsNav4Enabled();
	const { edition, userId, orgId, locale } = useSessionData();
	const [showPrequeryBanner, setShowPrequeryBanner] = useState(false);
	const [prequeryBannerEligible, prequeryBannerDismissed, prequeryBannerError] =
		useFeatureDiscovery(AI_SEARCH_PREQUERY_BANNER_MESSAGE_ID);
	const { isWhiteboardFeatureEnabled } = useIsWhiteboardFeatureEnabled();
	const isQuickSearchForWhiteboardsEnabled = isWhiteboardFeatureEnabled('whiteboardsEnabled');

	const isQuickSearchForDatabasesEnabled = isContentTypeEnabledInCurrentEnv('database');

	const autocorrectEnabled = locale.split('-')[0] === 'en';

	const [quickFindEnabled] = useQuickFindEnabled();

	const objectTypes: ActivitiesObjectType[] = [
		ActivitiesObjectType.PAGE,
		ActivitiesObjectType.BLOGPOST,
		ActivitiesObjectType.EMBED,
		...(isQuickSearchForWhiteboardsEnabled ? [ActivitiesObjectType.WHITEBOARD] : []),
		...(isQuickSearchForDatabasesEnabled ? [ActivitiesObjectType.DATABASE] : []),
	];

	const { experimentLayers, ...confluenceSuppliers } = useConfluenceItemSuppliers({
		cloudId,
		userId,
		principalId: userId || undefined,
		formatDate,
		autocorrectEnabled,
		isQuickSearchForWhiteboardsEnabled,
		isQuickSearchForDatabasesEnabled,
		objectTypes,
	});

	const dismissPrequeryBanner = () => {
		setShowPrequeryBanner(false);
		void prequeryBannerDismissed();
	};

	const { aiSearchConfig } = useAISearchSupplier({
		prequeryBannerEnabled: showPrequeryBanner,
		dismissPrequeryBanner,
	});

	useEffect(() => {
		if (prequeryBannerEligible && !prequeryBannerError) {
			setShowPrequeryBanner(true);
		}
	}, [prequeryBannerEligible, prequeryBannerError]);

	const onNavigateMultiProduct = (id: string, href: string) => {
		const navigationCallback = id === 'confluence' ? onNavigate : onNavigateSecondary;

		navigationCallback(href);
	};

	const { contentScope, extensibleScopes } = useConfluenceScopes({
		isQuickSearchForWhiteboardsEnabled,
		isQuickSearchForDatabasesEnabled,
	});

	const queryParams = getQueryParams();

	return (
		<UserInputContextProvider
			stickySearchEnabled
			isExpanded={isExpanded}
			resetStickySearchRef={clearStickySearchRef}
		>
			{quickFindEnabled ? (
				<ConfluenceQuickFind
					isExpanded={isExpanded}
					setIsExpanded={setIsExpanded}
					theme={theme}
					user={user}
					forwardRef={forwardRef}
					onNavigateGeneric={onNavigateMultiProduct}
					cloudId={cloudId}
					orgId={orgId}
					aggregatorUrl={aggregatorUrl}
					formatDate={formatDate}
					linkComponent={linkComponent}
					shouldFillContainer={shouldFillContainer}
					isRovoEnabled={quickFindEnabled}
					isGriffinNavEnabled={isNav4Enabled}
					queryParams={queryParams}
				/>
			) : (
				<JiraFilterContextProvider isEnabled={isExpanded}>
					<ConfluenceFilterContextProvider isEnabled={isExpanded}>
						<ExperimentLayersAnalyticsListener experimentLayers={experimentLayers}>
							<MultiProductDialog
								aiSearchConfig={aiSearchConfig}
								aggregatorUrl={aggregatorUrl}
								abTestCloudId={cloudId}
								orgId={orgId}
								isExpanded={isExpanded}
								setIsExpanded={setIsExpanded}
								theme={theme}
								user={user}
								forwardRef={forwardRef}
								onNavigateGeneric={onNavigateMultiProduct}
								dialogFeatures={{
									interactiveSkeleton,
									isGriffinNavEnabled: isNav4Enabled,
								}}
								edition={edition?.toString()}
								shouldFillContainer={shouldFillContainer}
								queryParams={queryParams}
							>
								{({ onRetry }) => (
									<div role="tabpanel">
										<ExtensibleConfluenceTab
											order={0}
											linkComponent={linkComponent}
											formatDate={formatDate}
											onRetry={onRetry}
											permissionSupplier={() => Promise.resolve(extensibleScopes)}
											{...confluenceSuppliers}
											isPrefetchingEnabled
											recentClientConfig={{
												url: CONTEXT_PATH,
												cloudId,
												isUserAnonymous: !userId,
												isCollaborationGraphEnabled: false,
												collaborationGraphUrl: '/gateway/api/collaboration',
											}}
											autocorrectEnabled={autocorrectEnabled}
											additionalContentTypesEnabled
											aiSearchConfig={aiSearchConfig}
											rendererOverride={{
												ConfluenceResultRenderer,
												contentScope,
											}}
										/>
										<ExtensibleJiraTab
											order={1}
											formatDate={formatDate}
											onRetry={onRetry}
											features={jiraFeatures}
										/>
										<BitbucketTab
											order={2}
											onRetry={onRetry}
											features={{
												enableSingleScopesCall: true,
											}}
										/>
										<OpsgenieTab order={3} onRetry={onRetry} cloudId={cloudId} />
										<TownsquareTab
											order={4}
											onRetry={onRetry}
											userTeamConfig={{
												context: {
													contextType: 'filter',
													organizationId: orgId,
													principalId: 'Context',
													productKey: TOWNSQUARE_SMARTS_PRODUCT_IDENTIFIER,
													siteId: cloudId,
												},
											}}
										/>
										<TrelloTab order={5} onRetry={onRetry} />
										<CompassTab
											sourceContext="confluence"
											isUserAnonymous={!userId}
											order={6}
											onRetry={onRetry}
											userTeamConfig={{
												context: {
													contextType: 'CompassFilter',
													organizationId: orgId,
													principalId: 'Context',
													productKey: COMPASS_PRODUCT_IDENTIFIER,
													siteId: cloudId,
												},
											}}
										/>
									</div>
								)}
							</MultiProductDialog>
						</ExperimentLayersAnalyticsListener>
					</ConfluenceFilterContextProvider>
				</JiraFilterContextProvider>
			)}
		</UserInputContextProvider>
	);
});
