import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	errorSearchHeading: {
		id: 'search_dialog_error_search_heading',
		defaultMessage: "We're having trouble loading your recently viewed content.",
		description: 'Heading of message shown when a search error occurs',
	},

	errorSearchBody: {
		id: 'search_dialog_error_search_body',
		defaultMessage:
			'It might just be a hiccup. Please try refreshing the page or <button>try again</button>.',
		description: 'Body of message shown when a search error occurs',
	},
});
