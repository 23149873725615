import React from 'react';
import { type SearchItemsTransformerProps } from '../../../product-router/product/result-provider/result-provider-types';
import { PreQueryFilterTransformer } from './pre-query-filter-transformer';
import { PreQueryRecentlyViewedTransformer } from './pre-query-recently-viewed-transformer';
import { RecentSearchesTransformer } from './recent-searches-transformer';
import { QueryFilterTransformer } from './query-filter-transformer';
import { PostQueryRecentlyViewedTransformer } from './post-query-recently-viewed-transformer';
import { DeduplicateRecentlyViewedAndResultsTransformer } from './deduplicate-recently-viewed-and-results-transformer';

export const QuickFindConfluenceTransformer: React.FC<SearchItemsTransformerProps> = ({
	children,
	...rest
}) => {
	return (
		<PreQueryFilterTransformer {...rest}>
			{(args) => (
				<PreQueryRecentlyViewedTransformer {...args}>
					{(args) => (
						<RecentSearchesTransformer {...args}>
							{(args) => (
								<QueryFilterTransformer {...args}>
									{(args) => (
										<PostQueryRecentlyViewedTransformer {...args}>
											{(args) => (
												<DeduplicateRecentlyViewedAndResultsTransformer {...args}>
													{(args) => children(args)}
												</DeduplicateRecentlyViewedAndResultsTransformer>
											)}
										</PostQueryRecentlyViewedTransformer>
									)}
								</QueryFilterTransformer>
							)}
						</RecentSearchesTransformer>
					)}
				</PreQueryRecentlyViewedTransformer>
			)}
		</PreQueryFilterTransformer>
	);
};
