import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	/**
	 * Quick Find
	 */
	quick_find_search_input_placeholder: {
		id: 'product_search_dialog.quick_find_search_input_rovo_placeholder',
		defaultMessage: 'Search or ask Rovo',
		description: 'A placeholder for the quick find search input',
	},

	quick_find_recent_search_text_dialog: {
		id: 'product_search_dialog.quick_find_recent_search_text_dialog',
		defaultMessage: 'From your recent searches',
		description: 'Recent search indicator text in search dialog box',
	},

	quick_find_show_organization_results: {
		id: 'product_search_dialog.quick_find_show_organization_results',
		defaultMessage: 'Search your organization',
		description: 'A label for a link that takes users to Full Page Search',
	},

	quick_find_show_filtered_results: {
		id: 'product_search_dialog.quick_find_show_filtered_confluence_results',
		defaultMessage: 'Filter by Confluence spaces, contributors and more',
		description:
			'A label for a link that takes users to Full Page Search with a product filter applied',
	},
	quick_find_show_filtered_results_with_icon: {
		id: 'product_search_dialog.quick_find_show_filtered_confluence_results_with_icon',
		defaultMessage: 'Filter by {confluenceIcon} Confluence spaces, contributors and more',
		description:
			'A label for a link that takes users to Full Page Search with a product filter applied with confluence icon',
	},
	quick_find_ask_atlassian_intelligence: {
		id: 'product_search_dialog.quick_find_ask_atlassian_intelligence',
		defaultMessage: 'Ask Atlassian Intelligence',
		description: 'A label for the link that takes users to an AI search',
	},

	quick_find_recently_viewed: {
		id: 'product_search_dialog.quick_find_recently_viewed',
		defaultMessage: 'Recently Viewed',
		description: 'Section title for recent content in Quick Find',
	},

	quick_find_recent_draft_label: {
		id: 'product_search_dialog.quick_find_recent_draft_label',
		defaultMessage: 'DRAFT',
		description: 'Lozenge label for draft results',
	},

	quick_find_recent_spaces_additional_meta: {
		id: 'product_search_dialog.quick_find_recent_spaces_additional_meta',
		defaultMessage: 'You recently visited',
		description: 'Additional meta for Quick Find recent spaces results',
	},

	quick_find_footer_text: {
		id: 'product_search_dialog.quick_find_footer_help_text',
		defaultMessage: 'Help us improve search',
		description: 'Quick find footer text',
	},

	quick_find_footer_full_page_search_link: {
		id: 'product_search_dialog.quick_find_footer_full_page_search_products_link',
		defaultMessage: 'Search across products',
		description: 'A label for a link that takes users to Full Page Search',
	},

	quick_find_no_content_header: {
		id: 'product_search_dialog.quick_find_no_content_header',
		defaultMessage: 'Seek and you shall find',
		description:
			'Header of prompt to begin search journey if no recent content results are rendered',
	},

	quick_find_no_content_body: {
		id: 'product_search_dialog.quick_find_no_content_body_rovo',
		defaultMessage:
			"Search or ask Rovo what you're looking for in your Atlassian and integrated products",
		description:
			'Body text of prompt to begin search journey if no recent content results are rendered',
	},

	quick_find_post_query_no_results_header: {
		id: 'product_search_dialog.quick_find_post_query_no_results_header',
		defaultMessage: "We couldn't find any results with that title",
		description: 'Header of prompt if entering query in quickfind returns no results',
	},

	quick_find_post_query_no_results_body: {
		id: 'product_search_dialog.quick_find_post_query_no_results_body',
		defaultMessage:
			'<SearchContentLink>Search all content across your organization</SearchContentLink> or try again with a different term.',
		description: 'Body text of prompt if entering query in quickfind returns no results',
	},

	quick_find_search_results: {
		id: 'product_search_dialog.quick_find_search_results',
		defaultMessage: 'Search Results',
		description: 'Section title for search results in Quick Find',
	},

	quick_find_confluence_space: {
		id: 'product_search_dialog.quick_find_confluence_space',
		defaultMessage: 'Confluence Space',
		description: 'Metadata for Confluence Space results',
	},

	quick_find_results_section: {
		id: 'product_search_dialog.quick_find_results_section',
		defaultMessage: 'Search Results',
		description: 'Section title for post query items in Quick Find',
	},

	quick_find_results_team: {
		id: 'product_search_dialog.quick_find_result_item_team',
		defaultMessage: 'Team',
		description: 'Label for team search results',
	},

	quick_find_recent_search: {
		id: 'product_search_dialog.quick_find_recent_search',
		defaultMessage: 'Recent search',
		description: 'Metadata for recent search results',
	},
	remove_button_label: {
		id: 'product_search_dialog.results.remove_button_label',
		defaultMessage: 'Remove this.',
		description: 'Label of the button that has remove function and appearance.',
	},
	quick_find_rovo_tip: {
		id: 'product_search_dialog.quick_find_rovo_tip',
		defaultMessage:
			'Enter a question like “What’s the vacation policy?” and Rovo will find the answer.',
		description: 'Banner message for showing tip of using question query.',
	},
});
