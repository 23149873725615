import React from 'react';
import {
	CollapsedResultContentWrapper,
	EnlargedIconContainer,
	EnlargedResultContentWrapper,
	EnlargedResultDetailSkeleton,
	EnlargedResultTitleContainerSkeleton,
	EnlargedSkeletonIcon,
} from './search-result.styled';

export const EnlargedSearchResultContentSkeleton: React.FC = ({}) => {
	return (
		<EnlargedResultContentWrapper>
			<EnlargedIconContainer>
				<EnlargedSkeletonIcon />
			</EnlargedIconContainer>
			<CollapsedResultContentWrapper>
				<EnlargedResultTitleContainerSkeleton />
				<EnlargedResultDetailSkeleton />
			</CollapsedResultContentWrapper>
		</EnlargedResultContentWrapper>
	);
};
