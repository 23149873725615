import { type AggRequest, fetchAgg } from '../../../../common/utils/fetch-agg';

import { operationName, quickFindSearchGraphQuery } from './query';
import { type QuickFindGraphQLSearchQueryType, type QuickFindSearchQueryVariables } from './types';

export type QuickFindSearchQueryProps = Pick<
	AggRequest<QuickFindSearchQueryVariables>,
	'aggAbsoluteUrl' | 'variables'
>;

export const quickFindSearchQuery = ({ variables, aggAbsoluteUrl }: QuickFindSearchQueryProps) => {
	return fetchAgg<QuickFindSearchQueryVariables, QuickFindGraphQLSearchQueryType>({
		variables,
		aggAbsoluteUrl,
		graphQuery: quickFindSearchGraphQuery,
		operationName,
	});
};
