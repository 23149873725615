import { type AttributeType } from '../../../jira/clients/response-types';
import { addQueryParams } from '../../../utils/url-utils';

interface ReferralIds {
	searchSessionId?: string;
	searchObjectId: string;
	searchContainerId?: string;
	searchContentType: AttributeType;
}

export const attachJiraReferralIds = (href: string, ids: ReferralIds) => {
	return addQueryParams(href, ids);
};
