import { type Size, sizes } from '@atlaskit/icon';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';

type IconBackgroundProps = {
	size: Size;
	color: string;
};

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconBackground = styled.div<IconBackgroundProps>`
	display: flex;
	align-items: center;
	justify-content: center;

	width: ${(props) => sizes[props.size]};
	height: ${(props) => sizes[props.size]};

	background-color: ${(props) => props.color};
	border-radius: 3px;

	& > svg,
	& > span[role='img'],
	& > span[role='presentation'] {
		width: 83.333333%;
	}
`;
