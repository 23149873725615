import { CancellablePromise } from '@atlassian/search-client';
import uniqBy from 'lodash/uniqBy';
import {
	AggregatorClient,
	type AggregatorConfig,
	type SearchContext,
	type ABTest,
	responseErrorToError,
} from '../../common/clients';
import {
	type AllFilters,
	type SpaceFiltersInterface,
	type UserFiltersInterface,
	type TimeFiltersInterface,
} from './filter-types';
import {
	Scope,
	type PropScopesType,
	type enumItemScope,
	type enumSpaceScope,
	type enumUrsUserScope,
	type SupportedScopedResponses,
	PersonResult,
	Person,
	ResultPerson,
	type ConfItemResults,
	type ConfSpaceResults,
	type ConfPeopleResults,
	type ConfItemResponse,
	ResultConfluence,
	type ContentType,
	ConfluenceObjectResult,
	type ConfUrsPeopleResponse,
	GenericContainerResult,
	ConfluenceSpace,
	type ConfSpaceResponse,
	RecentPerson,
} from './response-types';
import { type Products } from '../../common/product-context';
import { SimpleCache } from '../../utils/simple-cache';
import { type CollaborationGraphClient } from '../../common/clients/collaboration-graph-client';
import {
	type CollaborationGraphResponse,
	type CollaborationGraphUser,
	type ModelParam,
	type Site,
} from '../../common/clients/common-types';
import {
	generatePeopleProfileUrl,
	mapTenantIdToSiteUrl,
} from '../../common/clients/multi-site-utils';
import {
	GraphQLClient,
	CONFLUENCE_ITEM_ENTITIES,
} from '../../common/clients/graphql-search-client';

export type FilterType = 'space' | 'contributor' | 'lastModified';

export interface Filter {
	id: string;
	type: FilterType;
}

export type ConfluenceSearchClientConfig = AggregatorConfig & {
	isUserAnonymous: boolean;
	isCollaborationGraphEnabled: boolean;
	siteMasterList: Site[];
	scopes?: PropScopesType;
	useGraphQLClient?: boolean;
};

const NAVIGATION_V3_EXPERIENCE = 'confluence.nav-v3';

interface ConfluenceSearchResponse {
	[Scope.ConfluencePageBlogAttachment]: CancellablePromise<ConfItemResults>;
	[Scope.ConfluenceSpace]: CancellablePromise<ConfSpaceResults>;
	[Scope.UserConfluence]: CancellablePromise<ConfPeopleResults>;
}

const EMPTY_RESULT_PROMISE = Promise.resolve({
	items: [],
	timings: 0,
});

export class ConfluenceSearchClient {
	private static readonly ITEM_RESULT_LIMIT = 30;

	private static readonly SPACE_RESULT_LIMIT = 10;

	private static readonly PEOPLE_RESULT_LIMIT = 10;

	private aggregatorClient: AggregatorClient<SupportedScopedResponses, AllFilters>;

	private graphQLClient: GraphQLClient<SupportedScopedResponses>;

	private siteMasterList: Site[];

	private collaborationGraphClient: CollaborationGraphClient;

	private isCollabGraphEnabled: boolean;

	private isUserAnonymous: boolean;

	private useGraphQLClient: boolean;

	private scopes: {
		itemScope: enumItemScope;
		spaceScope: enumSpaceScope;
		ursUserScope: enumUrsUserScope;
	};

	private bootstrapPeopleCache: SimpleCache<Promise<ConfPeopleResults>, [SearchContext, Site[]]>;

	private abTestDataCache: ABTest | null = null;

	private permissionsDataCache: Products[] | null = null;

	constructor(config: ConfluenceSearchClientConfig, collabGraphClient: CollaborationGraphClient) {
		const { siteMasterList, scopes } = config;
		this.aggregatorClient = new AggregatorClient(config);
		this.graphQLClient = new GraphQLClient(config);
		this.isUserAnonymous = config.isUserAnonymous;
		this.useGraphQLClient = config.useGraphQLClient || false;
		this.bootstrapPeopleCache = new SimpleCache(this.recentPeopleSupplier);
		this.siteMasterList = siteMasterList;
		this.collaborationGraphClient = collabGraphClient;
		this.isCollabGraphEnabled = config.isCollaborationGraphEnabled;
		this.scopes = {
			itemScope: scopes?.itemScope
				? (scopes.itemScope as enumItemScope)
				: Scope.ConfluencePageBlogAttachment,
			spaceScope: scopes?.spaceScope
				? (scopes.spaceScope as enumSpaceScope)
				: Scope.ConfluenceSpace,
			ursUserScope: scopes?.ursUserScope
				? (scopes.ursUserScope as enumUrsUserScope)
				: Scope.UserConfluence,
		};
	}

	/**
	 * Makes a search across all relevant scopes.
	 */
	public search(
		query: string,
		filters: Filter[],
		context: SearchContext,
		queryVersion: number,
		sites: Site[],
	): ConfluenceSearchResponse {
		const modelParams = [
			{
				'@type': 'queryParams',
				queryVersion,
			},
		];

		if (filters.length > 0) {
			return this.searchWithFilters(query, filters, context, modelParams, sites);
		}

		if (this.useGraphQLClient) {
			// TODO: convert this response to expected confluence search response
			this.graphQLClient.search({
				query,
				context,
				entities: CONFLUENCE_ITEM_ENTITIES,
				modelParams,
				resultLimit: ConfluenceSearchClient.ITEM_RESULT_LIMIT,
				experience: NAVIGATION_V3_EXPERIENCE,
				sites: sites.length > 0 ? sites.map((s) => s.cloudId) : undefined,
			});
		}

		const scopes = [this.scopes.itemScope, this.scopes.spaceScope, this.scopes.ursUserScope];

		const responseAndTiming = this.aggregatorClient.search({
			query,
			context,
			scopes,
			modelParams,
			resultLimit: ConfluenceSearchClient.ITEM_RESULT_LIMIT,
			filters: [],
			experience: NAVIGATION_V3_EXPERIENCE,
			sites: sites.length > 0 ? sites.map((s) => s.cloudId) : undefined,
		});

		const allResults = {
			[Scope.ConfluencePageBlogAttachment]: CancellablePromise.from(
				responseAndTiming.then(({ response, requestDurationMs }) =>
					this.mapConfItem(response.retrieveScope(this.scopes.itemScope), requestDurationMs),
				),
			),
			[Scope.ConfluenceSpace]: CancellablePromise.from(
				responseAndTiming.then(({ response, requestDurationMs }) =>
					this.mapConfSpace(response.retrieveScope(this.scopes.spaceScope), requestDurationMs),
				),
			),
			[Scope.UserConfluence]: CancellablePromise.from(
				responseAndTiming.then(({ response, requestDurationMs }) =>
					this.mapUrsPeople(
						response.retrieveScope(this.scopes.ursUserScope),
						requestDurationMs,
						sites.length > 0,
					),
				),
			),
		};

		return allResults;
	}

	public searchSpaces(
		query: string,
		context: SearchContext,
		sites: Site[],
	): CancellablePromise<ConfSpaceResults> {
		const scopes = [Scope.ConfluenceSpace];

		const responseAndTiming = this.aggregatorClient.search({
			query,
			context,
			scopes,
			modelParams: [],
			resultLimit: ConfluenceSearchClient.SPACE_RESULT_LIMIT,
			filters: [],
			experience: NAVIGATION_V3_EXPERIENCE,
			sites: sites.length > 0 ? sites.map((s) => s.cloudId) : undefined,
		});

		return CancellablePromise.from(
			responseAndTiming.then(({ response, requestDurationMs }) =>
				this.mapConfSpace(response.retrieveScope(Scope.ConfluenceSpace), requestDurationMs),
			),
		);
	}

	public searchUsers(
		query: string,
		context: SearchContext,
		sites: Site[],
	): CancellablePromise<ConfPeopleResults> {
		const scopes = [Scope.UserConfluence];

		const responseAndTiming = this.aggregatorClient.search({
			query,
			context,
			scopes,
			modelParams: [],
			resultLimit: ConfluenceSearchClient.PEOPLE_RESULT_LIMIT,
			filters: [],
			experience: NAVIGATION_V3_EXPERIENCE,
			sites: sites.length > 0 ? sites.map((s) => s.cloudId) : undefined,
		});

		return CancellablePromise.from(
			responseAndTiming.then(({ response, requestDurationMs }) =>
				this.mapUrsPeople(
					response.retrieveScope(Scope.UserConfluence),
					requestDurationMs,
					sites.length > 0,
				),
			),
		);
	}

	private searchWithFilters: (
		query: string,
		filters: Filter[],
		context: SearchContext,
		modelParams: ModelParam[],
		sites: Site[],
	) => ConfluenceSearchResponse = (query, filters, context, modelParams, sites) => {
		const scopes = [Scope.ConfluencePageBlogAttachment];

		const allFilters: (SpaceFiltersInterface | UserFiltersInterface | TimeFiltersInterface)[] = [];
		let baseSpaceFilters: SpaceFiltersInterface | null = null;
		let baseUserFilters: UserFiltersInterface | null = null;
		let baseTimeFilters: TimeFiltersInterface | null = null;

		// TODO: GraphQL queries with filters

		filters.forEach((filter) => {
			switch (filter.type) {
				case 'space':
					if (!baseSpaceFilters) {
						baseSpaceFilters = {
							'@type': 'spaces',
							spaceKeys: [],
						};
						allFilters.push(baseSpaceFilters);
					}

					baseSpaceFilters.spaceKeys.push(filter.id);
					break;
				case 'contributor':
					if (!baseUserFilters) {
						baseUserFilters = {
							'@type': 'contributors',
							accountIds: [],
						};
						allFilters.push(baseUserFilters);
					}

					baseUserFilters.accountIds.push(filter.id);
					break;
				case 'lastModified':
					if (!baseTimeFilters) {
						baseTimeFilters = {
							'@type': 'lastModified',
							from: filter.id,
						};
						allFilters.push(baseTimeFilters);
					}
					break;
				default: {
					break;
				}
			}
		});

		const responseAndTiming = this.aggregatorClient.search({
			query,
			context,
			scopes,
			modelParams,
			resultLimit: ConfluenceSearchClient.ITEM_RESULT_LIMIT,
			filters: allFilters,
			experience: NAVIGATION_V3_EXPERIENCE,
			sites: sites.length > 0 ? sites.map((s) => s.cloudId) : undefined,
		});

		const results = {
			[Scope.ConfluencePageBlogAttachment]: CancellablePromise.from(
				responseAndTiming.then(({ response, requestDurationMs }) =>
					this.mapConfItem(
						response.retrieveScope(Scope.ConfluencePageBlogAttachment),
						requestDurationMs,
					),
				),
			),
			[Scope.ConfluenceSpace]: CancellablePromise.from(EMPTY_RESULT_PROMISE),
			[Scope.UserConfluence]: CancellablePromise.from(EMPTY_RESULT_PROMISE),
		};

		return results;
	};

	public getRecentPeople(
		context: SearchContext,
		sites: Site[],
	): CancellablePromise<ConfPeopleResults> {
		const { fromCache, value } = this.bootstrapPeopleCache.get(context, sites);
		return new CancellablePromise(value.then((results) => ({ ...results, isCached: fromCache })));
	}

	public recentPeopleSupplier: (
		context: SearchContext,
		sites: Site[],
	) => Promise<ConfPeopleResults> = (context, sites) => {
		if (this.isUserAnonymous) {
			return Promise.resolve({
				items: [],
				timings: 0,
			});
		}

		if (this.isCollabGraphEnabled) {
			return this.collaborationGraphClient
				.getUsers()
				.then((response) => this.mapCollabGraphPeople(response));
		}

		return this.aggregatorClient
			.search({
				query: '',
				context,
				scopes: [Scope.UserConfluence],
				modelParams: [],
				resultLimit: ConfluenceSearchClient.PEOPLE_RESULT_LIMIT,
				experience: NAVIGATION_V3_EXPERIENCE,
				sites: sites?.map((s) => s.cloudId),
			})
			.then(({ response, requestDurationMs }) =>
				this.mapUrsPeople(
					response.retrieveScope(Scope.UserConfluence),
					requestDurationMs,
					sites.length > 0,
				),
			);
	};

	/**
	 * Check the user permissions for the given list of products.
	 */
	public async getProductPermissions(products: Products[]): Promise<Products[]> {
		if (!this.permissionsDataCache) {
			this.permissionsDataCache = await this.aggregatorClient.getProductPermissions(
				products,
				NAVIGATION_V3_EXPERIENCE,
			);
		}

		return this.permissionsDataCache;
	}

	public async getAbTestData() {
		if (this.abTestDataCache) {
			return this.abTestDataCache;
		}

		const abTest = await this.aggregatorClient.getAbTestData(
			Scope.ConfluencePageBlogAttachment,
			NAVIGATION_V3_EXPERIENCE,
		);

		this.abTestDataCache = abTest;

		return abTest;
	}

	private mapConfItem(response: ConfItemResponse | null, timings: number): ConfItemResults {
		if (!response) {
			throw new Error(
				`Expected a response but did not get any for scope: ${Scope.ConfluencePageBlogAttachment}`,
			);
		}

		if (response.error) {
			throw responseErrorToError(response.error);
		}

		function removeHighlightTags(text: string): string {
			return text.replace(/@@@hl@@@|@@@endhl@@@/g, '');
		}

		/* eslint-disable @typescript-eslint/no-non-null-assertion */
		const items = response.results.map((item) => ({
			resultId: item.content!.id, // content always available for pages/blogs/attachments // TODO
			name: removeHighlightTags(item.title),
			href: `${item.baseUrl}${item.url}`,
			containerName: item.container.title,
			analyticsType: ResultConfluence,
			contentType: `confluence-${item.content!.type}` as ContentType,
			resultType: ConfluenceObjectResult,
			containerId:
				item.content!.space && item.content!.space!.id ? item.content!.space!.id : 'UNAVAILABLE',
			spaceId: item.container.id,
			iconClass: item.iconCssClass,
			lastModified: item.lastModified,
			isRecentResult: false,
		}));
		/* eslint-enable */

		return {
			items,
			totalSize: response.size !== undefined ? response.size : response.results.length,
			timings,
		};
	}

	private mapUrsPeople = (
		response: ConfUrsPeopleResponse | null,
		timings: number,
		isMultiSite: boolean,
	): ConfPeopleResults => {
		if (!response) {
			throw new Error(`Expected a response but did not get any for scope: ${Scope.UserConfluence}`);
		}

		if (response.error) {
			throw responseErrorToError(response.error);
		}

		const items = response.results.map((item) => ({
			resultType: PersonResult,
			resultId: `people-${item.id}`,
			userId: item.id,
			name: item.name,
			href: generatePeopleProfileUrl(isMultiSite, item.absoluteUrl, item.id),
			avatarUrl: item.avatarUrl,
			contentType: Person,
			analyticsType: ResultPerson,
			mentionName: item.nickname || '',
			presenceMessage: '',
		}));

		return {
			items: uniqBy(items, (item) => item.resultId),
			timings,
		};
	};

	private mapCollabGraphPeople = (
		response: CollaborationGraphResponse<CollaborationGraphUser>,
	): ConfPeopleResults => {
		const items = response.collaborationGraphEntities.map((item) => ({
			resultId: item.userProfile.account_id,
			userId: item.userProfile.account_id,
			name: item.userProfile.name,
			href: `${mapTenantIdToSiteUrl(item.siteId, this.siteMasterList)}/people/${
				item.userProfile.account_id
			}`,
			analyticsType: RecentPerson,
			mentionName: item.userProfile.name,
			presenceMessage: item.userProfile.name,
			resultType: PersonResult,
			avatarUrl: item.userProfile.picture,
			contentType: Person,
		}));

		return {
			items,
			timings: response.timings,
		};
	};

	private mapConfSpace(response: ConfSpaceResponse | null, timings: number): ConfSpaceResults {
		if (!response) {
			throw new Error(
				`Expected a response but did not get any for scope: ${Scope.ConfluenceSpace}`,
			);
		}

		if (response.error) {
			throw responseErrorToError(response.error);
		}

		/* eslint-disable @typescript-eslint/no-non-null-assertion */
		const items = response.results.map((item) => ({
			resultId: item.id,
			avatarUrl: `${item.baseUrl}${item.space!.icon.path}`,
			name: item.container.title,
			href: `${item.baseUrl || ''}${item.container.displayUrl}`,
			analyticsType: ResultConfluence,
			resultType: GenericContainerResult,
			contentType: ConfluenceSpace,
			key: item.space!.key, // TODO
			id: item.id,
		}));
		/* eslint-enable */

		return {
			items,
			timings,
		};
	}
}
