import React, { Fragment } from 'react';
import type { IntlShape } from 'react-intl-next';

import type { ProductStates } from '@atlassian/product-search-dialog';
import { AutocorrectAsyncWrapper, LoadingSkeleton } from '@atlassian/product-search-dialog';

import { i18n } from './messages';

export const ConfluenceTitleRenderer =
	(
		autocorrectEnabled: boolean,
		workedOnAPIEnabled: boolean,
		formatMessage: IntlShape['formatMessage'],
	) =>
	({ productState }: { productState: ProductStates }) => {
		const recentlyViewedTitle = (
			<Fragment>
				{workedOnAPIEnabled
					? formatMessage(i18n.recentlyWorkedOn)
					: formatMessage(i18n.recentlyViewed)}
			</Fragment>
		);
		const pageBlogAttachmentTitle = <Fragment>{formatMessage(i18n.contents)}</Fragment>;

		if (productState === 'PreQuerySuccess') {
			return recentlyViewedTitle;
		}

		if (autocorrectEnabled) {
			if (productState === 'PostQueryLoading') {
				return <LoadingSkeleton data-testid="autocorrection-loading-skeleton" />;
			} else {
				return <AutocorrectAsyncWrapper fallback={pageBlogAttachmentTitle} />;
			}
		}
		return pageBlogAttachmentTitle;
	};
