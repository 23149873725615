import React, { type ReactNode } from 'react';
import { token } from '@atlaskit/tokens';
import { N300 } from '@atlaskit/theme/colors';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

type QuickFindResultMetaProps = {
	meta: ReactNode[];
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ResultMetaWrapper = styled.span({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	color: token('color.text.subtle', N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const SpacerWrapper = styled.span({
	color: token('color.text.subtle', N300),
	margin: `0px ${token('space.100', '8px')}`,
});

export function capitalizeFirstLetter(word: string) {
	return word.charAt(0).toUpperCase() + word.slice(1);
}

export const QuickFindResultMeta = ({ meta }: QuickFindResultMetaProps) => {
	return (
		<ResultMetaWrapper>
			{meta
				.filter((metaItem) => metaItem !== null && metaItem !== undefined)
				.map((metaItem, index) => (
					<>
						{metaItem}
						{index !== meta.length - 1 && <SpacerWrapper>•</SpacerWrapper>}
					</>
				))}
		</ResultMetaWrapper>
	);
};
