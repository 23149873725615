import { type ScopedAggregatorResponse } from '../../../common/clients';

export enum TownsquareScope {
	TownsquareProject = 'townsquare.project',
	TownsquareGoal = 'townsquare.goal',
	TownsquareHelpLink = 'townsquare.help-pointer',
	TownsquareTag = 'townsquare.tag',
	TownsquareLearning = 'townsquare.learning',
	People = 'townsquare.people',
	Teams = 'townsquare.teams',
}

export interface TownsquareResponse {
	id: string;
	url: string;
	title: string;
	iconUrl: string;
}

export interface PeopleResponse {
	absoluteUrl?: string; // For any TCS errors this field will not be present in the response.
	account_id: string;
	name: string;
	nickname?: string;
	job_title?: string;
	picture: string;
}

export type TownsquareOrPeopleResponse = TownsquareResponse | PeopleResponse;

export interface AggregatorTownsquareResponse extends ScopedAggregatorResponse<TownsquareScope> {
	results: TownsquareOrPeopleResponse[];
}
