import React from 'react';

import { FormattedMessage } from 'react-intl-next';
import {
	type QuickFindSearchResultType,
	type ConfluenceGraphQLSpaceNode,
} from '@atlassian/search-client';
import { messages } from '../../messages';
import { transformAriToId } from '@atlassian/search-common';
import { GenericIconRenderer } from './content-icon-renderer';

export const quickFindConfluenceSpaceMapper = ({
	node: item,
}: {
	node?: ConfluenceGraphQLSpaceNode | null;
}): QuickFindSearchResultType => {
	return {
		id: transformAriToId(item?.id || '', item?.type || ''),
		title: item?.title || '',
		meta: <FormattedMessage {...messages.quick_find_confluence_space} />,
		url: item?.url || '',
		type: 'confluence space',
		containerId: item?.id,
		icon: GenericIconRenderer({
			iconUrl: `/wiki${item?.iconPath || ''}`,
			size: 'small',
		}),
	};
};
