import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

export const COMPONENT = 'search-dialog';

/**
 * An experience which measures the time taken for the search dialog to
 * initialise. This includes from the moment the dialog mounts until abTest
 * data arrives and when the primary product tab has loaded.
 */
export const initialisationExperience = new UFOExperience('intialisation', {
	type: ExperienceTypes.Load,
	performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	platform: { component: COMPONENT },
});
