import { type BitbucketContext, type BitbucketURLGenerators } from './types';

const getBitbucketBaseURL = () => {
	const hostname = window.location.hostname;
	if (hostname.includes('jira-dev.com') || hostname.startsWith('localhost')) {
		return 'https://staging.bb-inf.net';
	}

	return 'https://bitbucket.org';
};

/**
 * Generates URL functions for the Bitbucket tab, with appropriate absolute URLs.
 *
 * @returns functions for generating Bitbucket URLs
 */
export const bitbucketURLGenerators = (
	overrides?: Partial<BitbucketURLGenerators>,
): BitbucketURLGenerators => {
	const viewAllLinkGenerator = (query: string) =>
		`${getBitbucketBaseURL()}/dashboard/repositories?search=${encodeURIComponent(query)}`;

	const urlGeneratorForNoResultsScreen = (query?: string) =>
		query
			? `${getBitbucketBaseURL()}/dashboard/repositories?search=${encodeURIComponent(query)}`
			: `${getBitbucketBaseURL()}/dashboard/repositories`;

	const codeSearchUrlGenerator = (query: string) =>
		query
			? `${getBitbucketBaseURL()}/search?q=${encodeURIComponent(query)}`
			: `${getBitbucketBaseURL()}/search`;

	const codeSearchContextualUrlGenerator = (query: string, context: BitbucketContext) => {
		const { repository, workspace } = context;
		let fullModifier = query || repository ? '?q=' : '';

		// Populate query params
		if (repository) {
			const repoSlug = encodeURIComponent(`repo:${repository.slug} `);
			fullModifier = `${fullModifier}${repoSlug}`;
		}
		if (query) {
			fullModifier = `${fullModifier}${encodeURIComponent(query)}`;
		}

		const workspaceUUID = repository?.workspace?.uuid || workspace?.uuid;
		if (workspaceUUID) {
			fullModifier = fullModifier
				? `${fullModifier}&account=${encodeURIComponent(workspaceUUID)}`
				: `?account=${encodeURIComponent(workspaceUUID)}`;
		}

		return `${getBitbucketBaseURL()}/search${fullModifier}`;
	};

	return {
		viewAllLinkGenerator: overrides?.viewAllLinkGenerator || viewAllLinkGenerator,
		urlGeneratorForNoResultsScreen:
			overrides?.urlGeneratorForNoResultsScreen || urlGeneratorForNoResultsScreen,
		codeSearchUrlGenerator: overrides?.codeSearchUrlGenerator || codeSearchUrlGenerator,
		codeSearchContextualUrlGenerator:
			overrides?.codeSearchContextualUrlGenerator || codeSearchContextualUrlGenerator,
	};
};
