import gql from 'graphql-tag';

export const ConfluenceQuickSearchQuery = gql`
	query ConfluenceQuickSearchQuery(
		$query: String!
		$cloudIdARI: String!
		$pageBlogAttachmentLimit: Int!
		$spaceLimit: Int!
		$contentEntities: [String!]!
		$confluenceFilters: SearchConfluenceFilter
		$queryVersion: Int
		$searchSessionId: String
		$includeSpaces: Boolean!
		$includeAncestors: Boolean!
		$experimentContext: SearchExperimentContextInput
		$isLivePagesEnabled: Boolean!
	) {
		search {
			pageBlogAttachments: search(
				experience: "product-search-dialog"
				query: $query
				first: $pageBlogAttachmentLimit
				filters: {
					entities: $contentEntities
					locations: [$cloudIdARI]
					confluenceFilters: $confluenceFilters
				}
				analytics: { queryVersion: $queryVersion, searchSessionId: $searchSessionId }
				experimentContext: $experimentContext
			) {
				edges {
					node {
						... on SearchConfluencePageBlogAttachment {
							id
							title
							type
							url
							lastModified
							iconCssClass
							space {
								id
								name
							}
							confluenceEntity {
								... on ConfluencePage {
									subtype @include(if: $isLivePagesEnabled)
									ancestors @include(if: $includeAncestors) {
										id
										title
									}
								}
							}
						}
					}
				}
				abTest {
					abTestId
					controlId
					experimentId
				}
				totalCount
			}
			spaces: search(
				experience: "searchDialog"
				query: $query
				first: $spaceLimit
				filters: { entities: ["ati:cloud:confluence:space"], locations: [$cloudIdARI] }
				analytics: { queryVersion: $queryVersion, searchSessionId: $searchSessionId }
			) @include(if: $includeSpaces) {
				edges {
					node {
						... on SearchConfluenceSpace {
							id
							key
							title
							type
							url
							iconPath
						}
					}
				}
			}
		}
	}
`;
