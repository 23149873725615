import { fg } from '@atlaskit/platform-feature-flags';
import { type IntlShape } from 'react-intl-next';
import { type SearchResult } from '../../product-router/product/result-types';
import { type AggregatorSection } from '../../result-supplier/default-suppliers';
import { messages } from './messages';
import { componentResultRenderer, endpointResultRenderer } from './result-renderer';
import {
	type AggregatorCompassResponse,
	type CompassApiEndpointResponse,
	type CompassComponentResponse,
	CompassScope,
} from './types';

const mapComponentAggregatorResponseToSearchItem = (
	response: CompassComponentResponse,
): SearchResult => ({
	title: response.title,
	id: response.id,
	meta: response.ownerId,
	url: response.url,
	icon: response.type,
	containerId: '', // no container
});

const mapApiEndpointAggregatorResponseToSearchItem = (
	response: CompassApiEndpointResponse,
): SearchResult => ({
	title: response.title,
	id: response.id,
	url: response.url,
	icon: response.url.split('/').pop()?.split('.')[0] || '',
	meta: '',
});

export const getSections = (
	intl: IntlShape,
): AggregatorSection<CompassScope, AggregatorCompassResponse>[] => {
	const sections: AggregatorSection<CompassScope, AggregatorCompassResponse>[] = [
		{
			id: CompassScope.Component,
			title: intl.formatMessage(messages.compass_components_section_heading),
			scope: CompassScope.Component,
			showResultCount: true,
			resultRenderer: componentResultRenderer,
			resultMapper: ({ results }: AggregatorCompassResponse): SearchResult[] =>
				(results as CompassComponentResponse[]).map((value) =>
					mapComponentAggregatorResponseToSearchItem(value),
				),
		},
	];

	if (fg('api_endpoint_indexing')) {
		sections.push({
			id: CompassScope.ApiEndpoint,
			title: intl.formatMessage(messages.compass_endpoints_section_heading),
			scope: CompassScope.ApiEndpoint,
			showResultCount: true,
			resultRenderer: endpointResultRenderer,
			resultLimit: 5,
			resultMapper: ({ results }: AggregatorCompassResponse): SearchResult[] =>
				(results as CompassApiEndpointResponse[]).map((value) =>
					mapApiEndpointAggregatorResponseToSearchItem(value),
				),
		});
	}

	return sections;
};
