import React from 'react';
import { useIntl } from 'react-intl-next';
import { type LinkComponent, SearchFooter } from '@atlassian/search-dialog';
import { useUserContext } from '../../common/user-context';
import { useQuery } from '../query-context';
import {
	AdvancedSearchFooter,
	AdvancedSearchFooterLink,
	type AdvancedSearchFooterProps,
	ReturnIconWithModifier,
} from './advanced-search-footer';
import { messages } from '../messages';

const ModifierIcon = () => <ReturnIconWithModifier showModifierKey />;
const ReturnIcon = () => <ReturnIconWithModifier />;

export const getAtlassianHomeSearchUrl = (orgId: string, query?: string) => {
	const url = new URL(`https://home.atlassian.com/o/${orgId}/search`);
	if (query) {
		url.searchParams.set('text', query);
	}
	return url.toString();
};

export const AtlassianHomeSearchLink = ({
	orgId,
	linkComponent,
}: {
	orgId: string;
	linkComponent?: LinkComponent;
}) => {
	const { formatMessage } = useIntl();
	const { query } = useQuery();
	const href = getAtlassianHomeSearchUrl(orgId, query);
	return (
		<AdvancedSearchFooterLink
			href={href}
			onClick={() => window.location.assign(href)}
			linkComponent={linkComponent}
			contentLabel={formatMessage(messages.extensible_advanced_search_cross_product_footer)}
			returnKeyIconComponent={ModifierIcon}
		/>
	);
};

export const AtlassianHomeSearchFooter = (props: AdvancedSearchFooterProps) => {
	const { orgId } = useUserContext();
	return (
		<>
			<SearchFooter>
				<AdvancedSearchFooter {...props} returnKeyIconComponent={ReturnIcon} />
			</SearchFooter>
			{orgId && (
				<SearchFooter>
					<AtlassianHomeSearchLink orgId={orgId} linkComponent={props.linkComponent} />
				</SearchFooter>
			)}
		</>
	);
};
