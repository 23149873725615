import React from 'react';
import { fg } from '@atlaskit/platform-feature-flags';
import { withAnalytics } from '../../../common/analytics';
import { onAdvancedSearchSelected } from '../../../common/analytics/events';
import { messages } from '../../../messages';
import { useIntl } from 'react-intl-next';
import { type AdvancedSearchLinksProps } from './types';
import { GetDefaultFooter } from '../../product-router/product/result-provider/result-renderer/default-footer';

export const JiraAdvancedSearchLinks = (props: AdvancedSearchLinksProps) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<GetDefaultFooter
				linkComponent={props.linkComponent}
				productState={props.productState}
				searchFooterLabel={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.jira_view_all_issues_newIssueTermRefresh
						: messages.jira_view_all_issues_new,
				)}
			/>
		</>
	);
};

export const JiraAdvancedSearch = withAnalytics({
	advancedSearchSelected: onAdvancedSearchSelected,
})(JiraAdvancedSearchLinks);

export default JiraAdvancedSearch;
