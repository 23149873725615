import { type UserSearchItem } from '@atlaskit/smart-common';
import { type ActivityItemEdge, type SearchPageNode } from '@atlassian/search-client';

import type { SelectedQueryFilter } from '../../store/quick-find';

export type QuickFindResult<T> = {
	id: string;
	title: string;
	data: T;
	type: string;
};

export type RecentQueries = QuickFindResult<string>[];

export type RecentActivities = {
	result: QuickFindResult<ActivityItemEdge>[];
	duration: number;
};

export type SearchResults = {
	result: QuickFindResult<SearchPageNode>[];
	duration: number;
};

export type PeopleAndTeams = {
	result: QuickFindResult<UserSearchItem>[];
	duration: number;
};

/**
 * QuickFindContent contains query to ensure that the mapping between a set
 * of results and the query value is maintained. Query is used for analytics.
 */
export type QuickFindContent =
	| {
			state: 'PREQUERY_ERROR';
			query: '';
	  }
	| {
			state: 'PREQUERY_LOADING';
			query: '';
			recentQueries: QuickFindResult<string>[];
	  }
	| {
			state: 'PREQUERY_NO_RESULTS';
			query: '';
	  }
	| {
			state: 'PREQUERY_RESULTS';
			query: '';
			recentQueries: QuickFindResult<string>[];
			recentActivities: QuickFindResult<ActivityItemEdge>[];
	  }
	| {
			state: 'POSTQUERY_ERROR';
			query: string;
	  }
	| {
			state: 'POSTQUERY_LOADING';
			query: string;
			recentActivities: QuickFindResult<ActivityItemEdge>[];
	  }
	| {
			state: 'POSTQUERY_NO_RESULTS';
			query: string;
	  }
	| {
			state: 'POSTQUERY_RESULTS';
			query: string;
			recentQueries: QuickFindResult<string>[];
			recentActivities: QuickFindResult<ActivityItemEdge>[];
			searchResults: QuickFindResult<SearchPageNode>[];
			peopleAndTeams: QuickFindResult<UserSearchItem>[];
	  }
	| undefined;

type StateHandlerArgs = {
	query?: string;
	selectedQueryFilters: SelectedQueryFilter[];
	quickFindOpen: boolean;
	preQueryError?: Error;
	preQueryLoading: boolean;
	postQueryError?: Error;
	postQueryLoading: boolean;
	filteredRecentQueries: RecentQueries;
	filteredRecentActivities: RecentActivities;
	searchResults: SearchResults;
	peopleAndTeams: PeopleAndTeams;
	postQueryDisabled?: boolean;
};

const preQueryErrorHandler = ({
	query,
	selectedQueryFilters,
	preQueryError,
	postQueryDisabled,
}: StateHandlerArgs): QuickFindContent | undefined => {
	const hasQuery = query || selectedQueryFilters.length > 0;

	if ((!hasQuery || postQueryDisabled) && preQueryError) {
		return {
			state: 'PREQUERY_ERROR',
			query: '',
		};
	}
};

const preQueryLoadingHandler = ({
	query,
	selectedQueryFilters,
	preQueryLoading,
	filteredRecentQueries,
	postQueryDisabled,
}: StateHandlerArgs): QuickFindContent | undefined => {
	const hasQuery = query || selectedQueryFilters.length > 0;

	if ((!hasQuery || postQueryDisabled) && preQueryLoading) {
		return {
			state: 'PREQUERY_LOADING',
			query: '',
			recentQueries: filteredRecentQueries,
		};
	}
};

const preQueryNoResultsHandler = ({
	query,
	selectedQueryFilters,
	filteredRecentActivities,
	postQueryDisabled,
}: StateHandlerArgs): QuickFindContent | undefined => {
	const hasQuery = query || selectedQueryFilters.length > 0;
	const totalResults = filteredRecentActivities.result.length;

	if ((!hasQuery || postQueryDisabled) && totalResults === 0) {
		return {
			state: 'PREQUERY_NO_RESULTS',
			query: '',
		};
	}
};

const preQueryResultsHandler = ({
	query,
	selectedQueryFilters,
	filteredRecentQueries,
	filteredRecentActivities,
	postQueryDisabled,
}: StateHandlerArgs): QuickFindContent | undefined => {
	const hasQuery = query || selectedQueryFilters.length > 0;
	const totalResults = filteredRecentActivities.result.length;

	if ((!hasQuery || postQueryDisabled) && totalResults > 0) {
		return {
			state: 'PREQUERY_RESULTS',
			query: '',
			recentQueries: filteredRecentQueries,
			recentActivities: filteredRecentActivities.result,
		};
	}
};

const postQueryErrorHandler = ({
	query,
	selectedQueryFilters,
	postQueryError,
	postQueryDisabled,
}: StateHandlerArgs): QuickFindContent | undefined => {
	const hasQuery = query || selectedQueryFilters.length > 0;

	if (hasQuery && !postQueryDisabled && postQueryError) {
		return {
			state: 'POSTQUERY_ERROR',
			query: query || '',
		};
	}
};

const postQueryLoadingHandler = ({
	query,
	selectedQueryFilters,
	postQueryLoading,
	filteredRecentActivities,
	postQueryDisabled,
}: StateHandlerArgs): QuickFindContent | undefined => {
	const hasQuery = query || selectedQueryFilters.length > 0;

	if (hasQuery && !postQueryDisabled && postQueryLoading) {
		return {
			state: 'POSTQUERY_LOADING',
			query: query || '',
			recentActivities: filteredRecentActivities.result,
		};
	}
};

const postQueryNoResultsHandler = ({
	query,
	selectedQueryFilters,
	searchResults,
	peopleAndTeams,
	postQueryDisabled,
}: StateHandlerArgs): QuickFindContent | undefined => {
	const hasQuery = query || selectedQueryFilters.length > 0;
	const totalResults = searchResults.result.length + peopleAndTeams.result.length;

	if (hasQuery && !postQueryDisabled && totalResults === 0) {
		return {
			state: 'POSTQUERY_NO_RESULTS',
			query: query || '',
		};
	}
};

const postQueryResultsHandler = ({
	query,
	selectedQueryFilters,
	filteredRecentQueries,
	filteredRecentActivities,
	searchResults,
	peopleAndTeams,
	postQueryDisabled,
}: StateHandlerArgs): QuickFindContent | undefined => {
	const hasQuery = query || selectedQueryFilters.length > 0;
	const totalResults = searchResults.result.length + peopleAndTeams.result.length;

	if (hasQuery && !postQueryDisabled && totalResults > 0) {
		return {
			state: 'POSTQUERY_RESULTS',
			query: query || '',
			recentQueries: filteredRecentQueries,
			recentActivities: filteredRecentActivities.result,
			searchResults: searchResults.result,
			peopleAndTeams: peopleAndTeams.result,
		};
	}
};

export const getQuickFindContent = (args: StateHandlerArgs): QuickFindContent => {
	if (!args.quickFindOpen) {
		return;
	}

	const handlers = [
		preQueryErrorHandler,
		preQueryLoadingHandler,
		preQueryNoResultsHandler,
		preQueryResultsHandler,
		postQueryErrorHandler,
		postQueryLoadingHandler,
		postQueryNoResultsHandler,
		postQueryResultsHandler,
	];

	for (const handler of handlers) {
		const result = handler(args);

		if (result) {
			return result;
		}
	}

	// This should never happen, but default to error state
	if (!args.query) {
		return {
			state: 'PREQUERY_ERROR',
			query: '',
		};
	} else {
		return {
			state: 'POSTQUERY_ERROR',
			query: args.query,
		};
	}
};
