import { B100, N900 } from '@atlaskit/theme/colors';
import { borderRadius } from '@atlaskit/theme/constants';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import type React from 'react';
import { Link, type LinkComponentProps } from '../search-link-component';
import { dialogWidth } from '../style-utils';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchFooterLinksContainer = styled.div({
	display: 'flex',
	flexWrap: 'nowrap',
	justifyContent: 'start',
	flexDirection: 'row',
	padding: `${token('space.150', '12px')} ${token('space.200', '16px')}`,
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: dialogWidth,
	color: token('color.text', N900),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LabelContainer = styled.div({
	marginRight: token('space.050', '4px'),
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TruncatedTextDiv = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PrimaryButtonWrapper = styled.div({
	flex: '1 1 0',
	overflow: 'hidden',
	maxWidth: 'max-content',
	marginRight: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PrimaryButtonStyledLink = styled(Link)<
	LinkComponentProps & {
		onClick?: (e: React.MouseEvent<HTMLElement>) => any;
	}
>({
	display: 'flex',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius()}px`,
	transitionDuration: '0.2s',
	transition: 'box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	'&:focus': {
		outline: 'none',
		boxShadow: `0 0 0 2px ${token('color.border.focused', B100)} inset`,
	},
	'&:hover': {
		textDecoration: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DropownMenuLink = styled(Link)<
	LinkComponentProps & {
		onClick?: (e: React.MouseEvent<HTMLElement>) => any;
	}
>({
	display: 'block',
	position: 'relative',
	'&:focus': {
		outline: `2px solid ${token('color.border.focused', B100)}`,
		outlineOffset: '-2px',
	},
	'&:hover': {
		textDecoration: 'none',
	},
});
