import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	assetsUnavailableMessage: {
		id: 'search_dialog_assets_unavailable_message',
		defaultMessage:
			'<bold>Assets</bold> will be available here soon. For now, try <searchLink>Assets search</searchLink>.',
		description:
			'Informs that Assets (product name) is currently unavailable, with a link to the Assets search page.',
	},
});
