import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	searchDialogAriaLabel: {
		id: 'search_dialog_aria_label',
		defaultMessage: 'Search Dialog',
		description: 'aria-label for search dialog container',
	},
	recentsHeading: {
		id: 'search_dialog_recents_heading',
		defaultMessage: 'Recently viewed',
		description: 'Heading for recents section',
	},
	searchResultsHeading: {
		id: 'recents_heading',
		defaultMessage: 'Search Results',
		description: 'Heading for search results section',
	},
});
