import React, { type RefObject } from 'react';

import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';

import { useSelectFilter } from '../../../../controllers/filters/base-select-filter';
import { useCurrentQueryFilter } from '../../../../controllers/store/quick-find';
import { contributorFilter } from '../../../../controllers/store/search/filters';

import { Option } from './filter-option';
import { FilterOptionSkeletons } from './filter-option-skeleton';
import { NoOptions } from './no-options';

type useQueryFilterResultsArgs = {
	localQuery: string;
	setLocalQuery: React.Dispatch<React.SetStateAction<string>>;
	inputRef?: RefObject<HTMLInputElement>;
};

const titleContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});

const iconStyles = xcss({
	width: '24px',
	height: '24px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const titleStyles = xcss({
	marginLeft: 'space.075',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const ContributorQueryFilterOptions = ({
	localQuery,
	setLocalQuery,
	inputRef,
}: useQueryFilterResultsArgs) => {
	const [filterData, { setOption }] = useSelectFilter({
		filterId: contributorFilter.id,
	});

	if (filterData?.isLoading) {
		return <FilterOptionSkeletons />;
	}

	if (!filterData || filterData.options.length === 0) {
		return <NoOptions />;
	}

	return (
		<>
			{filterData.options.map((option) => {
				return (
					<Option
						key={option.value}
						title={
							<Box xcss={titleContainerStyles}>
								<Box xcss={iconStyles}>
									<Avatar
										src={option.avatarUrl}
										size="small"
										borderColor="transparent"
										appearance="circle"
										name={option.label}
									/>
								</Box>
								<Box xcss={titleStyles} as="span">
									{option.label}
								</Box>
							</Box>
						}
						option={option}
						setOption={setOption}
						localQuery={localQuery}
						setLocalQuery={setLocalQuery}
						inputRef={inputRef}
					/>
				);
			})}
		</>
	);
};

export const useQueryFilterResults = ({
	localQuery,
	setLocalQuery,
	inputRef,
}: useQueryFilterResultsArgs) => {
	const currentQueryFilter = useCurrentQueryFilter();

	if (!currentQueryFilter) {
		return null;
	}

	if (currentQueryFilter.filterId === 'contributor') {
		return (
			<ContributorQueryFilterOptions
				localQuery={localQuery}
				setLocalQuery={setLocalQuery}
				inputRef={inputRef}
			/>
		);
	}

	return null;
};
