import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	/**
	 * Compass
	 */
	compass_tab_label: {
		id: 'product_search_dialog.compass.tab_label',
		defaultMessage: 'Compass',
		description: 'Label to display for the Compass search tab',
	},

	compass_components_section_heading: {
		id: 'product_search_dialog.compass.components_section_heading',
		defaultMessage: 'Components',
		description: 'Header for the components search flyout section',
	},

	compass_people_section_heading: {
		id: 'product_search_dialog.compass.people_section_heading',
		defaultMessage: 'People',
		description: 'People section header',
	},
	compass_teams_section_heading: {
		id: 'product_search_dialog.compass.teams_section_heading',
		defaultMessage: 'Teams',
		description: 'Teams section header',
	},
	compass_endpoints_section_heading: {
		id: 'product_search_dialog.compass.endpoints_section_heading',
		defaultMessage: 'Endpoints',
		description: 'Header for the endpoints search flyout section',
	},
	compass_expanded_input_placeholder: {
		id: 'product_search_dialog.compass.expanded.input-placeholder',
		defaultMessage: 'Search Compass',
		description: 'The placeholder which is visible to the user when Compass tab is active ',
	},
	compass_view_all_components: {
		id: 'product_search_dialog.compass.view_all_components_label',
		defaultMessage: 'View all components',
		description: 'Compass tab advanced search link text',
	},
	compass_view_all_results: {
		id: 'product_search_dialog.compass.view_all_results_label',
		defaultMessage: 'View all results',
		description: 'Compass tab advanced search link text for post query and error states',
	},
	compass_component_type_service: {
		id: 'product_search_dialog.compass.component_type.service',
		defaultMessage: 'Service',
		description: 'Tool tip shown for Service components',
	},
	compass_component_type_library: {
		id: 'product_search_dialog.compass.component_type.library',
		defaultMessage: 'Library',
		description: 'Tool tip shown for Library components',
	},
	compass_component_type_application: {
		id: 'product_search_dialog.compass.component_type.application',
		defaultMessage: 'Application',
		description: 'Tool tip shown for Application components',
	},
	compass_component_type_capability: {
		id: 'product_search_dialog.compass.component_type.capability',
		defaultMessage: 'Capability',
		description: 'Tool tip shown for Capability components',
	},
	compass_component_type_cloud_resource: {
		id: 'product_search_dialog.compass.component_type.cloud_resource',
		defaultMessage: 'Cloud resource',
		description: 'Tool tip shown for Cloud resource components',
	},
	compass_component_type_data_pipeline: {
		id: 'product_search_dialog.compass.component_type.data_pipeline',
		defaultMessage: 'Data pipeline',
		description: 'Tool tip shown for Data pipeline components',
	},
	compass_component_type_machine_learning_model: {
		id: 'product_search_dialog.compass.component_type.machine_learning_model',
		defaultMessage: 'Machine learning model',
		description: 'Tool tip shown for Machine learning model components',
	},
	compass_component_type_ui_element: {
		id: 'product_search_dialog.compass.component_type.ui_element',
		defaultMessage: 'UI element',
		description: 'Tool tip shown for UI element components',
	},
	compass_component_type_website: {
		id: 'product_search_dialog.compass.component_type.website',
		defaultMessage: 'Website',
		description: 'Tool tip shown for Website components',
	},
	compass_component_type_other: {
		id: 'product_search_dialog.compass.component_type.other',
		defaultMessage: 'Other',
		description: 'Tool tip shown for Other components',
	},
	compass_recently_viewed_components: {
		id: 'product_search_dialog.compass.recently_viewed_components',
		defaultMessage: 'Recently viewed components',
		description: 'Header for recently viewed components',
	},
	compass_recently_viewed_teams: {
		id: 'product_search_dialog.compass.recently_viewed_teams',
		defaultMessage: 'Recently viewed teams',
		description: 'Header for recently viewed teams',
	},
	compass_recently_viewed_endpoints: {
		id: 'product_search_dialog.compass.recently_viewed_endpoints',
		defaultMessage: 'Recently viewed endpoints',
		description: 'Header for recently viewed endpoints',
	},
});
