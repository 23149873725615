import React from 'react';
import { type AsyncResultProviderProps } from './result-provider-types';

const AsyncResultProvider = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/product-search-dialog/extensible/async/result-provider" */ './result-provider'
	).then(({ ResultProvider }) => ({
		default: ResultProvider,
	})),
);

export const AsyncProvider: React.FC<AsyncResultProviderProps> = (props) => (
	<AsyncResultProvider {...props}>{props.children}</AsyncResultProvider>
);
