import React from 'react';

import {
	ConfluenceClientsProvider,
	JiraSearchClientProvider,
	ExperimentProvider,
} from '@atlassian/product-search-dialog';

import { CONTEXT_PATH } from '@confluence/named-routes';

import { useQuickFindEnabled } from './experiment/QuickFindExperiment';

export interface ClientProviderConfig {
	accountId: string;
	aggregatorUrl: string;
	cloudId: string;
	collaborationGraphUrl: string;
	isUserAnonymous: boolean;
	useUrsForPeopleSearch: boolean;
}

export const ClientProvider: React.FC<{
	userId: string;
	cloudId: string;
	useUrsForPeopleSearch: boolean;
	children: ({
		clientProviderConfig,
	}: {
		clientProviderConfig: ClientProviderConfig;
	}) => JSX.Element;
}> = ({ userId, cloudId, children, useUrsForPeopleSearch }) => {
	const clientProviderConfig: ClientProviderConfig = {
		isUserAnonymous: !userId,
		aggregatorUrl: '/gateway/api/xpsearch-aggregator',
		collaborationGraphUrl: '/gateway/api/collaboration',
		cloudId,
		accountId: userId,
		useUrsForPeopleSearch,
	};

	const [quickFindEnabled] = useQuickFindEnabled();

	return (
		<ExperimentProvider quickFindEnabled={quickFindEnabled}>
			<ConfluenceClientsProvider
				config={{
					...clientProviderConfig,
					baseUrl: CONTEXT_PATH,
					scopes: undefined,
				}}
			>
				<JiraSearchClientProvider
					{...clientProviderConfig}
					baseUrl={`${window.location.protocol}//${window.location.hostname}`}
				>
					{children({ clientProviderConfig })}
				</JiraSearchClientProvider>
			</ConfluenceClientsProvider>
		</ExperimentProvider>
	);
};
