import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	/**
	 * Bitbucket
	 */
	bitbucket_repository_heading: {
		id: 'product_search_dialog.bitbucket.repository_heading',
		defaultMessage: 'Repositories',
		description: '',
	},

	bitbucket_advanced_search: {
		id: 'product_search_dialog.bitbucket.bitbucket_advanced_search',
		defaultMessage: 'Search for code in {source}',
		description: '',
	},

	bitbucket_repositories_section_title: {
		id: 'product_search_dialog.bitbucket.bitbucket_repositories_section_title',
		defaultMessage: 'Repositories',
		description: '',
	},

	bitbucket_recent_repositories: {
		id: 'product_search_dialog.bitbucket.bitbucket_recent_repositories',
		defaultMessage: 'Recently updated repositories',
		description: '',
	},

	bitbucket_expanded_input_placeholder: {
		id: 'product_search_dialog.bitbucket.expanded.input-placeholder',
		defaultMessage: 'Search Bitbucket',
		description: 'The placeholder which is visible to the user when bitbucket tab is active ',
	},
});
