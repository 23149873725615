import React from 'react';
import { N30, N700 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.span({
	background: token('color.background.neutral', N30),
	height: token('space.300', '24px'),
	borderRadius: token('border.radius.400', '4px'),
	border: "'1px solid transparent'",
	color: token('color.icon.subtle', N700),
	font: token('font.body.UNSAFE_small'),
	display: 'flex',
	alignItems: 'center',
	padding: `0 ${token('space.075', '6px')}`,
});

export const ControlIcon = () => <IconContainer>Ctrl</IconContainer>;
