import React from 'react';
import EmojiFrequentIcon from '@atlaskit/icon/core/migration/clock--emoji-frequent';
import { N600 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.span({
	marginLeft: token('space.negative.025', '-2px'),
	marginTop: token('space.025', '2px'),
});

export const DefaultIcon = () => (
	<IconContainer>
		<EmojiFrequentIcon
			color={token('color.icon.accent.gray', N600)}
			LEGACY_size="medium"
			spacing="spacious"
			label="recent-search"
		/>
	</IconContainer>
);
