import React from 'react';
import { useIntl } from 'react-intl-next';

import Icon, { type Size } from '@atlaskit/icon';
import Tooltip from '@atlaskit/tooltip';

import { COMPONENT_TYPES } from './types';
import { IconBackground } from './styled';

type ResultIconProps = {
	type: string;
	size?: Size;
};

export const ResultIcon = ({ type, size = 'medium' }: ResultIconProps) => {
	const intl = useIntl();
	const componentType = COMPONENT_TYPES[type] ?? COMPONENT_TYPES.OTHER;

	return (
		<Tooltip content={intl.formatMessage(componentType.message)}>
			<IconBackground color={componentType.color} size={size}>
				<Icon label={type} glyph={componentType.icon} size={size} />
			</IconBackground>
		</Tooltip>
	);
};
