import { useMemo, useCallback } from 'react';
import { ConfluenceFilters, CONFLUENCE_PRODUCT_ID } from '../types';
import { FilterOptionSource } from '../../../../common/filters/types';
import {
	type GenericFilterOption,
	DEFAULT_OPTION,
	useFilterStore,
	FilterUIVariant,
	type DateFilterOptionsAndValues,
	LastModifiedValues,
} from '../../../filters';
import { messages } from '../messages';
import { useIntl } from 'react-intl-next';

const getUTCDateRange = (fromOffset: number, toOffset: number): { from: string; to: string } => {
	const currentDate = new Date();
	const startOfDay = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth(),
		currentDate.getDate(),
	);
	const endOfDay = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth(),
		currentDate.getDate(),
		23,
		59,
		59,
		999,
	);

	const from = new Date(startOfDay.getTime() - fromOffset * 24 * 60 * 60 * 1000);
	const to = new Date(endOfDay.getTime() - toOffset * 24 * 60 * 60 * 1000);

	return {
		from: from.toISOString().split('Z')[0],
		to: to.toISOString().split('Z')[0],
	};
};

export const useConfluenceDateFilter = () => {
	const { formatMessage } = useIntl();

	const dateFilterOptionsAndValues: DateFilterOptionsAndValues = useMemo(() => {
		return {
			[LastModifiedValues.DEFAULT]: {
				filter: {
					id: LastModifiedValues.DEFAULT,
					label: formatMessage(messages.confluence_date_filter_any_label),
					filterSource: FilterOptionSource.STATIC,
					avatarUrl: '',
					isChecked: true,
					isVisible: true,
				},
				dateRange: { from: '', to: '' },
			},
			[LastModifiedValues.TODAY]: {
				filter: {
					id: LastModifiedValues.TODAY,
					label: formatMessage(messages.confluence_date_filter_today_label),
					filterSource: FilterOptionSource.STATIC,
					avatarUrl: '',
					isChecked: false,
					isVisible: true,
				},
				dateRange: getUTCDateRange(0, 0),
			},
			[LastModifiedValues.YESTERDAY]: {
				filter: {
					id: LastModifiedValues.YESTERDAY,
					label: formatMessage(messages.confluence_date_filter_yesterday_label),
					filterSource: FilterOptionSource.STATIC,
					avatarUrl: '',
					isChecked: false,
					isVisible: true,
				},
				dateRange: getUTCDateRange(1, 1),
			},
			[LastModifiedValues.PAST_SEVEN_DAYS]: {
				filter: {
					id: LastModifiedValues.PAST_SEVEN_DAYS,
					label: formatMessage(messages.confluence_date_filter_week_label),
					filterSource: FilterOptionSource.STATIC,
					avatarUrl: '',
					isChecked: false,
					isVisible: true,
				},
				dateRange: getUTCDateRange(7, 0),
			},
			[LastModifiedValues.PAST_THIRTY_DAYS]: {
				filter: {
					id: LastModifiedValues.PAST_THIRTY_DAYS,
					label: formatMessage(messages.confluence_date_filter_month_label),
					filterSource: FilterOptionSource.STATIC,
					avatarUrl: '',
					isChecked: false,
					isVisible: true,
				},
				dateRange: getUTCDateRange(30, 0),
			},
			[LastModifiedValues.PAST_YEAR]: {
				filter: {
					id: LastModifiedValues.PAST_YEAR,
					label: formatMessage(messages.confluence_date_filter_year_label),
					filterSource: FilterOptionSource.STATIC,
					avatarUrl: '',
					isChecked: false,
					isVisible: true,
				},
				dateRange: getUTCDateRange(365, 0),
			},
		};
	}, [formatMessage]);

	const dateFilterOptions = useMemo(
		() =>
			dateFilterOptionsAndValues
				? Object.values(dateFilterOptionsAndValues).map((value) => value.filter)
				: [],
		[dateFilterOptionsAndValues],
	);

	const dateFilterStore = useFilterStore<GenericFilterOption>(
		dateFilterOptions,
		CONFLUENCE_PRODUCT_ID,
		ConfluenceFilters.Date,
	);

	const appliedDateFilter = dateFilterStore?.availableFilters?.find(
		(f) => f.isChecked && f.id !== DEFAULT_OPTION,
	)?.id;

	const getLastModifiedValue = useCallback(() => {
		return appliedDateFilter &&
			dateFilterOptionsAndValues &&
			dateFilterOptionsAndValues[appliedDateFilter]
			? {
					'@type': ConfluenceFilters.Date,
					from: dateFilterOptionsAndValues[appliedDateFilter].dateRange.from,
					to: dateFilterOptionsAndValues[appliedDateFilter].dateRange.to,
				}
			: null;
	}, [appliedDateFilter, dateFilterOptionsAndValues]);

	const confluenceTabDateFilter = useMemo(() => {
		return [
			{
				['@type']: ConfluenceFilters.Date,
				uiVariant: FilterUIVariant.STATIC_SELECT,
				fieldName: 'from',
				store: dateFilterStore,
				loadFilterOptions: async () => [],
				sectionLabel: formatMessage(messages.confluence_date_filter_title),
				findMoreLabel: '',
				mapSelectionToAppliedValue: () => getLastModifiedValue(),
			},
		];
	}, [dateFilterStore, formatMessage, getLastModifiedValue]);

	return {
		appliedDateFilter,
		confluenceTabDateFilter,
	};
};
