import React, { type ReactNode } from 'react';
import Badge from '@atlaskit/badge';
import {
	EnlargedSearchResultSectionContainer,
	SearchResultSectionTitleBadgeContainer,
	EnlargedSearchResultSectionHeading,
	EnlargedSearchResultsSectionTitle,
} from './search-result-section.styled';

export interface Props {
	children: ReactNode;
	/**
	 * The title of the section
	 */
	title: string | JSX.Element;
	/**
	 * If provided this will show the total number of results next to the title.
	 */
	totalResults?: number;
}

export const EnlargedSearchResultSection = ({ title, totalResults, children }: Props) => {
	const hasTitle = Boolean(title);

	return (
		<EnlargedSearchResultSectionContainer>
			{hasTitle && (
				<EnlargedSearchResultSectionHeading>
					<EnlargedSearchResultsSectionTitle>
						{title}
						{totalResults ? (
							<SearchResultSectionTitleBadgeContainer>
								<Badge testId="search-result-count-badge">{totalResults}</Badge>
							</SearchResultSectionTitleBadgeContainer>
						) : null}
					</EnlargedSearchResultsSectionTitle>
				</EnlargedSearchResultSectionHeading>
			)}

			{children}
		</EnlargedSearchResultSectionContainer>
	);
};
