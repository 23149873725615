import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { messages } from '../messages';
import FeedbackCollector from '../../feedback-collector/feedback-collector';
import { type FeedbackButtonProps } from '../../../common/feedback-collector/feedback-button';
import { Box, xcss } from '@atlaskit/primitives';
import { FeedbackButton } from '@atlassian/search-common';

const footerContainerStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	borderTop: '1px solid',
	borderColor: 'color.border',
	display: 'flex',
	alignItems: 'center',
	color: 'color.text',
	gap: 'space.150',
});

export const FeedbackFooter = () => {
	return (
		<Box xcss={footerContainerStyles}>
			<FormattedMessage {...messages.quick_find_footer_text} />
			<FeedbackCollector
				TriggerComponent={({ onClick }: FeedbackButtonProps) => (
					<FeedbackButton onClick={onClick} />
				)}
			/>
		</Box>
	);
};
