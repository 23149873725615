import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	// NOT USED
	jira_boards_icon_accessibility_text: {
		id: 'product_search_dialog.jira.boards_icon_accessibility_text',
		defaultMessage: 'Board icon',
		description: 'The alternative accessibility text for a Jira Software board icon.',
	},

	date_filter_today_label: {
		id: 'product_search_dialog.jira.date_filter_today',
		defaultMessage: 'Today',
		description: 'Date filter label for today.',
	},

	date_filter_yesterday_label: {
		id: 'product_search_dialog.jira.date_filter_yesterday',
		defaultMessage: 'Yesterday',
		description: 'Date filter label for yesterday.',
	},

	date_filter_past_7_days_label: {
		id: 'product_search_dialog.jira.date_filter_past_7_days',
		defaultMessage: 'Past 7 days',
		description: 'Date filter label for past 7 days.',
	},

	date_filter_past_30_days_label: {
		id: 'product_search_dialog.jira.date_filter_past_30_days',
		defaultMessage: 'Past 30 days',
		description: 'Date filter label for past 30 days.',
	},

	date_filter_past_year_label: {
		id: 'product_search_dialog.jira.date_filter_past_year',
		defaultMessage: 'Past year',
		description: 'Date filter label for past year.',
	},

	report_by_filters_title: {
		id: 'product_search_dialog.jira.report_by_filters',
		defaultMessage: 'Filter by reporter',
		description: 'Section title for report by filters in Jira',
	},

	reported_by_me_filter_option: {
		id: 'product_search_dialog.jira.reported_by_me_filter',
		defaultMessage: 'Reported by me',
		description: 'Filter option for reported by me',
	},

	issue_labels_filters_title: {
		id: 'product_search_dialog.jira.issue_labels_filters',
		defaultMessage: 'Filter by label',
		description: 'Section title for label filter in Jira',
	},

	issue_labels_filters_placeholder: {
		id: 'product_search_dialog.jira.issue_labels_filters_placeholder',
		defaultMessage: 'Select label',
		description: 'Placeholder text inside Dropdown Select component for label filter',
	},

	issue_labels_filters_options_group_label: {
		id: 'product_search_dialog.jira.issue_labels_filters_options_group_label',
		defaultMessage: 'All labels',
		description: 'Title/label of the options group within the issue labels filter dropdown',
	},
});
