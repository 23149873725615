import React from 'react';
import { type IntlShape } from 'react-intl-next';
import { messages } from '../../../messages';
import { dateFormatter } from '../utils/date-formatter';

export const getTimeDetailForJiraIssueResult = (
	intl: IntlShape,
	formatDate?: (updated: string) => JSX.Element,
	updated?: string,
): JSX.Element | undefined => {
	if (!updated) {
		const message = intl.formatMessage(messages.common_recently_viewed_section_heading);
		return <span title={message}>{message}</span>;
	}

	if (formatDate) {
		return formatDate(updated);
	} else {
		return dateFormatter(updated);
	}
};
