import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	externalLinkIconLabel: {
		id: 'inline-product-filters.external-link-icon.label',
		defaultMessage: 'External Link Icon',
		description: 'Label for external link icon',
	},
	moreButtonLabel: {
		id: 'inline-product-filters.more-filters.label',
		defaultMessage: 'More',
		description: 'Label to convey that there are more filters to choose from',
	},
});
