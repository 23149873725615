import React, { useCallback } from 'react';

import { useIntl } from 'react-intl-next';

import SearchIcon from '@atlaskit/icon/core/search';
import { Inline } from '@atlaskit/primitives';

import { CommandIcon } from '../../../../../common/constants/icons/command';
import { ReturnIcon } from '../../../../../common/constants/icons/return';
import { PrimaryProductKeys } from '../../../../../common/constants/products';
import { useAppContext } from '../../../../../common/ui/app-context';
import { SearchFooter } from '../../../../../common/ui/quick-find/search-dialog-footer';
import type { State } from '../../../../../common/utils/quick-find/state';
import { type QuickFindFooterEvent } from '../../../../../controllers/quick-find/utils';
import { useQuickFindQuery } from '../../../../../controllers/store/quick-find';

import { messages } from './messages';

const ACTION_SUBJECT_ID = 'jiraAdvancedIssueSearch';

type Props = {
	state: State;
	onClick: (props: QuickFindFooterEvent) => void;
};

export const JiraAdvancedSearchLink = ({ state, onClick }: Props) => {
	const intl = useIntl();

	const quickFindQuery = useQuickFindQuery();

	const { generateSearchUrl, queryParams } = useAppContext();

	const icon = (
		<SearchIcon label={intl.formatMessage(messages.jira_advanced_search)} color="currentColor" />
	);

	const rightMetadata = (
		<Inline alignBlock="center" space="space.100">
			<CommandIcon />
			<ReturnIcon />
		</Inline>
	);

	const onResultClicked = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			onClick({ state, event, actionSubjectId: ACTION_SUBJECT_ID });
		},
		[onClick, state],
	);

	const url = generateSearchUrl(
		{
			...queryParams,
			product: PrimaryProductKeys.Jira,
			text: quickFindQuery,
		},
		true,
	);

	return (
		<SearchFooter
			icon={icon}
			title={intl.formatMessage(messages.jira_advanced_search)}
			rightMetadata={rightMetadata}
			url={url}
			onResultClicked={onResultClicked}
		/>
	);
};
