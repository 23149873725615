/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActivitiesObjectType {
  BLOGPOST = "BLOGPOST",
  DATABASE = "DATABASE",
  EMBED = "EMBED",
  ISSUE = "ISSUE",
  PAGE = "PAGE",
  WHITEBOARD = "WHITEBOARD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecentQuickSearchQuery
// ====================================================

export interface RecentQuickSearchQuery_activities_myActivities_viewed_edges_node_object_content_links {
  webui: string | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_viewed_edges_node_object_content_space {
  id: string | null;
  name: string | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_viewed_edges_node_object_content {
  id: string | null;
  title: string | null;
  links: RecentQuickSearchQuery_activities_myActivities_viewed_edges_node_object_content_links | null;
  type: string | null;
  space: RecentQuickSearchQuery_activities_myActivities_viewed_edges_node_object_content_space | null;
  status: string | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_viewed_edges_node_object {
  id: string;
  content: RecentQuickSearchQuery_activities_myActivities_viewed_edges_node_object_content | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_viewed_edges_node {
  id: string;
  object: RecentQuickSearchQuery_activities_myActivities_viewed_edges_node_object | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_viewed_edges {
  node: RecentQuickSearchQuery_activities_myActivities_viewed_edges_node | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_viewed {
  edges: (RecentQuickSearchQuery_activities_myActivities_viewed_edges | null)[] | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_workedOn_edges_node_object_content_links {
  webui: string | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_workedOn_edges_node_object_content_space {
  id: string | null;
  name: string | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_workedOn_edges_node_object_content {
  id: string | null;
  title: string | null;
  links: RecentQuickSearchQuery_activities_myActivities_workedOn_edges_node_object_content_links | null;
  type: string | null;
  space: RecentQuickSearchQuery_activities_myActivities_workedOn_edges_node_object_content_space | null;
  status: string | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_workedOn_edges_node_object {
  id: string;
  content: RecentQuickSearchQuery_activities_myActivities_workedOn_edges_node_object_content | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_workedOn_edges_node {
  id: string;
  object: RecentQuickSearchQuery_activities_myActivities_workedOn_edges_node_object | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_workedOn_edges {
  node: RecentQuickSearchQuery_activities_myActivities_workedOn_edges_node | null;
}

export interface RecentQuickSearchQuery_activities_myActivities_workedOn {
  edges: (RecentQuickSearchQuery_activities_myActivities_workedOn_edges | null)[] | null;
}

export interface RecentQuickSearchQuery_activities_myActivities {
  viewed: RecentQuickSearchQuery_activities_myActivities_viewed | null;
  workedOn: RecentQuickSearchQuery_activities_myActivities_workedOn | null;
}

export interface RecentQuickSearchQuery_activities {
  myActivities: RecentQuickSearchQuery_activities_myActivities | null;
}

export interface RecentQuickSearchQuery_myVisitedSpaces_nodes_spaces_icon {
  path: string;
}

export interface RecentQuickSearchQuery_myVisitedSpaces_nodes_spaces_links {
  webui: string | null;
}

export interface RecentQuickSearchQuery_myVisitedSpaces_nodes_spaces {
  id: string | null;
  key: string | null;
  name: string | null;
  icon: RecentQuickSearchQuery_myVisitedSpaces_nodes_spaces_icon | null;
  links: RecentQuickSearchQuery_myVisitedSpaces_nodes_spaces_links | null;
}

export interface RecentQuickSearchQuery_myVisitedSpaces_nodes {
  spaces: (RecentQuickSearchQuery_myVisitedSpaces_nodes_spaces | null)[] | null;
}

export interface RecentQuickSearchQuery_myVisitedSpaces {
  nodes: RecentQuickSearchQuery_myVisitedSpaces_nodes;
}

export interface RecentQuickSearchQuery {
  activities: RecentQuickSearchQuery_activities | null;
  myVisitedSpaces: RecentQuickSearchQuery_myVisitedSpaces | null;
}

export interface RecentQuickSearchQueryVariables {
  cloudId: string;
  contentItemsLimit: number;
  spaceLimit: number;
  objectTypes?: ActivitiesObjectType[] | null;
  workedOnAPIEnabled: boolean;
}
