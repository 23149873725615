import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	confluence_typing_state_header_text: {
		id: 'product_search_dialog.confluence.typing_state_header_text',
		defaultMessage: 'Search for content in Confluence',
		description: 'Text for the typing state header for confluence',
	},

	confluence_date_filter_title: {
		id: 'product_search_dialog.confluence.date_filter_title',
		defaultMessage: 'Last updated',
		description: 'Section title for date filters in Confluence',
	},

	confluence_date_filter_today_label: {
		id: 'product_search_dialog.confluence.date_filter_today',
		defaultMessage: 'Today',
		description: 'Date filter label for current day.',
	},

	confluence_date_filter_yesterday_label: {
		id: 'product_search_dialog.confluence.date_filter_yesterday',
		defaultMessage: 'Yesterday',
		description: 'Date filter label for previous day.',
	},

	confluence_date_filter_week_label: {
		id: 'product_search_dialog.confluence.date_filters_week',
		defaultMessage: 'Past 7 days',
		description: 'Date filter label for past week.',
	},

	confluence_date_filter_month_label: {
		id: 'product_search_dialog.confluence.date_filters_month',
		defaultMessage: 'Past 30 days',
		description: 'Date filter label for past month.',
	},

	confluence_date_filter_year_label: {
		id: 'product_search_dialog.confluence.date_filters_year',
		defaultMessage: 'Past year',
		description: 'Date filter label for past year.',
	},

	confluence_date_filter_any_label: {
		id: 'product_search_dialog.confluence.date_filters_any',
		defaultMessage: 'Any time',
		description: 'Date filter label for any time.',
	},
});
