import { useCallback } from 'react';
import { type TrelloApiCard } from './types';

import { useAnalytics } from '../../../../common/analytics';
import { onCardRequestFailed } from '../../../../common/analytics/events';

const cards = new Map<string, TrelloApiCard>();

export const useTrelloCard = () => {
	const { fireAnalyticsEvent } = useAnalytics();
	const loadCard = useCallback(
		async (cardId: string): Promise<TrelloApiCard | undefined> => {
			try {
				const response = await fetch(
					`/1/card/${cardId}?fields=name,closed,url,cover,labels,idAttachmentCover&attachments=cover`,
				);
				if (response.ok) {
					const cardResponse = await response.json();
					cards.set(cardId, cardResponse);
					return cardResponse;
				} else {
					throw new Error(`Invalid response: ${response?.status}`);
				}
			} catch (e) {
				if (e instanceof Error) {
					fireAnalyticsEvent({
						...onCardRequestFailed({
							attributes: {
								errorMsg: e?.message,
								errorName: e?.name,
							},
						}),
					});
				}
			}
		},
		[fireAnalyticsEvent],
	);
	const getCard = useCallback(
		async (cardId: string) => {
			return cards.get(cardId) ? Promise.resolve(cards.get(cardId)) : loadCard(cardId);
		},
		[loadCard],
	);
	return {
		getCard,
	};
};
