import React, { useContext, useMemo, type PropsWithChildren } from 'react';
import { AggregatorClient, type ScopedAggregatorResponse } from '../../common/clients';
import type { AllFilters } from '../filters/filter-types';
import { useExperiments } from '../../common/experiment-provider';

// Scope is a string, Filters must be extended from FilterInterface
export type GenericAggregatorClient = AggregatorClient<
	ScopedAggregatorResponse<string>,
	AllFilters
>;

type AggregatorClientContext = GenericAggregatorClient | undefined;

const AggregatorClientContext = React.createContext<AggregatorClientContext>(undefined);

export interface AggregatorClientFeatures {}

export interface Props {
	abTestCloudId: string;
	aggregatorUrl: string;
	features?: AggregatorClientFeatures;
}

export const AggregatorClientContextProvider = ({
	abTestCloudId,
	aggregatorUrl,
	children,
}: PropsWithChildren<Props>) => {
	const { backendExperiment } = useExperiments();

	const aggregatorClient = useMemo(
		() =>
			new AggregatorClient<ScopedAggregatorResponse<string>, AllFilters>({
				url: aggregatorUrl,
				cloudId: abTestCloudId,
				siteMasterList: [],
				backendExperiment,
			}),
		[abTestCloudId, aggregatorUrl, backendExperiment],
	);

	return (
		<AggregatorClientContext.Provider value={aggregatorClient}>
			{children}
		</AggregatorClientContext.Provider>
	);
};

/**
 * Returns a generic Aggregator client with no typing
 * @deprecated use {@link useTypedAggregatorClient} instead
 */
export const useAggregatorClient = () => {
	const aggregatorClient = useContext(AggregatorClientContext);
	return aggregatorClient;
};

/**
 * Fetch an Aggregator client from the context.
 * @typeParam Response the client's response type
 * @typeParam Scope the client's scope type
 * @returns an {@link AggregatorClient}
 */
export const useTypedAggregatorClient = <
	Response extends ScopedAggregatorResponse<Scope>,
	Scope = Response['id'],
>() => {
	// Casting done here to ensure that the result types match the scopes requested
	const untypedClient: any = useContext(AggregatorClientContext);
	return untypedClient as AggregatorClient<Response, AllFilters> | undefined;
};
