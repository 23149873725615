import React from 'react';
import { N30, N700 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const CommandIcon = () => (
	<svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="20" height="20" rx="3" fill={token('color.background.neutral', N30)} />
		<path
			d="M7.89453 8.89551H6.88965C5.80957 8.89551 4.93457 8.04785 4.93457 6.96094C4.93457 5.87402 5.80957 4.99902 6.88965 4.99902C7.96973 4.99902 8.84473 5.87402 8.84473 6.96094V7.95898H11.1553V6.96094C11.1553 5.87402 12.0303 4.99902 13.1104 4.99902C14.1904 4.99902 15.0654 5.87402 15.0654 6.96094C15.0654 8.04785 14.1904 8.89551 13.1104 8.89551H12.1123V11.2402H13.1104C14.1904 11.2402 15.0654 12.0879 15.0654 13.1748C15.0654 14.2617 14.1904 15.1367 13.1104 15.1367C12.0303 15.1367 11.1553 14.2617 11.1553 13.1748V12.1768H8.84473V13.1748C8.84473 14.2617 7.96973 15.1367 6.88965 15.1367C5.80957 15.1367 4.93457 14.2617 4.93457 13.1748C4.93457 12.0879 5.80957 11.2402 6.88965 11.2402H7.89453V8.89551ZM6.88965 7.97266H7.89453V6.96094C7.89453 6.40723 7.43652 5.95605 6.88965 5.95605C6.34277 5.95605 5.88477 6.40723 5.88477 6.96094C5.88477 7.51465 6.34277 7.97266 6.88965 7.97266ZM13.1104 7.97266C13.6572 7.97266 14.1152 7.51465 14.1152 6.96094C14.1152 6.40723 13.6572 5.95605 13.1104 5.95605C12.5635 5.95605 12.1123 6.40723 12.1123 6.96094V7.97266H13.1104ZM8.84473 11.2539H11.1553V8.88184H8.84473V11.2539ZM6.88965 12.1562C6.34277 12.1562 5.88477 12.6143 5.88477 13.168C5.88477 13.7217 6.34277 14.1729 6.88965 14.1729C7.43652 14.1729 7.89453 13.7217 7.89453 13.168V12.1562H6.88965ZM13.1104 12.1562H12.1123V13.168C12.1123 13.7217 12.5635 14.1729 13.1104 14.1729C13.6572 14.1729 14.1152 13.7217 14.1152 13.168C14.1152 12.6143 13.6572 12.1562 13.1104 12.1562Z"
			fill={token('color.icon.subtle', N700)}
		/>
	</svg>
);
