import { type SearchItemsTransformerProps } from '../../../product-router/product/result-provider/result-provider-types';
import {
	RECENT_ITEMS_SECTION_ID,
	CONFLUENCE_PAGE_BLOGPOST_ATTACHMENT_SECTION_ID,
} from '@atlassian/search-client';

import type { SearchResult } from '../../../product-router';
import { type QuickFindSearchResultType } from '@atlassian/search-client';

const MAX_RENDERED_SEARCH_RESULTS = 5;
/**
 * This transformer removes any duplicate from search results and recently viewed items
 */
export const DeduplicateRecentlyViewedAndResultsTransformer = ({
	preQueryItems,
	postQueryItems,
	productState,
	children,
}: SearchItemsTransformerProps) => {
	const searchResults: SearchResult<QuickFindSearchResultType>[] =
		postQueryItems.sections.find(
			(section) => section.id === CONFLUENCE_PAGE_BLOGPOST_ATTACHMENT_SECTION_ID,
		)?.searchResults || [];
	const recentlyViewedResults: SearchResult<QuickFindSearchResultType>[] =
		postQueryItems.sections.find((section) => section.id === RECENT_ITEMS_SECTION_ID)
			?.searchResults || [];

	const dedupedSearchResults: SearchResult<QuickFindSearchResultType>[] = searchResults.filter(
		(searchResult) => {
			// Check for duplicates using ARIs
			const isDuplicate = recentlyViewedResults.some(
				(recentlyViewedResult) => recentlyViewedResult.ari === searchResult.ari,
			);
			return searchResult.ari && !isDuplicate;
		},
	);

	const postDeduplication = {
		...postQueryItems,
		sections: postQueryItems.sections.map((section) => {
			if (section.id === CONFLUENCE_PAGE_BLOGPOST_ATTACHMENT_SECTION_ID) {
				return {
					...section,
					searchResults: dedupedSearchResults.slice(0, MAX_RENDERED_SEARCH_RESULTS),
				};
			}
			return section;
		}),
	};

	return children({
		preQueryItems,
		postQueryItems: postDeduplication,
		productState,
	});
};
