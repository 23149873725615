import React from 'react';

import type { FocusAreaIconMapProps, FocusAreaSizeVariations } from './types';

export const ICON_SIZE_MAP = {
	xsmall: '16',
	small: '24',
	medium: '32',
	large: '48',
} as const;

export const DEFAULT_ICON_LABEL = 'Focus area icon';

const iconViewBox = (size: FocusAreaSizeVariations) =>
	size === 'xsmall' ? '4 4 16 16' : '0 0 24 24';

export const FocusAreaIconMap: FocusAreaIconMapProps = {
	0: (color, size, testId, label = DEFAULT_ICON_LABEL) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			height={ICON_SIZE_MAP[size]}
			width={ICON_SIZE_MAP[size]}
			viewBox={iconViewBox(size)}
			data-testid={testId}
			aria-label={label}
			role="img"
			stroke={color}
		>
			<circle cx="11.9167" cy="7.375" r="1.625" strokeWidth="1.5" />
			<circle cx="17.4583" cy="16.875" r="1.625" strokeWidth="1.5" />
			<path
				strokeWidth="1.5"
				d="M11.917 9.065v2.938m0 0h3.541a2 2 0 0 1 2 2v.84m-5.541-2.84H8.375a2 2 0 0 0-2 2v1.053m5.542-3.053v3.053"
			/>
			<circle cx="11.9167" cy="16.875" r="1.625" strokeWidth="1.5" />
			<circle cx="6.375" cy="16.875" r="1.625" strokeWidth="1.5" />
		</svg>
	),
	1: (color, size, testId, label = DEFAULT_ICON_LABEL) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			height={ICON_SIZE_MAP[size]}
			width={ICON_SIZE_MAP[size]}
			viewBox={iconViewBox(size)}
			data-testid={testId}
			aria-label={label}
			role="img"
			stroke={color}
		>
			<circle cx="11.9879" cy="6.99597" r="1.99597" strokeWidth="1.5" />
			<path
				strokeWidth="1.5"
				d="M12.099 9.214v2.543m0 0H14.2a2 2 0 0 1 2 2v.668M12.1 11.757H9.996a2 2 0 0 0-2 2v.668"
			/>
			<circle cx="16.2015" cy="16.754" r="1.99597" strokeWidth="1.5" />
			<circle cx="7.99597" cy="16.754" r="1.99597" strokeWidth="1.5" />
		</svg>
	),
	2: (color, size, testId, label = DEFAULT_ICON_LABEL) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			height={ICON_SIZE_MAP[size]}
			width={ICON_SIZE_MAP[size]}
			viewBox={iconViewBox(size)}
			data-testid={testId}
			aria-label={label}
			role="img"
			stroke={color}
		>
			<circle cx="6.66667" cy="17.3333" r="1.91667" strokeWidth="1.5" />
			<circle cx="12" cy="12" r="1.91667" strokeWidth="1.5" />
			<circle cx="17.3334" cy="6.66667" r="1.91667" strokeWidth="1.5" />
			<path
				strokeWidth="1.5"
				d="M6.667 15.556v-2.37c0-.655.53-1.186 1.185-1.186h2.37M17.333 8.444v2.37c0 .655-.53 1.186-1.185 1.186h-2.37"
			/>
		</svg>
	),
	3: (color, size, testId, label = DEFAULT_ICON_LABEL) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			height={ICON_SIZE_MAP[size]}
			width={ICON_SIZE_MAP[size]}
			viewBox={iconViewBox(size)}
			data-testid={testId}
			aria-label={label}
			role="img"
			stroke={color}
		>
			<circle
				cx="17.569"
				cy="16.6379"
				r="1.86207"
				strokeWidth="1.5"
				transform="rotate(90 17.569 16.638)"
			/>
			<circle
				cx="6.86205"
				cy="6.86207"
				r="1.86207"
				strokeWidth="1.5"
				transform="rotate(90 6.862 6.862)"
			/>
			<path
				strokeWidth="1.5"
				d="M8.048 6.897h1.074a2.5 2.5 0 0 1 2.5 2.5v4.775a2.5 2.5 0 0 0 2.5 2.5h2.28"
			/>
		</svg>
	),
	4: (color, size, testId, label = DEFAULT_ICON_LABEL) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			height={ICON_SIZE_MAP[size]}
			width={ICON_SIZE_MAP[size]}
			viewBox={iconViewBox(size)}
			data-testid={testId}
			aria-label={label}
			role="img"
			stroke={color}
		>
			<circle cx="16.8001" cy="16.8" r="2.2" strokeWidth="1.5" />
			<circle cx="7.95" cy="7.95" r="2.2" strokeWidth="1.5" />
			<path
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M7.95 10.446v6.104c0 .138.112.25.25.25h5.877m2.723-2.95V8.2a.25.25 0 0 0-.25-.25H10.9"
			/>
		</svg>
	),
	5: (color, size, testId, label = DEFAULT_ICON_LABEL) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			height={ICON_SIZE_MAP[size]}
			width={ICON_SIZE_MAP[size]}
			viewBox={iconViewBox(size)}
			data-testid={testId}
			aria-label={label}
			role="img"
			stroke={color}
		>
			<circle cx="16.7059" cy="15.7059" r="2.29412" strokeWidth="1.5" />
			<circle cx="8.29412" cy="7.29412" r="2.29412" strokeWidth="1.5" />
			<path
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M8.322 9.875v5.554c0 .138.111.25.25.25h5.553"
			/>
		</svg>
	),
	6: (color, size, testId, label = DEFAULT_ICON_LABEL) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			height={ICON_SIZE_MAP[size]}
			width={ICON_SIZE_MAP[size]}
			// Level 6 icon uses a custom xsmall viewport to fit and center icon
			viewBox={size === 'xsmall' ? '3 5 16.2 16.2' : '0 0 24 24'}
			data-testid={testId}
			aria-label={label}
			role="img"
			stroke={color}
		>
			<circle cx="12.6111" cy="7.58333" r="1.83333" strokeWidth="1.5" />
			<path
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M10.028 7.583H5a1 1 0 0 0-1 1v7.612M11.75 20.5h5.028a1 1 0 0 0 1-1v-7.611"
			/>
		</svg>
	),
	7: (color, size, testId, label = DEFAULT_ICON_LABEL) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			height={ICON_SIZE_MAP[size]}
			width={ICON_SIZE_MAP[size]}
			viewBox={iconViewBox(size)}
			data-testid={testId}
			aria-label={label}
			role="img"
			stroke={color}
		>
			<circle cx="14.4451" cy="16.3594" r="2.39062" strokeWidth="1.5" />
			<path
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M7 6v10.2c0 .139.112.25.25.25h4.258M7.41 9.893h3.688"
			/>
		</svg>
	),
	8: (color, size, testId, label = DEFAULT_ICON_LABEL) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			height={ICON_SIZE_MAP[size]}
			width={ICON_SIZE_MAP[size]}
			viewBox={iconViewBox(size)}
			data-testid={testId}
			aria-label={label}
			role="img"
			stroke={color}
		>
			<circle cx="15.5189" cy="15.3112" r="1.98113" strokeWidth="1.5" />
			<path strokeLinecap="round" strokeWidth="1.5" d="M7 6v4.505c0 .138.112.25.25.25h5.803" />
			<path
				fill={color}
				stroke="none"
				d="M10.777 10.854a.75.75 0 1 0-1.5 0h1.5Zm-1.5 0v3.987h1.5v-3.987h-1.5Zm1 4.987h2.776v-1.5h-2.776v1.5Zm-1-1a1 1 0 0 0 1 1v-1.5a.5.5 0 0 1 .5.5h-1.5Z"
			/>
		</svg>
	),
	9: (color, size, testId, label = DEFAULT_ICON_LABEL) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			height={ICON_SIZE_MAP[size]}
			width={ICON_SIZE_MAP[size]}
			viewBox={iconViewBox(size)}
			data-testid={testId}
			aria-label={label}
			role="img"
			stroke={color}
		>
			<circle cx="11.6249" cy="11.6249" r="2.08929" strokeWidth="1.5" />
			<path
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M5 12.098v5.152a1 1 0 0 0 1 1h5.625M18.25 11.152V6a1 1 0 0 0-1-1h-5.625"
			/>
		</svg>
	),
};
