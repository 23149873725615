import React, { type FC, useEffect, useState } from 'react';
import { injectIntl, type WrappedComponentProps } from 'react-intl-next';
import { isGlobalExperienceWorkspaceForCloudId } from '../../../common/clients/atlas-rest-client';
import { useSearchConfigContext } from '../../../common/search-config-provider';
import { messages } from './messages';
import { SearchDialogProduct } from '../../product-router';

import { useGenericFilters, useSiteFilterDefinition } from '../../filters';
import { getSections } from './sections';
import {
	TOWNSQUARE_PRODUCT_ID,
	type TownsquareTabProps,
	useTownsquareSuppliers,
} from './townsquare-item-suppliers';
import { TownsquareScope } from './types';
import { isFedRamp } from '@atlassian/atl-context';
import FeatureGates from '@atlaskit/feature-gate-js-client';

const defaultPermissionsSupplier = () => {
	const response = Object.values(TownsquareScope);
	return Promise.resolve(response);
};

export const TownsquareTabWithIntlInner: React.FC<WrappedComponentProps & TownsquareTabProps> = ({
	intl,
	preQueryItemSupplier,
	postQueryItemSupplier,
	userTeamConfig,
	workspaces,
	atlasClientBaseUrlOverride,
	organizationId,
	...rest
}) => {
	const [isGlobalExperienceWorkspace, setGlobalExperienceWorkspace] = useState<boolean>();
	const { atlasWorkspaces } = useSearchConfigContext();
	if (!workspaces) {
		workspaces = atlasWorkspaces;
	}
	const siteFilterDefinition = useSiteFilterDefinition(workspaces, TOWNSQUARE_PRODUCT_ID);
	const {
		preQueryItemSupplier: preQueryItemSupplierWithPeopleAndTeams,
		postQueryItemSupplier: postQueryItemSupplierWithPeopleAndTeams,
		preQuerySectionTitleGenerator,
	} = useTownsquareSuppliers({
		userTeamConfig,
		preQueryItemSupplier,
		postQueryItemSupplier,
		workspaces,
		isGlobalExperienceWorkspace,
	});

	const { defineFilters } = useGenericFilters();

	defineFilters(TOWNSQUARE_PRODUCT_ID, siteFilterDefinition);

	useEffect(() => {
		isGlobalExperienceWorkspaceForCloudId(
			userTeamConfig?.context.siteId,
			atlasClientBaseUrlOverride,
		)
			.then((result) => {
				setGlobalExperienceWorkspace(result);
			})
			.catch(() => {
				setGlobalExperienceWorkspace(false);
			});
	}, [atlasClientBaseUrlOverride, userTeamConfig?.context.siteId]);

	let permissionSupplier =
		(workspaces && workspaces.length > 0) || isGlobalExperienceWorkspace
			? defaultPermissionsSupplier
			: rest.permissionSupplier;

	if (isGlobalExperienceWorkspace === undefined) {
		return null;
	}

	return (
		<SearchDialogProduct
			{...rest}
			id={TOWNSQUARE_PRODUCT_ID}
			expandedStateInputPlaceholder={intl.formatMessage(
				isGlobalExperienceWorkspace
					? messages.townsquare_search_input_expanded_placeholder
					: messages.atlas_search_input_expanded_placeholder,
			)}
			sections={getSections(
				intl,
				workspaces,
				isGlobalExperienceWorkspace,
				userTeamConfig?.context.organizationId,
			)}
			title={intl.formatMessage(
				isGlobalExperienceWorkspace
					? messages.townsquare_globex_tab_label
					: messages.townsquare_tab_label,
			)}
			preQuerySectionTitleGenerator={preQuerySectionTitleGenerator}
			preQueryItemSupplier={preQueryItemSupplierWithPeopleAndTeams}
			postQueryItemSupplier={postQueryItemSupplierWithPeopleAndTeams}
			workspaces={workspaces}
			permissionSupplier={permissionSupplier}
		>
			{() => {
				return {
					// Atlas (Townsquare) doesn't have an advanced search url so rendering no Footer
					Footer: () => <></>,
				};
			}}
		</SearchDialogProduct>
	);
};

export const TownsquareTabWithIntl: React.FC<WrappedComponentProps & TownsquareTabProps> = (
	props,
) => {
	if (
		FeatureGates.initializeCompleted() &&
		FeatureGates.checkGate('ignore_atlas_product_search_dialog_on_fedramp') &&
		isFedRamp()
	) {
		return null;
	}
	return <TownsquareTabWithIntlInner {...props} />;
};

export const TownsquareTab: FC<TownsquareTabProps> = injectIntl(TownsquareTabWithIntl);
