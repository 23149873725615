import React, { type PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { type BackendExperiment } from '@atlassian/search-experiment';
import { SearchDialogAnalyticsContext, useAnalytics } from '../../common/analytics';
import { type ABTest } from '../../common/clients';
import { onExperimentLoaded } from '../../common/analytics/events';
import {
	type GenericAggregatorClient,
	PLACEHOLDER_EXPERIENCE,
	useAggregatorClient,
} from '../aggregator-client-context';
import { useProductContext } from '../product-router/product-router';

import { useExperiments } from '../../common/experiment-provider';
import { PERFORMANCE_IMPROVEMENT_DUMMY_SECTION } from '../product-router/product/check-product-permissions';

export const DEFAULT_AB_TEST: ABTest = {
	abTestId: 'default',
	controlId: 'default',
	experimentId: 'default',
};

export interface ABTestContext {
	abTest: ABTest | null | BackendExperiment;
}

export interface AbTestAttribute {
	experience?: string;
}

const ABTestContext = React.createContext<ABTestContext>({
	abTest: null,
});

interface Props extends AbTestAttribute {
	isMultiProduct?: boolean;
}

interface ContextProps {
	searchClient?: GenericAggregatorClient;
	scope?: string;
	primaryProductId?: string;
}

const ABTestContextProviderStateless = ({
	isMultiProduct,
	searchClient,
	scope,
	children,
	primaryProductId,
	experience,
}: PropsWithChildren<Props & ContextProps>) => {
	const { backendExperiment, frontendExperiments = {} } = useExperiments();
	const [abTest, setAbTest] = useState<ABTest | null | BackendExperiment>(
		backendExperiment || null,
	);

	const { fireAnalyticsEvent } = useAnalytics();

	useEffect(() => {
		const now = Date.now();

		if (!searchClient || !scope || abTest) {
			return;
		}

		if (scope === PERFORMANCE_IMPROVEMENT_DUMMY_SECTION) {
			setAbTest(DEFAULT_AB_TEST);
			return;
		}

		searchClient
			// TODO: Remove PLACEHOLDER_EXPERIENCE
			.getAbTestData(scope, experience ?? PLACEHOLDER_EXPERIENCE, primaryProductId)
			.then((abTestResponse: ABTest | null) => {
				const duration = Date.now() - now;
				const abTestWithDefault = abTestResponse || DEFAULT_AB_TEST;
				setAbTest(abTestWithDefault);
				fireAnalyticsEvent(onExperimentLoaded(abTestWithDefault, duration, !!isMultiProduct));
			})
			.catch((e) => {
				setAbTest(DEFAULT_AB_TEST);
			});
	}, [
		searchClient,
		fireAnalyticsEvent,
		isMultiProduct,
		scope,
		abTest,
		primaryProductId,
		experience,
		backendExperiment,
	]);

	return (
		<ABTestContext.Provider value={{ abTest }}>
			<SearchDialogAnalyticsContext
				analyticContext={{
					abTest: abTest || undefined,
					frontendExperiments: {
						activeExperiments: Object.keys(frontendExperiments).filter(
							(experiment) => frontendExperiments[experiment]?.serveExperiment,
						),
						...frontendExperiments,
					},
				}}
				nonPrivacySafeAnalyticContext={{}}
			>
				{children}
			</SearchDialogAnalyticsContext>
		</ABTestContext.Provider>
	);
};

export const ABTestContextProvider = (props: PropsWithChildren<Props>) => {
	const { getPrimaryProduct } = useProductContext();
	const searchClient = useAggregatorClient();
	const primaryProduct = getPrimaryProduct();

	const { sectionIds } = primaryProduct || {};
	const scope = useMemo(
		() => (sectionIds && sectionIds.length > 0 ? sectionIds[0] : undefined),
		[sectionIds],
	);

	return (
		<ABTestContextProviderStateless
			{...props}
			scope={scope}
			searchClient={searchClient}
			primaryProductId={primaryProduct?.id}
		/>
	);
};

export const useABTest = () => {
	const { abTest } = useContext(ABTestContext);
	return abTest;
};

export const withABTestContext = <Props,>(
	WrappedComponent: React.ComponentType<Props & ABTestContext>,
): React.ComponentType<Props> => {
	return (props: Props) => <WrappedComponent {...props} {...useContext(ABTestContext)} />;
};
